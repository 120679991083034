/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy, Suspense} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, useAuth} from '../modules/auth'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const Auth = lazy(() => import('../modules/auth/AuthPage'))

const AppRoutes: FC = () => {
  const {auth} = useAuth()
  return (
    <BrowserRouter basename={'/'}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              {auth && auth.token ? (
                <>
                  <Route path='auth/*' element={<Auth />} />
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<Auth />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </Suspense>
    </BrowserRouter>
  )
}

export {AppRoutes}
