import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import TransactionsPage from "../modules/transactions/TransactionsPage";
import RequestsPage from "../modules/requests/RequestsPage";
import {OnboardingPage} from "../modules/onboarding/OnboardingPage";
import IntegrationPage from "../integrations/IntegrationPage";
import CustomersPage from "../modules/customers/CustomersPage";
import ProductsPage from "../modules/products/ProductsPage";
import TaxPage from "../modules/tax/TaxPage";
import SettingsPage from "../modules/settings/SettingsPage";
import ProPage from "../modules/pro/ProPage";
import AffiliatePage from "../modules/affiliate/AffiliatePage";
import DesignPage from "../modules/design/DesignPage";
import PersonalPage from "../modules/personal/PersonalPage";
import ExtraFieldsPage from "../modules/extrafields/ExtraFieldsPage";
import SubscriptionsPage from "../modules/subscriptions/SubscriptionsPage";
import InvoicesPage from "../modules/invoices/Invoicespage";

const PrivateRoutes = () => {

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}

                <Route path='dashboard' element={<DashboardWrapper />} />

                <Route path='transactions' element={
                    <SuspensedView>
                        <TransactionsPage />
                    </SuspensedView>
                } />
                <Route path='transaction/*' element={
                    <SuspensedView>
                        <TransactionsPage />
                    </SuspensedView>
                } />

                <Route path='subscriptions' element={
                    <SuspensedView>
                        <SubscriptionsPage />
                    </SuspensedView>
                } />
                <Route path='subscription/*' element={
                    <SuspensedView>
                        <SubscriptionsPage />
                    </SuspensedView>
                } />

                <Route path='customers' element={
                    <SuspensedView>
                        <CustomersPage />
                    </SuspensedView>
                } />
                <Route path='customer/*' element={
                    <SuspensedView>
                        <CustomersPage />
                    </SuspensedView>
                } />

                <Route path='requests' element={
                    <SuspensedView>
                        <RequestsPage />
                    </SuspensedView>
                } />
                <Route path='request/*' element={
                    <SuspensedView>
                        <RequestsPage />
                    </SuspensedView>
                } />

                <Route path='products' element={
                    <SuspensedView>
                        <ProductsPage />
                    </SuspensedView>
                } />
                <Route path='product/*' element={
                    <SuspensedView>
                        <ProductsPage />
                    </SuspensedView>
                } />

                <Route path='tax' element={
                    <SuspensedView>
                        <TaxPage />
                    </SuspensedView>
                } />
                <Route path='tax/*' element={
                    <SuspensedView>
                        <TaxPage />
                    </SuspensedView>
                } />

                <Route path='extrafields' element={
                    <SuspensedView>
                        <ExtraFieldsPage />
                    </SuspensedView>
                } />
                <Route path='extrafields/*' element={
                    <SuspensedView>
                        <ExtraFieldsPage />
                    </SuspensedView>
                } />

                <Route path='settings' element={
                    <SuspensedView>
                        <SettingsPage />
                    </SuspensedView>
                } />
                <Route path='settings/*' element={
                    <SuspensedView>
                        <SettingsPage />
                    </SuspensedView>
                } />
                <Route path='design' element={
                    <SuspensedView>
                        <DesignPage />
                    </SuspensedView>
                } />
                <Route path='personal' element={
                    <SuspensedView>
                        <PersonalPage />
                    </SuspensedView>
                } />

                <Route path='integrations/*' element={
                    <SuspensedView>
                        <IntegrationPage />
                    </SuspensedView>
                } />
                <Route path='integration/*' element={
                    <SuspensedView>
                        <IntegrationPage />
                    </SuspensedView>
                } />

                <Route path='pro' element={
                    <SuspensedView>
                        <ProPage />
                    </SuspensedView>
                } />
                <Route path='pro/*' element={
                    <SuspensedView>
                        <ProPage />
                    </SuspensedView>
                } />

                <Route path='affiliate' element={
                    <SuspensedView>
                        <AffiliatePage />
                    </SuspensedView>
                } />

                <Route path='invoices' element={
                    <SuspensedView>
                        <InvoicesPage />
                    </SuspensedView>
                } />

                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
            <Route path='welcome' element={ <OnboardingPage /> } />
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
