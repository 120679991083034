import {OverlayTrigger, Tooltip} from "react-bootstrap";
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import React, {Fragment} from "react";
import {Row, UseExpandedRowProps} from "react-table";
import {useIntl} from "react-intl";

interface iContent {
    prepareRow: Function,
    row: Row & UseExpandedRowProps<any>,
    rawdata: any[]
}
export const Transactions:React.FC<iContent> = ({prepareRow, row, rawdata}) => {
    const intl = useIntl()
    prepareRow(row)
    const raw = rawdata[parseInt(row.id)]
    return (
        <Fragment key={`transactionRow-${row.id}`}>
            <tr {...row.getRowProps()} key={`trans-main-${row.id}`}>
                {row.cells.map(cell => {
                    if(cell.column.Header==="Actions"){
                        return (<td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <OverlayTrigger
                                    key={"tooltip-note-"+row.id+"-"+cell.column.id}
                                    placement='top'
                                    overlay={<Tooltip id={"tooltip-note-item-"+row.id+"-"+cell.column.id}>
                                        {raw.customFields===false?
                                            (intl.formatMessage({id: 'TRANSACTIONS.NOCF', defaultMessage: 'No extra fields given'})) :
                                            (intl.formatMessage({id: 'TRANSACTIONS.SHOWCF', defaultMessage: 'Open/close extra fields'}))}
                                    </Tooltip>}
                                >
                                    {/*
                                                    @ts-ignore */}
                                    <a href="#" onClick={(e) => {e.preventDefault();if(raw.customFields!==false) row.toggleRowExpanded();}}
                                       className={clsx('btn btn-icon btn-bg-white btn-text-gray-500 btn-active-color-primary btn-sm me-1',raw.customFields===false?'disabled-with-tooltip':'')}>
                                        {/*
                                                    @ts-ignore */}
                                        {row.isExpanded?(<FontAwesomeIcon icon="fa-duotone fa-comment-minus" className='fs-3' />):(<FontAwesomeIcon icon="fa-duotone fa-comment-plus" className='fs-3' />)}
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key={"tooltip-show-"+row.id+"-"+cell.column.id}
                                    placement='top'
                                    overlay={<Tooltip id={"tooltip-show-item-"+row.id+"-"+cell.column.id}>
                                        {intl.formatMessage({id: 'TRANSACTIONS.OPENLINK', defaultMessage: 'Open payment or request'})}
                                    </Tooltip>}
                                >
                                    {/*
                                    @ts-ignore */}
                                    {row.original?._type !== "PERSONAL" ? (<Link
                                        //@ts-ignore
                                        to={row.original?.urlpath ?? '/'}
                                        className='btn btn-icon btn-bg-white btn-text-gray-500 btn-active-color-primary btn-sm me-1'
                                    >
                                        {/*
                                                        @ts-ignore*/}
                                        <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3' />
                                        {/*
                                        @ts-ignore*/}
                                    </Link>) : (row.original?.invoice?<a download href={`${process.env.REACT_APP_API_URL}/uploads/invoices/${row.original?.invoice}`} className='btn btn-icon btn-bg-white btn-text-gray-500 btn-active-color-primary btn-sm me-1'>{/*
                                                        @ts-ignore*/}
                                        <FontAwesomeIcon icon="fa-duotone fa-download" className='fs-3' />
                                    </a>:<span>&nbsp;</span>)}
                                </OverlayTrigger>
                            </div>
                        </td>)
                    }
                    return <td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>{cell.render('Cell')}</td>
                })}
            </tr>
            {raw.customFields!==false&&(
                <tr key={'hiddenrow'+row.id} className={clsx(row.isExpanded?'':'d-none')}>
                    <td role='cell' colSpan={5}>
                        {/*
                        @ts-ignore */}
                        {row.original.customFields}
                    </td>
                </tr>
            )}
        </Fragment>
    )
};