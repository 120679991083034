export const paymentMethodIcons: {[index: string]:any} = {
    "visa": "Visa-Logo.png",
    "mastercard": "mastercard-logo.png",
    "creditcard": "mastercard-logo.png",
    "maestro": "Maestro_2016.svg",
    "americanexpress": "amex.svg",
    "ideal": "ideal-logo.svg",
    "bancontact": "bancontact-new.svg",
    "paypal": "paypal-new.svg",
    "eps": "eps-new.svg",
    "giropay": "giropay-logo.png",
    "p24": "p24-new.svg",
    "sofort": "sofort-new.svg",
    "multibanco": "mb-new.svg",
    "applepay": "apple-pay-new.svg",
    "alipay":"alipay-new.svg",
    "trustly": "trustly-new.svg",
    "klarna": "klarna-new.svg",
    "wechat": "wechat-pay-logo.png",
    "sepa_debit": "sepa-new.svg"
};
export const paymentMethodDescriptions: {[index: string]:any} = {
    "visa": "Popular Payment Method used All Over The World",
    "mastercard": "Popular Payment Method used All Over The World",
    "maestro": "Popular Payment Method used All Over The World",
    "creditcard": "Popular Payment Method used All Over The World",
    "americanexpress": "Popular Payment Method used All Over The World",
    "ideal": "Popular Payment Method in The Netherlands",
    "bancontact": "Popular Payment Method in The Belgium",
    "paypal": "Popular Payment Method used All Over The World",
    "eps": "Popular Payment Method in Austria",
    "giropay": "Popular Payment Method in Germany",
    "p24": "Popular Payment Method in Poland",
    "sofort": "Popular Payment Method in Germany",
    "multibanco": "Popular Payment Method in Portugal",
    "applepay": "Popular Payment Method used All Over The World",
    "alipay":"Popular Payment Method used mostly in Asia",
    "trustly": "Popular Payment Method used All Over Europe",
    "klarna": "Popular Payment Method used All Over Europe",
    "wechat": "Popular Payment Method used in China",
    "sepa_debit": "Popular Payment Method used All Over Europe",
}