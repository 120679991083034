import React, {FC, useCallback, useEffect, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {PaymentLinkTypes, useAuth} from "../../auth";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router";
import {
    cancelPaymentLink,
    capturePaymentLink,
    deletePaymentLink,
    getPaymentLink,
    getPaymentLinkLogs,
    refundPaymentLink
} from "../core/_requests";
import {PaymentLink, RequestLog} from "../core/_models";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {QRCodeSVG} from 'qrcode.react';
import {downloadBlob, downloadDataUri} from "../../../../_metronic/helpers/svgDownload";
import {Canvg} from "canvg";
import {Link} from "react-router-dom";

import {getCustomFields, transactionType} from "../RequestHelper";
import {ShowIcon} from "./ShowIcon";
import {RequestStatus} from "./RequestStatus";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const transactionsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Requests',
        path: '/requests',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const LinkPage: FC = () => {
    const {config} = useLayout()
    const { link } = useParams()
    const intl = useIntl()
    const {currentUser} = useAuth()
    const MySwal = withReactContent(Swal)
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [paymentLink, setPaymentLink] = useState<PaymentLink>()
    const [paymentLogs, setPaymentLogs] = useState<Array<RequestLog>>()
    const [beatFade, setBeatFade] = useState(false)
    const [dataUpdate, setDataUpdate] = useState(0)

    config.toolbar.layout = 'request'


    useEffect(() => {
        if(typeof link !== 'undefined') {
            getPaymentLink(link).then(res => {
                if(typeof res === "boolean" || ("error" in res) )
                    return
                setPaymentLink(res)
                setLoadingData(false)
            })
            getPaymentLinkLogs(link).then(res => {
               if(res === null)
                   return
                setPaymentLogs(res)
            });
        }
    }, [dataUpdate]);

    const svgRef = useRef();
    const downloadSVG = useCallback(() => {
        // @ts-ignore
        const svg = svgRef.current.innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
        downloadBlob(blob, `${link}-qrcode.svg`);
    }, [])
    const downloadPNG = useCallback(async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if(!ctx)
            return;
        let v = null
        // @ts-ignore
        v = await Canvg.fromString(ctx, svgRef.current.innerHTML)
        v.start()
        const png = canvas.toDataURL("img/png")
        downloadDataUri(png, `${link}-qrcode.png`)
    } ,[])

    const triggerUpdate = () => setDataUpdate(dataUpdate+1)

    const processRefund = (refundAmount:number) => {
        if(typeof paymentLink === "undefined")
            return
        MySwal.fire({
            title: intl.formatMessage({
                id: 'SWAL.REFUND.REASON',
                defaultMessage: 'Enter the reason for your refund'
            }),
            input: 'textarea',
            inputPlaceholder: intl.formatMessage({
                id: 'SWAL.REFUND.REASON.PLACEHOLDER',
                defaultMessage: 'Enter the reason for your refund'
            }),
            preConfirm: (reason: string) => refundPaymentLink(paymentLink.id, refundAmount, reason).then(res => {
                if(res.status==='failed' && "error" in res){
                    Swal.showValidationMessage(
                        `Request failed: ${res.error}`
                    )
                }else{
                    triggerUpdate()
                    MySwal.fire({
                        title: intl.formatMessage({
                            id: 'SWAL.REFUND.SUCCESS',
                            defaultMessage: 'Refund request sent successfully'
                        }),
                        icon: 'success'
                    })
                }
            }).catch((err:any) => {
                Swal.showValidationMessage(
                    `Request failed: Unknown error`
                )})
        })
    }
    const refundSwal = () => {
        if(typeof paymentLink === "undefined")
            return
        MySwal.fire({
            title: intl.formatMessage({id: 'SWAL.REFUND.CONFIRM', defaultMessage: 'Do you want to refund partially or fully?'}),
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'SWAL.REFUND.PARTIALLY', defaultMessage: 'Partially'}),
            denyButtonText: intl.formatMessage({id: 'SWAL.REFUND.FULLY', defaultMessage: 'Fully'}),
            denyButtonColor: '#009ef7'
        }).then(async (result) => {
            if(result.isConfirmed){
                const {value:refundAmount} = await MySwal.fire({
                    title: intl.formatMessage({id: 'SWAL.REFUND.AMOUNT', defaultMessage: 'Enter the amount to refund'}),
                    input: 'number',
                    inputAttributes: {
                        min: "1",
                        max: (paymentLink.amount).toString(),
                    },
                    inputPlaceholder: intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.PLACEHOLDER', defaultMessage: 'Enter the amount to refund'}),
                    inputValidator: (value) => new Promise((resolve) => {
                        if(!value || typeof value === "boolean")
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.REQUIRED', defaultMessage: 'Amount is required'}))
                        else if(parseFloat(value) > paymentLink.amount)
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.MAX', defaultMessage: 'Amount must be less than or equal to {amount}'}, {amount: paymentLink.amount}))
                        else if(parseFloat(value) < 1)
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.MIN', defaultMessage: 'Amount must be greater than or equal to 1'}))
                        else
                        { // @ts-ignore
                            resolve()
                        }
                    })
                })
                if(refundAmount)
                    processRefund(refundAmount)
            } else if (result.isDenied)
                processRefund(paymentLink.amount)
        });
    }
    const processCapture = (captureAmount:number) => {
        if(typeof paymentLink === "undefined")
            return
        capturePaymentLink(paymentLink.id, captureAmount*100).then(res => {
            if(res.status==='failed' && "error" in res){
                Swal.showValidationMessage(
                    `Request failed: ${res.error}`
                )
            }else{
                triggerUpdate()
                MySwal.fire({
                    title: intl.formatMessage({
                        id: 'SWAL.CAPTURE.SUCCESS',
                        defaultMessage: 'Request captured successfully'
                    }),
                    icon: 'success'
                })
            }
        }).catch((err:any) => {
            Swal.showValidationMessage(
                `Request failed: Unknown error`
            )})
    }
    const captureSwal = () => {
        if(typeof paymentLink === "undefined")
            return
        MySwal.fire({
            title: intl.formatMessage({id: 'SWAL.CAPTURE.CONFIRM', defaultMessage: 'Do you want to capture partially or fully?'}),
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'SWAL.REFUND.PARTIALLY', defaultMessage: 'Partially'}),
            denyButtonText: intl.formatMessage({id: 'SWAL.REFUND.FULLY', defaultMessage: 'Fully'}),
            denyButtonColor: '#009ef7'
        }).then(async (result) => {
            if(result.isConfirmed){
                const {value:captureAmount} = await MySwal.fire({
                    title: intl.formatMessage({id: 'SWAL.CAPTURE.AMOUNT', defaultMessage: 'Enter the amount to capture'}),
                    input: 'number',
                    inputAttributes: {
                        min: "1",
                        max: (paymentLink.amount).toString(),
                    },
                    inputPlaceholder: intl.formatMessage({id: 'SWAL.CAPTURE.AMOUNT.PLACEHOLDER', defaultMessage: 'Enter the amount to capture'}),
                    inputValidator: (value) => new Promise((resolve) => {
                        if(!value || typeof value === "boolean")
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.REQUIRED', defaultMessage: 'Amount is required'}))
                        else if(parseFloat(value) > paymentLink.amount)
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.MAX', defaultMessage: 'Amount must be less than or equal to {amount}'}, {amount: paymentLink.amount}))
                        else if(parseFloat(value) < 1)
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.MIN', defaultMessage: 'Amount must be greater than or equal to 1'}))
                        else
                        { // @ts-ignore
                            resolve()
                        }
                    })
                })
                if(captureAmount)
                    processCapture(captureAmount)
            } else if (result.isDenied)
                processCapture(paymentLink.amount)
        });
    }
    const cancelSwal = () => {
        if(typeof paymentLink === "undefined")
            return
        MySwal.fire({
            title: intl.formatMessage({id: 'SWAL.CANCEL.CONFIRM', defaultMessage: 'Are you sure you want to release the pre authorisation?'}),
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'SWAL.CANCEL.PARTIALLY', defaultMessage: 'Yes'}),
            preConfirm: () => {
                return cancelPaymentLink(paymentLink.id).then(res => {
                    if(res.status==='failed' && "error" in res){
                        Swal.showValidationMessage(
                            `Request failed: ${res.error}`
                        )
                    }else{
                        triggerUpdate()
                        MySwal.fire({
                            title: intl.formatMessage({
                                id: 'SWAL.CANCEL.SUCCESS',
                                defaultMessage: 'Authorisation released successfully'
                            }),
                            icon: 'success'
                        })
                    }
                }).catch((err:any) => {
                    Swal.showValidationMessage(
                        `Request failed: Unknown error`
                    )})
            }
        });
    }
    const deleteSwal = () => {
        if(typeof paymentLink === "undefined")
            return
        MySwal.fire({
            title: intl.formatMessage({id: 'SWAL.DELETE.CONFIRM', defaultMessage: 'Are you sure you want to cancel the request?'}),
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'SWAL.CANCEL.PARTIALLY', defaultMessage: 'Yes'}),
            preConfirm: () => {
                return deletePaymentLink(paymentLink.id).then(res => {
                    if(res.status==='failed' && "error" in res){
                        Swal.showValidationMessage(
                            `Request failed: ${res.error}`
                        )
                    }else{
                        triggerUpdate()
                        MySwal.fire({
                            title: intl.formatMessage({
                                id: 'SWAL.DELETE.SUCCESS',
                                defaultMessage: 'Request cancelled successfully'
                            }),
                            icon: 'success'
                        })
                    }
                }).catch((err:any) => {
                    Swal.showValidationMessage(
                        `Request failed: Unknown error`
                    )})
            }
        });
    }
    const CheckRefundable = () => {
        if(typeof paymentLink?.capturedOn === "undefined" || typeof paymentLink?.captureId === "undefined" || paymentLink?.capturedOn === null)
            return <></>
        const maxRefundDate = new Date()
        const capturedOn = new Date(paymentLink?.capturedOn)
        maxRefundDate.setDate(maxRefundDate.getDate() - (paymentLink?.captureId?.substring(0,3)==="pi_"?7:29))
        if(capturedOn > maxRefundDate)
            return <div className='mb-5 btn btn-light-warning w-100 btn-sm' onClick={refundSwal}>Refund Payment</div>
        return <></>
    }

    return (
        <>
            <PageTitle breadcrumbs={transactionsBreadCrumbs}>{`Link #${paymentLink?.urlpath ?? link}`}</PageTitle>
            <PageToolbar>
                {paymentLink?.status !== "CANCELLED" && (<>
                    {paymentLink?.status === "CAPTURE" && (<div className='mb-5 btn btn-light-success w-100 btn-sm' onClick={captureSwal}>Capture</div>)}

                    {paymentLink?.status === "CAPTURE" && (<div className='mb-105 btn btn-light-primary w-100 btn-sm' onClick={cancelSwal}>Release Authorization</div>)}

                    {paymentLink?.status === "PAID" && (<div className='mb-5 btn btn-light-warning w-100 btn-sm' onClick={refundSwal}>Refund Payment</div>)}

                    {paymentLink?.status === "CAPTURED" && (<CheckRefundable />)}

                    <div className='btn btn-light-danger w-100 btn-sm' onClick={deleteSwal}>Cancel Request</div>
                </>)}
            </PageToolbar>
            <div className="row g-5">
                <div className="col-md-8 col-12">
                    <div className='card mb-10 mb-xl-10'>
                        <div className='card-header border-0 py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Payment Details</span>
                                <span className='text-muted fw-bold fs-7'>General information about your request</span>
                            </h3>
                        </div>
                        <div className='card-body py-3'>
                            {/* fill 2 columns with multiple rows of information [title]: [value] */}
                            <div className='row'>
                                <div className='col-md-6 col-12'>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.TITLE', defaultMessage: 'Title'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>{paymentLink?.title ?? <Skeleton width={80} height={14}/> }</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>ID</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                <OverlayTrigger
                                                    key={"tooltip-id"}
                                                    placement='bottom'
                                                    overlay={<Tooltip id={"tooltip-id-copy"}>Click to copy the link</Tooltip>}
                                                >
                                                    <CopyToClipboard text={paymentLink?.directlink ?? ""}
                                                    onCopy={() => {
                                                        setBeatFade(true);
                                                        window.setTimeout(()=>setBeatFade(false),2000)
                                                    }}>
                                                        <span>
                                                            {paymentLink?.urlpath ?? <Skeleton width={40} height={14} /> }
                                                            {/*
                                                            @ts-ignore */}
                                                            {paymentLink?.urlpath? (<FontAwesomeIcon beatFade={beatFade} icon="fa-duotone fa-copy" className='fs-3 ms-3 text-secondary' />):null}
                                                        </span>
                                                    </CopyToClipboard>
                                                </OverlayTrigger>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.REMIND', defaultMessage: 'Reminders'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {paymentLink?.remindMail? "Yes" : "No"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.TYPE', defaultMessage: 'Type'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                <span className="badge badge-light-primary">
                                                    {transactionType(intl, paymentLink?.type)}
                                                  </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.CREATED', defaultMessage: 'Created At'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                <span>
                                                    {intl.formatDate(paymentLink?.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                </span>
                                                <span className='text-muted d-block fs-7'>
                                                    {intl.formatTime(paymentLink?.createdAt,{hour12:false})}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.CURRENCY', defaultMessage: 'Currency'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof paymentLink?.amount !== "undefined"? paymentLink?.currency ?? currentUser?.currency : <Skeleton width={40} height={14}/>}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.PRICE', defaultMessage: 'Price'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof paymentLink?.amount !== "undefined"? <CurrencyFormat className='ms-1' currency={paymentLink?.currency ?? currentUser?.currency} amount={paymentLink?.amount}/>:<Skeleton width={70} height={14}/> }
                                            </span>
                                        </div>
                                    </div>
                                    {(typeof paymentLink?.fee !== "undefined"&&paymentLink?.fee>0)&&<div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.FEE', defaultMessage: 'Fee'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof paymentLink?.fee !== "undefined"? <CurrencyFormat className='ms-1' currency={paymentLink?.currency ?? currentUser?.currency} amount={paymentLink?.fee}/>:<Skeleton width={70} height={14}/> }
                                            </span>
                                        </div>
                                    </div>}
                                    {(typeof paymentLink?.tax !== "undefined"&&paymentLink?.tax!==null)&&<div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'REQUESTS.TAX', defaultMessage: 'Tax'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <Link to={`/tax/${paymentLink?.tax?.id}`} className='form-control-plaintext pt-0 mb-2'>
                                                {paymentLink?.tax?.name} ({paymentLink?.tax.rates.length} {paymentLink?.tax.rates.length===1?'Rate':'Rates'}
                                            </Link>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card mb-10 mb-xl-10'>
                        <div className='card-header border-0 py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Payments</span>
                            </h3>
                        </div>
                        <div className='card-body pt-3 pb-10'>
                            {typeof paymentLink?.transactions !== "undefined"?(
                                (paymentLink?.transactions == null || paymentLink?.transactions.length < 1)?
                                    <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                                        <span className="svg-icon svg-icon-2hx svg-icon-info me-3">
                                            {/*
                                            @ts-ignore */}
                                            <FontAwesomeIcon icon="fa-duotone fa-face-sad-cry" />
                                        </span>
                                        <div className="d-flex flex-column"><h5 className="mb-1">No payments found!</h5>
                                            <span>Sorry to bring the bad news, you don't have any payments yet on this request.</span>
                                        </div>
                                    </div> :
                                    <div className="table-responsive">
                                        <table className="table table-striped gy-3 gs-3">
                                            <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                                <th className="min-w-200px">Customer</th>
                                                <th className="min-w-100px">Amount</th>
                                                <th className="min-w-150px">Paid on</th>
                                                <th className="min-w-80px">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {paymentLink.transactions.map((transaction, index) => (
                                                <>
                                                <tr>
                                                    <td>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <Link to={typeof transaction.customer === "undefined"?'#':`/customer/${transaction.customer?.id}`} className='text-dark fw-bolder text-hover-primary fs-6'>
                                                                {transaction.customer?.name ?? transaction.name}
                                                            </Link>
                                                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                                                                {transaction.customer?.email ?? transaction.email}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                          <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                                                        </span>
                                                        {typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && transaction.currency !== null && transaction.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                                                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                                                                <CurrencyFormat currency={currentUser.currency} amount={transaction.amountBase}/>
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                            {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                        </span>
                                                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                                                            {intl.formatTime(transaction.createdAt,{hour12:false})}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            {transaction?.invoice && (<a download
                                                                href={`${process.env.REACT_APP_API_URL}/uploads/invoices/${transaction?.invoice?.file}`} target="_blank"
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' >
                                                                {/*
                                                                @ts-ignore*/}
                                                                <FontAwesomeIcon icon="fa-duotone fa-file-invoice" className='fs-3' />
                                                            </a>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                                    {Object.keys(getCustomFields(transaction)).length>0 && (<tr>
                                                        <td colSpan={4}>
                                                            <h5 className='fw-bold fs-6'>
                                                                Custom Fields:
                                                            </h5>
                                                            <div>
                                                                {Object.values(Object.fromEntries(Object.entries(getCustomFields(transaction)).map(([customFieldKey, customField]) => [
                                                                    customFieldKey,
                                                                    (typeof customField === "object" && customField !== null)?
                                                                        Object.values(Object.fromEntries(Object.entries(customField).map(([subFieldKey, subField]) => [subFieldKey,(<div className='py-2'><strong>{subFieldKey[0].toUpperCase() + subFieldKey.substring(1).toLowerCase()}: </strong>{subField}</div>)])))
                                                                        :
                                                                        (<div className='py-2'><strong>{customFieldKey[0].toUpperCase() + customFieldKey.substring(1).toLowerCase()}: </strong>{customField}</div>)
                                                                ])))}
                                                            </div>
                                                        </td>
                                                    </tr>)}
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ):(
                                    <Skeleton count={5} height={40} width={"100%"}/>
                            )}
                        </div>
                    </div>
                    <div className='card mb-10 mb-xl-10'>
                        <div className='card-header border-0 py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Events</span>
                            </h3>
                        </div>
                        <div className='card-body py-3 card-scroll h-500px'>
                            {paymentLogs?(
                                <>
                                    <table className="table table-striped gy-3 gs-3">
                                        <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                                <th className="min-w-50px">Type</th>
                                                <th className="min-w-100px">Title</th>
                                                <th className="min-w-150px">Created at</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentLogs.sort((a,b)=>{
                                                if(a.createdAt < b.createdAt)
                                                    return 1
                                                else if(a.createdAt > b.createdAt)
                                                    return -1
                                                return 0
                                            }).map((paymentLog, index) => (
                                                <tr>
                                                    <td className='text-center'>
                                                        <ShowIcon type={paymentLog.type}/>
                                                    </td>
                                                    <td dangerouslySetInnerHTML={{__html: paymentLog.title}}></td>
                                                    <td>
                                                        <span className='form-control-plaintext pt-0 mb-2'>
                                                <span>
                                                    {intl.formatDate(paymentLog?.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                </span>
                                                <span className='text-muted d-block fs-7'>
                                                    {intl.formatTime(paymentLog?.createdAt,{hour12:false})}
                                                </span>
                                            </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            ):<Skeleton count={5} height={40} width={"100%"}/>}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div data-kt-sticky="true" data-kt-sticky-width={true}>
                        <RequestStatus status={paymentLink?.status}/>
                        <div className='card mb-5 mb-xl-10'>
                            {paymentLink?.type!==PaymentLinkTypes.DONATION&&<div className='card-header border-0 py-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Customer</span>
                                    <span className='text-muted fw-bold fs-7'>Who did you send it to?</span>
                                </h3>
                            </div>}
                            <div className='card-body py-3'>
                                {/* show avatar on the left, name and mail next to it*/}
                                {paymentLink?.type!==PaymentLinkTypes.DONATION&&<>
                                    <div className='row'>
                                        <div className='col-md-3 col-12'>
                                            <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                                {paymentLink?.customer?.avatar?<img src={paymentLink?.customer?.avatar} alt={paymentLink?.customer?.name} />:<Skeleton width={40} height={40} />}
                                            </div>
                                        </div>
                                        <div className='col-md-9 col-12'>
                                            {paymentLink?.customer?.name ?? <Skeleton width={100} height={18} />}
                                            <span className='d-block text-muted fw-bold fs-7'>{paymentLink?.customer?.email ?? <Skeleton width={120} height={14}/> }</span>
                                        </div>
                                    </div>
                                    <div className='row mt-5 pt-2'>
                                        <div className='col-12'>
                                            <Link to={`/customer/${paymentLink?.customer?.id}`} className='btn btn-outline-primary btn-active-light-primary btn-outline btn-block w-100'>Open Customer</Link>
                                        </div>
                                    </div>
                                    <div className="separator separator-dashed border-secondary my-10"></div>
                                </>}
                                <h3 className=' fw-bolder fs-3 mb-4'>
                                    QR Code
                                </h3>
                                {/* @ts-ignore */}
                                <div ref={svgRef}>
                                    {paymentLink?.directlink?<QRCodeSVG fgColor={'#1954bf'} value={paymentLink?.directlink} size={200} />:<Skeleton width={200} height={200} />}
                                </div>
                                <div className="mb-10">
                                    <button
                                        type='button'
                                        className='btn btn-light-primary mt-4 btn-sm'
                                        id="svgDownloadButton"
                                        data-kt-menu-trigger='hover'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='bottom'
                                    >
                                        {/*
                                @ts-ignore */}
                                        <FontAwesomeIcon icon="fa-duotone fa-download" className="me-2" />
                                        Download QR Code
                                    </button>

                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-active-bg fw-semibold py-3" data-kt-menu="true" style={{maxWidth: 200}}>
                                        <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                            <a href="javascript:void(0);" onClick={downloadSVG}
                                               className="menu-link px-3">
                                                <span className="menu-title">SVG</span>
                                            </a>
                                        </div>
                                        <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                            <a href="javascript:void(0);" onClick={downloadPNG}
                                               className="menu-link px-3">
                                                <span className="menu-title">PNG</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {LinkPage}