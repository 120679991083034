/* eslint-disable jsx-a11y/anchor-is-valid */
import {Navigate, useNavigate} from 'react-router-dom'
import {getUserByToken, refreshToken, validate} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useParams} from "react-router"
import {useEffect} from "react"
import {useIntl} from "react-intl"
import {getAuth} from "../core/AuthHelpers";
import { Crisp } from "crisp-sdk-web";
import * as authHelper from "../core/AuthHelpers";

export function Validate() {
  const intl = useIntl()
  const {saveAuth, setCurrentUser,logout} = useAuth()
  const {email,expires,hash} = useParams()
  let tokenRefresher: NodeJS.Timeout|undefined = undefined;
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `PayRequest.io | ${intl.formatMessage({id: 'GENERAL.WAIT', defaultMessage: 'Please wait...'})}`
    if(typeof email !=='undefined'&&typeof expires !=='undefined'&&typeof hash !=='undefined') {
      validate(email, parseInt(expires), hash).then(async auth => {
        if(typeof auth !== "boolean" && typeof auth !== "boolean"){
          saveAuth(auth.data)
          const user = await getUserByToken(auth.data)
          if(typeof user !== "boolean") {
            setCurrentUser(user)
            if(typeof user !== "boolean" && user?.email?.length > 1) {
              if (user?.email)
                Crisp.user.setEmail(user.email)
              if (user?.name)
                Crisp.user.setNickname(user.name)
              /*Crisp.session.setData({
                email: user?.email ?? '',
                userId: user?.id?.toString() ?? '',
                name: user?.name ?? '',
                customAttributes: {
                  psp: user?.psp ?? '',
                  slug: user?.slug ?? '',
                  company_name: user?.companyName ?? '',
                  company_mail: user?.companyMail ?? ''
                }
              })*/
            }
            const setTokenRefresher = () => tokenRefresher = setInterval(() => {
              if(typeof auth === "undefined")
                return;
              const newAuth = getAuth()
              if(typeof newAuth === "undefined") {
                logout()
                return;
              }
              refreshToken(newAuth).then(resp => {
                console.log(resp)
                if(!("error" in resp)){
                  authHelper.setAuth({
                    token: resp.token,
                    refresh_token: resp.refresh_token,
                    api_token: ''
                  })
                }else if(resp.status === 401){
                  logout()
                }
              }).catch(e=>logout())
            }, 60000)
            setTokenRefresher()
            if(user.onboarding===0)
              navigate('/welcome', {replace: true})
            else
              navigate('/dashboard', {replace: true})
          }else
            navigate('/auth/login', {replace: true})
        }else{
          navigate('/auth/login', {replace: true})
        }
      }).catch(err => {
        navigate('/auth/login', {replace: true})
      })
    }
  },[])

  return (
      <h3 data-kt-indicator="on" className="d-flex justify-content-center">
        <span className="indicator-label">
            {intl.formatMessage({id: 'GENERAL.WAIT', defaultMessage: 'Please wait...'})}
        </span>
        <span className="indicator-progress">
            {intl.formatMessage({id: 'GENERAL.WAIT', defaultMessage: 'Please wait...'})} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </h3>
  )
}
