import axios from 'axios'
import {ErrorMessage, StatusMessage} from "../../requests/core/_models";
import {CustomFieldGroups, Subscription} from "../../auth";
import {ItemType} from "./_models";

const API_URL = process.env.REACT_APP_API_URL

export const GROUPS_API = `${API_URL}/api/extrafieldgroups`
export const GROUP_UPDATE_API = `${API_URL}/api/extrafieldgroup/update`
export const GROUP_DELETE_API = `${API_URL}/api/extrafieldgroup/delete`
export const GROUP_DUP_API = `${API_URL}/api/extrafieldgroup/duplicate`
export const GET_FIELDS = `${API_URL}/api/fields`

export const controller = new AbortController();


export function getExtraFieldGroups(): Promise<Array<CustomFieldGroups>|ErrorMessage> {
    return axios.get(`${GROUPS_API}`, {signal: controller.signal})
        .then(response => response.data as Array<CustomFieldGroups>)
        .catch(err => err.response.data as ErrorMessage)
}
export function tableExtraFieldGroups(pageIndex:number,pageSize:number,localFilter:Array<any>, globalFilter:string, sortBy:Array<any>): Promise<{ results: Array<CustomFieldGroups>; count: any }|ErrorMessage> {
    return axios.post(`${GROUPS_API}/table`,{pageIndex, pageSize, localFilter, globalFilter, sortBy})
        .then(response => response.data as unknown as { results: Array<CustomFieldGroups>; count: any })
        .catch(err => err.response.data as ErrorMessage)
}

export function getExtraFields(id:number): Promise<Array<ItemType>|ErrorMessage> {
    return axios.get(`${GET_FIELDS}/${id}`, {signal: controller.signal})
        .then(response => response.data as Array<ItemType>)
        .catch(err => err.response.data as ErrorMessage)
}

export function updateOrAddGroup(id:number|string,enabled:boolean,name:string,fields:any[]): Promise<StatusMessage|ErrorMessage> {
    return axios.post(`${GROUP_UPDATE_API}`,{id,enabled,name,fields}, {signal: controller.signal})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function setGroupStatus(id:number,enabled:boolean): Promise<StatusMessage|ErrorMessage> {
    return axios.post(`${GROUP_UPDATE_API}`,{id,enabled}, {signal: controller.signal})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function deleteGroup(id:number): Promise<StatusMessage|ErrorMessage> {
    return axios.post(`${GROUP_DELETE_API}`,{id}, {signal: controller.signal})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function duplicateGroup(id:number): Promise<StatusMessage|ErrorMessage> {
    return axios.post(`${GROUP_DUP_API}`,{id}, {signal: controller.signal})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}