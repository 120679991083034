/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type LogIconProps = {
    type: string
}
const ShowIcon: FC<LogIconProps> = ({type}) => {
    // switch types and return fontawesome icons for it
    switch (type) {
        case 'bell-1':
            // @ts-ignore
            return <span className="d-inline-block bg-secondary rounded-circle w-45px h-45px"><FontAwesomeIcon icon='fa-duotone fa-bells' className="fs-2 mt-1 p-3 text-white" /></span>
        case 'check-mark':
            // @ts-ignore
            return <span className="d-inline-block bg-success rounded-circle w-45px h-45px"><FontAwesomeIcon icon='fa-duotone fa-check' className="fs-2 mt-1 p-3 text-white" /></span>
        case 'close-cross':
            // @ts-ignore
            return <span className="d-inline-block bg-danger rounded-circle w-45px h-45px"><FontAwesomeIcon icon='fa-duotone fa-xmark' className="fs-2 mt-1 p-3 text-white" /></span>
        case 'notification':
            // @ts-ignore
            return <span className="d-inline-block bg-warning rounded-circle w-45px h-45px"><FontAwesomeIcon icon='fa-duotone fa-exclamation' className="fs-2 mt-1 p-3 text-white" /></span>
        case 'open-box':
            // @ts-ignore
            return <span className="d-inline-block bg-primary rounded-circle w-45px h-45px"><FontAwesomeIcon icon='fa-duotone fa-eye' className="fs-2 mt-1 p-3 text-white" /></span>
        default:
            return <></>
    }
}

export { ShowIcon };