import {OverlayTrigger, Tooltip} from "react-bootstrap";
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {Row, UseExpandedRowProps} from "react-table";
import {useIntl} from "react-intl";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {doDuplicateProduct, statusChangeProduct} from "../../../../../app/modules/products/core/_requests";
import {KTSVG} from "../../../../helpers";
import {doDuplicateTax, statusChangeTax} from "../../../../../app/modules/tax/core/_requests";

interface iContent {
    prepareRow: Function,
    row: Row & UseExpandedRowProps<any>,
    rawdata: any[],
    reloadTrigger?: Function
}
export const Taxes:React.FC<iContent> = ({prepareRow, row, rawdata, reloadTrigger}) => {
    const intl = useIntl()
    prepareRow(row)
    const navigate = useNavigate();
    const raw = rawdata[parseInt(row.id)]
    const MySwal = withReactContent(Swal)
    const disableTax = (taxid:number) => {
        // verify if user really wants to disable tax, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'TAX.CONFIRMDISABLE', defaultMessage: 'Are you sure you want to disable this tax?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeTax(taxid, 'DISABLED').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.DISABLESUCCESS', defaultMessage: 'Tax disabled successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const enableTax = (taxid:number) => {
        // verify if user really wants to enable tax, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'TAX.CONFIRMENABLE', defaultMessage: 'Are you sure you want to enable this tax?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeTax(taxid, 'ENABLED').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.ENABLESUCCESS', defaultMessage: 'Tax enabled successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const deleteTax = (taxid:number) => {
        // verify if user really wants to enable tax, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'TAX.CONFIRMDELETE', defaultMessage: 'Are you sure you want to delete this tax?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeTax(taxid, 'REALDELETE').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.DELETED', defaultMessage: 'Tax deleted successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const duplicateTax = (taxid:number) => {
        // verify if user really wants to enable tax, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'TAX.CONFIRMDUPLICATE', defaultMessage: 'Are you sure you want to duplicate this tax?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                doDuplicateTax(taxid).then((message) => {
                    if("id" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.DUPLICATESUCCESS', defaultMessage: 'Tax is duplicated successfully'}),
                            html: 'You will be redirected to the tax page',
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000,
                            didClose: () => navigate('/tax/'+message?.id,{replace: true})
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else{
                        MySwal.fire({
                            title: intl.formatMessage({id: 'TAX.ERROR', defaultMessage: 'Something went wrong!'}),
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    return (
        <tr {...row.getRowProps()} key={`row${row.id}`}>
            {row.cells.map(cell => {
                if(cell.column.Header==="Actions"){
                    return (<td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* begin::Menu */}
                            <button type="button"
                                    className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                            >
                                Actions
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='ms-3 me-0'
                                />
                            </button>

                            {/* begin::SubMenu */}
                            <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4' data-kt-menu='true'>

                                {/* begin::Content */}
                                <div className="menu-item px-3">
                                    {/*
                                        @ts-ignore */}
                                    <Link to={`/tax/${row.original?.id??''}`}
                                          className="menu-link px-3 text-start">Edit</Link>
                                    {/*
                                        @ts-ignore */}
                                    {row.original?.status && <div className="menu-link px-3 text-start" onClick={event => disableTax(row.original?.id)}>Disable</div>}
                                    {/*
                                        @ts-ignore */}
                                    {!row.original?.status && <div className="menu-link px-3 text-start" onClick={event => enableTax(row.original?.id)}>Enable</div>}
                                    {/*
                                        @ts-ignore */}
                                    <div className="menu-link px-3 text-start" onClick={event=>duplicateTax(row.original?.id)}>Duplicate</div>
                                    {/*
                                        @ts-ignore */}
                                    <div className="menu-link px-3 text-start" onClick={event => deleteTax(row.original?.id)}>Delete</div>
                                </div>
                                {/* end::Content */}
                            </div>
                            {/* end::SubMenu */}
                            {/* end::Menu */}
                        </div>
                    </td>)
                }
                return <td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>{cell.render('Cell')}</td>
            })}
        </tr>
    )
};