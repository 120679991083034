import {OverlayTrigger, Tooltip} from "react-bootstrap";
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Row, UseExpandedRowProps} from "react-table";
import {useIntl} from "react-intl";
import {KTSVG} from "../../../../helpers";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {doDuplicateProduct, statusChangeProduct} from "../../../../../app/modules/products/core/_requests";
import CopyToClipboard from "react-copy-to-clipboard";
import Skeleton from "react-loading-skeleton";

interface iContent {
    prepareRow: Function,
    row: Row & UseExpandedRowProps<any>,
    rawdata: any[],
    reloadTrigger?: Function
}
export const Products:React.FC<iContent> = ({prepareRow, row, rawdata, reloadTrigger}) => {
    const intl = useIntl()
    prepareRow(row)
    const navigate = useNavigate();
    const raw = rawdata[parseInt(row.id)]
    const MySwal = withReactContent(Swal)

    const disableProduct = (productid:number) => {
        // verify if user really wants to disable product, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'PRODUCTS.CONFIRMDISABLE', defaultMessage: 'Are you sure you want to disable this product?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeProduct(productid, 'DISABLED').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.DISABLESUCCESS', defaultMessage: 'Product disabled successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const enableProduct = (productid:number) => {
        // verify if user really wants to enable product, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'PRODUCTS.CONFIRMENABLE', defaultMessage: 'Are you sure you want to enable this product?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeProduct(productid, 'ENABLED').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ENABLESUCCESS', defaultMessage: 'Product enabled successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const conceptProduct = (productid:number) => {
        // verify if user really wants to enable product, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'PRODUCTS.CONFIRMCONCEPT', defaultMessage: 'Are you sure you want to move this product to concept?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeProduct(productid, 'CONCEPT').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ENABLECONCEPT', defaultMessage: 'Product moved to concept successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const deleteProduct = (productid:number) => {
        // verify if user really wants to enable product, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'PRODUCTS.CONFIRMDELETE', defaultMessage: 'Are you sure you want to delete this product?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                statusChangeProduct(productid, 'REALDELETE').then((message) => {
                    if(typeof reloadTrigger!=="undefined")
                        reloadTrigger()
                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.DELETED', defaultMessage: 'Product deleted successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    const duplicateProduct = (productid:number) => {
        // verify if user really wants to enable product, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'PRODUCTS.CONFIRMDUPLICATE', defaultMessage: 'Are you sure you want to duplicate this product?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                doDuplicateProduct(productid).then((message) => {
                    if("id" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.DUPLICATESUCCESS', defaultMessage: 'Product is duplicated successfully'}),
                            html: 'You will be redirected to the product page',
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000,
                            didClose: () => navigate('/products/'+message?.id,{replace: true})
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else{
                        MySwal.fire({
                            title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    return (
            <tr {...row.getRowProps()} key={`row${row.id}`}>
                {row.cells.map(cell => {
                    if(cell.column.Header==="Actions"){
                        return (<td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                {/* begin::Menu */}
                                <button type="button"
                                        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                >
                                    Actions
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr072.svg'
                                        className='ms-3 me-0'
                                    />
                                </button>

                                {/* begin::SubMenu */}
                                <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4' data-kt-menu='true'>

                                    {/* begin::Content */}
                                    <div className="menu-item px-3">
                                        {/*
                                        @ts-ignore */}
                                        <CopyToClipboard text={`${process.env.REACT_APP_PERSONAL_URL}/${row.original?.slug}`} onCopy={() => MySwal.fire({
                                            title: intl.formatMessage({id: 'PRODUCT.COPIED', defaultMessage: 'Copied!'}),
                                            icon: 'success',
                                            showConfirmButton: false,
                                            didOpen: () => MySwal.showLoading(),
                                            timer: 2000
                                        })}>
                                            <div className={`menu-link px-3 text-start`}>Copy direct link</div>
                                        </CopyToClipboard>
                                        {/*
                                        @ts-ignore */}
                                        <Link to={`/product/${row.original?.id??''}`}
                                              className="menu-link px-3 text-start">Edit</Link>
                                        {/*
                                        @ts-ignore */}
                                        {row.original?.fstatus !== 'DELETED' && <div className="menu-link px-3 text-start" onClick={event => disableProduct(row.original?.id)}>Disable</div>}
                                        {/*
                                        @ts-ignore */}
                                        {row.original?.fstatus !== 'ENABLED' && <div className="menu-link px-3 text-start" onClick={event => enableProduct(row.original?.id)}>Enable</div>}
                                        {/*
                                        @ts-ignore */}
                                        {row.original?.fstatus !== 'CONCEPT' && <div className="menu-link px-3 text-start" onClick={event => conceptProduct(row.original?.id)}>To concept</div>}
                                        {/*
                                        @ts-ignore */}
                                        <div className="menu-link px-3 text-start" onClick={event=>duplicateProduct(row.original?.id)}>Duplicate</div>
                                        {/*
                                        @ts-ignore */}
                                        <div className="menu-link px-3 text-start" onClick={event => deleteProduct(row.original?.id)}>Delete</div>
                                    </div>
                                    {/* end::Content */}
                                </div>
                                {/* end::SubMenu */}
                                {/* end::Menu */}
                            </div>
                        </td>)
                    }
                    return <td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>{cell.render('Cell')}</td>
                })}
            </tr>
    )
};