/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useIntl} from "react-intl";
import {PaymentLinkStatus, useAuth} from "../../../../app/modules/auth";
import {getPayRate} from "../../../../app/modules/auth/core/_requests";

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const PayRate: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl();
  const {currentUser} = useAuth()

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    if(typeof currentUser === "undefined")
      return
    let chart: ApexCharts|null
    getPayRate().then((res) => {
      if("error" in res){
        console.log(res.error)
        return
      }
      chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,res.count,res.paid))
      if (chart)
        chart.render()
    }).catch((err) => {
      console.log(err)
      return
    })

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef,currentUser])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'GLOBAL.PAYRATE', defaultMessage: 'Payrate'})}
          </span>
          <span className='text-muted fw-bold fs-7'>
            {intl.formatMessage({id: 'GLOBAL.PAYRATEEXPLAIN', defaultMessage: 'Only paymentlinks are measured'})}
          </span>
        </h3>

      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column py-2'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, totalLinks: number, paidLinks:number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [(paidLinks===0&&totalLinks===0)?0:parseFloat(((paidLinks/totalLinks)*100).toFixed(2))],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {PayRate}
