/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {FC, useEffect, useState} from "react";
import {StepInterface} from "../../core/_scheme";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {currencyList, getCustomerList} from "../../../auth/core/_requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAuth} from "../../../auth";
import {KTSVG} from "../../../../../_metronic/helpers";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {CurrencyIcon} from "../../../currency/components/icon";
import Skeleton from "react-loading-skeleton";
import {getCustomFieldGroups} from "../../core/_requests";
import clsx from "clsx";

const Step3: FC<StepInterface> = ({data, setData, errors}) => {
    const {currentUser} = useAuth()
    const [customers, setCustomers] = useState<any[]>([])
    const [chosenCustomer, setChosenCustomer] = useState<any>();
    const [cycles, setCycles] = useState<any[]>([
        {value:'P1D',label:(<>Daily</>)},
        {value:'P2D',label:(<>Every other day</>)},
        {value:'P1W',label:(<>Weekly</>)},
        {value:'P1M',label:(<>Monthly</>)}
    ]);
    const updateData = (key:string, value:any) => {
        const newData = {...data}
        newData[key] = value
        setData(newData)
    }
    const toggleData = (key:string, value:string) => {
        const newData = {...data}
        if(typeof newData[key] === "undefined")
            newData[key] = [value]
        else if(newData[key]?.includes(value))
            newData[key] = newData[key].filter((item:string) => item !== value)
        else
            newData[key].push(value)
        setData(newData)
    }
    useEffect(() => {
        if(typeof currentUser === "undefined")
            return
        getCustomerList().then((res) => {
            if("error" in res) {
                setCustomers([])
                return
            }
            const newlist = res.map(customer=>{
                return {value:`${customer.id}`,label:(<div className='d-flex justify-content-start flex-column'>
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                    {customer.name}
                </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                    {typeof customer?.email !== "undefined"&&customer?.email?.length>1?(<>
                        {/*
                        @ts-ignore */}
                        <FontAwesomeIcon icon="fa-duotone fa-at" className='fs-7 me-2' />
                        {customer?.email}
                    </>):''}
                            {typeof customer?.phone !== "undefined"&&customer?.phone?.length>1?(<>
                                {/*
                        @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-phone" className={clsx('fs-7 me-2',typeof customer?.email !== "undefined"&&customer?.email?.length>1?'ms-5':'')} />
                                {customer?.phone}
                            </>):''}
                </span>
                    </div>)};
            })
            //newlist.unshift({value:'default',label:<>Default currency</>})
            setCustomers(newlist)
        })
    }, [currentUser])
    useEffect(()=>{
        if(typeof customers === "undefined" || typeof data?.customer === "undefined")
            return
        const newCustomer = customers.find(curr=>curr.value===data.customer)
        if(typeof newCustomer !== "undefined")
            setChosenCustomer(newCustomer)
    },[data?.customer])
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    return <>
        <div className="pb-10 pb-lg-15">
            <h2 className="fw-bolder d-flex align-items-center text-dark">
                Receiver details
            </h2>
            <div className="text-gray-400 fw-bold fs-6">
                Who is the request going to and how do you want to contact or update them?
            </div>
        </div>
        {data?.type!==2&&<div className="mb-5 fv-row non-product-group">
            <label className="form-label mb-3 required">Customer</label>
            {typeof customers === "undefined"?<Skeleton width={'100%'} height={46}/>:
                <Select
                    //@ts-ignore
                    defaultValue={chosenCustomer}
                    options={customers}
                    isLoading={typeof customers === "undefined"}
                    isSearchable={true}
                    components={{ DropdownIndicator }}
                    placeholder={<>Select a customer...</>}
                    //@ts-ignore
                    onChange={event => { updateData('customer',event?.value) }}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            background: '#f5f8fa',
                            borderColor: '#f5f8fa',
                            color: '#5e6278',
                            transition: 'color 0.2s ease, background-color 0.2s ease',
                            borderRadius: '0.3rem'
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            minHeight: 'calc(1.5em + 1rem + 2px)',
                            padding: '0.5rem 1rem',
                            fontSize: '1.25rem',
                        })
                    }}
                />}
            {errors?.customer && <span className="form-text text-danger">{errors?.customer}</span>}
        </div>}
        <div className="row g-10">
            <div className="col-12 col-lg-6">
                <div className='mb-5 fv-row non-product-group'>
                    <label className="form-label mb-3">Reminders</label>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <label className="form-check-label me-3" htmlFor={`reminder_boolean`}>
                            No
                        </label>
                        <input className="form-check-input" type="checkbox" value="1" id={`reminder_boolean`}
                               checked={data?.reminder??false} onChange={()=>updateData('reminder',typeof data?.reminder === "undefined"?true:!data?.reminder)}/>
                        <label className="form-check-label" htmlFor={`reminder_boolean`}>
                            Yes
                        </label>
                    </div>
                    <span className='text-muted fs-8'>Do you want to send reminders to the customer?</span>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className='mb-5 fv-row non-product-group'>
                    <label className="form-label mb-3">Delivery method</label>
                    <div className="mb-5">
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" id="delivery-mail" value={data?.delivery?.includes('mail')} onClick={e=>{toggleData('delivery','mail')}}/>
                            <label className="form-check-label" htmlFor="delivery-mail">
                                E-mail
                            </label>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" id="delivery-sms" value={data?.delivery?.includes('sms')} onClick={e=>{toggleData('delivery','sms')}}/>
                            <label className="form-check-label" htmlFor="delivery-sms">
                                SMS
                            </label>
                        </div>
                    </div>
                    <span className='text-muted fs-8'>Don't want to send an automated message? Leave both boxes unchecked.</span>
                </div>
            </div>
        </div>
        {data?.reminder === true && (<div className="rounded border p-5 mb-5 d-flex flex-row flex-wrap w-100">
            <div className="row g-10 w-100">
                <div className={`col-12 col-lg-6`}>
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3 required">Cycle</label>
                        {data?.reminder === true && <Select
                            //@ts-ignore
                            defaultValue={data?.reminderCycle}
                            options={cycles}
                            components={{ DropdownIndicator }}
                            placeholder={<>Select a cycle...</>}
                            //@ts-ignore
                            onChange={event => { updateData('reminderCycle',event?.value) }}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    background: '#f5f8fa',
                                    borderColor: '#f5f8fa',
                                    color: '#5e6278',
                                    transition: 'color 0.2s ease, background-color 0.2s ease',
                                    borderRadius: '0.3rem'
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    minHeight: 'calc(1.5em + 1rem + 2px)',
                                    padding: '0.5rem 1rem',
                                    fontSize: '1.25rem',
                                })
                            }}
                        />}
                        {errors?.reminderCycle && <span className="form-text text-danger">{errors?.reminderCycle}</span>}
                        <span className='text-muted fs-8'>Keep in mind, sending to many reminders will maybe push the receiver in not paying at all!</span>
                    </div>
                </div>
                <div className={`col-12 col-lg-6`}>
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3 required">How many times?</label>
                        <input type="number" step={1} min={0} max={9999} className="form-control form-control-lg form-control-solid"
                               value={data?.reminderCount} onChange={event=>{updateData('reminderCount',event?.target?.value)}}/>
                        {errors?.reminderCount && <span className="form-text text-danger">{errors?.reminderCount}</span>}
                        <span className='text-muted fs-8'>If you want them to send an infinite amount, set the times to zero.</span>
                    </div>
                </div>
            </div>
        </div>)}
    </>
}

export {Step3}