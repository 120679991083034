import {Route, Routes, Outlet} from 'react-router-dom'
import Check from "./pages/Check";


const Mollie = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='check'
                    element={<Check />}
                />
                <Route
                    index
                    element={<Check />}
                />
            </Route>
        </Routes>
    )
}

export default Mollie
