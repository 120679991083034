import {Route, Routes, Outlet, useNavigate, Link} from 'react-router-dom'
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {checkIntegrationStripe} from "../core/_requests";
import {useIntl} from "react-intl";
import { Crisp } from "crisp-sdk-web";

const Check = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const show = () => Crisp.chat?.show()
    const scope = urlParams.get('scope')
    const code = urlParams.get('code')
    const error = urlParams.get('error')
    const navigate = useNavigate()
    const intl = useIntl()
    const [status, setStatus] = useState<boolean|null>(null);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    useEffect(() => {
        if (scope && code) {
            checkIntegrationStripe(code, scope, error).then(resp => {
                if("success" in resp && resp.success) {
                    setStatus(resp.success)
                    navigate('/integration')
                }else if(typeof resp.error !== "undefined")
                    setErrorMessage(resp.error);
            });
        }
        return () => {

        };
    }, []);

    return (<>{typeof status !== "boolean"?
        (
            <div className={`card bgi-no-repeat`}>
                <div className='card-header border-0 pt-5 pb-3  justify-content-center'>
                    <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3">
                        <span className="indicator-label">
                            {intl.formatMessage({id: 'GENERAL.VERIFY', defaultMessage: 'Verifying connection...'})}
                        </span>
                        <span className="card-label fw-bolder fs-3 indicator-progress">
                            {intl.formatMessage({id: 'GENERAL.VERIFY', defaultMessage: 'Verifying connection...'})} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </h3>
                </div>
            </div>
        )
        :
        (
            <div className={`card bgi-no-repeat`}>
                <div className='card-header border-0 pt-5 pb-3 justify-content-center'>
                    <h3 className='card-title align-items-center flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>
                            {intl.formatMessage({id: 'GENERAL.WENTWRONG', defaultMessage: 'Oops! Something went wrong'})}
                        </span>
                    </h3>
                </div>
                <div className='card-body d-flex flex-center flex-column p-9'>
                    <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                        <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                            <h5 className="mb-1">{errorMessage}</h5>
                            <span>Please try to connect once again, if this problem persists please <span onClick={show} role="button" tabIndex={0} className={'text-decoration-underline'}>contact us</span></span>
                        </div>
                    </div>
                </div>
            </div>
        )}</>)
}

export default Check
