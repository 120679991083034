import React, {FC, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {CustomFieldGroups, useAuth} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PrTable, IlocalFilter, PrTableSelect} from "../../../../_metronic/partials/widgets/tables/PrTable";
import {Taxes} from "../../../../_metronic/partials/widgets/tables/content/taxes";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import {getProductTaxes} from "../../products/core/_requests";
import {getExtraFieldGroups, tableExtraFieldGroups} from "../core/_requests";
import {Extrafieldgroups} from "../../../../_metronic/partials/widgets/tables/content/extrafieldgroups";
import {useNavigate} from "react-router-dom";

const extrafieldsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Extra Fields',
        path: '/extrafields',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Overview: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate()
    const {currentUser} = useAuth()
    const [extraFieldGroups, setExtraFieldGroups] = useState<Array<any>>();
    const [reload, setReload] = useState<number>(0);

    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: Date = new Date(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: Date = new Date(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: number = parseFloat(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.FIELDS', defaultMessage: '# Fields'}),
            accessor: 'fields',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            defaultCanSort: true
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const parser = (fields:Array<CustomFieldGroups>) => fields.map(group=>({
        id: group?.id,
        enabled: group?.enabled,
        name: <span className='text-dark fw-bolder text-hover-primary fs-6'>{group?.name}</span>,
        fields: <span className="badge badge-light-primary">{group?.fields} Fields</span>,
        status: <span className={`badge badge-${group?.enabled?'success':'danger'}`}>
                            {group?.enabled?'Enabled':'Disabled'}
                        </span>,
    }))
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshing, setRefreshing] = useState<boolean>(false)
    const reloadTrigger = () => setRefreshData(!refreshData)
    const initialSort = useMemo( () => [ { id: "id", desc: false } ],[])
    return (
        <>
            <PageTitle breadcrumbs={extrafieldsBreadCrumbs}>{intl.formatMessage({id: 'MENU.EXTRAFIELDS', defaultMessage: 'Extra Fields'})}</PageTitle>
            <PageToolbar>
                <button className='btn btn-light-primary btn-sm me-5' onClick={e=>navigate('/extrafields/new')}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' /> New Group
                </button>
            </PageToolbar>
            <div className={'card mb-5 mb-xl-10'}>
                <div className='card-header border-0'>
                    <div className='d-flex align-items-center my-5 pt-5 w-100 justify-content-between'>
                        <div className="d-flex align-items-center position-relative my-1">
                            {/*
                                 @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                            <input type="text"
                                   value={globalFilter}
                                   className="form-control form-control-solid w-250px ps-14"
                                   placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                   onChange={onChange}/>
                        </div>

                        <div className='d-flex justify-content-end flex-shrink-0 ms-auto my-1'>

                            <button className='btn btn-light-primary btn-sm ms-5 fw-bolder px-7' onClick={e=>navigate('/extrafields/new')}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' /> New Group
                            </button>
                        </div>
                        <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                            {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                        </div>
                        <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                            setRefreshing(true)
                            setRefreshData(!refreshData)
                            setTimeout(() => setRefreshing(false), 1000)
                        }}>
                            {/*
                                @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                        </button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <PrTable columns={columns} dataFetcher={tableExtraFieldGroups} invalidate={refreshData} parser={parser} sortee={initialSort} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Extrafieldgroups} reloadTrigger={reloadTrigger} tableType={'Extra field groups'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}