import React, {FC, useEffect, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {useAuth, UserModel} from "../../auth";
import {KTSVG} from "../../../../_metronic/helpers";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {
    defaultImageInputOptions,
    defaultImageInputQueires,
    ImageInputComponent,
} from "../../../../_metronic/assets/ts/components";
import {
    fileUploadString,
    updateUser,
    UPLOAD_URLS
} from "../../auth/core/_requests";
import {v4 as uuidv4} from "uuid";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import Skeleton from "react-loading-skeleton";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Crisp } from "crisp-sdk-web";
import {SketchPicker} from "react-color";
import CodeEditor from '@uiw/react-textarea-code-editor';

const designBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Design',
        path: '/design',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Overview: FC = () => {
    const show = () => Crisp.chat?.show()
    const {config} = useLayout()
    config.toolbar.layout = 'buttons'
    const intl = useIntl()
    const {currentUser, setCurrentUser} = useAuth()
    const navigate = useNavigate()
    const [userDetails, setUserDetails] = useState<UserModel>()
    const [isBusy, setIsBusy] = useState(false)
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };

    const chunkSize = 1048576 * 3
    const [uploadFile, setUploadFile] = useState<string>("");
    const [uploadBackground, setUploadBackground] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [backgroundUrl, setBackgroundUrl] = useState<string>("");
    const [counter, setCounter] = useState(0)
    const [bcounter, setBcounter] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [uploadingBackground, setUploadingBackground] = useState(false);
    const [chunks, setChunks] = useState(0)
    const [backgroundChunks, setBackgroundChunks] = useState(0)
    const [chunkStart, setChunkStart] = useState(0)
    const [backgroundChunkStart, setBackgroundChunkStart] = useState(0)
    const [chunkEnd, setChunkEnd] = useState(chunkSize)
    const [backgroundChunkEnd, setBackgroundChunkEnd] = useState(chunkSize)
    const [fileType, setFileType] = useState("")
    const [backgroundType, setBackgroundType] = useState("")
    const [fileGuid, setFileGuid] = useState("")
    const [backgroundGuid, setBackgroundGuid] = useState("")
    const [fileName, setFileName] = useState("")
    const [backgroundName, setBackgroundName] = useState("")

    const processUpload = () => {

        fileUploadString(UPLOAD_URLS['logo'],fileName,fileGuid,uploadFile.slice(chunkStart,chunkEnd), fileType).then(response => {
            if(typeof response !== "boolean")
                setImgUrl(`${process.env.REACT_APP_API_URL}/uploads/avatar/${response.url}`)
            setChunkStart(chunkStart + chunkSize)
            setChunkEnd(chunkEnd + chunkSize)
            setCounter(counter+1)
        })
    }
    useEffect(() => {
        if(counter<chunks) {
            setUploading(true)
            processUpload()
        }else if(counter>=1) {
            setUploading(false)
            setChunkStart(0)
            setChunkEnd(chunkSize)
            setCounter(0)
            setChunks(0)
            if (imgUrl)
                setUserDetails({...userDetails, CompanyLogo: imgUrl} as UserModel)
        }
    }, [counter,uploadFile])
    const handleFileChange = (e:any) => {
        if(e===null){
            setUserDetails({...userDetails, CompanyLogo: ""} as UserModel)
            return
        }
        const file = e
        setImgUrl('')
        setFileName(file.name)
        setFileGuid(uuidv4())
        setChunkStart(0)
        setChunkEnd(chunkSize)
        setChunks(Math.ceil(file.size / chunkSize))
        setFileType(file.type)
        setUploadFile(file)
        setCounter(0)
    }
    const processBackground = () => {

        fileUploadString(UPLOAD_URLS['logo'],backgroundName,backgroundGuid,uploadBackground.slice(backgroundChunkStart,backgroundChunkEnd), backgroundType).then(response => {
            if(typeof response !== "boolean")
                setBackgroundUrl(`${process.env.REACT_APP_API_URL}/uploads/avatar/${response.url}`)
            setBackgroundChunkStart(backgroundChunkStart + chunkSize)
            setBackgroundChunkEnd(backgroundChunkEnd + chunkSize)
            setBcounter(bcounter+1)
        })
    }
    useEffect(() => {
        if(bcounter<backgroundChunks) {
            setUploadingBackground(true)
            processBackground()
        }else if(bcounter>=1) {
            setUploadingBackground(false)
            setBackgroundChunkStart(0)
            setBackgroundChunkEnd(chunkSize)
            setBcounter(0)
            setBackgroundChunks(0)
            if (backgroundUrl)
                setUserDetails({...userDetails, backgroundImage: backgroundUrl} as UserModel)
        }
    }, [bcounter,uploadBackground])
    const handleBGChange = (e:any) => {
        if(e===null){
            setUserDetails({...userDetails, backgroundImage: ""} as UserModel)
            return
        }
        const file = e
        setBackgroundUrl('')
        setBackgroundName(file.name)
        setBackgroundGuid(uuidv4())
        setBackgroundChunkStart(0)
        setBackgroundChunkEnd(chunkSize)
        setBackgroundChunks(Math.ceil(file.size / chunkSize))
        setBackgroundType(file.type)
        setUploadBackground(file)
        setBcounter(0)
    }
    useEffect(() => {
        if(typeof currentUser?.currency === "undefined")
            return
        setUserDetails(currentUser)
        const imgInputs:NodeListOf<HTMLElement> = document.querySelectorAll(defaultImageInputQueires.instanseQuery)
        if (imgInputs.length > 0)
            imgInputs.forEach((imgInput: HTMLElement) => new ImageInputComponent(imgInput as HTMLElement, defaultImageInputOptions, defaultImageInputQueires, imgInput.classList.contains('backgroundUpload')?handleBGChange:handleFileChange))

    }, [currentUser]);

    const MySwal = withReactContent(Swal)
    const saveHandle = () => {
        if(typeof userDetails === "undefined")
            return;
        setIsBusy(true)
        MySwal.fire({
            title: 'Saving...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })
        updateUser(userDetails).then(res => {
            if(!("error" in res)){
                MySwal.fire({
                    title: <p>Design is saved!</p>,
                    /*html: <p>Something went wrong. Please try again later.</p>,*/
                    icon: 'success',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
                setCurrentUser(userDetails)
            }else if("error" in res){
                MySwal.fire({
                    title: <p>Error!</p>,
                    html: <>
                        <p>Something went wrong. Please try again later.</p>
                        <pre>{res.error}</pre>
                    </>,
                    icon: 'error',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
            }
            setIsBusy(false)
        })
    }
    return (
        <>
            <PageTitle breadcrumbs={designBreadCrumbs}>{intl.formatMessage({id: 'MENU.DESIGN', defaultMessage: 'Design'})}</PageTitle>
            <PageToolbar>
                <a className='btn btn-white btn-sm me-5 btn-active-primary' target={'_blank'} href={`${process.env.REACT_APP_LINK_URL}/${currentUser?.personalLink?.slug}`}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='me-3' /> Show personal page
                </a>
                <button className='btn btn-light-primary btn-sm me-5' disabled={isBusy} onClick={e=>{saveHandle()}}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk" className='me-3' /> Save
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
            </PageToolbar>
            <form className="form d-flex flex-column flex-lg-row gap-3 gap-lg-5">
                <div className="d-flex flex-column gap-3 gap-lg-5 w-100 w-lg-300px flex-shrink-0">
                    <div className="card card-flush py-4 h-lg-50">
                        <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                                <h2>
                                    Your logo
                                </h2>
                                <span className='text-muted fw-bold fs-7'>When not set, your avatar will be used!</span>
                            </div>
                        </div>
                        <div className={isBlocked(uploading,"card-body text-center")}>
                            <div className="image-input  image-input-outline mb-3" data-kt-image-input="true">
                                {typeof userDetails?.CompanyLogo!=="undefined"?<div className="image-input-wrapper w-150px h-150px"
                                                                                    style={{backgroundImage: `url('${userDetails?.CompanyLogo}')`}} ></div>:<Skeleton height={150} width={150}/>}
                                <OverlayTrigger
                                    key='main-image'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image'>{intl.formatMessage({id: 'IMAGE.CHANGE', defaultMessage: 'Click to change image'})}</Tooltip>}
                                >
                                    <label
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute top-min-10 end-min-10">
                                        <i className="bi bi-pencil-fill fs-7"></i>
                                        <input type="file" name="mainimage"
                                               className="main_image_upload d-none" accept=".png, .jpg, .jpeg, .gif, .webp" />
                                        <input type="hidden" name="mainimage_url" className="mainimage_url"
                                               value={currentUser?.CompanyLogo}/>
                                    </label>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='main-image-cancel'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-cancel'>{intl.formatMessage({id: 'IMAGE.CANCEL', defaultMessage: 'Revert to previous image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="cancel">
                                        <i className="bi bi-arrow-counterclockwise fs-2"></i>
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    key='main-image-remove'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-remove'>{intl.formatMessage({id: 'IMAGE.REMOVE', defaultMessage: 'Remove image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="remove">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="text-muted fs-7 mt-2">
                                Only *.png, *.jpg, *.jpeg, *.gif and *.webp image files are accepted.
                            </div>
                            <Blocker state={uploading}/>
                        </div>
                    </div>
                    <div className="card card-flush py-4 h-lg-50">
                        <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                                <h2>
                                    Background
                                </h2>
                                <span className='text-muted fw-bold fs-7'>When not set default background is used!</span>
                            </div>
                        </div>
                        <div className={isBlocked(uploadingBackground,"card-body text-center")}>
                            <div className="image-input  image-input-outline mb-3 backgroundUpload w-100" data-kt-image-input="true">
                                {typeof userDetails?.backgroundImage!=="undefined"?<div className="image-input-wrapper w-100 h-150px"
                                                                                    style={{backgroundImage: `url('${userDetails?.backgroundImage}')`}} ></div>:<Skeleton height={150} width={'100%'}/>}
                                <OverlayTrigger
                                    key='main-image'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image'>{intl.formatMessage({id: 'IMAGE.CHANGE', defaultMessage: 'Click to change image'})}</Tooltip>}
                                >
                                    <label
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute top-min-10 end-min-10">
                                        <i className="bi bi-pencil-fill fs-7"></i>
                                        <input type="file" name="background"
                                               className="main_image_upload d-none" accept=".png, .jpg, .jpeg, .gif, .webp" />
                                        <input type="hidden" name="background_url" className="background_url"
                                               value={currentUser?.backgroundImage}/>
                                    </label>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='main-image-cancel'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-cancel'>{intl.formatMessage({id: 'IMAGE.CANCEL', defaultMessage: 'Revert to previous image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="cancel">
                                        <i className="bi bi-arrow-counterclockwise fs-2"></i>
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    key='main-image-remove'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-remove'>{intl.formatMessage({id: 'IMAGE.REMOVE', defaultMessage: 'Remove image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="remove">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="text-muted fs-7 mt-2">
                                Only *.png, *.jpg, *.jpeg, *.gif and *.webp image files are accepted.
                            </div>
                            <Blocker state={uploadingBackground}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">
                    <div className="card card-flush py-4">
                        <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                                <h2>
                                    Colors & CSS
                                </h2>
                                <span className='text-muted fw-bold fs-7'>&nbsp;</span>
                            </div>
                        </div>
                        <div className={"card-body row"}>
                            <div className={'col-12 col-md-6'}>
                                <label className="form-label">Company color #1</label>
                                {typeof userDetails?.background1 === "undefined"?<div><Skeleton width={230} height={305}/></div>:
                                    <SketchPicker
                                        disableAlpha={true}
                                        color={`#${userDetails?.background1??'238fe1'}`}
                                        onChangeComplete={resp=>setUserDetails({...userDetails,background1:resp.hex.replace('#','')})} />}
                            </div>
                            <div className={'col-12 col-md-6'}>
                                <label className="form-label">Company color #2</label>
                                {typeof userDetails?.background2 === "undefined"?<div><Skeleton width={230} height={305}/></div>:
                                    <SketchPicker
                                        disableAlpha={true}
                                        color={`${userDetails?.background2??'248dc2'}`}
                                        onChangeComplete={resp=>setUserDetails({...userDetails,background2:resp.hex.replace('#','')})} />}
                            </div>
                            <div className="col-12 mt-15">
                                <label className="form-label">Custom CSS</label>
                                <CodeEditor
                                    value={userDetails?.css}
                                    language="css"
                                    /*placeholder="Enter your custom CSS here to make your pages look great!"*/
                                    onChange={(evn) => setUserDetails({...userDetails, css:evn.target.value??''} as UserModel)}
                                    padding={15}
                                    minHeight={200}
                                    style={{
                                        fontSize: 12,
                                        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                    }}
                                />
                                <span className='mt-5 text-muted d-block fs-7'>
                                  Having issues using custom CSS or don't know where to start?
                                  <span onClick={e=>show} style={{textDecoration: 'underline',cursor: 'pointer'}}>Open our chat </span> and let us know what you would like to change.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="d-flex w-100 flex-row flex-row-fluid pt-3 pt-lg-5">
                <div className="card card-flush h-md-100 w-100">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <h2>
                                Want a custom design?
                            </h2>
                            {/*<span className='text-muted fw-bold fs-7'>Don't have one yet? Follow their onboarding while choosing one from below.</span>*/}
                        </div>
                    </div>
                    <div className="card-body pt-1">
                        <div className="text-gray-600 mb-5">
                            Do you need custom elements or a specific design? Let us know <a className='link-primary fw-bolder' href='#' onClick={event => { event.preventDefault(); show(); }}>with a chat</a> or at  info@payrequest.io!<br/>
                            We can provide customer specific designs or translate your design to your page.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}
