import {FC} from "react";
import {useIntl} from "react-intl";


type Props = {
    type?: string
}

const ProductsStatus: FC<Props> = (props) => {
    const intl = useIntl();
    const {type} = props;
    switch (type) {
        case 'ENABLED':
            return <span className="badge badge-success"> {intl.formatMessage({id: 'PRODUCTS.ENABLED', defaultMessage: 'Enabled'})}</span>
        case 'CONCEPT':
            return <span className="badge badge-primary"> {intl.formatMessage({id: 'PRODUCTS.CONCEPT', defaultMessage: 'Concept'})}</span>
        default:
            return <span className="badge badge-danger"> {intl.formatMessage({id: 'PRODUCTS.DELETED', defaultMessage: 'Deleted'})}</span>
    }
}

export {ProductsStatus}