import React, {FC, useEffect, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {
    Metas,
    MetaValues,
    Products,
    ProductsStatus,
    ProductType,
    Subscription, SubscriptionLogs,
    SubscriptionStatus,
    TaxClasses, Transactions,
    useAuth
} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {Link, useNavigate} from "react-router-dom";
import {CurrencyFormat, CurrencyIcon} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
    getSubscription,
    getSubscriptionLogs,
    getSubscriptionPayments,
    getSubscriptionProduct, setSubscriptionCancel,
    setSubscriptionPause, setSubscriptionRefund, setSubscriptionResume
} from "../core/_requests";
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";
import Select, {GroupBase} from "react-select";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {getCustomFields, transactionType} from "../../requests/RequestHelper";
import {ShowIcon} from "../../requests/components/ShowIcon";
import {RequestStatus} from "../../requests/components/RequestStatus";
import {QRCodeSVG} from "qrcode.react";
import {SubscriptionStatus as SubStatus} from '../../products/components/SubscriptionStatus'
import {cycleUntilFormat} from "../../products/ProductsHelper";
import {getMollieSubscriptionMandate} from "../../../integrations/mollie/core/_requests";
import {MollieMandate} from "../../../integrations/mollie/core/_models";
import {deleteGroup as deleteGroupCall} from "../../extrafields/core/_requests";
import {refundPaymentLink} from "../../requests/core/_requests";

const subViewBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Products',
        path: '/products',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const SubscriptionView: FC = () => {
    const {config} = useLayout()

    config.toolbar.layout = 'buttons'
    const intl = useIntl()
    const { sid } = useParams()
    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [title, setTitle] = useState(intl.formatMessage({id: 'MENU.SUBSCRIPTION', defaultMessage: 'Subscription'}));
    const [subscription, setSubscription] = useState<Subscription>()
    const [product, setProduct] = useState<Products>()
    const [payments, setPayments] = useState<Array<Transactions>>();
    const [logs, setLogs] = useState<Array<SubscriptionLogs>>()
    const [mollieMandate, setMollieMandate] = useState<MollieMandate>();
    const errorSwal = (title?:string,descr?:string) => MySwal.fire({
        title: <p>{title??'Woops'}</p>,
        icon: 'error',
        html: <p>{descr??'Something went wrong. Please try again later.'}</p>,
        didOpen: () => MySwal.showLoading(),
        timer: 3000
    })
    useEffect(() => {
        if(typeof subscription === "undefined" || typeof sid === "undefined")
            return
        setTitle(`${intl.formatMessage({id: 'MENU.SUBSCRIPTION', defaultMessage: 'Subscription'})} #${subscription?.id}`)
        if(subscription?.psp === 1 && sid !== 'manage'){
            getMollieSubscriptionMandate(sid).then(mandate => {
                if("error" in mandate){
                    console.error(mandate.error)
                    return
                }
                setMollieMandate(mandate)
            })
        }
    }, [subscription]);

    useEffect(() => {
        if(typeof sid === "undefined")
            return
        getSubscription(sid).then(sub=>{
            if("error" in sub){
                console.error(sub.error)
                errorSwal()
                return
            }
            setSubscription(sub)
        })
        getSubscriptionLogs(sid).then(subLogs=>{
            if("error" in subLogs){
                console.error(subLogs.error)
                return
            }
            setLogs(subLogs)
        })
        getSubscriptionProduct(sid).then(prod=>{
            if("error" in prod){
                console.error(prod.error)
                return
            }
            setProduct(prod)
        })
        getSubscriptionPayments(sid).then(subPayments=>{
            if("error" in subPayments){
                console.error(subPayments.error)
                return
            }
            setPayments(subPayments)
        })
    }, []);

    const getPsp = (pspid:number) => {
        switch (pspid) {
            case 1:
                return 'mollie'
            case 2:
                return 'stripe'
            case 3:
                return 'multisafepay'
            case 4:
                return 'paypal'
            default:
                return '';
        }
    }
    const pauseSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMPAUSE', defaultMessage: 'Do you want to pause the subscription?'}),
            html: <p>Remember! the maximum pause duration is 12 months.</p>,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                setSubscriptionPause(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.PAUSED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISPAUSED', defaultMessage: 'Subscription is paused for a max of 12 months'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        })
    }
    const refundSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMREFUND', defaultMessage: 'Do you want to refund the subscription?'}),
            html: <p>Do you want to refund the initial fee, or a custom amount?</p>,
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'SWAL.INITIAL',defaultMessage: 'Initial fee'}),
            denyButtonText: intl.formatMessage({id: 'SWAL.REFUND.CUSTOM', defaultMessage: 'Custom'}),
            denyButtonColor: '#009ef7',
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            /*preConfirm(inputValue: any) {
                setSubscriptionPause(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.PAUSED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISPAUSED', defaultMessage: 'Subscription is paused for a max of 12 months'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }*/
        }).then(async (result) => {
            if(result.isConfirmed){
                processRefund('fee')
            }else if(result.isDenied){
                const {value:refundAmount} = await MySwal.fire({
                    title: intl.formatMessage({id: 'SWAL.REFUND.AMOUNT', defaultMessage: 'Enter the amount to refund'}),
                    input: 'number',
                    inputAttributes: {
                        min: "1",
                        // @ts-ignore
                        max: (parseFloat(subscription.price) + (parseFloat(product?.metaValues?.find(value => value.slug === 'fee'||value.slug==='starting_fee')?.value??"0")??0)).toString(),
                    },
                    inputPlaceholder: intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.PLACEHOLDER', defaultMessage: 'Enter the amount to refund'}),
                    inputValidator: (value) => new Promise((resolve) => {
                        if(!value || typeof value === "boolean")
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.REQUIRED', defaultMessage: 'Amount is required'}))// @ts-ignore
                        else if(parseFloat(value) > (parseFloat(subscription.price) + (parseFloat(product?.metaValues?.find(value => value.slug === 'fee'||value.slug==='starting_fee')?.value??"0")??0)))// @ts-ignore
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.MAX', defaultMessage: 'Amount must be less than or equal to {amount}'}, {amount: parseFloat(subscription.price) + (parseFloat(product?.metaValues?.find(value => value.slug === 'fee'||value.slug==='starting_fee')?.value??"0")??0)}))
                        else if(parseFloat(value) < 1)
                            resolve(intl.formatMessage({id: 'SWAL.REFUND.AMOUNT.MIN', defaultMessage: 'Amount must be greater than or equal to 1'}))
                        else
                        { // @ts-ignore
                            resolve()
                        }
                    })
                })
                if(refundAmount)
                    processRefund(refundAmount)
            }
        })
    }
    const processRefund = (refundAmount:number|string) => {
        if(typeof subscription === "undefined")
            return
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({
                id: 'SWAL.REFUND.REASON',
                defaultMessage: 'Enter the reason for your refund'
            }),
            input: 'textarea',
            inputPlaceholder: intl.formatMessage({
                id: 'SWAL.REFUND.REASON.PLACEHOLDER',
                defaultMessage: 'Enter the reason for your refund'
            }),
            preConfirm: (reason: string) => setSubscriptionRefund(subscription.id.toString(), psp, typeof refundAmount === 'string'?(parseFloat(product?.metaValues?.find(value => value.slug === 'fee'||value.slug==='starting_fee')?.value??"0")??0):refundAmount, reason).then(res => {
                if(res.status==='failed' && "error" in res){
                    Swal.showValidationMessage(
                        `Request failed: ${res.error}`
                    )
                }else{
                    MySwal.fire({
                        title: intl.formatMessage({
                            id: 'SWAL.REFUND.SUCCESS',
                            defaultMessage: 'Refund request sent successfully'
                        }),
                        icon: 'success',
                        didOpen: () => MySwal.showLoading(),
                        timer: 3000
                    })
                }
            }).catch((err:any) => {
                Swal.showValidationMessage(
                    `Request failed: Unknown error`
                )})
        })
    }
    const resumeSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMRESUME', defaultMessage: 'Do you want to resume the subscription?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                setSubscriptionResume(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.ENABLED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISRESUMED', defaultMessage: 'Subscription is resumed'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        })
    }
    const cancelSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMCANCEL', defaultMessage: 'Are you sure you want to cancel your subscription?'}),
            icon: "warning",
            input: 'textarea',
            inputLabel: 'Reason to cancel',
            inputPlaceholder: 'No reason? Just leave this empty.',
            inputAttributes: {
                'aria-label': 'No reason? Just leave this empty.'
            },
            inputValue: '',
            buttonsStyling: false,
            confirmButtonText: "Yes, as of now",
            cancelButtonText: "Never mind",
            denyButtonText: "Yes, at the end of this period",
            showDenyButton: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            showLoaderOnDeny: true,
            customClass: {
                confirmButton: "btn fw-bold btn-primary",
                cancelButton: "btn fw-bold btn-secondary",
                denyButton: "btn fw-bold btn-primary",
            },
            preConfirm(inputValue: any) {
                setSubscriptionCancel(subscription?.id.toString(), psp, 'direct', inputValue).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.CANCELLED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISCANCELLED', defaultMessage: 'Subscription is cancelled'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            },
            preDeny(inputValue: any){
                setSubscriptionCancel(subscription?.id.toString(), psp, 'end', inputValue).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.PENDING_CANCEL}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISCANCELLED', defaultMessage: 'Subscription will be cancelled at the end of the period'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        })
        /*
        preConfirm(inputValue: any) {
                setSubscriptionResume(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.ENABLED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISRESUMED', defaultMessage: 'Subscription is resumed'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        * */
    }

    return <>
            <PageTitle breadcrumbs={subViewBreadCrumbs}>{title}</PageTitle>
            <PageToolbar>
                <button className='btn btn-secondary btn-sm me-5' disabled={isBusy}>
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-3'
                    /> Back
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>

                <button type="button"
                        className='btn btn-icon btn-flex btn-primary fw-bolder px-7'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                >
                    <KTSVG
                        path='/media/icons/duotune/general/gen052.svg'
                        className='me-3 ms-3'
                    />
                </button>

                {/* begin::SubMenu */}
                <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-lg-350px py-4' data-kt-menu='true'>
                    <div className="px-7 py-5">
                        <div className="fs-5 text-dark fw-bolder">Actions</div>
                    </div>

                    <div className="separator border-gray-200"></div>

                    <div className="px-7 py-5 d-flex flex-column gap-5" data-kt-sub-table-filter="form">

                        {/*{(typeof subscription?.status !== "undefined" && !([SubscriptionStatus.CANCELLED,].includes(subscription?.status))) &&*/}
                        {/*    <button className='btn btn-primary btn-sm' disabled={isBusy}>*/}
                        {/*        /!**/}
                        {/*        @ts-ignore *!/*/}
                        {/*        <FontAwesomeIcon icon="fa-duotone fa-floppy-disk-circle-arrow-right" className='me-3'/>*/}
                        {/*        Save Subscription and return*/}
                        {/*        /!**/}
                        {/*         @ts-ignore *!/*/}
                        {/*        {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"}/>}*/}
                        {/*    </button>*/}
                        {/*}*/}

                        {(sid!=='manage'&&typeof subscription?.status !== "undefined" && !([SubscriptionStatus.PAUSED,SubscriptionStatus.PENDING_CANCEL,SubscriptionStatus.CANCELLED].includes(subscription?.status))) &&
                            <button className='btn btn-light-warning btn-sm' disabled={isBusy} onClick={()=>pauseSubscription()}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-pause" className='me-3'/>
                                Pause Subscription
                                {/*
                                @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"}/>}
                            </button>
                        }
                        {(sid!=='manage'&&typeof subscription?.status !== "undefined" && !([SubscriptionStatus.PAUSED,SubscriptionStatus.PENDING_CANCEL,SubscriptionStatus.CANCELLED].includes(subscription?.status))) &&
                            <button className='btn btn-light-warning btn-sm' disabled={isBusy} onClick={()=>refundSubscription()}>
                                {/*
                                    @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-money-bill-transfer" className='me-3'/>
                                Refund Subscription
                                {/*
                                    @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"}/>}
                            </button>
                        }
                        {(typeof subscription?.status !== "undefined" && ([SubscriptionStatus.PAUSED,].includes(subscription?.status))) &&
                            <button className='btn btn-light-warning btn-sm' disabled={isBusy} onClick={()=>resumeSubscription()}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-play-pause" className='me-3'/>
                                Resume Subscription
                                {/*
                                @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"}/>}
                            </button>
                        }

                        {(typeof subscription?.status !== "undefined" && !([SubscriptionStatus.CANCELLED,SubscriptionStatus.PENDING_CANCEL].includes(subscription?.status))) &&
                            <button className='btn btn-light-danger btn-sm' disabled={isBusy} onClick={() => cancelSubscription()}>
                                {/*
                    @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-trash-can" className='me-3' />
                                Cancel Subscription
                                {/*
                    @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                            </button>}
                    {/*    {(typeof subscription?.status !== "undefined" && ([SubscriptionStatus.PENDING_CANCEL].includes(subscription?.status))) &&*/}
                    {/*    <button className='btn btn-light-success btn-sm' disabled={isBusy}>*/}
                    {/*        /!**/}
                    {/*@ts-ignore *!/*/}
                    {/*        <FontAwesomeIcon icon="fa-duotone fa-play" className='me-3' />*/}
                    {/*        Continue Subscription*/}
                    {/*        /!**/}
                    {/*@ts-ignore *!/*/}
                    {/*        {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}*/}
                    {/*    </button>}*/}
                    </div>
                </div>
            </PageToolbar>
            <div className="row g-5">
            <div className="col-md-8 col-12">
                <div className='card mb-10 mb-xl-10'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Subscription Details</span>
                            <span className='text-muted fw-bold fs-7'>General information about your subscription</span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        {/* fill 2 columns with multiple rows of information [title]: [value] */}
                        <div className='row'>
                            <div className='col-md-6 col-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12'>
                                        <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.NAME', defaultMessage: 'Name'})}</label>
                                    </div>
                                    <div className='col-md-8 col-12'>
                                        <span className='form-control-plaintext pt-0 mb-2'>{subscription?.name ?? <Skeleton width={80} height={14}/> }</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 col-12'>
                                        <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.CYCLE', defaultMessage: 'Cycle'})}</label>
                                    </div>
                                    <div className='col-md-8 col-12'>
                                        <span className='form-control-plaintext pt-0 mb-2'>{product?.metaValues?cycleUntilFormat(intl, product.metaValues.find(value => value.slug==='cycle')?.value ?? 'P1M', product.metaValues.find(value => value.slug==='until')?.value ?? -1):<Skeleton width={80} height={14}/>}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12'>
                                        <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.CREATED', defaultMessage: 'Created At'})}</label>
                                    </div>
                                    <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                <span>
                                                    {intl.formatDate(subscription?.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                </span>
                                                <span className='text-muted d-block fs-7'>
                                                    {intl.formatTime(subscription?.createdAt,{hour12:false})}
                                                </span>
                                            </span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 col-12'>
                                        <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.CURRENCY', defaultMessage: 'Currency'})}</label>
                                    </div>
                                    <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof subscription?.currency !== "undefined"? subscription?.currency ?? currentUser?.currency : <Skeleton width={40} height={14}/>}
                                            </span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 col-12'>
                                        <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.PRICE', defaultMessage: 'Price'})}</label>
                                    </div>
                                    <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof subscription?.price !== "undefined"? <CurrencyFormat className='ms-1' currency={subscription?.currency ?? currentUser?.currency} amount={subscription?.price}/>:<Skeleton width={70} height={14}/> }
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card mb-10 mb-xl-10'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Payments</span>
                        </h3>
                    </div>
                    <div className='card-body pt-3 pb-10'>
                        {typeof payments !== "undefined"?(
                            (payments == null || payments.length < 1)?
                                <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                                        <span className="svg-icon svg-icon-2hx svg-icon-info me-3">
                                            {/*
                                            @ts-ignore*/}
                                            <FontAwesomeIcon icon="fa-duotone fa-face-sad-cry" />
                                        </span>
                                    <div className="d-flex flex-column"><h5 className="mb-1">No payments found!</h5>
                                        <span>Sorry to bring the bad news, you don't have any payments yet on this request.</span>
                                    </div>
                                </div> :
                                <div className="table-responsive">
                                    <table className="table table-striped gy-3 gs-3">
                                        <thead>
                                        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                            <th className="min-w-100px">Invoice #</th>
                                            <th className="min-w-100px">Amount</th>
                                            <th className="min-w-150px">Paid on</th>
                                            <th className="min-w-80px">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {payments.map((transaction, index) => (
                                            <>
                                                <tr key={`trans-${index}`}>
                                                    <td className="align-middle">
                                                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                            #{transaction?.invoice?.id ?? ''}
                                                        </span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                          <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                                                        </span>
                                                        {typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && transaction.currency !== null && transaction.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                                                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                                                                <CurrencyFormat currency={currentUser.currency} amount={transaction.amountBase}/>
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="align-middle">
                                                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                            {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                        </span>
                                                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                                                            {intl.formatTime(transaction.createdAt,{hour12:false})}
                                                        </span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            {transaction?.invoice && (<a download
                                                                href={`${process.env.REACT_APP_API_URL}/uploads/invoices/${transaction?.invoice?.file}`} target="_blank"
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' >
                                                                {/*
                                                                @ts-ignore*/}
                                                                <FontAwesomeIcon icon="fa-duotone fa-file-invoice" className='fs-3' />
                                                            </a>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                                {Object.keys(getCustomFields(transaction)).length>0 && (<tr>
                                                    <td colSpan={4}>
                                                        <h5 className='fw-bold fs-6'>
                                                            Custom Fields:
                                                        </h5>
                                                        <div>
                                                            {Object.values(Object.fromEntries(Object.entries(getCustomFields(transaction))
                                                                .map(([customFieldKey, customField]) => [
                                                                    customFieldKey,
                                                                    (typeof customField === "object" && customField !== null)?
                                                                        Object.values(Object.fromEntries(Object.entries(customField).map(([subFieldKey, subField]) => [
                                                                            subFieldKey,
                                                                            (<div className='py-2'>
                                                                                <strong>
                                                                                    {subFieldKey[0].toUpperCase() + subFieldKey.substring(1).toLowerCase()}:
                                                                                </strong>
                                                                                {subField}
                                                                            </div>)
                                                                        ])))
                                                                        :
                                                                        (<div className='py-2'>
                                                                            <strong>
                                                                                {customFieldKey[0].toUpperCase() + customFieldKey.substring(1).toLowerCase()}:
                                                                            </strong>
                                                                            {customField}
                                                                        </div>)
                                                                ]))
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                        ):(
                            <Skeleton count={5} height={40} width={"100%"}/>
                        )}
                    </div>
                </div>
                <div className='card mb-10 mb-xl-10'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Events</span>
                        </h3>
                    </div>
                    <div className='card-body py-3 card-scroll h-500px'>
                        {logs?(
                            <>
                                <table className="table table-striped gy-3 gs-3">
                                    <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                        <th className="min-w-50px">Type</th>
                                        <th className="min-w-100px">Title</th>
                                        <th className="min-w-150px">Created at</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {logs.sort((a,b)=>{
                                        if(a.createdAt < b.createdAt)
                                            return 1
                                        else if(a.createdAt > b.createdAt)
                                            return -1
                                        return 0
                                    }).map((subscriptionLog, index) => (
                                        <tr key={`sublog-${index}`}>
                                            <td className='text-center align-middle'>
                                                {/*
                                                @ts-ignore*/}
                                                <span className={`fs-3 btn btn-lg btn-${subscriptionLog.type} btn-icon rounded-circle`}><FontAwesomeIcon icon={`fa-duotone ${subscriptionLog.icon}`} className="fs-2x" /></span>
                                            </td>
                                            <td className="align-middle" dangerouslySetInnerHTML={{__html: subscriptionLog.description}}></td>
                                            <td className="align-middle">
                                                <span className='form-control-plaintext py-0'>
                                                    <span>
                                                        {intl.formatDate(subscriptionLog.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                    </span>
                                                    <span className='text-muted d-block fs-7'>
                                                        {intl.formatTime(subscriptionLog.createdAt,{hour12:false})}
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </>
                        ):<Skeleton count={5} height={40} width={"100%"}/>}
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div data-kt-sticky="true" data-kt-sticky-width={true}>
                    <SubStatus expanded={true} status={subscription?.status}/>
                    {sid!=='manage'&&
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-header border-0 py-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Customer</span>
                                    <span className='text-muted fw-bold fs-7'>Who did you send it to?</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>
                                {/* show avatar on the left, name and mail next to it*/}
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                            {subscription?.customer?.avatar?<img src={subscription?.customer?.avatar} alt={subscription?.customer?.name} />:<Skeleton width={40} height={40} />}
                                        </div>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        {subscription?.customer?.name ?? <Skeleton width={100} height={18} />}
                                        <span className='d-block text-muted fw-bold fs-7'>{subscription?.customer?.email ?? <Skeleton width={120} height={14}/> }</span>
                                    </div>
                                </div>
                                <div className='row mt-5 pt-2'>
                                    <div className='col-12'>
                                        <Link to={`/customer/${subscription?.customer?.id}`} className='btn btn-outline-primary btn-active-light-primary btn-outline btn-block w-100'>Open Customer</Link>
                                    </div>
                                </div>
                                {(typeof mollieMandate !== "undefined" && subscription?.psp && subscription?.psp === 1) && (<>
                                    <div className="separator separator-dashed border-secondary my-10"></div>
                                    <h5 className="mb-4">Payment Details</h5>
                                    <div className="fw-semibold text-gray-600 d-flex align-items-center mb-4">
                                        {/*
                                        @ts-ignore */}
                                        {mollieMandate?.method === "ideal" && <FontAwesomeIcon icon={'fab fa-ideal'} className="me-2 fs-2" />}
                                        {/*
                                        @ts-ignore */}
                                        {mollieMandate?.method === "creditcard" && <FontAwesomeIcon icon={'fad fa-credit-card-front'} className="me-2 fs-2" />}
                                        {/*
                                        @ts-ignore */}
                                        {mollieMandate?.method === "paypal" && <FontAwesomeIcon icon={'fab fa-paypal'} className="me-2 fs-2" />}
                                        {/*
                                        @ts-ignore */}
                                        {mollieMandate?.method === "directdebit" && <FontAwesomeIcon icon={'fad fa-credit-card'} className="me-2 fs-2" />}
                                        {mollieMandate?.details?.consumerAccount ?? mollieMandate?.details?.cardLabel}
                                    </div>
                                    <div className="fw-semibold text-gray-600 d-flex align-items-center mb-4">

                                        {/*
                                        @ts-ignore */}
                                        {mollieMandate?.details?.consumerName && <FontAwesomeIcon icon={'fad fa-user'} className="me-2 fs-2" />}
                                        {mollieMandate?.details?.consumerName ?? mollieMandate?.details?.cardHolder}
                                    </div>
                                </>)}
                                {/*
                                <h3 className=' fw-bolder fs-3 mb-4'>
                                    QR Code
                                </h3>
                                 @ts-ignore
                                <div ref={svgRef}>
                                    {paymentLink?.directlink?<QRCodeSVG fgColor={'#1954bf'} value={paymentLink?.directlink} size={200} />:<Skeleton width={200} height={200} />}
                                </div>
                                <div className="mb-10">
                                    <button
                                        type='button'
                                        className='btn btn-light-primary mt-4 btn-sm'
                                        id="svgDownloadButton"
                                        data-kt-menu-trigger='hover'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='bottom'
                                    >

                                    @ts-ignore
                                        <FontAwesomeIcon icon="fa-duotone fa-download" className="me-2" />
                                        Download QR Code
                                    </button>

                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-active-bg fw-semibold py-3" data-kt-menu="true" style={{maxWidth: 200}}>
                                        <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                            <a href="javascript:void(0);" onClick={downloadSVG}
                                               className="menu-link px-3">
                                                <span className="menu-title">SVG</span>
                                            </a>
                                        </div>
                                        <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                            <a href="javascript:void(0);" onClick={downloadPNG}
                                               className="menu-link px-3">
                                                <span className="menu-title">PNG</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
        </>
}

export {SubscriptionView}
