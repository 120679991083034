/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FormEvent, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {Form, Formik, FormikValues, useFormik} from 'formik'
import {getUserByToken,/*getUserByToken,*/ login, updateUserField} from '../../auth/core/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth/core/Auth'
import { defineMessages,useIntl,FormattedMessage } from 'react-intl'
import {onboardingSchemas} from "../core/_scheme";
import {initOnboarding, IOnboarding} from "../core/_models";
import {StepperComponent} from "../../../../_metronic/assets/ts/components";
import {Step1} from "./steps/Step1";
import {Step2} from "./steps/Step2";
import {Step3} from "./steps/Step3";
import {Step4} from "./steps/Step4";
import Skeleton from "react-loading-skeleton";
import { Crisp } from "crisp-sdk-web";

export const Welcome: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(onboardingSchemas[0])
    const [initValues,setInitValues] = useState<IOnboarding>(initOnboarding)
    const [loadingValues, setLoadingValues] = useState(true);
    const {auth, saveAuth/*, setCurrentUser*/, currentUser} = useAuth()
    const intl = useIntl();

    const translatable = defineMessages({

    });
    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }
    const prevStep = () => {
        if (!stepper.current)
            return
        stepper.current.goPrev()
        setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex - 1])
    }
    const submitStep = (values: IOnboarding, actions: FormikValues) => {
        if (!stepper.current)
            return
        setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex])
        console.log(stepper.current.currentStepIndex)
        console.log(stepper.current.totatStepsNumber)
        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber)
            stepper.current.goNext()
        else {
            stepper.current.goto(1)
            actions.resetForm()
        }
    }

    useEffect(() => {
        document.title = "PayRequest.io | Welcome!"
        if (!stepperRef.current)
            return
        loadStepper()
        if(auth)
            getUserByToken(auth).then(data=>{
                if(typeof data !== "boolean" && data?.email?.length > 1) {
                    if (data?.email)
                        Crisp.user.setEmail(data.email)
                    if (data?.name)
                        Crisp.user.setNickname(data.name)
                    /*Crisp.session.setData({
                        email: data?.email ?? '',
                        userId: data?.id?.toString() ?? '',
                        name: data?.name ?? '',
                        customAttributes: {
                            psp: data?.psp ?? '',
                            slug: data?.slug ?? '',
                            company_name: data?.companyName ?? '',
                            company_mail: data?.companyMail ?? ''
                        }
                    })*/
                }
                return Object.keys(initValues)
                    .reduce(function(accumulator, key) {
                        // @ts-ignore
                        accumulator[key] = data[key]
                        return accumulator
                    }, {})
            }).then(newValues=>{
                setInitValues(newValues as IOnboarding)
                console.log(newValues)
                setLoadingValues(false)
            })
        return () => {
        }
    }, [stepperRef])
    const waitList: Record<string, any> = {};
    const updateField = (field:string, value:string) => {
        if(typeof waitList[field] !== "undefined")
            clearTimeout(waitList[field])
        waitList[field] = setTimeout(()=>updateUserField(field,value),500);
    }
    const handleChanges = (event: any) => {
        const errors: Record<string, any> = {};
        const mailRegex = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])', 'm')
        const siteRegex = new RegExp('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$', 'm')
        const sitePrefixRegex = new RegExp('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$', 'm')
        if (typeof event.companyName !== "undefined" && event.companyName!==null) {
            if (event.companyName.length < 1)
                errors.companyName = "Company Name can't be empty!"
            else
                updateField('companyName', event.companyName)
        }
        if (typeof event.companyMail !== "undefined" && event.companyMail!==null) {
            if(!mailRegex.test(event.companyMail))
                errors.companyMail = "Please fill in a valid mail adress"
            else if (event.companyMail.length < 1)
                errors.companyMail = "Company Mail can't be empty!"
            else
                updateField('companyMail', event.companyMail)
        }
        if (typeof event.companySite !== "undefined" && event.companySite!==null) {
            if(sitePrefixRegex.test(event.companySite) && !siteRegex.test(event.companySite))
                errors.companySite = "Please add http or https in front of your url"
            else if(!siteRegex.test(event.companySite))
                errors.companySite = "Please fill in a valid site url"
            else if (event.companySite.length < 1)
                errors.companySite = "Company Site can't be empty!"
            else
                updateField('companySite', event.companySite)
        }
        if (typeof event.currency !== "undefined") {
            console.log(event)
            if (event.currency.length < 1)
                errors.currency = "Please select a default currency"
            else
                updateField('currency', event.currency)
        }
        if (typeof event.background1 !== "undefined"&&event.background1!==null) {
            if (event.background1.length >0)
                updateField('background1', event.background1.replace('#',''))
        }
        if (typeof event.background2 !== "undefined"&&event.background2!==null) {
            if (event.background2.length >0)
                updateField('background2', event.background2.replace('#',''))
        }
        return errors;
    }

    return (
        <div
            ref={stepperRef}
            id='onboarding_stepper'
            className='d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column first' >
            <div className='d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y bgi-position-y-bottom position-x-center bgi-no-repeat bgi-attachment-fixed'
                     style={{
                         backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/onboarding/stepper.svg')})`,
                         backgroundSize: `400px`,
                     }}>
                    <div className='d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20'>
                        <div className='mb-10 mb-lg-20'>
                            <img src={toAbsoluteUrl('/media/logo/logo.svg')} alt='logo' className='h-45px' />
                        </div>
                        <div className='stepper-nav'>

                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                                <div className='stepper-line w-40px'></div>

                                <div className='stepper-icon w-40px h-40px'>
                                    <i className='stepper-check fas fa-check'></i>
                                    <span className='stepper-number'>1</span>
                                </div>

                                <div className='stepper-label'>
                                    <h3 className='stepper-title'>Onboarding Start</h3>

                                    <div className='stepper-desc fw-bold'>Setup Your Page Details.</div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line w-40px'></div>

                                <div className='stepper-icon w-40px h-40px'>
                                    <i className='stepper-check fas fa-check'></i>
                                    <span className='stepper-number'>2</span>
                                </div>

                                <div className='stepper-label'>
                                    <h3 className='stepper-title'>Looks</h3>
                                    <div className='stepper-desc fw-bold'>Make it your own!</div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line w-40px'></div>

                                <div className='stepper-icon w-40px h-40px'>
                                    <i className='stepper-check fas fa-check'></i>
                                    <span className='stepper-number'>3</span>
                                </div>

                                <div className='stepper-label'>
                                    <h3 className='stepper-title'>Additional business information</h3>
                                    <div className='stepper-desc fw-bold'>Adding information increases your paymentrate!</div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav' style={{marginBottom: 100}}>
                                <div className='stepper-line w-40px'></div>

                                <div className='stepper-icon w-40px h-40px'>
                                    <i className='stepper-check fas fa-check'></i>
                                    <span className='stepper-number'>4</span>
                                </div>

                                <div className='stepper-label'>
                                    <h3 className='stepper-title'>Connect your PSP</h3>
                                    <div className='stepper-desc fw-bold'>Connect your Payment Service Provider to get it all up and running!</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10'>
                <div className='d-flex flex-center flex-column flex-column-fluid'>
                    <div className='w-lg-1200px p-15 mx-auto min-w-800px'>
                        {loadingValues?(
                            <>
                                <Skeleton width={200} height={22} style={{marginTop: 65}}/>
                                <Skeleton width={"100%"} height={15}/>

                                <Skeleton width={150} height={22} style={{marginTop: 55}}/>
                                <Skeleton width={"100%"} height={47} style={{marginTop:10}}/>
                                <Skeleton width={150} height={22} style={{marginTop: 35}}/>
                                <Skeleton width={"100%"} height={47} style={{marginTop:10}}/>
                                <Skeleton width={150} height={22} style={{marginTop: 35}}/>
                                <Skeleton width={"100%"} height={47} style={{marginTop:10}}/>
                                <Skeleton width={150} height={22} style={{marginTop: 35}}/>
                                <Skeleton width={"100%"} height={47} style={{marginTop:10}}/>
                                <Skeleton width={150} height={22} style={{marginTop: 35}}/>
                                <Skeleton width={"100%"} height={47} style={{marginTop:10}}/>
                            </>
                        ):(<Formik initialValues={initValues} onSubmit={submitStep} validate={handleChanges}>
                            {(props) => (
                                <Form className='w-100' noValidate id='onboarding_stepper_form'>
                                    <div className='current' data-kt-stepper-element='content'>
                                        <Step1 values={props.values}></Step1>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <Step2 values={props.values} ></Step2>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <Step3 values={props.values} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <Step4  props={props}/>
                                    </div>

                                    <div className='d-flex flex-stack pt-10'>
                                        <div className='mr-2'>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                Back
                                            </button>
                                        </div>

                                        <div>
                                            {stepper.current?.currentStepIndex !== 4 &&
                                            <button type='submit' className='btn btn-lg btn-primary me-3'>
                                                <span className='indicator-label'>
                                                  {stepper.current?.currentStepIndex !==
                                                      stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                                                    {stepper.current?.currentStepIndex ===
                                                        stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-3 ms-2 me-0'
                                                    />
                                                </span>
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>)}
                    </div>
                </div>
            </div>
        </div>
    )
}
