import React, {FC, useEffect, useMemo, useState} from 'react'

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Subscription, SubscriptionStatus, useAuth} from "../../auth";
import {Link} from "react-router-dom";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IlocalFilter, PrTable} from "../../../../_metronic/partials/widgets/tables/PrTable";
import Skeleton from "react-loading-skeleton";
import {KTSVG} from "../../../../_metronic/helpers";
import Select, {components, DropdownIndicatorProps, GroupBase} from "react-select";
import {Subscriptions} from "../../../../_metronic/partials/widgets/tables/content/subscriptions";
import {getSubscriptions, tableSubscriptions} from "../core/_requests";
import {SubscriptionStatus as SubStatus} from "../../products/components/SubscriptionStatus"

const subscriptionsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Overview: FC = () => {
    const intl = useIntl();
    const {currentUser} = useAuth()
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [exportData, setExportData] = useState<Array<any>>([]);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [print, setPrint] = useState(false);
    const [csv, setCsv] = useState(false);
    const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(a.original[columnId].props.children[0].props['data-filter']);
            const bv: number = parseFloat(b.original[columnId].props.children[0].props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            console.log(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter'])
            const av: Date = new Date(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            console.log(av)
            const bv: Date = new Date(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortee = useMemo(() => [
            {
                id: "createdAt",
                desc: true
            }
        ],
        [])
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.CUSTOMER', defaultMessage: 'Customer'}),
            accessor: 'customer',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.PRICE', defaultMessage: 'Price'}),
            accessor: 'price',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            sortType: sortPrice
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-120px',
            filter: 'filterSearch',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.CREATED', defaultMessage: 'Created at'}),
            accessor: 'createdAt',
            className: 'min-w-120px',
            filter: 'rangeDate',
            defaultCanSort: true,
            sortType: sortDateTime
        },{
            Header: intl.formatMessage({id: 'TABLE.NEXT', defaultMessage: 'Next payment'}),
            accessor: 'next',
            className: 'min-w-120px',
            filter: 'rangeDate',
            sortType: sortDateTime
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    const parser = (subscriptions:Array<Subscription>) => subscriptions.map(sub => {
        return {
            id: sub.id,
            name: <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {sub.name}
                            </span>,
            customer: (
                <div className='d-flex justify-content-start flex-column'>
                    <Link to={typeof sub.customer === "undefined"?'#':`/customer/${sub.customer?.id}`} className='text-dark fw-bolder text-hover-primary fs-6'>
                        {sub.customer?.name ?? "Unknown"}
                    </Link>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {sub.customer?.email ?? ''}
                          </span>
                </div>
            ),
            price: (
                <>
                            <span data-filter={sub.price} className='text-dark fw-bolder text-hover-primary fs-6'>
                              <CurrencyFormat currency={sub.currency} amount={sub.price} formatAmount={true}/>
                            </span>
                    {/*{typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && sub.currency !== null && sub.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                                <CurrencyFormat currency={currentUser.currency} amount={sub.amountBase}/>
                              </span>
                            )}*/}
                </>
            ),
            status: <SubStatus data-filter={sub.status} status={sub.status}/>,
            createdAt: sub.createdAt!==null && <>
                                <span data-filter={sub.createdAt}  className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {intl.formatDate(sub.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                </span>
                <span className='text-muted fw-bold text-muted d-block fs-8'>
                                  {intl.formatTime(sub.createdAt,{hour12:false})}
                                </span>
            </>,
            next: sub.next!==null && <>
                                <span data-filter={sub.next}  className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {intl.formatDate(sub.next,{year:'numeric',month:'long',day:'numeric'})}
                                </span>
                <span className='text-muted fw-bold text-muted d-block fs-8'>
                                  {intl.formatTime(sub.next,{hour12:false})}
                                </span>
            </>,
            actions: false
        }
    }).reverse()
    const initialSort = useMemo( () => [ { id: "createdAt", desc: true } ],[])
    return (
        <>
            <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>{intl.formatMessage({id: 'MENU.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                        <div className='d-flex align-items-center my-5 pt-5 w-100'>
                            <div className="d-flex align-items-center position-relative my-1">
                                {/*
                                     @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                                <input type="text"
                                       value={globalFilter}
                                       className="form-control form-control-solid w-250px ps-14"
                                       placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                       onChange={onChange}/>
                            </div>
                            <div className='d-flex justify-content-end flex-shrink-0 ms-auto my-1'>
                                {/* begin::Menu */}
                                <button type="button"
                                        className='btn btn-sm btn-flex btn-primary fw-bolder px-7'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='me-3 ms-0'
                                    />
                                    Filter
                                </button>

                                {/* begin::SubMenu */}
                                <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-lg-350px py-4' data-kt-menu='true'>
                                    <div className="px-7 py-5">
                                        <div className="fs-5 text-dark fw-bolder">Filteroptions</div>
                                    </div>

                                    <div className="separator border-gray-200"></div>

                                    <div className="px-7 py-5" data-kt-products-table-filter="form">

                                        <div className="mb-10">
                                            <label className="form-label fs-6 fw-bold">Status:</label>
                                            <Select
                                                components={{DropdownIndicator}}
                                                // @ts-ignore
                                                options={[
                                                    { value:'',label:"All"},
                                                    { value:SubscriptionStatus.ENABLED,label:"Enabled"},
                                                    { value:SubscriptionStatus.PAUSED,label:"Paused"},
                                                    { value:SubscriptionStatus.CANCELLED,label:"Cancelled"},
                                                    { value:SubscriptionStatus.PENDING_CANCEL,label:"Pending cancel"},
                                                ] as GroupBase<any>}
                                                onChange={event => {
                                                    // @ts-ignore
                                                    setLocalFilter({columnId: 'status', filter: event?.value ?? null})
                                                }}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        background: '#f5f8fa',
                                                        borderColor: '#f5f8fa',
                                                        color: '#5e6278',
                                                        transition: 'color 0.2s ease, background-color 0.2s ease',
                                                        borderRadius: '0.3rem'
                                                    }),
                                                    valueContainer: (provided) => ({
                                                        ...provided,
                                                        minHeight: 'calc(1.5em + 1rem + 2px)',
                                                        padding: '0.5rem 1rem',
                                                        fontSize: '1.25rem',
                                                    })
                                                }}/>
                                        </div>

                                    </div>
                                </div>
                                {/* end::SubMenu */}
                                {/* end::Menu */}
                            </div>
                            <div className="d-flex align-items-center ms-auto position-relative my-1">
                                <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                    {intl.formatMessage({id: 'GLOBAL.EXPORT', defaultMessage: 'Export to'})}
                                </div>
                                <div className='btn-group btn-group-sm ms-5' role='group' aria-label='Export possibilities'>
                                        <span className='btn btn-primary' style={{pointerEvents: 'none'}}>
                                        {/*
                                             @ts-ignore */}
                                            <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-down" />
                                        </span>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setCsv(!csv)}>
                                        CSV
                                    </button>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setPrint(!print)}>
                                        PDF/Print
                                    </button>
                                </div>
                            </div>
                            <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                            </div>
                            <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                                setRefreshing(true)
                                setRefreshData(!refreshData)
                                setTimeout(() => setRefreshing(false), 1000)
                            }}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                            </button>
                        </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <PrTable columns={columns} csvDownload={csv} print={print} sortee={sortee} dataFetcher={tableSubscriptions} parser={parser} invalidate={refreshData} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Subscriptions} tableType={'subscriptions'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}