import axios from 'axios'
import {StatusResponse} from "../../stripe/core/_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/api/integrations`

export function getPaypalLink(): Promise<string|boolean> {
    return axios.get<any>(`${INTEGRATIONS_GET}/paypal`)
        .then(response => response.data?.action_url ?? false)
        .catch(err => false)
}

export function checkIntegrationPaypal(data:object): Promise<StatusResponse|ErrorMessage> {
    // @ts-ignore
    const getVariables = new URLSearchParams(data).toString()
    return axios.get(
        `${API_URL}/integrations/paypal/check?${getVariables}`).then(response => response.data as unknown as StatusResponse)
        .catch(err => ({success:false, error: "Unknown error"}))
}