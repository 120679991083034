import React, {createContext, FC, useContext, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PrTable, IlocalFilter, PrTableSelect} from "../../../../_metronic/partials/widgets/tables/PrTable";
import {Products} from "../../../../_metronic/partials/widgets/tables/content/products";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import {getProducts, statusChangeProduct, statusChangeProducts, tableProducts} from "../core/_requests";
import {cycleUntilFormat, getCycleFormat} from "../ProductsHelper";
import {ProductsStatus} from "./ProductsStatus";
import {Products as ProductsModel, useAuth} from "../../auth";
import {KTSVG} from "../../../../_metronic/helpers";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const productsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Products',
        path: '/products',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Overview: FC = () => {
    const {config} = useLayout()
    const intl = useIntl()
    const {currentUser} = useAuth()
    const MySwal = withReactContent(Swal)
    const [selectedIds, setSelectedIds] = useState<Array<number>>([])
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshing, setRefreshing] = useState<boolean>(false)
    const reloadTrigger = () => setRefreshData(!refreshData)
    const [print, setPrint] = useState(false);
    const [csv, setCsv] = useState(false);
    const navigate = useNavigate()
    config.toolbar.layout = 'buttons'
    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: Date = new Date(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: Date = new Date(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: number = parseFloat(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.SKU', defaultMessage: 'SKU'}),
            accessor: 'sku',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.TITLE', defaultMessage: 'Title'}),
            accessor: 'title',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.TYPE', defaultMessage: 'Type'}),
            accessor: 'type',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.PRICE', defaultMessage: 'Price'}),
            accessor: 'price',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            sortType: sortPrice
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-120px',
            filter: 'filterSearch',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.LASTEDIT', defaultMessage: 'Last edited'}),
            accessor: 'updatedAt',
            className: 'min-w-120px',
            filter: 'fuzzyText',
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    const parser = (products:Array<ProductsModel>) => products.map(product => ({
        id: product.id,
        sku: (
            <Link to={`/product/${product.id}`}
                  className="d-flex align-items-center">
                                    <span
                                        className="symbol symbol-50px">
                                        <span className="symbol-label"
                                              style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/uploads/avatar/${product.metaValues.find(value => value.slug==='main_image')?.value ?? "fallback.jpg"})`}}></span>
                                    </span>
                <span className="text-gray-600 ms-4">{product.metaValues.find(value => value.slug==='sku')?.value ?? ""}</span>
            </Link>
        ),
        slug: product.slug,
        title: (
            <Link to={`/product/${product.id}`} className='text-dark fw-bolder text-hover-primary fs-6'>
                {product.name}
            </Link>
        ),
        type: (
            <span data-filter={product.type.name} className="badge badge-light-primary text-start d-inline-block p-2">
                                {product.type.name}
                {product.type.id===2&&(<span className="text-muted fw-bold d-block fs-9">
                                    {cycleUntilFormat(intl, product.metaValues.find(value => value.slug==='cycle')?.value ?? 'P1M', product.metaValues.find(value => value.slug==='until')?.value ?? -1)}
                                </span>)}
                            </span>
        ),
        price: (
            <>
                                <span data-filter={product.price}
                                      className='text-dark fw-bolder text-hover-primary fs-6'>
                                  <CurrencyFormat currency={product.currency} amount={product.price}/>
                                </span>
            </>
        ),
        fstatus: product.status,
        status: (
            <div data-filter={product.status}>
                <ProductsStatus type={product.status} />
            </div>
        ),
        updatedAt: (
            <span className="text-muted fw-bold fs-9">
                                {intl.formatDate(product.updatedAt,{year:'numeric',month:'long',day:'numeric'})}
                            </span>
        ),
        actions: false
    }))
    const initialSort = useMemo( () => [ { id: "updatedAt", desc: false } ],[])
    const statusOptions = [
        {value: '', label: 'All'},
        {value: 'ENABLED', label: 'Enabled'},
        {value: 'CONCEPT', label: 'Concept'},
        {value: 'DELETED', label: 'Deleted'},
    ];
    return (
        <>
            <PageTitle breadcrumbs={productsBreadCrumbs}>{intl.formatMessage({id: 'MENU.PRODUCTS', defaultMessage: 'Products'})}</PageTitle>
            <PageToolbar>
                <button className='btn btn-light-primary btn-sm me-5' onClick={e=>navigate('/product/new')}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' /> New Product
                </button>
            </PageToolbar>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                    {selectedIds.length > 0?
                        (<div className='d-flex align-items-center my-5 pt-5 w-100 ms-auto'>
                            <div className="me-5 ms-auto">
                                <span className="me-2">{selectedIds.length}</span> selected
                            </div>
                            <button className="btn btn-success btn-sm me-5" onClick={e=>{
                                e.preventDefault()
                                MySwal.fire({
                                    title: intl.formatMessage({id: 'PRODUCTS.CONFIRMENABLEBULK', defaultMessage: 'Are you sure you want to enable these products?'}),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
                                    cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
                                    reverseButtons: true,
                                    preConfirm(inputValue: any) {
                                        statusChangeProducts(selectedIds, 'ENABLED').then((message) => {
                                            if(typeof reloadTrigger!=="undefined")
                                                reloadTrigger()
                                            if(message.status==='success'){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.ENABLESUCCESSBULK', defaultMessage: 'Products enabled successfully'}),
                                                    icon: 'success',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }else if("error" in message){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                                                    html: message.error,
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }
                                        })
                                    }
                                })
                            }}>
                                {/*
                                            @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-check" className='me-3' /> Enable Selected
                            </button>
                            <button className="btn btn-warning btn-sm me-5" onClick={e=>{
                                e.preventDefault()
                                MySwal.fire({
                                    title: intl.formatMessage({id: 'PRODUCTS.CONFIRMDISABLEBULK', defaultMessage: 'Are you sure you want to disable these products?'}),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
                                    cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
                                    reverseButtons: true,
                                    preConfirm(inputValue: any) {
                                        statusChangeProducts(selectedIds, 'DISABLED').then((message) => {
                                            if(typeof reloadTrigger!=="undefined")
                                                reloadTrigger()
                                            if(message.status==='success'){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.DISABLESUCCESSBULK', defaultMessage: 'Products disabled successfully'}),
                                                    icon: 'success',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }else if("error" in message){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                                                    html: message.error,
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }
                                        })
                                    }
                                })
                            }}>
                                {/*
                                            @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-ban" className='me-3' /> Disable Selected
                            </button>
                            <button className="btn btn-warning btn-sm me-5" onClick={e=>{
                                e.preventDefault()
                                MySwal.fire({
                                    title: intl.formatMessage({id: 'PRODUCTS.CONFIRMCONCEPTBULK', defaultMessage: 'Are you sure you want to move these products to concept?'}),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
                                    cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
                                    reverseButtons: true,
                                    preConfirm(inputValue: any) {
                                        statusChangeProducts(selectedIds, 'CONCEPT').then((message) => {
                                            if(typeof reloadTrigger!=="undefined")
                                                reloadTrigger()
                                            if(message.status==='success'){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.ENABLECONCEPTBULK', defaultMessage: 'Products moved to concept successfully'}),
                                                    icon: 'success',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }else if("error" in message){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                                                    html: message.error,
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }
                                        })
                                    }
                                })
                            }}>
                                {/*
                                            @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-pause" className='me-3' /> Selected to concept
                            </button>
                            <button className="btn btn-danger btn-sm" onClick={e=>{
                                e.preventDefault()
                                MySwal.fire({
                                    title: intl.formatMessage({id: 'PRODUCTS.CONFIRMDELETEBULK', defaultMessage: 'Are you sure you want to delete these products?'}),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
                                    cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
                                    reverseButtons: true,
                                    preConfirm(inputValue: any) {
                                        statusChangeProducts(selectedIds, 'REALDELETE').then((message) => {
                                            if(typeof reloadTrigger!=="undefined")
                                                reloadTrigger()
                                            if(message.status==='success'){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.DELETEDBULK', defaultMessage: 'Products deleted successfully'}),
                                                    icon: 'success',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }else if("error" in message){
                                                MySwal.fire({
                                                    title: intl.formatMessage({id: 'PRODUCT.ERROR', defaultMessage: 'Something went wrong!'}),
                                                    html: message.error,
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    didOpen: () => MySwal.showLoading(),
                                                    timer: 3000
                                                })
                                            }
                                        })
                                    }
                                })
                            }}>
                                {/*
                                            @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-xmark" className='me-3' /> Delete Selected
                            </button>
                        </div>):
                        (<div className='d-flex align-items-center my-5 pt-5 w-100'>
                            <div className="d-flex align-items-center position-relative my-1">
                                {/*
                                     @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                                <input type="text"
                                       value={globalFilter}
                                       className="form-control form-control-solid w-250px ps-14"
                                       placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                       onChange={onChange}/>
                            </div>

                            <div className='d-flex align-items-center justify-content-end flex-shrink-0 ms-auto my-1'>
                                {/* begin::Menu */}
                                <button type="button"
                                        className='btn btn-sm btn-flex btn-primary fw-bolder px-7'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='me-3 ms-0'
                                    />
                                    Filter
                                </button>

                                {/* begin::SubMenu */}
                                <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-lg-350px py-4' data-kt-menu='true'>
                                    <div className="px-7 py-5">
                                        <div className="fs-5 text-dark fw-bolder">Filteroptions</div>
                                    </div>

                                    <div className="separator border-gray-200"></div>

                                    <div className="px-7 py-5" data-kt-products-table-filter="form">

                                        <div className="mb-10">
                                            <label className="form-label fs-6 fw-bold">Status:</label>
                                            <Select
                                                components={{DropdownIndicator}}
                                                // @ts-ignore
                                                options={statusOptions}
                                                onChange={event => {
                                                    // @ts-ignore
                                                    setLocalFilter({columnId: 'status', filter: event?.value ?? null})
                                                }}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        background: '#f5f8fa',
                                                        borderColor: '#f5f8fa',
                                                        color: '#5e6278',
                                                        transition: 'color 0.2s ease, background-color 0.2s ease',
                                                        borderRadius: '0.3rem'
                                                    }),
                                                    valueContainer: (provided) => ({
                                                        ...provided,
                                                        minHeight: 'calc(1.5em + 1rem + 2px)',
                                                        padding: '0.5rem 1rem',
                                                        fontSize: '1.25rem',
                                                    })
                                                }}/>
                                        </div>

                                    </div>
                                </div>
                                {/* end::SubMenu */}
                                {/* end::Menu */}
                            <div className="d-flex align-items-center ms-auto position-relative my-1">
                                <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                    {intl.formatMessage({id: 'GLOBAL.EXPORT', defaultMessage: 'Export to'})}
                                </div>
                                <div className='btn-group btn-group-sm ms-5' role='group' aria-label='Export possibilities'>
                                    <span className='btn btn-primary' style={{pointerEvents: 'none'}}>
                                    {/*
                                         @ts-ignore */}
                                        <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-down" />
                                    </span>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setCsv(!csv)}>
                                        CSV
                                    </button>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setPrint(!print)}>
                                        PDF/Print
                                    </button>
                                </div>
                            </div>

                            <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                            </div>
                            <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                                setRefreshing(true)
                                setRefreshData(!refreshData)
                                setTimeout(() => setRefreshing(false), 1000)
                            }}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                            </button>
                            </div>
                        </div>)}
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <PrTable columns={columns} parser={parser} dataFetcher={tableProducts} reloadTrigger={reloadTrigger} invalidate={refreshData} sortee={initialSort} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Products} tableType={'products'} selectedRows={setSelectedIds} rowSelect={true}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}