import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {FC} from "react";
import {useIntl} from "react-intl";
import {useAuth} from "../../auth";
import BecomePro from "./BecomePro";
import PayProModal2 from "./PayProModal2";

const proBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Pro',
        path: '/pro',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Overview: FC = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    return <>
        <PageTitle breadcrumbs={proBreadCrumbs}>{intl.formatMessage({id: 'MENU.PRO', defaultMessage: 'PRO'})}</PageTitle>
        <BecomePro/>
        <PayProModal2/>
    </>
}

export default Overview