import React, {FC} from "react";
import {useIntl} from "react-intl";
import {SubscriptionStatus as Substatus} from "../../auth";
import Skeleton from "react-loading-skeleton";


type Props = {
    status: Substatus|undefined
    expanded?: boolean
}

const SubscriptionStatus: FC<Props> = ({status, expanded=false}) => {
    const intl = useIntl();
    let border = 'primary'
    let title = ''
    let description = '';
    if(expanded){
        if(typeof status === "undefined")
            return <Skeleton width={"100%"} height = {60} />
    }
    switch (status) {
        case Substatus.ENABLED:
            border = 'success'
            title = intl.formatMessage({id: 'SUBSCRIPTION.STATUS.ENABLED', defaultMessage: 'Enabled'})
            description = "The subscription is running as expected."
            break;
        case Substatus.PAUSED:
            border = 'warning'
            title = intl.formatMessage({id: 'SUBSCRIPTION.STATUS.PAUSED', defaultMessage: 'Paused'})
            description = "The subscription is paused, no charges will be made and you can continue at any point."
            break;
        case Substatus.CANCELLED:
            border = 'danger'
            title = intl.formatMessage({id: 'SUBSCRIPTION.STATUS.CANCELLED', defaultMessage: 'Cancelled'})
            description = "The subscription has been cancelled, no charges will be made and you can't re-activate it."
            break;
        case Substatus.PENDING_CANCEL:
            border = 'light-danger'
            title = intl.formatMessage({id: 'SUBSCRIPTION.STATUS.PENDING_CANCEL', defaultMessage: 'Pending cancel'})
            description = "The subscription is cancelled for a given date. Will continue to function until given date."
            break;
        default:
            border = 'danger'
            title = intl.formatMessage({id: 'PRODUCTS.DELETED', defaultMessage: 'Deleted'})
            description = "This subscription is deleted, your customer can't see it anymore."
            break;
    }
    if(expanded){
        return (
            <div className={`alert alert-secondary bg-white border border-${border} d-flex flex-column flex-sm-row p-5 mb-5`}>
                <div className="d-flex flex-column pe-0 pe-sm-10">
                    <h5 className={`mb-1 text-${border}`}>
                        {title}
                    </h5>
                    <span>{description}</span>
                </div>
            </div>
        )
    }
    return <span data-filter={status} className={`badge badge-${border}`}> {title}</span>
}

export {SubscriptionStatus}