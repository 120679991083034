import React, {FC} from "react";
import { Crisp } from "crisp-sdk-web";
import {KTSVG} from "../../../../_metronic/helpers";
const BecomePro: FC = () => {
    const show = () => Crisp.chat?.show()
    return <div className='card card-flush py-4'>
        <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Become PRO</span>
                <span className='text-muted fw-bold fs-7'>Subscribe to PayRequest PRO and benefit right away!</span>
            </h3>
        </div>
        <div className='card-body py-3'>
            <div className="d-flex flex-column">
                <div className="row mt-10">
                    <div className="col-12 col-lg-6 mb-10 mb-lg-0">
                        <div className="nav flex-column">
                            <div
                                className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 active mb-6"
                                data-bs-toggle="tab" data-bs-target="#become_pro_free">
                                <div className="d-flex align-items-center me-2">
                                    <div
                                        className="form-check form-check-custom form-check-solid form-check-success me-6">
                                        <input className="form-check-input" type="radio" name="plan"
                                               checked={true} value="free"/>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">Payrequest
                                            Basic</h2>
                                        <div className="opacity-50 fs-7">Perfect for
                                            startups or companies with a small amount of
                                            payments.</div>
                                    </div>
                                </div>
                                <div className="ms-5">
                                    <span className="mb-2">€</span>
                                    <span className="fs-3x fw-bolder">0</span>
                                    <span className="fs-7 opacity-50">/
													<span data-kt-element="period">Month</span></span>
                                </div>
                            </div>
                            <div
                                className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                                data-bs-toggle="tab" data-bs-target="#become_pro_pro">
                                <div className="d-flex align-items-center me-2">
                                    <div
                                        className="form-check form-check-custom form-check-solid form-check-success me-6">
                                        <input className="form-check-input" type="radio" name="plan"
                                               value="pro"/>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">Payrequest
                                            PRO</h2>
                                        <div className="opacity-50 fs-7">Want to access
                                            extra solutions or a lower fee?</div>
                                    </div>
                                </div>
                                <div className="ms-5">
                                    <span className="mb-2">€</span>
                                    <span className="fs-3x fw-bolder">20</span>
                                    <span className="fs-7 opacity-50">/
													<span data-kt-element="period">Month</span></span>
                                </div>
                            </div>
                            <a href="#" onClick={(e)=>{e.preventDefault();show()}}
                               className="nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6">
                                <div className="d-flex align-items-center me-2">
                                    <div
                                        className="form-check form-check-custom form-check-solid form-check-success me-6">
                                        <input className="form-check-input" type="radio" name="plan"
                                               value="enterprise"/>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">Enterprise</h2>
                                        <div className="opacity-50 fs-7">Need custom
                                            solutions? We can help!</div>
                                    </div>
                                </div>
                                <div className="ms-0">
                                    <div className="btn btn-sm btn-primary">Chat with us</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="tab-content rounded h-100 border border-light p-10">
                            <div className="tab-pane fade show active" id="become_pro_free">
                                <div className="pb-5">
                                    <h2 className="fw-bolder text-dark">What do you get in
                                        Basic?</h2>
                                    <div className="text-muted fw-bold">Only pay when you get
                                        paid!</div>
                                </div>
                                <div className="pt-1">
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">1% service fee</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Up to 1000 Customers</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Zapier integration</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Payrequest Basic API</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-muted flex-grow-1">Extended Payrequest API</span>
                                        <span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                                                                    fill="#000000"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-muted flex-grow-1">Multisafepay & Mollie Integration</span>
                                        <span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                                                                    fill="#000000"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-muted flex-grow-1">Subscriptions and payment plans</span>
                                        <span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                                                                    fill="#000000"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-muted flex-grow-1">Custom css</span>
                                        <span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                                                                    fill="#000000"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                                        <span
                                                            className="fw-bold fs-5 text-muted flex-grow-1">No PR branding</span>
                                        <span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                                                                    fill="#000000"/>
															</svg>
														</span>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="become_pro_pro">
                                <div className="pb-5">
                                    <h2 className="fw-bolder text-dark">Why PayRequest
                                        PRO?</h2>
                                    <div className="text-muted fw-bold">More functions, lower
                                        fees and more customizability!</div>
                                </div>
                                <div className="pt-1">
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Only 0.5% service fee</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Unlimited customers</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Zapier integration</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Full Payrequest API</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Multisafepay & Mollie Integration</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Subscriptions and payment plans</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-7">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">Custom css</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                                        <span
                                                            className="fw-bold fs-5 text-gray-700 flex-grow-1">No PR branding</span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                 height="24px" viewBox="0 0 24 24" version="1.1">
																<circle fill="#000000" opacity="0.3" cx="12" cy="12"
                                                                        r="10"/>
																<path
                                                                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                                                                    fill="#000000" fillRule="nonzero"/>
															</svg>
														</span>
                                    </div>
                                    <div className="d-flex align-items-center pt-5 mt-5">
                                        <a href="#" data-bs-dismiss="modal" data-bs-toggle="modal"
                                           data-bs-target="#get_pro"
                                           className="btn btn-dark w-100 mt-5">
                                            <KTSVG path="/media/icons/duotune/abstract/abs023.svg" className="svg-icon-white svg-icon-2hx" />
                                            Upgrade & Benefit!
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default BecomePro
