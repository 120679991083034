/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect, useCallback} from 'react'
import socialIcons from './icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {Button, Modal} from "react-bootstrap";
import {ErrorMessage, Field} from "formik";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {DndProvider, useDrop} from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {Pill} from "./Pill";
import update from 'immutability-helper'

export type SocialIcon = {
    icon: string,
    link: string
}

type Props = {
    className?: string
    values: SocialIcon[],
    setValues: Function,
    addNewComponent?: FC,
    addNewProps?: object,
    useFormik?: boolean,
}

const SocialPicker: FC<Props> = ({
    className,
    values = [],
    setValues,
    addNewComponent,
    addNewProps,
    useFormik = false,
}) => {
    const [editIcon, setEditIcon] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [newIcon, setNewIcon] = useState<string>();
    const [location, setLocation] = useState<number>();
    const [newLink, setNewLink] = useState<string>();
    const [iconOptions, setIconOptions] = useState<any[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    useEffect(() => {
        setIconOptions(socialIcons.map(icon => {
            return {
                value: icon,
                // @ts-ignore
                label: (<> <FontAwesomeIcon icon={`${['phone','mobile','envelope', 'at','globe'].includes(icon)?'fa-duotone':'fa-brands'} fa-${icon==='wikipedia'?`${icon}-w`:icon}`} className='me-3 fs-1'/> {icon} </>)
            }
        }))
        setLoadingData(false)
        return () => { };
    }, []);

    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                {/*
                                    @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-caret-down" className='fs-3 p-2' />
            </components.DropdownIndicator>
        );
    };
    const movePill = useCallback((dragIndex: number, hoverIndex: number) => {
        setValues((prevPills: SocialIcon[]) =>
            update(prevPills, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevPills[dragIndex] as SocialIcon],
                ],
            }),
        )
    }, [])
    const renderSocial = useCallback((icon:string,link:string, i:number) => {
        return <Pill
                id={icon+link}
                key={icon+link}
                index={i}
                movePill={movePill}
                className="socialItem btn btn-outline btn-outline-primary btn-active-light-primary rounded-pill me-3"
                icon={icon}
                link={link}>
                {/*
            @ts-ignore */}
                <FontAwesomeIcon icon={`fa-duotone fa-pencil`} className='ms-2'  onClick={()=>{
                    setNewIcon(icon)
                    setNewLink(link)
                    setLocation(i)
                    setEditIcon(true)
                    setOpenModal(true)
                }}/>
            </Pill>
    },[])

    return <div className={clsx('socialPicker',className)}>
        <DndProvider backend={HTML5Backend} options={{enableMouseEvents:true}}>
        {values.map((socialItem,i) => renderSocial(socialItem.icon,socialItem.link,i))}
        </DndProvider>
        <div {...addNewProps} onClick={()=>{
            setEditIcon(false)
            setOpenModal(true)
        }}>
            {addNewComponent}
        </div>
        <Modal
            id='socialpicker_modal'
            dialogClassName='modal-dialog-centered mw-1000px h-auto'
            show={openModal}
            onHide={()=>setOpenModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>{editIcon? 'Edit Social' : 'Add Social'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='fv-row mb-10'>
                    <label className='form-label'>Choose an icon</label>
                    <Select
                        //@ts-ignore
                        defaultValue={""}
                        value={iconOptions.find(icon => icon.value === newIcon)}
                        options={iconOptions}
                        isLoading={loadingData}
                        isSearchable={true}
                        components={{ DropdownIndicator }}
                        name='social_select2'
                        onChange={event => {
                            if(event!==null) {
                                // @ts-ignore
                                setNewIcon(event.value)
                            }
                        }}
                        styles={{
                            option: (provided) => ({
                                ...provided,
                                width: '33% !important',
                                display: 'inline-flex',
                                fontSize: '1.2rem'
                            }),
                            control: (provided) => ({
                                ...provided,
                                background: '#f5f8fa',
                                borderColor: '#f5f8fa',
                                color: '#5e6278',
                                transition: 'color 0.2s ease, background-color 0.2s ease',
                                borderRadius: '0.3rem'
                            }),
                            valueContainer: (provided) => ({
                                ...provided,
                                minHeight: 'calc(1.5em + 1rem + 2px)',
                                padding: '0.5rem 1rem',
                                fontSize: '1.25rem',
                                width: '30%'
                            })
                        }}
                    />
                </div>

                <div className='fv-row mb-10'>
                    <label className='form-label required'>Social link</label>

                    {useFormik?
                        <Field name='sociallink' className='form-control form-control-solid' onChange={(e:any) =>setNewLink(e.target.value)} value={newLink} />
                        :
                        <input className='form-control form-control-solid' onChange={(e) =>setNewLink(e.target.value)} value={newLink} />
                    }
                    <div className='text-danger mt-2'>
                        {useFormik&&<ErrorMessage name='sociallink' />}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{
                    if(editIcon && typeof newIcon !== "undefined"&&typeof newLink !== "undefined"){

                        /*values.push({
                            icon: newIcon,
                            link: newLink
                        })
                        setValues(values)*/
                        setLocation(undefined)
                        setNewLink("")
                        setNewIcon("")
                    }
                    setOpenModal(false)
                }}>
                    Close
                </Button>
                {editIcon && (<Button variant="danger" onClick={()=>{
                    let newValues = [...values]
                    if(typeof location !== "undefined")
                        newValues.splice(location,1)
                    setValues(newValues)
                    setLocation(undefined)
                    setNewLink("")
                    setNewIcon("")
                    setOpenModal(false)
                }}>
                    Remove
                </Button>)}
                <Button variant="primary" onClick={()=>{
                    if(typeof values !== "undefined") {
                        if(typeof newIcon !== "undefined"&&typeof newLink !== "undefined"){
                            let newValues = [...values]
                            if(typeof location !== "undefined")
                                newValues.splice(location,1,{icon:newIcon,link:newLink})
                            else
                                newValues.push({icon: newIcon, link: newLink})
                            setValues(newValues)
                            setLocation(undefined)
                            setNewLink("")
                            setNewIcon("")
                        }
                    }
                    setOpenModal(false)
                } }>
                    {editIcon? 'Save changes' : 'Save Social'}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default SocialPicker