import {KTSVG} from "../../../../_metronic/helpers";
import React, {FC, useEffect, useState} from "react";
import {ItemType} from "../core/_models";
import {Text} from "./fieldtypes/Text";
import {Multi} from "./fieldtypes/Multi";
import {DateTime} from "./fieldtypes/DateTime";
import {Media} from "./fieldtypes/Media";

type itemProps = {
    item: ItemType,
    index: number,
    setItem: (item: ItemType) => void,
    fullWidth?: number,
    removeItem: (index: number) => void,
    setActive: (index: number) => void,
}

const FormField: FC<itemProps> = ({item, index, setItem, fullWidth, removeItem, setActive}) => {
    const [dragging, setDragging] = useState<boolean>(false);
    const [initialWidth, setInitialWidth] = useState<number|undefined>();
    const [dragWith, setDragWith] = useState<number|undefined>();
    const [widthOptions, setWidthOptions] = useState<Array<number>>();
    const [originalWidth, setOriginalWidth] = useState<number|undefined>();
    const [field, setField] = useState<ItemType>(item);
    const [margin, setMargin] = useState<number>(10);

    const updateOriginalWidth = () => {
        if(typeof widthOptions === "undefined" || typeof fullWidth === "undefined")
            return
        setOriginalWidth(widthOptions.find(widthOption => widthOption === ((field?.general?.width??12)*(fullWidth/12))))
    }
    /*useEffect(() => {
        updateOriginalWidth()
    }, [widthOptions, fullWidth, index]);*/

    useEffect(() => {
        if(typeof fullWidth === "undefined")
            return;
        setWidthOptions(Array(12).fill(fullWidth / 12).map((item, index) => item*(index+1)));
        setMargin(fullWidth / 48);
    }, [fullWidth]);

    useEffect(() => {
        setField(item)
    }, [item]);

    useEffect(() => {
        if(typeof widthOptions === "undefined" || typeof dragWith === "undefined"||typeof fullWidth === "undefined"||typeof originalWidth === "undefined")
            return
        const calcWidth = originalWidth + dragWith;
        let match = widthOptions.find(widthOption => calcWidth >= widthOption - margin && calcWidth <= widthOption + margin);
        if(typeof match === "undefined" && calcWidth>=fullWidth)
            match = fullWidth;
        if(match) {
            setField({...field, general: {...field?.general, width: widthOptions.indexOf(match)+1}} as ItemType);
        }
    }, [dragWith]);


    return <div className={`col-${field?.general?.width??12} form-group rounded position-relative ${dragging&&'active'}`} key={`formfields-${index}`}>
        <div className="form-field-handler cursor-pointer position-absolute top-0 start-50 translate-middle-x flex-row flex-nowrap justify-content-center rounded-bottom bg-primary">
            <div className="p-2 d-flex justify-content-center align-items-center border-end border-secondary" onClick={()=>setActive(index)}>
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon svg-icon-white' />
            </div>
            <div className="p-2 d-flex justify-content-center align-items-center border-end border-secondary form-field-handle">
                <KTSVG path='/media/icons/duotune/abstract/abs031.svg' className='svg-icon svg-icon-white' />
            </div>
            <div onClick={()=>removeItem(index)} className='p-2 d-flex justify-content-center align-items-center'>
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon svg-icon-white' />
            </div>
        </div>

        {(field?.name?.toLowerCase()!=='spacer'&&
            (field?.label?.visible??true))&&<label className={`form-label ${field?.general?.required?'required':''}`}>{field?.label?.text??field?.name}</label>}

        {field?.name?.toLowerCase()==='plain text'&&<div className="form-text" dangerouslySetInnerHTML={{__html:field?.html??''}}></div>}

        {field?.name?.toLowerCase()==='spacer'&&<div style={{height:field?.field?.height??100}} className="empty-spacer d-flex justify-content-center align-items-center">
            <span className={`fs-2 fw-bolder`}>Empty spacer</span>
        </div>}

        {['single line','number','multi line','email','telephone','wysiwyg'].includes(field?.name?.toLowerCase())&&<Text item={field} index={index} />}

        {['date','time','date and time'].includes(field?.name?.toLowerCase())&&<DateTime item={field} index={index} />}

        {['yes/no','yesno','dropdown','checkbox','radiobutton'].includes(field?.name?.toLowerCase())&&<Multi item={field} index={index} />}

        {['image upload','video upload','file upload'].includes(field?.name?.toLowerCase())&&<Media item={field} index={index} />}

        <div className="form-field-resizer position-absolute end-0 top-0 bottom-0 rounded-end" draggable={true} onDragStart={e=>{
            updateOriginalWidth()
            setInitialWidth(e.clientX)
            setDragging(true);
        }}
         onDrag={e=>{
            if(typeof initialWidth !== 'number')
                setInitialWidth(e.clientX)
            if(dragging && typeof widthOptions !== 'undefined'&&typeof initialWidth === 'number')
                setDragWith(e.clientX-initialWidth)
        }}
        onDragEnd={e=>{setDragging(false);setInitialWidth(undefined);updateOriginalWidth();setItem(field)}}></div>
    </div>
}

export {FormField}