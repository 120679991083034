import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, refreshToken} from './_requests'
import { Crisp } from "crisp-sdk-web";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AUTH_LOCAL_STORAGE_KEY, getAuth} from "./AuthHelpers";

type AuthContextProps = {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  referral: number | undefined
  setReferral: Dispatch<SetStateAction<number|undefined>>
}

const initAuthContextPropsState = {
  loading: true,
  setLoading: () => {},
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  referral: undefined,
  setReferral: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      setLoading(false)
    }else
      authHelper.removeAuth()
  }
  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setLoading(false)
  }
  const [referral, setReferral] = useState<number|undefined>(undefined);
  return (
    <AuthContext.Provider value={{loading, setLoading, auth, saveAuth, currentUser, setCurrentUser, logout,referral,setReferral}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {loading, setLoading, auth, logout, setCurrentUser,referral,setReferral} = useAuth()
  const didRequest = useRef(false)
  const navigate = useNavigate()
  let tokenRefresher: NodeJS.Timeout|undefined = undefined;
  let _referral =  searchParams.get('ref')!==null?Number(searchParams.get('ref')):undefined
  if(_referral === undefined)
    _referral = localStorage.getItem("pr-referral-id")!==null?Number(localStorage.getItem("pr-referral-id")):undefined
  else
    localStorage.setItem("pr-referral-id", _referral.toString())
  setReferral(_referral)

  const setTokenRefresher = () => tokenRefresher = setInterval(() => {
    if(typeof auth === "undefined")
      return;
    const newAuth = getAuth()
    if(typeof newAuth === "undefined") {
      logout()
      return;
    }
    refreshToken(newAuth).then(resp => {
      console.log(resp)
      if(!("error" in resp)){
        authHelper.setAuth({
          token: resp.token,
          refresh_token: resp.refresh_token,
          api_token: ''
        })
      }else if(resp.status === 401){
        logout()
      }
    }).catch(e=>logout())
  }, 60000)
  useEffect(() => {
    Crisp.configure("2d3b7b0e-572c-4ceb-874d-2c577b089bd7")
    const requestUser = async (auth: AuthModel) => {
      try {
        if (!didRequest.current) {
          const data = await getUserByToken(auth)
          if (typeof data !== "boolean") {
            if(data?.email?.length > 1) {
              if (data?.email)
                Crisp.user.setEmail(data.email)
              if (data?.name)
                Crisp.user.setNickname(data.name)
              /*Crisp.session.setData({
                email: data?.email ?? '',
                userId: data?.id?.toString() ?? '',
                name: data?.name ?? '',
                customAttributes: {
                  psp: data?.psp ?? '',
                  slug: data?.slug ?? '',
                  company_name: data?.companyName ?? '',
                  company_mail: data?.companyMail ?? ''
                }
              })*/
            }
            setCurrentUser(data)
            setLoading(false)
            setTokenRefresher()

            if(data.onboarding === 0 && !window.location.pathname.includes('integration/'))
                navigate('/welcome')
            // @ts-ignore
            window.webpushr('fetch_id',sid=>console.log(sid===false?'no webpushr id yet..':`webpushr subscriber id: ${sid}`));
          }else{
            console.log('logout, no valid response right?')
            logout()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current)
          logout()
      }
      return () => {
        didRequest.current = true
        if(typeof tokenRefresher !== "undefined")
          clearInterval(tokenRefresher)
      }
    }

    if (auth && auth.token) {
      requestUser(auth)
    } else {
      logout()
    }
    // eslint-disable-next-line
  }, [])

  return <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
