/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Languages} from "../../../../_metronic/partials/content/dropdown/Languages";

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const {saveAuth, referral} = useAuth()
  const intl = useIntl()
  const translatable = defineMessages({
    senderror: {
      id: 'GLOBAL.SENDERROR',
      defaultMessage: 'Something went wrong, please try again',
    },
    duplicate: {
      id: 'AUTH.DUPLiCATE',
      defaultMessage: 'Your mail address is already in use',
    },
    format: {
      id: 'AUTH.FORMAT',
      defaultMessage: 'Wrong email format',
    },
    xsymbols: {
      id: 'AUTH.XSYMBOLS',
      defaultMessage: '{type} {num} symbols',
    },
    emailrequired: {
      id: 'AUTH.EMAILREQUIRED',
      defaultMessage: 'Email is required',
    },
    isrequired: {
      id: 'AUTH.ISREQUIRED',
      defaultMessage: '{type} is required',
    },
    tosaccept: {
      id: 'AUTH.TOS',
      defaultMessage: 'You must accept the terms and conditions'
    },
    isincorrect: {
      id: 'AUTH.ISINCORRECT',
      defaultMessage: 'The registration details are incorrect'
    }
  });
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    acceptTerms: false,
  }
  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, intl.formatMessage(translatable.xsymbols, {type: 'Minimal', num: 3}))
        .max(50, intl.formatMessage(translatable.xsymbols, {type: 'Maximum', num: 50}))
        .required(intl.formatMessage(translatable.isrequired, {type: 'First name'})),
    lastname: Yup.string()
        .min(3, intl.formatMessage(translatable.xsymbols, {type: 'Minimal', num: 3}))
        .max(50, intl.formatMessage(translatable.xsymbols, {type: 'Maximum', num: 50}))
        .required(intl.formatMessage(translatable.isrequired, {type: 'Last name'})),
    email: Yup.string()
        .email(intl.formatMessage(translatable.format))
        .min(3, intl.formatMessage(translatable.xsymbols, {type: 'Minimal', num: 3}))
        .max(50, intl.formatMessage(translatable.xsymbols, {type: 'Maximum', num: 50}))
        .required(intl.formatMessage(translatable.emailrequired)),
    acceptTerms: Yup.bool().required(intl.formatMessage(translatable.tosaccept)),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstname,
          values.lastname,
          typeof referral === 'number'? referral.toString() : referral
        )
        if(auth) {
          setSubmitted(true)
        }else{
          setStatus(intl.formatMessage(translatable.duplicate))
        }
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage(translatable.senderror))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    document.title = "PayRequest.io | Registration"
    return () => {
    }
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>
          <FormattedMessage
              id="AUTH.CREATE"
              defaultMessage="Create an Account"
          />
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          <FormattedMessage
              id="AUTH.USEREXISTS"
              defaultMessage="Already have an account?"
          />
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {submitted? (
        <div className='text-center'>
          <h1 className="text-dark mb-3">
            <img alt="Logo"
                 src={toAbsoluteUrl('/media/icons/communication/mail-notification.svg')}
                 className="me-1"/>
            <FormattedMessage
                id="AUTH.MAIL"
                defaultMessage="You've got mail!"
            />
          </h1>
          <div className="text-gray-400 fw-bold fs-4">
            <FormattedMessage
                id="AUTH.SUCCESSMAIL"
                defaultMessage="You successfully created your account! To finish your account please open your mail client and click on the magic login link we sent to you"
            />
          </div>
        </div>
      ):( <>

        {/* begin::Action */}
        <a href='/auth/google' className='btn btn-light-primary fw-bolder w-100 mb-10'>
          <img
              alt='Google'
            src={toAbsoluteUrl('/media/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          <FormattedMessage
              id="GENERAL.SIGNINWITH"
              defaultMessage="Sign in with {subject}"
              values={{subject: 'Google'}}
          />
        </a>
        {/* end::Action */}

        <div className='d-flex align-items-center mb-10'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <span className='fw-bold text-gray-400 fs-7 mx-2'>
            <FormattedMessage
                id="GENERAL.OR"
                defaultMessage="or"
            />
          </span>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='row fv-row mb-7'>
          <div className='col-xl-6'>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                <FormattedMessage
                    id="GENERAL.FIRSTNAME"
                    defaultMessage="First name"
                />
              </label>
              <input
                placeholder='First name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    'is-valid': formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstname}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-xl-6'>
            {/* begin::Form group Lastname */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                <FormattedMessage
                    id="GENERAL.LASTNAME"
                    defaultMessage="Last name"
                /></label>
              <input
                placeholder='Last name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('lastname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    'is-valid': formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastname}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6'>
            <FormattedMessage
                id="GENERAL.EMAIL"
                defaultMessage="Email"
            />
          </label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              htmlFor='kt_login_toc_agree'
            >
              <FormattedMessage
                  id="GENERAL.PRETOS"
                  defaultMessage="I Agree to the"
              />{' '}
              <a href='https://payrequest.io/tos/' target='_blank' className='ms-1 link-primary'>
                <FormattedMessage
                    id="GENERAL.TOS"
                    defaultMessage="terms and conditions"
                />
              </a>
              .
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptTerms}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </>)}
      {/* begin::Language Selector */}
      <div className='separator my-5'></div>

      <Languages />
      {/* end::Language Selector */}
    </form>
  )
}
