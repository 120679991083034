/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {useIntl} from "react-intl";

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const intl = useIntl()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
        

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.companyName ?? currentUser?.name}
              {currentUser?.Pro===1?(<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>):""}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.companyMail ?? currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/personal'} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADERMENU.PERSONALPAGE', defaultMessage: 'My personal page'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/requests'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/transactions'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/customers/'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}
        </Link>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to={'/settings'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.SIGNOUT', defaultMessage: 'Sign out'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
