/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Welcome} from './components/Welcome'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const OnboardingLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-white')
        // document.title = "PayRequest.io | Login"
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid position-x-center'
        >
            {/* begin::Content */}
            <Outlet />
            {/* end::Content */}
        </div>
    )
}

const OnboardingPage = () => (
    <Routes>
        <Route element={<OnboardingLayout />}>
            <Route index element={<Welcome />} />
        </Route>
    </Routes>
)

export {OnboardingPage}
