import {IntlShape} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FC} from "react";
import {ColumnInstance} from "react-table";
import {User} from "../apps/user-management/users-list/core/_models";
import Skeleton from "react-loading-skeleton";
import {Transactions} from "../auth";

export const getCustomFields = (transaction:Transactions): {[index:string]:any} => {
    let customFields = transaction.customFields;
    if(!customFields) return {};
    if('address' in customFields && typeof customFields.address !== "undefined") {
        //const address = customFields.address;
        delete customFields.address;
    }
    return customFields
}
export const transactionType = (intl: IntlShape, type?:string) => {
    switch (type) {
        case 'DONATION':
            return intl.formatMessage({id: 'REQUESTS.DONATIONSINGLE', defaultMessage: 'Donation'})
        case 'PREAUTH':
            return intl.formatMessage({id: 'REQUESTS.PREAUTHSINGLE', defaultMessage: 'Pre-authorisation'})
        case 'PRODUCT':
            return intl.formatMessage({id: 'REQUESTS.PRODUCTSINGLE', defaultMessage: 'Product'})
        case 'PERSONAL':
            return intl.formatMessage({id: 'REQUESTS.PERSONALSINGLE', defaultMessage: 'Personal'})
        default:
            return intl.formatMessage({id: 'REQUESTS.NORMALSINGLE', defaultMessage: 'Normal'})
    }
}