import axios from 'axios'
import {AuthModel, CustomFieldGroups, Transactions} from "../../auth";
import {CustomerOverview, VALIDATE_URL} from "../../auth/core/_requests";
import {ErrorMessage, PaymentLink, RequestLog, StatusMessage} from "./_models";

const API_URL = process.env.REACT_APP_API_URL

export const CREATE_API = `${API_URL}/api/create`
export const LINK_API = `${API_URL}/api/link`
export const LINKS_API = `${API_URL}/api/requests`
export const LINK_LOG_API = `${API_URL}/api/sendLog`
export const LINK_METHODS_API = `${API_URL}/api/methods`
export const LINK_LOG_GET = `${API_URL}/api/log`
export const LINK_CUSTOMFIELDGROUPS = `${API_URL}/api/customfieldgroups`
export const LINK_REFUND = `${API_URL}/api/request/refund`
export const LINK_CAPTURE = `${API_URL}/api/request/capture`
export const LINK_CANCEL = `${API_URL}/api/request/cancel`
export const LINK_DELETE = `${API_URL}/api/request/delete`

// get paymnentlink by id and return response data
export function getPaymentLink(linkId:string): Promise<PaymentLink | ErrorMessage | boolean> {
    return axios.post(LINK_API,{ linkId })
        .then(response => response.data as unknown as PaymentLink)
        .catch(err => typeof err.error!=='undefined' ? err : false)
}

export function getPaymentLinks(): Promise<Array<PaymentLink>| ErrorMessage> {
    return axios.post(LINKS_API)
        .then(response => response.data as unknown as Array<PaymentLink>)
        .catch(err => ({status:'error', error: err?.response?.data?.error??"Unknown error"}) as ErrorMessage)
}

export function tablePaymentLinks(pageIndex:number,pageSize:number,localFilter:Array<any>, globalFilter:string, sortBy:Array<any>): Promise<{ results: Array<PaymentLink>; count: any }| ErrorMessage> {
    return axios.post(`${LINKS_API}/table`,
        {pageIndex, pageSize, localFilter, globalFilter, sortBy})
        .then(response => response.data as unknown as { results: Array<PaymentLink>; count: any })
        .catch(err => ({status:'error', error: err?.response?.data?.error??"Unknown error"}) as ErrorMessage)
}

export function sendPaymentLinkLog(linkId:string, title: string, type: string = "open-box") {
    axios.post(LINK_LOG_API, { linkId, title, type })
}

export function getPaymentLinkLogs(linkId:string): Promise<Array<RequestLog> | null> {
    return axios.post(LINK_LOG_GET, { linkId })
        .then(response => response.data as unknown as Array<RequestLog>)
        .catch(err => null)
}

export function getPaymentMethods(linkId:string) {
    return axios.post(LINK_METHODS_API, { linkId })
        .then(response => response.data)
        .catch(err => typeof err.error!=='undefined' ? err : false)
}

export function refundPaymentLink(linkId:number, amount:number, reason?: string): Promise<ErrorMessage | StatusMessage> {

    return axios.post(LINK_REFUND, {linkId, amount, reason})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function capturePaymentLink(linkId:number, amount:number): Promise<ErrorMessage | StatusMessage> {

    return axios.post(LINK_CAPTURE, {linkId, amount})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function cancelPaymentLink(linkId:number): Promise<ErrorMessage | StatusMessage> {

    return axios.post(LINK_CANCEL, {linkId})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function deletePaymentLink(linkId:number): Promise<ErrorMessage | StatusMessage> {

    return axios.post(LINK_DELETE, {linkId})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

export function getCustomFieldGroups(): Promise<Array<CustomFieldGroups>|ErrorMessage> {
    return axios.get(LINK_CUSTOMFIELDGROUPS)
        .then(response => response.data as unknown as Array<CustomFieldGroups>)
        .catch(err => err.response.data as ErrorMessage)
}

export function createPaymentLink(data:{[index: string]:any}): Promise<StatusMessage|ErrorMessage> {
    return axios.post(CREATE_API, data)
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}