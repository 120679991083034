import React, {FC, ReactNode, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {CustomFieldGroups, PersonalLink, TaxClasses, useAuth, UserModel} from "../../auth";
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";
import Select, {components, DropdownIndicatorProps} from "react-select";
import Skeleton from "react-loading-skeleton";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
// @ts-ignore
import DragSort from '@yaireo/dragsort'
import Tags from "@yaireo/tagify/dist/react.tagify"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Crisp } from "crisp-sdk-web";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {getCustomFieldGroups} from "../../requests/core/_requests";
import TextEditor from "../../../../_metronic/partials/widgets/texteditor/TextEditor";
import SocialPicker, {SocialIcon} from "../../../../_metronic/partials/widgets/socialpicker/SocialPicker";
import {checkSlug, updateUser} from "../../auth/core/_requests";
import {getProductTaxes} from "../../products/core/_requests";
import {Gif, Grid} from '@giphy/react-components'
import {GifResult, GiphyFetch} from '@giphy/js-fetch-api'
import IGif from "@giphy/js-types/dist/gif";


const personalBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Personal page',
        path: '/personal',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

type GiphyProp = {
    value: string,
    label: ReactNode
}

const Overview: FC = () => {
    const {config} = useLayout()
    config.toolbar.layout = 'buttons'
    const intl = useIntl()
    const {currentUser, setCurrentUser} = useAuth()
    const [userDetails, setUserDetails] = useState<UserModel>();
    const [customFieldGroups, setCustomFieldGroups] = useState<any[]>();
    const [chosenFields, setChosenFields] = useState<any>();
    const navigate = useNavigate()
    const [copied, setCopied] = useState(false)
    const [isBusy, setIsBusy] = useState(false)
    const [socialIcons, setSocialIcons] = useState<SocialIcon[]>();
    const [slugError, setSlugError] = useState<null|string>(null);
    const [taxClasses, setTaxClasses] = useState<Array<TaxClasses>>();
    const [chosenTax, setChosenTax] = useState<any>();
    const [gifSearch, setGifSearch] = useState<string>("");
    const [chosenGif, setChosenGif] = useState<IGif|undefined>(undefined);
    const fetchGifs = (offset: number) => gf.search(gifSearch, { offset, limit: 10 })
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };

    const MySwal = withReactContent(Swal)
    const saveHandle = () => {
        if(typeof userDetails?.id === "undefined")
            return;
        setIsBusy(true)
        MySwal.fire({
            title: 'Saving...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })
        console.log(userDetails)
        updateUser(chosenFields?.value===0?({...userDetails,personalLink:{...userDetails?.personalLink,customfieldGroup:null} as PersonalLink} as UserModel):userDetails).then(res => {
            if(!("error" in res)){
                MySwal.fire({
                    title: <p>Personal page is saved!</p>,
                    /*html: <p>Something went wrong. Please try again later.</p>,*/
                    icon: 'success',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
                setCurrentUser(userDetails)
            }else if("error" in res){
                MySwal.fire({
                    title: <p>Error!</p>,
                    html: <>
                        <p>Something went wrong. Please try again later.</p>
                        <pre>{res.error}</pre>
                    </>,
                    icon: 'error',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
            }
            setIsBusy(false)
        })
    }
    const gf = new GiphyFetch('3nqHFo1i8VgFtTTYZHJANlSqVcL1eK8g')
    const tagifyRefDragSort = useRef()
    const [tagifyProps, setTagifyProps] = useState({})
    useEffect(() => {
        setTagifyProps({loading: true})

        // do something
        setTagifyProps((lastProps) => ({
            ...lastProps,
            whitelist: ["5","10","15","25","50","100"],
            showFilteredDropdown: true,
            loading: false
        }))
    }, [])
    const tagifySettings = {
        blacklist: [],
        maxTags: 6,
        pattern: /^[0-9\.]+$/,
        keepInvalidTags: false,
        //backspace: "edit",
        whitelist: ["5","10","15","25","50","100"],
        placeholder: "Leave empty to not show any suggestions",
        dropdown: {
            enabled: 0 // always show suggestions dropdown
        }
    }
    useMemo(() => {
        if(  tagifyRefDragSort.current )
            // @ts-ignore
            new DragSort(tagifyRefDragSort.current.DOM.scope, {
                selector: '.tagify__tag',
                callbacks: {
                    dragEnd: onDragEnd
                }
            })
    }, [tagifyRefDragSort.current])
    function onDragEnd(elm:any){
        // @ts-ignore
        tagifyRefDragSort.current.updateValueByDOMTags()
    }
    let controller = new AbortController()
    let slugWaiter: NodeJS.Timeout|null = null;
    const [personalSlug, setPersonalSlug] = useState<string>();
    const [rawGroups, setRawGroups] = useState<CustomFieldGroups[]>();
    useEffect(() => {
        if(typeof currentUser?.id==="undefined")
            return
        getProductTaxes().then(taxClasses => {
            const newTaxClasses = "error" in taxClasses? [] : taxClasses
            newTaxClasses.unshift({id:0, name: 'No tax', status: true, createdAt: new Date(), updatedAt: new Date(), description:'',rates:[]} as TaxClasses)
            setTaxClasses(newTaxClasses)
        })
        getCustomFieldGroups().then(customFieldGroups => {
            if("error" in customFieldGroups)
                return
            setRawGroups(customFieldGroups)
            const newGroups = customFieldGroups.map(group => {
                return {
                    value: group.id,
                    label: <>{group.name} <small>({group.fields} fields)</small></>
                }
            })
            const defaultChoice = {value: 0, label: <>No custom fields</>}
            newGroups.unshift(defaultChoice)
            setCustomFieldGroups(newGroups)
            if(typeof currentUser?.personalLink?.customfieldGroup?.id !== "undefined")
                setChosenFields(newGroups.find(group => group.value === currentUser?.personalLink?.customfieldGroup?.id))
            else
                setChosenFields(defaultChoice)
        })
        setPersonalSlug(currentUser?.personalLink?.slug ?? '')
        setUserDetails(currentUser)
        if(currentUser?.personalLink?.gif)
            gf.gif(currentUser?.personalLink?.gif??'').then(gif=>setChosenGif(gif['data']))
        if(typeof currentUser?.socials !== "undefined" && currentUser?.socials)
            setSocialIcons(Object.entries(currentUser?.socials).reduce((n:Array<SocialIcon>,c:Array<string>)=>(n.push({link:c[1],icon:c[0]}),n),[]))
        else
            setSocialIcons([])
    }, [currentUser]);
    useEffect(()=>{
        if(typeof customFieldGroups === "undefined" || typeof userDetails?.personalLink === "undefined" || typeof rawGroups === "undefined")
            return
        if(chosenFields?.value===0)
            setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, customfieldGroup: null} as PersonalLink} as UserModel))
        else
            setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, customfieldGroup: (chosenFields?.value===0?null:(rawGroups?.find(group => group.id === chosenFields.value) ?? null))} as PersonalLink} as UserModel))
    },[chosenFields])

    useEffect(() => {
        if(typeof socialIcons === "undefined")
            return
        setUserDetails(oldDetails=>({...oldDetails, socials: socialIcons.reduce((n:{[index: string]:any},c:SocialIcon)=>(n[c['icon']]=c['link'],n),{})} as UserModel))
    }, [socialIcons]);
    const [slugSuccess, setSlugSuccess] = useState(false);
    useEffect(() => {
        if(gifSearch.length<1) return

    }, [gifSearch]);

    return (
        <>
            <PageTitle breadcrumbs={personalBreadCrumbs}>{intl.formatMessage({id: 'MENU.PERSONAL', defaultMessage: 'Personal Page'})}</PageTitle>
            <PageToolbar>
                {typeof currentUser?.personalLink?.slug !== "undefined"&& <a className='btn btn-white btn-sm me-5 btn-active-primary' rel={'noreferrer'} target={'_blank'} href={`${process.env.REACT_APP_LINK_URL}/${currentUser?.personalLink?.slug}`}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='me-3' /> Show personal page
                </a>}
                <button className='btn btn-light-primary btn-sm me-5' disabled={isBusy} onClick={e=>{saveHandle()}}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk" className='me-3' /> Save
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
            </PageToolbar>
            <form className="form d-flex flex-column gap-3 gap-lg-5">
                <div className="card card-flush py-4">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <h2>
                                Your personal page
                            </h2>
                            <span className='text-muted fw-bold fs-7'>Claim it if you haven't!</span>
                        </div>
                    </div>
                    <div className={"card-body text-left"}>
                        <div className="mb-5 fv-row">
                            <div className="d-flex flex-column">
                                {typeof currentUser?.id === "undefined"?<Skeleton width={'100%'} height={48}/>:<div className='input-group input-group-solid flex-grow-1'>
                                    <span className='pe-5 input-group-text'>
                                      https://payreque.st/
                                    </span>
                                    <input type="text" className={`form-control form-control-solid ${slugError!==null?'is-invalid':''} ${slugSuccess?'is-valid':''}`} placeholder="Enter your personal page slug"
                                           onChange={e=>{

                                               setSlugError(null)
                                               setSlugSuccess(false)
                                               setPersonalSlug(e.target.value)
                                               if(slugWaiter!==null)
                                                   clearTimeout(slugWaiter)
                                               slugWaiter = setTimeout(()=>{
                                                   controller.abort()
                                                   controller = new AbortController()
                                                   checkSlug(e.target.value, controller).then(res=>{
                                                       if(!("error" in res)) {
                                                           setSlugError(null)
                                                           setSlugSuccess(true)
                                                           setUserDetails(oldDetails=>({
                                                               ...oldDetails,
                                                               personalLink: res as unknown as PersonalLink
                                                           } as UserModel))
                                                       }else{
                                                           setSlugSuccess(false)
                                                           if(res.error === "taken"){
                                                               setSlugError(intl.formatMessage({id: 'PERSONAL.SLUG_TAKEN', defaultMessage: 'This slug is already taken'}))
                                                           }else{
                                                                setSlugError(intl.formatMessage({id: 'PERSONAL.SLUG_INVALID', defaultMessage: 'This slug is invalid'}))
                                                           }
                                                       }
                                                   })
                                               },400)
                                           }}
                                           value={personalSlug} />
                                    {/*<CopyToClipboard text={userDetails?.personalLink?.slug ?? ''} onCopy={() => {
                                        setCopied(true)
                                        setTimeout(() => setCopied(false),3000)
                                    }}>
                                        <button className="input-group-text btn btn-light flex-shrink-0 highlight-copy h-100 px-6 ms-0" onClick={e=>e.preventDefault()} style={{borderLeftStyle: 'solid',borderColor: '#e4e6ef',borderLeftWidth: '1px'}}>

                                        @ts-ignore
                                            <FontAwesomeIcon icon="fa-duotone fa-copy" className='fs-4 d-block' beatFade={copied} />
                                        </button>
                                    </CopyToClipboard>*/}
                                </div>}
                                {slugError !== null && <div className="invalid-feedback d-block text-center">{slugError}</div>}
                                {slugSuccess && <div className="valid-feedback d-block text-center">Your personal slug is accepted!</div>}
                            </div>
                        </div>
                    </div>
                </div>
                {typeof userDetails?.personalLink?.id === "number"&&
                    <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">
                        <div className="card card-flush py-4">
                            <div className="card-header pt-5">
                                <div className="card-title d-flex flex-column">
                                    <h2>
                                        Personal page settings
                                    </h2>
                                    <span className='text-muted fw-bold fs-7'>&nbsp;</span>
                                </div>
                            </div>
                            <div className={"card-body"}>
                                <label className="form-label text-start">Description</label>
                                {typeof userDetails?.personalLink?.description !== "undefined"?
                                    <TextEditor className='tiptapSmall' save={(description:string) => setUserDetails(oldDetails=>({...oldDetails,personalLink:{...oldDetails?.personalLink, description:description} as PersonalLink} as UserModel))}
                                                liveSave={true} content={userDetails?.personalLink?.description} />:
                                    <Skeleton width={'100%'} height={300} className='d-block' />}

                                <div className="row mb-3 mb-lg-5 mt-10">
                                    <div className="col-12 col-md-4 form-group">
                                        <label className="form-label text-start">Pre-defined amounts</label>
                                       {typeof userDetails?.personalLink?.amounts !== "undefined"?<Tags
                                            tagifyRef={tagifyRefDragSort}
                                            settings={tagifySettings}
                                            className='form-control form-control-solid py-1'
                                            {...tagifyProps}
                                            onChange={(e)=> {
                                                console.log(e.detail)
                                                setUserDetails(oldDetails=>({...oldDetails,
                                                    personalLink: {
                                                        ...oldDetails?.personalLink,
                                                        amounts: Object.entries(e.detail.tagify.getCleanValue()).map(v => v?.[1]?.value ?? '10')
                                                    } as PersonalLink
                                                } as UserModel))
                                            }}
                                            defaultValue={userDetails?.personalLink?.amounts!==null?userDetails?.personalLink?.amounts.join(','):tagifySettings.whitelist}/>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                    </div>
                                    <div className="col-12 col-md-3 form-group row">
                                        <label className="form-label text-start">Extra fields</label>
                                        {typeof chosenFields === "undefined"?<Skeleton width={'100%'} height={46}/>:
                                            <Select
                                                //@ts-ignore
                                                defaultValue={chosenFields}
                                                options={customFieldGroups}
                                                isLoading={typeof chosenFields === "undefined"}
                                                isSearchable={true}
                                                components={{ DropdownIndicator }}
                                                //@ts-ignore
                                                onChange={event => setChosenFields(customFieldGroups.find(x=>x.value===event.value) ?? null)}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        background: '#f5f8fa',
                                                        borderColor: '#f5f8fa',
                                                        color: '#5e6278',
                                                        transition: 'color 0.2s ease, background-color 0.2s ease',
                                                        borderRadius: '0.3rem'
                                                    }),
                                                    valueContainer: (provided) => ({
                                                        ...provided,
                                                        minHeight: 'calc(1.5em + 1rem + 2px)',
                                                        padding: '0.5rem 1rem',
                                                        fontSize: '1.25rem',
                                                    })
                                                }}
                                            />}
                                        <span className='text-muted fs-7'>Do you want to let the user fill in extra fields?</span>
                                    </div>
                                    <div className="col-12 col-md-2 form-group">
                                        <label className="form-label text-start">Tax</label>
                                        {taxClasses?<Select
                                            components={{DropdownIndicator}}
                                            //@ts-ignore
                                            defaultValue={chosenTax}
                                            isLoading={typeof taxClasses === "undefined"}
                                            // @ts-ignore
                                            options={taxClasses?.map(tax => ({value: tax.id, label: tax.name}))}
                                            onChange={event => {
                                                if(event === null)
                                                    return
                                                // @ts-ignore
                                                setChosenTax(taxClasses?.map(tax => ({value: tax.id, label: tax.name}))?.find(tax => tax.value === event?.value))
                                            }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    background: '#f5f8fa',
                                                    borderColor: '#f5f8fa',
                                                    color: '#5e6278',
                                                    transition: 'color 0.2s ease, background-color 0.2s ease',
                                                    borderRadius: '0.3rem'
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    minHeight: 'calc(1.5em + 1rem + 2px)',
                                                    padding: '0.5rem 1rem',
                                                    fontSize: '1.25rem',
                                                })
                                            }}/>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                    </div>
                                    <div className="col-10 col-md-2 form-group">
                                        <label className="form-label text-start">Additional Fee</label>
                                        <input type="number" min={0} step={0.01} className={`form-control form-control-solid`}
                                               placeholder="Enter zero of leave empty to have no additional fee" value={userDetails?.personalLink?.fee ?? ''}
                                               onChange={e=>{setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, fee: parseFloat(e.target.value) ?? 0} as PersonalLink} as UserModel))}} />
                                    </div>
                                    <div className="col-2 col-md-1 form-group">
                                        <label className="form-label text-start">&nbsp;</label>
                                        <select
                                            name='type'
                                            style={{fontSize:'1rem',lineHeight:'2.1rem'}}
                                            data-control='select2'
                                            data-hide-search='true'
                                            onChange={e=>{setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, feeType: e.target.value} as PersonalLink} as UserModel))}}
                                            className='form-select form-select-solid form-select-lg'
                                            defaultValue={userDetails?.personalLink?.feeType ?? 'Fixed'}
                                        >
                                            <option value='Fixed'>Fixed</option>
                                            <option value='Percentage'>Percentage</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-12 col-md-6 form-group">
                                        <label className="form-label text-start">Success URL</label>
                                        <input type="text" className={`form-control form-control-solid`}
                                               placeholder="Enter success URL" value={userDetails?.personalLink?.successurl ?? ''}
                                               onChange={e=>{setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, successurl: e.target.value ?? ''} as PersonalLink} as UserModel))}} />
                                        <span className='text-muted fs-7'>User will be redirected to this url after a successful payment. <span className="fw-bold">Leave empty to use our default!</span></span>
                                    </div>
                                    <div className="col-12 col-md-6 form-group">
                                        <label className="form-label text-end">Failed URL</label>
                                        <input type="text" className={`form-control form-control-solid`}
                                               placeholder="Enter failed URL" value={userDetails?.personalLink?.failedurl ?? ''}
                                               onChange={e=>{setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, failedurl: e.target.value ?? ''} as PersonalLink} as UserModel))}} />
                                        <span className='text-muted fs-7'>User will be redirected to this url after a failed payment. <span className="fw-bold">Leave empty to use our default!</span></span>
                                    </div>
                                </div>
                                <label className="form-label text-start">Social & contact links</label>
                                {/*
                                @ts-ignore */}
                                <SocialPicker values={socialIcons} setValues={setSocialIcons} addNewComponent={<><FontAwesomeIcon icon="fa-duotone fa-add" className="me-2"/>Add Social</>} addNewProps={{className:"btn btn-success rounded-pill"}}/>
                                <div className='form-text'>
                                    Drag 'n drop us to get the correct order!
                                </div>
                                <div className="row mb-10">
                                    <div className="col-12 col-md-6 form-group">
                                        <label className="form-label text-start mt-10">Success GIF</label>
                                        <div className='form-text'>
                                            Select a GIF that you want to show when a user has paid.
                                        </div>
                                        <input type="text" className={`form-control form-control-solid`}
                                               placeholder="What kind of gif do you want?" value={gifSearch}
                                               onChange={e=>setGifSearch(e?.target?.value ?? "")} />
                                        <div>
                                            <img
                                                alt='Giphy'
                                                src={toAbsoluteUrl('/media/giphy.png')}
                                                className='w-200px mt-2'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 form-group d-flex flex-column">
                                        {chosenGif && (<>
                                            <label className="form-label text-start mt-10" style={{marginLeft: 'auto',width:'200px'}}>Your current GIF</label>
                                            <Gif gif={chosenGif} width={200} style={{marginLeft: 'auto'}} />
                                            <button className="btn btn-danger btn-sm mt-1" onClick={e=>{setChosenGif(undefined);setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, gif: ""} as PersonalLink} as UserModel))}} style={{marginLeft: 'auto',width:'200px'}}>Remove GIF</button>
                                        </>)}
                                    </div>
                                </div>
                                <div className={"d-flex mt-5 justify-content-center"}>
                                    {gifSearch.length>0&&(
                                        <Grid width={800} columns={3} gutter={6} fetchGifs={fetchGifs} key={gifSearch} onGifClick={(gif,e)=>{
                                            e.preventDefault();
                                            setChosenGif(gif)
                                            setGifSearch("")
                                            setUserDetails(oldDetails=>({...oldDetails, personalLink: {...oldDetails?.personalLink, gif: gif.id} as PersonalLink} as UserModel))
                                        }} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
            </form>
        </>
    )
}

export {Overview}
