/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {Customers, useAuth} from "../../../../app/modules/auth";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {getHeads} from "../../../../app/modules/auth/core/_requests";

type Props = {
    className: string,
    image: string
    title: string
}

type Head = {
    id: number,
    name: string,
    avatar: string
}

const CustomerHeads: React.FC<Props> = ({className, image, title}) => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const [heads, setHeads] = useState<Array<Head>>()
    const [count, setCount] = useState<number>(0)
    useEffect(() => {
        if(typeof currentUser === "undefined")
            return
        getHeads().then((response) => {
            if("error" in response)
                return
            setHeads(response.heads)
            setCount(response.count)
        })
    }, [currentUser]);

    return (
        <div
            className={`card bgi-no-repeat ${className}`}
            style={{
                backgroundPosition: '101% top',
                backgroundSize: '45% auto',
                backgroundImage: `url(${toAbsoluteUrl('/media/shapes/' + image)})`,
            }}
        >
            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
                <div className="fs-2hx fw-bolder">{typeof heads !== "undefined"?(count+heads.length):<Skeleton width={30} height={30}/>}</div>
                <div className="fs-4 fw-bold text-gray-400 mb-7">{typeof heads !== "undefined"?title:<Skeleton width={110} height={20}/>}</div>

                <div className="symbol-group symbol-hover mb-2">
                    {typeof heads !== "undefined"?(
                        <>
                            {heads.map(customer => (
                                <OverlayTrigger
                                    key={"customertooltip-"+customer.id}
                                    placement='top'
                                    overlay={<Tooltip id={"tooltip-customertooltip-"+customer.id}>{customer.name}</Tooltip>}
                                >
                                <Link key={"customerhead-"+customer.id}
                                      to={'/customer/'+customer.id}
                                      className="symbol symbol-circle symbol-40px mb-2"
                                      data-bs-toggle='tooltip'
                                      data-bs-boundary='window'
                                      data-bs-placement='top'
                                      title={customer.name}>
                                    {customer.avatar===''?(
                                        <span className='symbol-label bg-dark text-gray-300 fs-3 fw-bolder'>{customer.name.charAt(0)+customer.name.charAt(1)}</span>
                                    ):(<img src={customer.avatar} alt={customer.name} />)}
                                </Link>
                                </OverlayTrigger>
                            ))}
                            {count>0&&(
                                <OverlayTrigger
                                    key='all-customers'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-all-customers'>{intl.formatMessage({id: 'CUSTOMERS.ALL', defaultMessage: 'All customers'})}</Tooltip>}
                                >
                                <Link to={'/customers/'}
                                      className="symbol symbol-circle symbol-40px mb-2">
                                    <span className='symbol-label bg-dark text-gray-300 fs-3 fw-bolder'>+{count}</span>
                                </Link>
                                </OverlayTrigger>
                            )}
                        </>
                    ):(<Skeleton inline={true} count={5} height={40} width={40} circle={true} style={{marginLeft:-10}}/>)}
                </div>
                <div className="d-flex mt-auto">
                    <Link to="/customers/" className="btn btn-primary btn-sm me-3">
                        {intl.formatMessage({id: 'CUSTOMERS.ALL', defaultMessage: 'All customers'})}
                    </Link>
                    <Link to="/customer/new/" className="btn btn-light btn-sm">
                        {intl.formatMessage({id: 'CUSTOMERS.NEW', defaultMessage: 'Add new customer'})}
                    </Link>
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}

export {CustomerHeads}
