import axios from 'axios'
import {ErrorMessage, StatusMessage} from "../../requests/core/_models";
import {Metas, Products, ProductType, TaxClasses, Transactions} from "../../auth";

const API_URL = process.env.REACT_APP_API_URL

export const PRODUCTS_API = `${API_URL}/api/products`
export const PRODUCT_API = `${API_URL}/api/product/`
export const PRODUCTS_DUPLICATE_API = `${API_URL}/products/duplicate/`

export const controller = new AbortController();

export function getProducts(): Promise<Array<Products>|ErrorMessage> {
    return axios.get(PRODUCTS_API, {signal: controller.signal})
        .then(response => response.data as Array<Products>)
        .catch(err => err.response.data as ErrorMessage)
}

export function tableProducts(pageIndex:number, pageSize:number, localFilter:Array<any>, globalFilter:string, sortBy:Array<any>): Promise<{ results: Array<Products>; count: any }|ErrorMessage> {
    return axios.post(`${PRODUCTS_API}/table`, {pageIndex, pageSize, localFilter, globalFilter, sortBy})
        .then(response => response.data as unknown as { results: Array<Products>; count: any })
        .catch(err => err.response.data as ErrorMessage)
}

export function statusChangeProduct(pid:number, status: string): Promise<ErrorMessage|StatusMessage> {
    return axios.post(`${PRODUCTS_API}/status`, {pid, status},{signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}
export function statusChangeProducts(pids:Array<number>, status: string): Promise<ErrorMessage|StatusMessage> {
    return axios.post(`${PRODUCTS_API}/statusbulk`, {pids, status},{signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

//duplicate product fetch
export function doDuplicateProduct(pid:number): Promise<StatusMessage|ErrorMessage> {
    return axios.get(`${PRODUCTS_DUPLICATE_API}${pid}`,{signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

export function getProduct(pid:number): Promise<Products|ErrorMessage> {
    return axios.get(`${PRODUCT_API}${pid}`, {signal: controller.signal})
        .then(response => response.data as Products)
        .catch(err => err.response.data as ErrorMessage)
}

export function getProductTypes(): Promise<Array<ProductType>|ErrorMessage> {
    return axios.get(`${PRODUCTS_API}/types`, {signal: controller.signal})
        .then(response => response.data as Array<ProductType>)
        .catch(err => err.response.data as ErrorMessage)
}

export function getProductMetas(): Promise<Array<Metas>|ErrorMessage> {
    return axios.get(`${PRODUCTS_API}/meta`, {signal: controller.signal})
        .then(response => response.data as Array<Metas>)
        .catch(err => err.response.data as ErrorMessage)
}

export function getProductTaxes(): Promise<Array<TaxClasses>|ErrorMessage> {
    return axios.get(`${PRODUCTS_API}/taxes`, {signal: controller.signal})
        .then(response => response.data as Array<TaxClasses>)
        .catch(err => err.response.data as ErrorMessage)
}
export function tableProductTaxes(pageIndex:number, pageSize:number, localFilter:Array<any>, globalFilter:string, sortBy:Array<any>): Promise<{ results: Array<TaxClasses>; count: any }|ErrorMessage> {
    return axios.post(`${PRODUCTS_API}/taxes/table`,{pageIndex, pageSize, localFilter, globalFilter, sortBy})
        .then(response => response.data as unknown as { results: Array<TaxClasses>; count: any })
        .catch(err => err.response.data as ErrorMessage)
}

export function saveProduct(product:Products): Promise<StatusMessage|ErrorMessage> {
    return axios.post(`${PRODUCT_API}${product.id}/save`, product, {signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

export function deleteProduct(pid:number): Promise<StatusMessage|ErrorMessage> {
    return axios.get(`${PRODUCT_API}${pid}/delete`, {signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}