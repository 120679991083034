/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useState,useEffect,useCallback} from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import TextEditorMenuBar from "./TextEditorMenuBar";
//import Image from '@tiptap/extension-image'
import {TextAlign} from "@tiptap/extension-text-align";
 import {ImageResize} from "./modules/ImageResize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type Props = {
    className: string
    save: Function
    liveSave?: boolean
    content:string
}

const TextEditor: FC<Props> = ({
   className,
   save,
   liveSave = true,
    content
}) => {
    let saveJob:any = null;
    const editor = useEditor({
        extensions: [
            // @ts-ignore
            StarterKit,Text, TextStyle, Color, ImageResize.configure({resizeIcon: <FontAwesomeIcon icon="fa-duotone fa-circle-dot" />}), Link.configure({openOnClick: false,}),
            TextAlign.configure({types: ['heading', 'paragraph'],})
        ],
        content: content??'<p>&nbsp;</p>',
        onUpdate: ({editor}) => {
            if(saveJob!==null)
                window.clearTimeout(saveJob)
            saveJob = window.setTimeout(()=>save(editor.getHTML()),1000)
        }
    })
    return <>
        {editor!==null && (
            <div className={clsx('fv-row border rounded-1 tiptapEditor',className)}>
                <TextEditorMenuBar editor={editor}/>
                <div className='py-5 px-4'>
                    <EditorContent editor={editor} />
                </div>
            </div>
        )}
    </>
}

export default TextEditor