import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Customers, PaymentLinks, Subscription, Transactions, useAuth} from "../../auth";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router";
import {cancelPaymentLink, capturePaymentLink, deletePaymentLink, getPaymentLink, getPaymentLinkLogs, refundPaymentLink} from "../../requests/core/_requests";
import {PaymentLink, RequestLog} from "../../requests/core/_models";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import clsx from "clsx";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {QRCodeSVG} from 'qrcode.react';
import {downloadBlob, downloadDataUri} from "../../../../_metronic/helpers/svgDownload";
import {Canvg} from "canvg";
import {Link, useNavigate} from "react-router-dom";
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";

import {getCustomFields, transactionType} from "../../requests/RequestHelper";
import {ShowIcon} from "../../requests/components/ShowIcon";
import {RequestStatus} from "../../requests/components/RequestStatus";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Column, useTable} from "react-table";
import {getAllTransactions} from "../../dashboard/core/_requests";
import {getCustomer, getCustomerRequests, getCustomerTransactions, saveCustomer} from "../core/_requests";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import countryList from 'react-select-country-list'
import {SubscriptionStatus} from "../../products/components/SubscriptionStatus";

const transactionsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Customers',
        path: '/customers',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

type CustomerPrTable = {
    columns: Array<Column>,
    rawdata: Array<any>
}

const PrTable: React.FC<CustomerPrTable> = ({columns,rawdata}) => {
    const data = useMemo(() => rawdata,[])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns,data})
    return (
        <table {...getTableProps({className:'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'})}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps({className:'fw-bolder text-muted'})}>
                    {headerGroup.headers.map(column => (
                        // @ts-ignore
                        <th {...column.getHeaderProps({className:column.className})}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const CustomerView: FC = () => {
    const {config} = useLayout()
    const { customerid } = useParams()
    const intl = useIntl()
    const {currentUser} = useAuth()
    const MySwal = withReactContent(Swal)
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [paymentLinks, setPaymentLinks] = useState<Array<any>>()
    const [subscriptions, setSubscriptions] = useState<Array<any>>()
    const [linksLoaded, setLinksLoaded] = useState(false);
    const [payments, setPayments] = useState<Array<any>>();
    const [paymentsLoaded, setPaymentsLoaded] = useState(false);
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [postal, setPostal] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [note, setNote] = useState('');
    /*const [paymentLink, setPaymentLink] = useState<PaymentLink>()
    const [paymentLogs, setPaymentLogs] = useState<Array<RequestLog>>()*/
    const [customer, setCustomer] = useState<Customers>();
    const [beatFade, setBeatFade] = useState(false)
    const [dataUpdate, setDataUpdate] = useState(0)
    const [isSaving, setIsSaving] = useState(false);
    const countryOptions = useMemo(() => countryList().getData(), [])

    config.toolbar.layout = 'buttons'

    const fillLinks = () => {
        if(typeof customerid === "undefined") {
            setPaymentLinks([])
            return
        }
        getCustomerRequests(customerid).then((response) => {
            if("error" in response){
                setPaymentLinks([])
                console.error(response.error)
                return
            }
            setPaymentLinks(response
                .filter(p => p.customer !== null && p.customer.id.toString() === customerid)
                .map(request => {
                    return {
                        l_title: (
                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {request.title}
                            </span>
                        ),
                        l_amount: (
                            <>
                                <span data-filter={request.amount}
                                      className='text-dark fw-bolder text-hover-primary fs-6'>
                                  <CurrencyFormat currency={request.currency ?? currentUser?.currency} amount={request.amount}/>
                                </span>
                            </>
                        ),
                        l_type: (
                            <span className="badge badge-light-primary">
                                {transactionType(intl,request.type)}
                            </span>
                        ),
                        l_status: (
                            <RequestStatus status={request.status} size='sm' />
                        ),
                        l_paid: (
                            <>
                                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {intl.formatDate(request.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                </span>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {intl.formatTime(request.createdAt,{hour12:false})}
                                </span>
                            </>
                        ),
                        l_actions: (
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <Link
                                    to={`/request/${request?.urlpath}`}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    {/*
                                    @ts-ignore*/}
                                    <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3' />
                                </Link>
                                <a href={request?.directlink} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                    {/*
                                    @ts-ignore*/}
                                    <FontAwesomeIcon icon="fa-duotone fa-external-link-alt" className='fs-3' />
                                </a>
                            </div>
                        )
                    }
                }))
        });
    }
    const fillPayments = () => {
        if(typeof customerid === "undefined"){
            setPayments([])
            return
        }
        getCustomerTransactions(customerid)
            .then(transactions => {
            if("error" in transactions){
                setPayments([])
                console.error(transactions.error)
                return
            }
            setPayments(transactions
                .filter(transaction => typeof transaction.customer !== "undefined" && transaction.customer.id.toString() === customerid)
                .map(transaction => {
                    return {
                        amount: (
                            <>
                                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                  <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                                </span>
                                {typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && transaction.currency !== null && transaction.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                    <CurrencyFormat currency={currentUser.currency} amount={transaction.amountBase}/>
                                  </span>
                                )}
                            </>
                        ),
                        type: (
                            <span className="badge badge-light-primary">
                            {transactionType(intl, transaction.type)}
                        </span>
                        ),
                        paid: (
                            <>
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                  {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                </span>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                  {intl.formatTime(transaction.createdAt,{hour12:false})}
                </span>
                            </>
                        ),
                        actions: (
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <Link
                                    to={transaction?.urlpath ?? '/'}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    {/*
                       @ts-ignore*/}
                                    <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3' />
                                </Link>
                            </div>
                        )
                    }
                }))
        })
    }
    useEffect(() => {
        if(typeof currentUser=== "undefined")
            return
        if(typeof customerid === "undefined") {
            setName('')
            setEmail('')
            setGender('')
            setPhone('')

            //setLinksLoaded(true)
            setPaymentLinks([])
            //setPaymentsLoaded(true)
            setPayments([])
            setStreet('')
            setPostal('')
            setCity('')
            setCountry('')
            setNote('')
            setCustomer({
                id: 0,
                name: '',
                email: '',
                gender: '',
                phone: '',
                avatar: '',
                createdAt: new Date(),
                street: '',
                postal: '',
                city: '',
                country: '',
                note: '',
                subscriptions: []
            } as Customers)
            setSubscriptions([])
        }else{
            getCustomer(customerid).then(customerExtended => {
                if(typeof customerExtended !== "boolean") {
                    setName(customerExtended?.name ?? '')
                    setEmail(customerExtended?.email ?? '')
                    setGender(customerExtended?.gender ?? '')
                    setPhone(customerExtended?.phone ?? '')
                    setStreet(customerExtended?.street ?? '')
                    setPostal(customerExtended?.postal ?? '')
                    setCity(customerExtended?.city ?? '')
                    setCountry(customerExtended?.country ?? '')
                    setNote(customerExtended?.note ?? '')
                    setCustomer(customerExtended)
                    fillLinks()
                    fillPayments()
                    setSubscriptions((customerExtended?.subscriptions ?? []).map(subscription => ({
                        id: subscription.id,
                        name: <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {subscription.name}
                        </span>,
                        price: <>
                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                              <CurrencyFormat currency={subscription.currency ?? currentUser?.currency} amount={subscription.price}/>
                            </span>
                        </>,
                        status: <SubscriptionStatus status={subscription.status}/>,
                        next: <>
                            {subscription.next!==null && <>
                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                              {intl.formatDate(subscription.next,{year:'numeric',month:'long',day:'numeric'})}
                            </span>
                                <span className='text-muted fw-bold text-muted d-block fs-8'>
                              Created at: {intl.formatTime(subscription.start,{hour12:false})}
                            </span>
                            </>}
                        </>,
                        actions: <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link
                                to={`/subscription/${subscription?.id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                {/*
                                 @ts-ignore*/}
                                <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3' />
                            </Link>
                        </div>
                    })))
                    setLoadingData(false)
                }
            })
        }
    }, [currentUser]);
    useEffect(() => {
        if(typeof customerid !== "undefined") {
            getCustomer(customerid).then(customerExtended => {
                if (typeof customerExtended !== "boolean") {
                    setStreet(customerExtended?.street ?? '')
                    setPostal(customerExtended?.postal ?? '')
                    setCity(customerExtended?.city ?? '')
                    setCountry(customerExtended?.country ?? '')
                    setNote(customerExtended?.note ?? '')
                    setCustomer(customerExtended)
                    setSubscriptions((customerExtended?.subscriptions ?? []).map(subscription => ({
                        id: subscription.id,
                        name: <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {subscription.name}
                            </span>,
                        price: <>
                                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                  <CurrencyFormat currency={subscription.currency ?? currentUser?.currency}
                                                  amount={subscription.price}/>
                                </span>
                        </>,
                        status: <SubscriptionStatus status={subscription.status}/>,
                        next: <>
                            {subscription.next !== null && <>
                                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {intl.formatDate(subscription.next, {year: 'numeric', month: 'long', day: 'numeric'})}
                                </span>
                                <span className='text-muted fw-bold text-muted d-block fs-8'>
                                  Created at: {intl.formatTime(subscription.start, {hour12: false})}
                                </span>
                            </>}
                        </>,
                        actions: <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link
                                to={`/subscription/${subscription?.id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                {/*
                                     @ts-ignore*/}
                                <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3'/>
                            </Link>
                        </div>
                    })))
                }
            })
        }
    }, [customerid]);

    const triggerUpdate = () => setDataUpdate(dataUpdate+1)
    const navigate = useNavigate()
    const linkColumns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.TITLE', defaultMessage: 'Title'}),
            accessor: 'l_title',
            className: 'min-w-150px'
        },{
            Header: intl.formatMessage({id: 'TABLE.AMOUNT', defaultMessage: 'Amount'}),
            accessor: 'l_amount',
            className: 'min-w-80px'
        },{
            Header: intl.formatMessage({id: 'TABLE.TYPE', defaultMessage: 'Type'}),
            accessor: 'l_type',
            className: 'min-w-120px'
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'l_status',
            className: 'min-w-120px'
        },{
            Header: intl.formatMessage({id: 'TABLE.CREATEDAT', defaultMessage: 'Created at'}),
            accessor: 'l_paid',
            className: 'min-w-120px'
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'l_actions',
            className: 'min-w-0px text-end'
        }
    ], [])
    const paymentColumns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.AMOUNT', defaultMessage: 'Amount'}),
            accessor: 'amount',
            className: 'min-w-80px'
        },{
            Header: intl.formatMessage({id: 'TABLE.TYPE', defaultMessage: 'Type'}),
            accessor: 'type',
            className: 'min-w-120px'
        },{
            Header: intl.formatMessage({id: 'TABLE.PAIDON', defaultMessage: 'Paid on'}),
            accessor: 'paid',
            className: 'min-w-120px'
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end'
        }
    ], [])
    const subsColumns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-150px'
        },{
            Header: intl.formatMessage({id: 'TABLE.PRICE', defaultMessage: 'Price'}),
            accessor: 'price',
            className: 'min-w-80px'
        },{
            Header: intl.formatMessage({id: 'TABLE.NEXT', defaultMessage: 'Next Payment'}),
            accessor: 'next',
            className: 'min-w-120px'
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-80px'
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end'
        }
    ], [])

    const startSave = () => {
        setIsSaving(true)
        saveCustomer({customerId:customerid,name,email,gender,phone,street,postal,city,country,note}).then(response => {
            if(response.status==='success'){
                MySwal.fire({
                    title: <p>Customer is saved!</p>,
                    /*html: <p>Something went wrong. Please try again later.</p>,*/
                    icon: 'success',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000,
                    didClose() {
                        if(typeof customerid === "undefined" && "id" in response)
                            navigate(`/customer/${response.id}`)
                    }
                })
            }else if("error" in response){
                MySwal.fire({
                    title: <p>Error!</p>,
                    html: <>
                        <p>Something went wrong. Please try again later.</p>
                        <pre>{response.error}</pre>
                    </>,
                    icon: 'error',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
            }
            setIsSaving(false)
        })
    };

    return (
        <>
            <PageTitle breadcrumbs={transactionsBreadCrumbs}>{`Customer ${customer?.name??""}`}</PageTitle>
            <PageToolbar>
                <button className='btn btn-light-primary w-100 btn-sm' disabled={isSaving} onClick={startSave}>
                    Save Customer
                    {/*
                    @ts-ignore */}
                    {isSaving && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
            </PageToolbar>
            <div className="row g-5">
                <div className={clsx("col-12",typeof customerid === "undefined"?'':'col-md-4')}>
                    <form action={'/'} method='POST' className='card mb-5 mb-xl-10'>
                        <div className='card-header border-0 py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Details</span>
                                <span className='text-muted fw-bold fs-7'>Edit your customer details</span>
                            </h3>
                        </div>
                        <div className='card-body py-3 row'>
                            <div className={typeof customerid !== "undefined"?'col-12':'col-md-6 col-12'}>
                                {/* show avatar on the left, name and mail next to it*/}
                                {typeof customerid !== "undefined" &&<>
                                    <div className='row'>
                                        <div className='col-md-3 col-12'>
                                            <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                                {typeof customer?.avatar !=="undefined"?<img src={customer?.avatar} alt={customer?.name} />:<Skeleton width={40} height={40} />}
                                            </div>
                                        </div>
                                        <div className='col-md-9 col-12'>
                                            {typeof customer?.email ==="undefined" ?? <Skeleton width={100} height={18} />}
                                            <span className='d-block text-muted fw-bold fs-7'>{customer?.email ?? <Skeleton width={120} height={14}/> }</span>
                                        </div>
                                    </div>
                                    <div className="separator separator-dashed border-secondary my-10"></div>
                                </>}

                                <div className={'mb-5'}>
                                    <label className="form-label">Name</label>
                                    {typeof customer?.name !=="undefined"?<input
                                        type="text"
                                        className="form-control form-control-solid"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                                <div className={'mb-5'}>
                                    <label className="form-label">Email</label>
                                    {typeof customer?.email !=="undefined"?<input
                                        type="email"
                                        className="form-control form-control-solid"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                                <div className={'mb-5'}>
                                    <label className="form-label">Gender</label>
                                    {typeof customer?.gender !== "undefined"?(<>
                                        <label className="form-check form-check-custom form-check-solid mb-5" onClick={()=>gender==='male'?setGender(''):setGender('male')}>
                                            <input className="form-check-input" type="checkbox" value="male" name="gender" checked={gender==='male'}/>
                                            <span className="form-check-label" onClick={()=>gender==='male'?setGender(''):setGender('male')}>
                                                Male
                                            </span>
                                        </label>
                                        <label className="form-check form-check-custom form-check-solid mb-5" onClick={()=>gender==='female'?setGender(''):setGender('female')}>
                                            <input className="form-check-input" type="checkbox" value="female" name="gender" checked={gender==='female'}/>
                                            <span className="form-check-label" onClick={()=>gender==='female'?setGender(''):setGender('female')}>
                                                Female
                                            </span>
                                        </label>
                                        <label className="form-check form-check-custom form-check-solid" onClick={()=>gender==='other'?setGender(''):setGender('other')}>
                                            <input className="form-check-input" type="checkbox" value="other" name="gender" checked={gender==='other'}/>
                                            <span className="form-check-label" onClick={()=>gender==='other'?setGender(''):setGender('other')}>
                                                Other
                                            </span>
                                        </label>
                                    </>):<Skeleton width={85} height={25} count={3} className='d-block' />}
                                </div>
                            </div>
                            <div className={typeof customerid !== "undefined"?'col-12':'col-md-6 col-12'}>
                                <div className={'mb-5'}>
                                    <label className="form-label">Phone</label>
                                    {typeof customer?.phone !== "undefined"?<PhoneInput
                                        value={phone}
                                        className="input-group input-group-lg input-group-solid"
                                        numberInputProps={{
                                            className: "form-control form-control-lg form-control-solid",
                                            name: "phone"
                                        }}
                                        // @ts-ignore
                                        onChange={setPhone}/>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                                <div className={'mb-5'}>
                                    <label className="form-label">Street</label>
                                    {typeof customer?.street !== "undefined"?<input
                                        type="text"
                                        name="street"
                                        className="form-control form-control-solid"
                                        value={street}
                                        onChange={e => setStreet(e.target.value)}
                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                                <div className={'mb-5'}>
                                    <label className="form-label">Postal</label>
                                    {typeof customer?.postal !== "undefined"?<input
                                        type="text"
                                        name="postal"
                                        className="form-control form-control-solid"
                                        value={postal}
                                        onChange={e => setPostal(e.target.value)}
                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                                <div className={'mb-5'}>
                                    <label className="form-label">City</label>
                                    {typeof customer?.city !== "undefined"?<input
                                        type="text"
                                        name="city"
                                        className="form-control form-control-solid"
                                        value={city}
                                        onChange={e => setCity(e.target.value)}
                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                                <div className={'mb-5'}>
                                    <label className="form-label">Country</label>
                                    {typeof customer?.country !== "undefined"?
                                        <Select
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    background: '#f5f8fa',
                                                    padding: '0.75rem 1rem',
                                                    fontSize: '1.1rem',
                                                    color: '#3f4254',
                                                    border: 0
                                                })
                                            }}
                                            // @ts-ignore
                                            options={countryOptions}
                                            defaultValue={countryOptions.find(_country => _country.value === country.toUpperCase())}
                                            onChange={value => {
                                                    // @ts-ignore
                                                    setCountry(value.value ?? '')
                                                }
                                            }
                                        />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                </div>
                            </div>
                            <div className={'mb-5'}>
                                <label className="form-label">Note</label>
                                {typeof customer?.note !== "undefined"?<textarea
                                    name="note"
                                    className="form-control form-control-solid"
                                    onChange={e => setNote(e.target.value)}
                                >{note}</textarea>:<Skeleton width={'100%'} height={100} className='d-block' />}
                            </div>
                        </div>
                        <div className={`card-footer py-5 justify-content-center d-flex`}>
                            <button className="btn btn-light-primary w-75 btn-sm" disabled={isSaving} onClick={startSave}>
                                Save Customer
                                {/*
                                @ts-ignore */}
                                {isSaving && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                            </button>
                        </div>
                    </form>
                </div>
                {typeof customerid !== "undefined" &&
                    <div className="col-md-8 col-12">

                        <ul
                            className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
                            role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link text-active-primary pb-4 active"
                                   data-bs-toggle="tab" href="#pr_payment_links"
                                   aria-selected="true" role="tab">
                                    Payment Links</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link text-active-primary pb-4"
                                   data-bs-toggle="tab" href="#pr_payments"
                                   aria-selected="false" role="tab">
                                    Payments</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link text-active-primary pb-4"
                                   data-kt-countup-tabs="true" data-bs-toggle="tab"
                                   href="#pr_subscriptions" data-kt-initialized="1"
                                   aria-selected="false" role="tab">
                                    Subscriptions</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pr_customer_tabs">
                            <div className="tab-pane fade active show" id="pr_payment_links"
                                 role="tabpanel">
                                <div className='card mb-10 mb-xl-10'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Payment Links</span>
                                            <span className='text-muted fw-bold fs-7'>All payment links connected to your customer</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        {typeof paymentLinks !== "undefined" ? (<PrTable columns={linkColumns} rawdata={paymentLinks}/>) : (
                                            <>
                                                <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                                                <Skeleton width={'100%'} height={55} count={10} className='mb-3'/>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pr_payments"
                                 role="tabpanel">
                                <div className='card mb-10 mb-xl-10'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Payments</span>
                                            <span
                                                className='text-muted fw-bold fs-7'>All transactions your customer did</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        {typeof payments !== "undefined" ? (<PrTable columns={paymentColumns} rawdata={payments}/>) : (
                                            <>
                                                <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                                                <Skeleton width={'100%'} height={55} count={10} className='mb-3'/>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pr_subscriptions"
                                 role="tabpanel">
                                <div className='card mb-10 mb-xl-10'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Subscriptions</span>
                                            <span
                                                className='text-muted fw-bold fs-7'>All transactions your customer did</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        {typeof subscriptions === "undefined" ?
                                            <>
                                                <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                                                <Skeleton width={'100%'} height={55} count={10} className='mb-3'/>
                                            </> : <PrTable columns={subsColumns} rawdata={subscriptions}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export {CustomerView}