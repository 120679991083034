/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from "react";
import {StepInterface} from "../../core/_scheme";

const Step1: FC<StepInterface> = ({data, setData}) => {
    const setType = (type:number) => {
        const newData = {...data}
        newData.type = type
        setData(newData)
    }
    return <>
        <div className="pb-10 pb-lg-15">
            <h2 className="fw-bolder d-flex align-items-center text-dark">
                Choose Request Type
            </h2>
            <div className="text-gray-400 fw-bold fs-6">
                What kind of request do you want to create?
            </div>
        </div>
        <div className="fv-row fv-plugins-icon-container">
            <div className="row">
                <div className="col-lg-6">
                    <input type="radio" className="btn-check" name="type" value="1" onClick={event=>{setType(1)}} checked={data.type===1} />
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                            onClick={event=>{setType(1)}}>
                            <span className="svg-icon svg-icon-3x me-5">
                                                    <img
                                                        src="https://media.payrequest.nl/images/brand/product-icons/payment-links-icon.svg"
                                                        className="mw-60px" />
                                                </span>
                            <span className="d-block fw-bold text-start">
                                                    <span className="text-dark fw-bolder d-block fs-4 mb-2">
                                                        Payment Link                                                    </span>
                                                    <span className="text-gray-400 fw-bold fs-6">
                                                        A Normal payment (static) link                                                    </span>
                                                </span>
                        </label>
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <div className="col-lg-6">
                    <input type="radio" className="btn-check" name="request-type" value="2"  onClick={event=>{setType(2)}} checked={data.type===2} />
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                            onClick={event=>{setType(2)}}>
                            <span className="svg-icon svg-icon-3x me-5">
                                                    <img
                                                        src="https://media.payrequest.nl/images/brand/product-icons/donation-pages-icon.svg"
                                                        className="mw-60px" />
                                                </span>
                            <span className="d-block fw-bold text-start">
                                                    <span className="text-dark fw-bolder d-block fs-4 mb-2">Donation Page</span>
                                                    <span className="text-gray-400 fw-bold fs-6">Create a donation payment link</span>
                                                </span>
                        </label>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <input type="radio" className="btn-check " name="request-type" value="6"  onClick={event=>{setType(6)}} checked={data.type===6} />
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                            onClick={event=>{setType(6)}}>
                            <span className="svg-icon svg-icon-3x me-5">
                                                    <img
                                                        src="https://media.payrequest.nl/images/brand/product-icons/subscriptions-icon.svg"
                                                        className="mw-60px" />
                                                </span>
                            <span className="d-block fw-bold text-start">
                                                    <span
                                                        className="text-dark fw-bolder d-block fs-4 mb-2">Products</span>
                                                    <span className="text-gray-400 fw-bold fs-6">Send a subscription or simple product.</span>
                                                </span>
                        </label>
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <div className="col-lg-6">
                    <input type="radio" className="btn-check" name="request-type" value="3"  onClick={event=>{setType(3)}} checked={data.type===3} />
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                            onClick={event=>{setType(3)}}>
                            <span className="svg-icon svg-icon-3x me-5">
                                                    <img
                                                        src="https://media.payrequest.nl/images/brand/product-icons/pre-authorization-icon.svg"
                                                        style={{maxWidth: 60, maxHeight: 53}} />
                                                </span>
                            <span className="d-block fw-bold text-start">
                                                    <span className="text-dark fw-bolder d-block fs-4 mb-2">Pre Authorization</span>
                                                    <span className="text-gray-400 fw-bold fs-6">Send Pre-Authorization Requests</span>
                                                </span>
                        </label>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <input type="radio" className="btn-check" name="request-type" value="4"  onClick={event=>{setType(4)}} checked={data.type===4} />
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                            onClick={event=>{setType(4)}}>
                        <span className="svg-icon svg-icon-3x me-5">
                                                    <img
                                                        src="https://media.payrequest.nl/images/brand/product-icons/payment-links-icon.svg"
                                                        className="mw-60px" />
                                                </span>
                            <span className="d-block fw-bold text-start">
                                                    <span
                                                        className="text-dark fw-bolder d-block fs-4 mb-2">Dynamic Link</span>
                                                    <span className="text-gray-400 fw-bold fs-6">Create a dynamic payment link</span>
                                                </span>
                        </label>
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <div className="col-lg-6">
                    <input type="radio" className="btn-check" name="request-type" value="5"  onClick={event=>{setType(5)}} checked={data.type===5} />
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                            onClick={event=>{setType(5)}}>
                        <span className="svg-icon svg-icon-3x me-5">
                                                    <img
                                                        src="https://media.payrequest.nl/images/brand/product-icons/payment-links-icon.svg"
                                                        className="mw-60px" />
                                                </span>
                            <span className="d-block fw-bold text-start">
                                                    <span className="text-dark fw-bolder d-block fs-4 mb-2">QR Payment Link</span>
                                                    <span className="text-gray-400 fw-bold fs-6">Create a payment link with a QR Code.</span>
                                                </span>
                        </label>
                </div>
            </div>
        </div>
    </>
}

export {Step1}