import React, {FC,useCallback,useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {useDropzone} from 'react-dropzone'
import {ColorChangeHandler, ColorResult, SketchPicker} from 'react-color';
import DropZoneWidget from "../../../../../_metronic/partials/widgets/dropzone/DropZoneWidget";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {saveDescription} from "../../../auth/core/_requests";

interface iStep2 {
  values: any
}
const Step2: FC<iStep2> = (props) => {
  const [backgroundColor, setBackgroundColor] = useState<string>(props.values?.background1 ?? "");
  const [backgroundColor2, setBackgroundColor2] = useState<string>(props.values?.background2 ?? "");
  // @ts-ignore
  const setPropBackground = (hex:string) => props.values.background1 = hex
  // @ts-ignore
  const setPropBackground2 = (hex:string) => props.values.background2 = hex
  const changeBackgroundColor = (response:object) => {
    // @ts-ignore
    setBackgroundColor(response.hex)
    // @ts-ignore
    setPropBackground(response.hex)
  }
  const changeBackgroundColor2 = (response:object) => {
    // @ts-ignore
    setBackgroundColor2(response.hex);
    // @ts-ignore
    setPropBackground2(response.hex)
  }
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Looks</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Make your page and links recognizable!
        </div>
      </div>

      <div className='mb-10 fv-row row'>
        <div className='col-4'>
          <label className='form-label mb-3'>Company Logo</label>
        </div>

        <div className='col-8'>
          {/*
           @ts-ignore*/}
          <DropZoneWidget className='dropzone' type="logo" chosenImage={props.values?.avatar??false} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='companyLogo' />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row row'>
        <div className='col-4'>
          <label className='form-label mb-3'>Company Colors</label>
        </div>

        <div className='col-8'>
          <div className='row'>
            <div className='col-6'>
              <SketchPicker
                  disableAlpha={true}
                  color={backgroundColor}
                  onChangeComplete={changeBackgroundColor} />
              <input type='hidden' name='companyColors' value={backgroundColor}/>
              <div className='row-text mt-5 text-center'>Company color #1</div>
            </div>
            <div className='col-6'>
              <SketchPicker
                  disableAlpha={true}
                  color={backgroundColor2}
                  onChangeComplete={changeBackgroundColor2} />
              <input type='hidden' name='companyColors2' value={backgroundColor2}/>
              <div className='row-text mt-5 text-center'>Company color #2</div>
            </div>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='companyColors1' />
            <ErrorMessage name='companyColors2' />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-2'>Company Description</label>
        <div className='row-text text-muted mb-3'>A small description which will be seen on every payment link or payment page.</div>

        <TextEditor className='' save={saveDescription} liveSave={true} content={props.values?.Description} />
        <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div>
      </div>

    </div>
  )
}

export {Step2}
