import React, {FC, useEffect, useMemo, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Metas, MetaValues, Products, ProductsStatus, ProductType, TaxClasses, TaxRates, useAuth} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {useNavigate} from "react-router-dom";
import {CurrencyIcon} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {deleteTax, getProductTax, getProductTaxes, saveTax} from "../core/_requests";
import {useParams} from "react-router";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Select, {components, DropdownIndicatorProps, PropsValue} from "react-select";
import {KTSVG} from "../../../../_metronic/helpers";
import TextEditor from "../../../../_metronic/partials/widgets/texteditor/TextEditor";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
    defaultImageInputOptions,
    defaultImageInputQueires,
    ImageInputComponent
} from "../../../../_metronic/assets/ts/components";
import {fileUploadString, UPLOAD_URLS} from "../../auth/core/_requests";
import {v4 as uuidv4} from "uuid";
import {IlocalFilter, PrTable, PrTableSelect} from "../../../../_metronic/partials/widgets/tables/PrTable";
import countryList from "react-select-country-list";
import {Rates} from "../../../../_metronic/partials/widgets/tables/content/rates";
import clsx from "clsx";

const TaxBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Taxes',
        path: '/tax',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const TaxView: FC = () => {
    const {config} = useLayout()

    config.toolbar.layout = 'buttons'
    const intl = useIntl()
    const { id } = useParams()
    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)
    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [title, setTitle] = useState(intl.formatMessage({id: 'MENU.TAX', defaultMessage: 'Tax'}));
    const [isBusy, setIsBusy] = useState(false);
    const [tax, setTax] = useState<TaxClasses>()
    const [rates, setRates] = useState<Array<any>>();
    const [status, setStatus] = useState<PropsValue<any>>();
    const countryOptions = useMemo(() => countryList().getData(), [])
    const [selectedRows, setSelectedRows] = useState<Array<number>>([]);
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshing, setRefreshing] = useState<boolean>(false)
    const reloadTrigger = () => setRefreshData(!refreshData)
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    let faketax:TaxClasses|undefined = undefined;

    useEffect(() => {
        if(typeof currentUser?.id === "undefined" || typeof id === "undefined")
            return
        if(id === "new"){
            setStatus({value:true,label:'Enabled'})
            setTitle('')
            setTax({
                id: 0,
                name: '',
                status: true,
                createdAt: new Date(),
                updatedAt: new Date(),
                description: '',
                rates:[]
            } as TaxClasses)
            return
        }
        getProductTax(parseInt(id)).then(res => {
            if("error" in res)
                return
            setStatus({value: res.status, label: res.status?'Enabled':'Disabled'})
            setTax(res)
            setTitle(tax?.name??title)
        })
    }, [currentUser]);
    const changeValue = (id: number, key: string, value: any) => setTax({...tax, rates: tax?.rates.map(item => {
            if(item.id === id)
                return {...item, [key]: value}
            return item
        })} as TaxClasses)
    const getRates = (pageIndex:number, pageSize:number, localFilter:Array<any>, globalFilter:string, sortBy:Array<any>) => {
        if(typeof tax === "undefined" || tax?.rates?.length === 0)
            return {count:0,results:[]};
        let rates = tax?.rates ?? [];
        if(rates.length === 0)
            return {count:0,results:[]};
        if(globalFilter.trim().length>0)
           rates = rates.filter(item => item.name.toLowerCase().includes(globalFilter.toLowerCase()))
        if(sortBy.length > 0){
            rates = rates.sort((a, b) => {
                let aa,bb;
                switch (sortBy[0].id) {
                    case "name":
                    default:
                        aa = a.name.toLowerCase()
                        bb = b.name.toLowerCase()
                        break;
                    case "rate":
                        aa = a.rate
                        bb = b.rate
                        break;
                    case "country":
                        aa = a.country
                        bb = b.country
                        break;
                    case "zipcode":
                        aa = a.zip
                        bb = b.zip
                        break;
                    case "state":
                        aa = a.state
                        bb = b.state
                        break;

                }
                return aa < bb ? -1 : aa > bb ? 1 : 0;
            })
            if(sortBy[0].desc)
                rates = rates.reverse()
        }
        return {
            count: rates.length,
            results: rates.slice(pageIndex*pageSize, (pageIndex+1)*pageSize)??[]
        };
    }
    const parser = (rates:Array<TaxRates>) => rates.map(rate => {
        return ({
            id: rate.id,
            name: (<input type='text' className='form-control form-control-solid' onChange={e=>{changeValue(rate.id,'name', e?.target?.value ?? '')}} value={rate.name} />),
            country: (loadingData?<Skeleton height={45} width={'100%'}/>:<Select
                instanceId={generateId()}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        background: '#f5f8fa',
                        padding: '0.65rem 1rem',
                        fontSize: '1.1rem',
                        color: '#3f4254',
                        border: 0
                    })
                }}
                isLoading={loadingData}
                // @ts-ignore
                options={countryOptions}
                defaultValue={countryOptions.find(_country => _country.value === rate.country.toUpperCase())}
                onChange={value => {changeValue(rate.id,'country',value?.value ?? '')}}
            />),
            state: (<input type='text' className='form-control form-control-solid' onChange={e=>{changeValue(rate.id,'state', e?.target?.value ?? '*')}} value={rate.state ?? '*'} />),
            zipcode: (<input type='text' className='form-control form-control-solid' onChange={e=>{changeValue(rate.id,'zip', e?.target?.value ?? '*')}} value={rate.zip ?? '*'} />),
            rate: (<input type='number' min={0} max={100} step={0.01} className='form-control form-control-solid' onChange={e=>{changeValue(rate.id,'rate', e?.target?.value ?? 0)}} value={rate.rate ?? 0} />),
            action: (<button className='btn btn-sm btn-danger btn-icon' onClick={e=>{
                e.preventDefault()
                MySwal.fire({
                    title: intl.formatMessage({id: 'SWAL.DELETE.TAX', defaultMessage: 'Delete Rate?'}),
                    text: intl.formatMessage({id: 'SWAL.DELETE.TAXTEXT', defaultMessage: 'Are you sure you want to delete this rate?'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'SWAL.DELETE.CONFIRM', defaultMessage: 'Delete'}),
                    cancelButtonText: intl.formatMessage({id: 'SWAL.DELETE.CANCEL', defaultMessage: 'Cancel'}),
                }).then(result => {
                    if(result.value) {
                        setTax({...tax, rates: tax?.rates.filter(r => r.id !== rate.id)} as TaxClasses)
                    }
                })
            }}>
                {/*
                    @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-trash-can" />
            </button>)
        })
    })
    const initialSort = useMemo( () => [ { id: "name", desc: false } ],[])
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.COUNTRY', defaultMessage: 'Country'}),
            accessor: 'country',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.STATE', defaultMessage: 'State'}),
            accessor: 'state',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.ZIP', defaultMessage: 'Zipcode'}),
            accessor: 'zipcode',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.RATE', defaultMessage: 'Rate'}),
            accessor: 'rate',
            className: 'min-w-150px',
            filter: 'filterSearch',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTION', defaultMessage: 'Action'}),
            accessor: 'action',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const generateId = () => `${performance.now()}${Math.random().toString().slice(5)}`.replace('.','')
    return (
        <>
            <PageTitle breadcrumbs={TaxBreadCrumbs}>{title}</PageTitle>
            <PageToolbar>

                <button className='btn btn-secondary btn-sm me-5' disabled={isBusy} onClick={e=>{
                    e.preventDefault()
                    navigate(`/tax`)
                }}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-angle-left" className='me-3' /> Go back
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
                <button className='btn btn-light-primary btn-sm me-5' disabled={isBusy} onClick={e=>{
                    e.preventDefault()
                    if(typeof tax?.id === "undefined")
                        return;
                    setIsBusy(true)
                    saveTax(tax?.id,tax).then(()=>{
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SWAL.SAVE.TITLE', defaultMessage: 'Saved!'}),
                            text: intl.formatMessage({id: 'SWAL.SAVE.TAX', defaultMessage: 'Tax saved successfully!'}),
                            icon: 'success',
                            confirmButtonText: intl.formatMessage({id: 'SWAL.SAVE.CONFIRM', defaultMessage: 'Ok'}),
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000,
                            didClose: () => setIsBusy(false)
                        })
                    })
                }}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk" className='me-3' /> Save Tax
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
                {id!=="new"&&<button className='btn btn-light-danger btn-sm' disabled={isBusy} onClick={e=>{

                    e.preventDefault()
                    if(typeof tax?.id === "undefined")
                        return;
                    setIsBusy(true)
                    deleteTax(tax?.id).then(()=>{
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SWAL.DELETE.TAX', defaultMessage: 'Deleted!'}),
                            text: intl.formatMessage({id: 'SWAL.DELETE.TAX', defaultMessage: 'Tax has been deleted, you will be redirected to the overview.'}),
                            icon: 'success',
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000,
                            didClose: () => navigate('/tax',{replace: true})
                        })
                    })
                }}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-trash-can" className='me-3' />
                    Delete Tax
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>}
            </PageToolbar>
            <form className="form d-flex flex-column gap-3 gap-lg-5">
                <div className='card card-flush py-4 w-100'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>General</span>
                            {/*<span className='text-muted fw-bold fs-7'>All important product details</span>*/}
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='row g-5'>
                            <div className='col-12 col-md-6'>
                                <div className={'mb-5'}>
                                    <label className="form-label required">Tax Name</label>
                                    {typeof tax?.name !== "undefined"?<input
                                        type="text"
                                        className="form-control form-control-solid"
                                        value={tax?.name}
                                        onChange={e => {
                                            let newTax = {...tax}
                                            // @ts-ignore
                                            newTax.name = e?.target?.value
                                            setTax(newTax as TaxClasses)
                                        }
                                        }
                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                    <span className='text-muted fs-8'>A tax name is required and recommended to be unique.</span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className={'mb-5'}>
                                    <label className="form-label required">Status</label>
                                    {typeof tax?.status === "undefined" ? <Skeleton height={45} width={'100%'}/> :
                                        <Select
                                            components={{DropdownIndicator}}
                                            //@ts-ignore
                                            defaultValue={status}
                                            isLoading={typeof tax?.status === "undefined"}
                                            // @ts-ignore
                                            options={[{value: true, label: 'Enabled'}, {value: false, label: 'Disabled'}]}
                                            onChange={event => {
                                                let newTax = {...tax}
                                                if(typeof newTax !== "undefined" && typeof event !== "undefined") {
                                                    // @ts-ignore
                                                    const _status = event?.value ?? false;
                                                    newTax.status = _status
                                                    setStatus({value: newTax.status, label: newTax.status?`Enabled`:`Disabled`})
                                                    setTax(newTax as TaxClasses)
                                                }
                                            }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    background: '#f5f8fa',
                                                    borderColor: '#f5f8fa',
                                                    color: '#5e6278',
                                                    transition: 'color 0.2s ease, background-color 0.2s ease',
                                                    borderRadius: '0.3rem'
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    minHeight: 'calc(1.5em + 1rem + 2px)',
                                                    padding: '0.5rem 1rem',
                                                    fontSize: '1rem',
                                                })
                                            }}/>}
                                    <span className='text-muted fs-8'><strong>Keep in mind: </strong>If disabled the tax won't be used in assigned products!</span>
                                </div>
                            </div>
                        </div>
                        <label className='form-label'>Description</label>
                        {typeof tax?.description !== "undefined"?<textarea className='form-control form-control-solid' rows={3} defaultValue={tax?.description} onChange={e => {
                            let newTax = {...tax}
                            // @ts-ignore
                            newTax.description = e?.target?.value
                            setTax(newTax as TaxClasses)
                        }}/>:<Skeleton height={85} width={'100%'}/>}
                    </div>
                </div>
                <div className='card card-flush py-4 w-100'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start justify-content-between flex-row w-100'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Rates</span>
                            {/*<span className='text-muted fw-bold fs-7'>All important product details</span>*/}
                            <div className="card-toolbar my-0">
                                <div className="d-flex align-items-center justify-content-end" data-kt-tax-table-toolbar="base">
                                    <button className='btn btn-light-primary btn-sm me-5' onClick={e=>{
                                        e.preventDefault()
                                        const newTax = {id:`00${generateId()}`,rate:'',country:'',state:'',zip:'',name:''}
                                        const changedTax = {...tax, rates: [...(tax?.rates ?? []), newTax]} as TaxClasses;
                                        setTax(changedTax)
                                        setTimeout(()=>{
                                            setRefreshData(!refreshData)
                                        },500);
                                    }}>
                                        {/*
                                            @ts-ignore */}
                                        <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' /> Add New Rate
                                    </button>
                                </div>
                                {selectedRows.length>0 &&
                                    (<div className={'d-flex justify-content-end align-items-center'}>
                                        <div className="fs-4 me-5">
                                            <span className="me-2">{selectedRows.length}</span> selected
                                        </div>
                                        <button className="btn btn-danger btn-sm" onClick={e=>{
                                            e.preventDefault()
                                            MySwal.fire({
                                                title: intl.formatMessage({id: 'SWAL.DELETE.RATES', defaultMessage: 'Delete Rates?'}),
                                                text: intl.formatMessage({id: 'SWAL.DELETE.RATESTEXT', defaultMessage: 'Are you sure you want to delete these rates?'}),
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: intl.formatMessage({id: 'SWAL.DELETE.CONFIRM', defaultMessage: 'Delete'}),
                                                cancelButtonText: intl.formatMessage({id: 'SWAL.DELETE.CANCEL', defaultMessage: 'Cancel'}),
                                            }).then(result => {
                                                if(result.value) {
                                                    setTax({
                                                        ...tax,
                                                        rates: (tax?.rates ?? []).filter(r => !selectedRows.includes(r.id))
                                                    } as TaxClasses)
                                                }
                                            })
                                        }}>
                                            {/*
                                            @ts-ignore */}
                                            <FontAwesomeIcon icon="fa-duotone fa-xmark" className='me-3' /> Delete Selected
                                        </button>
                                    </div>)}
                                {/*<div className='ms-5' style={{whiteSpace: 'nowrap'}}>*/}
                                {/*    {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}*/}
                                {/*</div>*/}
                                {/*<button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={(e) => {*/}
                                {/*    e.preventDefault();*/}
                                {/*    setRefreshing(true)*/}
                                {/*    setRefreshData(!refreshData)*/}
                                {/*    setTimeout(() => setRefreshing(false), 1000)*/}
                                {/*}}>*/}
                                {/*    /!**/}
                                {/*@ts-ignore *!/*/}
                                {/*    <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />*/}
                                {/*</button>*/}
                            </div>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        {typeof tax !== "undefined"?(<>
                            <PrTable selectedRows={setSelectedRows} invalidate={refreshData} columns={columns} sortee={initialSort} random={Math.random()} parser={parser} dataFetcher={getRates} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Rates} tableType={'rates'} rowSelect={true} reloadTrigger={reloadTrigger}/>
                        </>):(
                            <>
                                <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                                <Skeleton width={'100%'} height={55} count={10} className='mb-3'/>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </>
    )
}

export {TaxView}