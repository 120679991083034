import * as Yup from "yup";

const onboardingSchemas = [
    Yup.object({
        companyName: Yup.string().required().label('Company Name'),
        companyMail: Yup.string().required().label('Company Mail'),
        companySite: Yup.string().required().label('Company Mail'),
        /*currency:  Yup.string().required().label('Currency'),*/
    }),
    Yup.object({
       /* companyColors1: Yup.string().required().label('Company Color #1'),
        companyColors2: Yup.string().required().label('Company Color #2'),*/
    }),
    Yup.object({
    }),
    Yup.object({
    }),
]

export {onboardingSchemas}