import React, {FC, useState} from "react";
import {ItemType} from "../../core/_models";
import PhoneInput from "react-phone-number-input";
import {E164Number} from "libphonenumber-js/core";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";

type itemProps = {
    item: ItemType
    index: number
}

const Media:FC<itemProps> = ({item, index}) => {

    switch (item?.name.toLowerCase()) {
        default:
        case "text":
            return <div className="h-100px empty-spacer d-flex justify-content-center align-items-center">
                <span className={`fs-3 fw-bolder`}>Click here or drop a file to upload</span>
            </div>
    }
}

export {Media}