/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout, usePageData} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {MenuComponent} from "../../../assets/ts/components";

const Toolbar2: FC = () => {
  const {classes} = useLayout()
  const {pageToolbar} = usePageData()

  setTimeout(() => MenuComponent.reinitialization(), 1000)
  return (
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
            id='kt_toolbar_container'
            className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          {pageToolbar && (
          <div className='d-flex align-items-center py-1'>
            {/* begin::Wrapper */}
            <div className='me-4'>
              {/* begin::Menu */}
              <a
                  href='#'
                  className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
              >
                Actions
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='ms-3 me-0'
                />
              </a>

              {/* begin::SubMenu */}
              <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>

                {/* begin::Content */}
                <div className='px-7 py-5'>
                  {pageToolbar ?? <></>}
                </div>
                {/* end::Content */}
              </div>
              {/* end::SubMenu */}
              {/* end::Menu */}
            </div>
            {/* end::Wrapper */}
          </div>
          )}
        </div>
        {/* end::Container */}
      </div>
  )
}

export {Toolbar2}
