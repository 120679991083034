import axios from 'axios'
import {StatusResponse, SuccessResponse} from "../../stripe/core/_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";
import {Transactions} from "../../../modules/auth";
import {SUBSCRIPTION_API} from "../../../modules/subscriptions/core/_requests";
import {MollieMandate} from "./_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/api/integrations`
export const INTEGRATIONS_MOLLIE = `${API_URL}/integrations/mollie`

export function getMollieLink(): Promise<string|boolean> {
    return axios.get<any>(`${INTEGRATIONS_GET}/mollie`)
        .then(response => response.data?.action_url ?? false)
        .catch(err => false)
}

export function checkIntegrationMollie(data:object): Promise<SuccessResponse|ErrorMessage> {
    return axios.post(
        `${INTEGRATIONS_MOLLIE}/check`,
        data).then(response => response.data as unknown as SuccessResponse)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function assignIntegrationMollie(data:object): Promise<SuccessResponse|ErrorMessage> {
    return axios.post(
        `${INTEGRATIONS_MOLLIE}/assign`,
        data).then(response => response.data as unknown as SuccessResponse)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function getMollieSubscriptionMandate(id:string): Promise<MollieMandate|ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}/mollie/mandate`)
        .then(response => response.data as unknown as MollieMandate)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}