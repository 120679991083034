/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useIntl} from "react-intl";
import {useAuth} from "../../../../app/modules/auth";
import { useCountUp } from 'react-countup';
import {getSubscriptionsChart, ISubscriptionCharts, controller} from "../../../../app/modules/dashboard/core/_requests";
import Skeleton from "react-loading-skeleton";
import {Blocker, isBlocked} from "../../../../app/modules/widgets/components/Blocker";


type Props = {
  className: string
}

const SetCounter: React.FC<{type:string, end:number,decimals?:number,prefix?:string,suffix?:string}> = ({type, end, decimals,prefix,suffix}) => {
  useCountUp({ ref: 'counter-'+type, end: end, decimals: decimals ?? 2, prefix:prefix ?? '',suffix: suffix ?? '' })
  return (<span id={'counter-'+type} />)
}

const SubscriptionWidgets: React.FC<Props> = ({ className}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const mrrRef = useRef<HTMLDivElement | null>(null)
  const [mrrLoaded, setMrrLoaded] = useState(false);
  const [mrrChange, setMrrChange] = useState(0);
  const churnRef = useRef<HTMLDivElement | null>(null)
  const [churnLoaded, setChurnLoaded] = useState(false);
  const [churnChange, setChurnChange] = useState(0);
  const subsRef = useRef<HTMLDivElement | null>(null)
  const [subsLoaded, setSubsLoaded] = useState(false);
  const [subsChange, setSubsChange] = useState(0);
  const items = [
    {
      type: 'mrr',
      color: 'success',
      className: 'card-xl-stretch mb-xl-8',
      title: 'MRR',
      description: intl.formatMessage({id: 'PRODUCTS.MRR', defaultMessage: 'Monthly Recurring Revenue'})
    },
    {
      type: 'churn',
      color: 'warning',
      className: 'card-xl-stretch mb-xl-8',
      title: intl.formatMessage({id: 'PRODUCTS.CHURN', defaultMessage: 'Churn'}),
      description: intl.formatMessage({id: 'PRODUCTS.CHURNEXPLAINED', defaultMessage: 'Percentage of customers that leave per month.'})
    },
    {
      type: 'newsubs',
      color: 'info',
      className: 'card-xl-stretch mb-xl-8',
      title: intl.formatMessage({id: 'PRODUCTS.NEWSUBS', defaultMessage: 'New Subscribers'}),
      description: intl.formatMessage({id: 'PRODUCTS.PERMONTH', defaultMessage: 'Per month'})
    }
  ];

  useEffect(() => {
    /*if (!mrrRef.current) {
      return
    }*/
    getSubscriptionsChart().then(chartData => {
      const chart: { churn: any; newsubs: any; mrr: any } = {mrr:null,churn:null,newsubs:null};
      if(typeof chartData !== "undefined"&&typeof chartData !== "boolean") {
        setMrrChange(
            chartData.mrr.data[4]===0&&chartData.mrr.data[5]>0?100:
            (-(((chartData.mrr.data[4] - chartData.mrr.data[5]) / chartData.mrr.data[4])
                * 100))
        )
        setMrrLoaded(true)
        setChurnChange(
            chartData.churn.data[4]===0&&chartData.churn.data[5]>0?100:
            (-(((chartData.churn.data[4] - chartData.churn.data[5]) / chartData.churn.data[4])
                * 100))
        )
        setChurnLoaded(true)
        setSubsChange(
            chartData.newsubs.data[4]===0&&chartData.newsubs.data[5]>0?100:
            (-(((chartData.newsubs.data[4] - chartData.newsubs.data[5]) / chartData.newsubs.data[4])
                * 100))
        )
        setSubsLoaded(true)
        items.forEach(item => {
          const ref = item.type === 'mrr' ? mrrRef : (item.type === 'churn' ? churnRef : subsRef);
          if (ref !== null && ref.current !== null) {
            if(item.type==='mrr'||item.type==='churn'||item.type==='newsubs') {
              const height = parseInt(getCSS(ref.current, 'height'))
              const labelColor = getCSSVariableValue('--bs-gray-800')
              const baseColor = getCSSVariableValue('--bs-' + item.color)
              const lightColor = getCSSVariableValue('--bs-light-' + item.color)
              let format = (val: any) => {
                return val.toString()
              }
              if(item.type==='mrr'){
                format = (val: any) => {
                  return intl.formatNumber(val,{style: 'currency', currency: currentUser?.currency ?? 'EUR', currencyDisplay:'symbol'}).toString()
                }
              }

              if(Math.max(...chartData[item.type].data) > 0) {
                chart[item.type] = new ApexCharts(
                    ref.current,
                    getChartOptions(item.title, chartData[item.type].data, chartData[item.type].categories, format, height, labelColor, baseColor, lightColor)
                )
                if (chart[item.type] !== null) {
                  chart[item.type].render()
                }
              }
            }
          }
        })
      }
    })
    return () => {
      //controller.abort()
      // if (chart.mrr!==null)
      //   chart.mrr.destroy()
      // if (chart.churn!==null)
      //   chart.churn.destroy()
      // if (chart.newsubs!==null)
      //   chart.newsubs.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mrrRef,churnRef,subsRef])

  return (
      <div className={className}>
        {items.map(item => (
            <div className='col-xl-4' key={item.title}>
              <div className={`card ${item.className}`}>
                {/* begin::Body */}
                <div className='card-body d-flex flex-column p-0'>
                  <div className='d-flex flex-stack flex-grow-1 card-p'>
                    <div className='d-flex flex-column me-2'>
                      <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                        {item.title}
                      </a>

                      <span
                          className='text-muted fw-bold mt-1'
                          dangerouslySetInnerHTML={{__html: item.description}}
                      ></span>
                    </div>

                    <span className='symbol symbol-50px symbol-2by3'>
                      <span className={`symbol-label fs-5 fw-bolder bg-light-${item.color} text-${item.color}`}>
                        {(item.type==='mrr'?mrrLoaded:(item.type==='churn'?churnLoaded:subsLoaded))?
                        <SetCounter
                            type={item.type}
                            end={item.type==='mrr'?mrrChange:(item.type==='churn'?churnChange:subsChange)}
                            decimals={2}
                            suffix={'%'}
                        />:<Skeleton width={77} height={50}/>}
                      </span>
                    </span>
                  </div>
                  {(item.type==='mrr'?mrrLoaded:(item.type==='churn'?churnLoaded:subsLoaded))?
                    <div
                        ref={item.type==='mrr'?mrrRef:(item.type==='churn'?churnRef:subsRef)}
                        key={item.type}
                        id={`chart-${item.type}`}
                        className='card-rounded-bottom'
                        style={{height: '150px'}}
                    ></div>:<Skeleton width={'100%'} height={150}/>}
                </div>
                {/* end::Body */}
              </div>
            </div>
        ))}
      </div>
  )
}

export {SubscriptionWidgets}

function getChartOptions(
    subject: string,
    data: number[],
    labels: string[],
    formatter: Function,
    height: number,
    labelColor: string,
    baseColor: string,
    lightColor: string
): ApexOptions {
  const roundHalf = (num:number) => {
    return Math.floor(Math.ceil(num * 2) / 2)
  }
  const max = Math.max(...data)<20?Math.max(...data):roundHalf(Math.ceil(Math.max(...data)*1.1));
  const base = Math.max(...data)<20?10:roundHalf(max/10);
  const ticks = Math.ceil(max/base)<9?9:Math.ceil(max/base);
  console.log(max,base, ticks)
  const options: ApexOptions = {
    series: [
      {
        name: subject,
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: '#E4E6EF',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      logarithmic: true,
      min: 0,
      max: max,
      tickAmount: ticks,
      logBase: base,
      forceNiceScale: true,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) { return formatter(val)} ,
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
  return options
}