import {Route, Routes, Outlet, useNavigate, Link} from 'react-router-dom'
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {assignIntegrationMollie, checkIntegrationMollie} from "../core/_requests";
import {useIntl} from "react-intl";

import { Crisp } from "crisp-sdk-web";
import {KTSVG} from "../../../../_metronic/helpers";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useAuth} from "../../../modules/auth";

const Check = () => {
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search))
    const navigate = useNavigate()
    const show = () => Crisp.chat?.show()
    const intl = useIntl()
    const [status, setStatus] = useState<boolean|null>(null);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [profiles, setProfiles] = useState<any[]>();
    const MySwal = withReactContent(Swal)
    useEffect(() => {

        if (urlParams) {
            checkIntegrationMollie(urlParams).then(resp => {
                if("error" in resp) {
                    setStatus(false)
                    setErrorMessage(resp.error)
                }else if(resp?.status === "chooseProfile"){
                    setStatus(true)
                    setProfiles(resp.profiles)
                }else{
                    //setStatus(true)
                    navigate('/integration/overview')
                }
            });
        }
    }, []);
    const setProfile = (profileid: string) => {
        MySwal.fire({
            title: 'Saving...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
        })
        assignIntegrationMollie({profileid}).then(resp => {
            MySwal.close()
            if("error" in resp){
                setStatus(false)
                setErrorMessage(resp.error)
            }else
                navigate('/integration/overview')
        })
    }

    return (<>{typeof status !== "boolean"?
        (
            <div className={`card bgi-no-repeat`}>
                <div className='card-header border-0 pt-5 pb-3  justify-content-center'>
                    <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3">
                        <span className="indicator-label">
                            {intl.formatMessage({id: 'GENERAL.VERIFY', defaultMessage: 'Verifying connection...'})}
                        </span>
                        <span className="card-label fw-bolder fs-3 indicator-progress">
                            {intl.formatMessage({id: 'GENERAL.VERIFY', defaultMessage: 'Verifying connection...'})} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </h3>
                </div>
            </div>
        )
        :
        (
            status?<>
                    <div className={`card bgi-no-repeat`}>
                        <div className='card-header border-0 pt-5 pb-3 justify-content-center'>
                            <h3 className='card-title align-items-center flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>
                            {intl.formatMessage({id: 'MOLLIE.CHOOSE', defaultMessage: 'Select your preferred profile'})}
                        </span>
                                <span className='text-muted fw-bold fs-7'>
                            Please choose a profile that you want to use with PayRequest.
                        </span>
                            </h3>
                        </div>
                    </div>
                    <div className='container gx-0 mt-10'>
                        <div className='row g-10'>
                            {profiles?.map((profile, index) => <div key={profile.id} className={'col-12 col-md-6'} style={{cursor:"pointer"}} onClick={e=>{e.preventDefault();setProfile(profile.id)}}>
                                <div className="d-flex flex-column border border-white border-2 card border-hover-primary rounded-3 p-10 shadow-sm" style={{transition: 'all .3s ease'}}>
                                    <div className="d-flex flex-stack mb-6">
                                        <div className="flex-shrink-0 me-5">
                                        <span
                                            className="text-gray-400 fs-7 fw-bold me-2 d-block lh-1 pb-1">{profile?.mode}</span>
                                            <span className="text-gray-800 fs-1 fw-bold">{profile?.name}</span>
                                        </div>
                                        <span
                                            className={`badge badge-light-${profile?.status==='blocked'?'danger':'primary'} flex-shrink-0 align-self-center py-3 px-4 fs-7`}>{profile?.status}</span>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap d-grid gap-2">
                                        <div className="d-flex align-items-center me-5 me-xl-13">
                                            <div className="symbol symbol-30px symbol-circle me-3">
                                            <span
                                                className="symbol-label bg-success">
                                                <KTSVG path={'/media/icons/duotune/communication/com001.svg'} className='svg-icon-5 svg-icon-white' />
                                            </span>
                                            </div>
                                            <div className="m-0">
                                                <span className="fw-semibold text-gray-400 d-block fs-8">Website</span>
                                                <a href={profile?.website} target={'_blank'}
                                                   className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                    {profile?.website}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center me-5 me-xl-13">
                                            <div className="symbol symbol-30px symbol-circle me-3">
                                            <span
                                                className="symbol-label bg-primary">
                                                <KTSVG path={'/media/icons/duotune/communication/com007.svg'} className='svg-icon-5 svg-icon-white' />
                                            </span>
                                            </div>
                                            <div className="m-0">
                                                <span className="fw-semibold text-gray-400 d-block fs-8">Email</span>
                                                <a href={`mailto:${profile?.email}`}
                                                   className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                    {profile?.email}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center me-5 me-xl-13">
                                            <div className="symbol symbol-30px symbol-circle me-3">
                                            <span
                                                className="symbol-label bg-primary">
                                                <KTSVG path={'/media/icons/duotune/communication/com013.svg'} className='svg-icon-5 svg-icon-white' />
                                            </span>
                                            </div>
                                            <div className="m-0">
                                                <span className="fw-semibold text-gray-400 d-block fs-8">Phone</span>
                                                <a href={`tel:${profile?.phone}`}
                                                   className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                    {profile?.phone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </>:<div className={`card bgi-no-repeat`}>
                <div className='card-header border-0 pt-5 pb-3 justify-content-center'>
                    <h3 className='card-title align-items-center flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>
                            {intl.formatMessage({id: 'GENERAL.WENTWRONG', defaultMessage: 'Oops! Something went wrong'})}
                        </span>
                    </h3>
                </div>
                <div className='card-body d-flex flex-center flex-column p-9'>
                    <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                        <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                            <h5 className="mb-1">{errorMessage}</h5>
                            <span>Please try to connect once again, if this problem persists please <span onClick={show} role="button" tabIndex={0} className={'text-decoration-underline'}>contact us</span></span>
                        </div>
                    </div>
                </div>
            </div>
        )}</>)
}

export default Check
