export enum PaymentLinkStatus {
  PAID = "PAID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  CAPTURE = "CAPTURE",
  CAPTURED = "CAPTURED",
  CANCELLED = "CANCELLED"
}

export enum PaymentLinkStatusColors {
  PAID = "success",
  PENDING = "warning",
  REFUNDED = "info",
  CAPTURE = "primary",
  CAPTURED = "success",
  CANCELLED = "danger"
}

export enum PaymentLinkStatusIcons {
  PAID = "fa-check",
  PENDING = "fa-hourglass-clock",
  REFUNDED = "fa-rotate-left",
  CAPTURE = "fa-credit-card",
  CAPTURED = "fa-check-double",
  CANCELLED = "fa-xmark"
}

export enum PaymentLinkTypes {
  NORMAL = "NORMAL",      // 1
  DONATION = "DONATION",  // 2
  PREAUTH = "PREAUTH",    // 3
  PRODUCT = "PRODUCT",    // 6
  SUBSCRIPTION = "SUBSCRIPTION", // 99
  PERSONAL = "PERSONAL"    // PERSONAL link transaction
}

export enum ProductsStatus {
  ENABLED = "ENABLED",
  CONCEPT = "CONCEPT",
  DELETED = "DELETED"
}

export enum SubscriptionStatus {
  ENABLED = "ENABLED",
  PAUSED = "PAUSED",
  CANCELLED = "CANCELLED",
  PENDING_CANCEL = "PENDING_CANCEL"
}

export interface AuthModel {
  api_token: string
  token: string
  refresh_token: string
}

export interface PersonalLink {
  id: number
  slug: string
  description: string
  amounts: string[]|null
  successurl: string|null
  failedurl: string|null
  shipping: boolean
  customfieldGroup: CustomFieldGroups|null
  fee: number|null
  feeType: string|null
  gif: string|null
}

export interface ProductType {
  id: number,
  name: string,
  extra: boolean,
  status: boolean,
  pro: boolean,
  description?: string
}

export interface MetaValues {
  id: number,
  name: string,
  value: string,
  slug: string,
  status: boolean,
  options: Array<any>
}

export interface Metas {
  id: number,
  name: string,
  slug: string,
  status: boolean,
  options: {[index: string]:any}
}

// @todo: expand
export interface Products {
  id: number,
  name: string,
  slug: string,
  description: string,
  price: number,
  type: ProductType,
  status: ProductsStatus,
  currency: string|null,
  createdAt: Date,
  updatedAt: Date,
  customFields: CustomFieldGroups|null, //@deprecated: don't use Array for this!
  metaValues: Array<MetaValues>,
  taxClass: any,
}

export interface Currency {
  key: string
  unicode: string
  sign: string
  icon: string|Array<string>
  text: string
  plural: string
  zeroCurrency?: boolean
}

// @todo: expand
export interface Customers {
  id: number,
  name: string,
  email: string,
  gender: string,
  phone: string|undefined,
  avatar: string,
  createdAt: Date,
  street?: string,
  postal?: string,
  city?: string,
  country?: string,
  note?: string,
  subscriptions?: Array<Subscription>
}

export interface SubscriptionLogs {
  description: string,
  createdAt: Date,
  type: string,
  icon: string
}

export interface Subscription {
  id: number,
  createdAt: Date,
  name: string,
  price: number,
  currency: string|null,
  start: Date,
  end: Date|null,
  next: Date|null,
  customer?: Customers,
  status: SubscriptionStatus,
  psp: number
}

// @todo: expand
export interface PaymentLinks {
  id: number
  urlpath: string
  status: PaymentLinkStatus
  title: string
  type: PaymentLinkTypes
  customer: Customers|null
  amount: number
  captured?: number
  currency?: string
  createdAt: Date
  directlink: string
  fee: number
  tax: TaxClasses|null
}

// @todo: expand
export interface Transactions {
  id: number,
  urlpath?: string,
  customer?: Customers,
  name: string,
  email: string,
  currency: string,
  amount: number,
  amountBase: number,
  createdAt: Date,
  type?: PaymentLinkTypes,
  note?: string,
  customFields?: {[index:string]:any},
  producttype?: "Simple"|"Subscription"|"Pre-auth"|"SUBSCRIPTION",
  invoice?: Invoice,
}

export interface Invoice {
  id: string,
  file: string
}

export interface CustomFieldGroups {
  id: number,
  name: string,
  enabled: boolean,
  fields: number
}

export interface ProductMetas {
  id: number
}

export interface TaxRates {
  id: number
  rate: string
  country: string
  state: string
  zip: string
  name: string
}

export interface TaxClasses {
  id: number
  name: string
  status: boolean
  createdAt: Date
  updatedAt: Date
  description: string
  rates: Array<TaxRates>
  products?: number
}

export interface AffiliateFees {
  id: number
}

export interface AffiliatePayouts {
  id: number
}

export interface UserModel {
  id: number
  name: string
  website: string | undefined
  email: string
  phone: string | undefined
  googleid: string
  createdAt: Date
  avatar: string | undefined
  displayName: string | undefined
  companyName: string | undefined
  companyAdres: string | undefined
  companyMail: string | undefined
  companySite: string | undefined
  emailnotifications: number
  pushnotifications: number
  completedmail: number
  openmail: number
  updatemail: number
  completedpush: number
  integrationpush: number
  openpush: number
  slug: string
  psp: string | undefined
  roles: Array<string>
  /*mailTemplates?: Array<any>*/
  CompanyLogo: string
  currency: string | undefined
  password?: string | undefined
  locale: string
  backgroundImage: string | undefined
  firstStart: number
  personalLink?: PersonalLink
  Description: string | undefined
  socials: object | undefined
  /*Facebook: string | undefined
  Twitter: string | undefined
  Youtube: string | undefined
  Instagram: string | undefined*/
  spf: boolean | undefined
  sendexternal: boolean | undefined
  token: string | undefined
  /*mail: string | undefined
  telephone: string | undefined
  github: string | undefined
  skype: string | undefined
  producthunt: string | undefined
  whatsapp: string | undefined*/
  background1: string | undefined
  background2: string | undefined
  pushr: string | undefined
  /*shopPage*/
  products?: Array<Products>
  customers?: Array<Customers>
  methods: Array<string> | undefined
  webhook: string | undefined
  testmode: boolean
  invoiceSettings: {
    enabled: boolean,
    invoiceid: number,
    invoiceidtemplate: Array<{[index: string]:any}>
  }
  max_sms: number
  /*smsLogs*/
  solutionType: string | undefined
  aboutBusiness: string | undefined
  platformType: string | undefined
  onboarding: number
  stripeSync: number
  Pro: number
  /*paymentPlans*/
  css: string | undefined
  paymentLinks?: Array<PaymentLinks>
  tokenV2: string | undefined
  postmarkId: number | undefined
  country: string | undefined
  customernotifications: number
  customFieldGroups?: Array<CustomFieldGroups>
  productMetas?: Array<MetaValues>
  taxClasses?: Array<TaxClasses>
  affiliate: boolean
  referral?: Array<UserModel>
  affiliates?: Array<UserModel>
  affiliateFees?: Array<AffiliateFees>
  affiliatePayouts?: Array<AffiliatePayouts>
  feeTitle: string|null
  emailChange: string|undefined
  changeBefore: Date|undefined
}

export interface ISearchResults {
  requests: Array<PaymentLinks>,
  transactions: Array<Transactions>,
  customers: Array<Customers>,
  products: Array<Products>
}
