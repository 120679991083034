import React, {FC, useState} from "react";
import {ItemType} from "../../core/_models";
import DatePicker from "react-datepicker";

type itemProps = {
    item: ItemType
    index: number
}

const DateTime:FC<itemProps> = ({item}) => {
    const [chosenDate, setChosenDate] = useState<Date>(new Date());
    switch (item?.name.toLowerCase()) {
        default:
        case "date and time":
            return <DatePicker
                className={'form-control mb-1'}
                selected={chosenDate}
                onChange={(date:Date) => setChosenDate(date)}
                locale={item?.datetime?.locale??'en'}
                showTimeSelect
                timeFormat={item?.datetime?.timeFormat??'p'}
                timeIntervals={item?.datetime?.timeInterval??15}
                dateFormat={item?.datetime?.dateFormat??'Pp'}
            />
        case "date":
            return <DatePicker
                className={'form-control mb-1'}
                selected={chosenDate}
                onChange={(date:Date) => setChosenDate(date)}
                locale={item?.date?.locale??'en'}
                dateFormat={item?.date?.dateFormat??'Pp'}
            />
        case "time":
            return <DatePicker
                className={'form-control mb-1'}
                selected={chosenDate}
                onChange={(date:Date) => setChosenDate(date)}
                locale={item?.date?.locale??'en'}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={item?.time?.timeInterval??15}
                timeCaption={item?.time?.caption??'Time'}
                dateFormat={item?.time?.timeFormat??'h:mm aa'}
            />
    }
}

export {DateTime}