import axios from 'axios'
import {AuthModel, Transactions} from "../../auth";
import {VALIDATE_URL} from "../../auth/core/_requests";
import {ErrorMessage} from "../../requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const TRANSACTIONS_API = `${API_URL}/api/dashboard/transactions`
export const TRANSACTIONS_BYMONTH_API = `${API_URL}/api/dashboard/transactionsbymonth`
export const TRANSACTIONS_LIMIT_API = `${API_URL}/api/dashboard/transactionslimit`
export const SUBSCRIPTIONS_CHARTS = `${API_URL}/api/dashboard/subscriptions`

// export function getTransactions() {
//     return axios.get<Array<Transactions>>(TRANSACTIONS_API).then(response => response.data ?? []).catch(err => [])
// }
export const controller = new AbortController();

export function getTransactionsRange(start:Date,end:Date): Promise<Array<Transactions>> {
    return axios.post(TRANSACTIONS_API,{ start: start.getTime() / 1000, end: end.getTime() / 1000 }, {signal: controller.signal})
        .then(response => response.data as unknown as Array<Transactions>)
        .catch(err => [])
}
export function getTransactionsByMonth(months:number) {
    return axios.post<Object>(TRANSACTIONS_BYMONTH_API,{ months: months },{signal: controller.signal})
        .then(response => response.data ?? [])
        .catch(err => [])
}
export function getTransactionsLimit(limit:number) {
    return axios.get<Array<Transactions>>(`${TRANSACTIONS_LIMIT_API}?limit=${limit}`,{signal: controller.signal})
        .then(response => response.data ?? [])
        .catch(err => [])
}
export function getAllTransactions(): Promise<Array<Transactions>> {
    return axios.post(`${TRANSACTIONS_API}`,{})
        .then(response => response.data as unknown as Array<Transactions>)
        .catch(err => [])
}

export function tableTransactions(pageIndex:number, pageSize:number, localFilter:Array<any>, globalFilter:string, sortBy:Array<any>): Promise<{ results: Array<Transactions>; count: any }|ErrorMessage> {
    return axios.post(`${TRANSACTIONS_API}/table`,{
        pageIndex, pageSize, localFilter, globalFilter, sortBy
    })
        .then(response => response.data as unknown as ({ results: Array<Transactions>; count: any }))
        .catch(err => ({status:'error',error:err.message}) as ErrorMessage)
}

interface IChartGroup {
    categories: Array<string>,
    data: Array<number>
}

export interface ISubscriptionCharts {
    mrr: IChartGroup,
    churn: IChartGroup,
    newsubs: IChartGroup
}
export function getSubscriptionsChart(): Promise<ISubscriptionCharts | boolean> {
    return axios.get(`${SUBSCRIPTIONS_CHARTS}`,{signal: controller.signal})
        .then(response => response.data as unknown as ISubscriptionCharts)
        .catch(err => false)
}