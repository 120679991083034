import {Route, Routes, Outlet} from 'react-router-dom'
import {Overview} from "./components/Overview";
import {TaxView} from "./components/TaxView";

const TaxPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
            path='overview'
            element={<Overview />}
        />
        <Route
            path=':id'
            element={<TaxView />}
        />
        <Route
            index
            element={<Overview />}
        />
      </Route>
    </Routes>
  )
}

export default TaxPage
