import {
    Column,
    Row,
    TableInstance,
    useExpanded,
    UseExpandedOptions, UseExpandedRowProps,
    UseExpandedState,
    useFilters,
    UseFiltersInstanceProps,
    UseFiltersOptions,
    UseFiltersState,
    useGlobalFilter,
    UseGlobalFiltersInstanceProps,
    UseGlobalFiltersOptions,
    UseGlobalFiltersState,
    usePagination,
    UsePaginationInstanceProps,
    UsePaginationState,
    useRowSelect,
    UseRowSelectInstanceProps,
    UseRowSelectOptions,
    UseRowSelectState,
    useSortBy, UseSortByState,
    useTable
} from "react-table";
import React, {
    useEffect,
    useMemo,
    useRef,
    forwardRef,
    HTMLInputTypeAttribute,
    Fragment,
    useReducer,
    useState
} from "react";
import {useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fuzzyTextFilter from "./filters/fuzzyTextFilter";
import rangeDateFilter from "./filters/rangeDateFilter";
import dumbTextFilter from "./filters/dumbTextFilter";
import defaultGlobalFilter from "./filters/defualtGlobalFilter";
import {Pagination} from "./addons/pagination";
import {InputProps} from "react-select";
import {MenuComponent} from "../../../assets/ts/components";
import dataFilter from "./filters/dataFilter";
import {useQuery, useQueryClient} from "react-query";
import Skeleton from "react-loading-skeleton";
import {useReactToPrint} from "react-to-print";
import {CSVDownload} from "react-csv";

export type PaginationTableInstance<T extends Object> = TableInstance<T> &
    UsePaginationInstanceProps<any>  &
    UseGlobalFiltersInstanceProps<any> &
    UseGlobalFiltersOptions<any> &
    UseExpandedOptions<any> &
    UseFiltersInstanceProps<any> &
    UseFiltersOptions<any> &
    UseRowSelectInstanceProps<any> &
    UseRowSelectOptions<any> & {
    state: UsePaginationState<any> & UseExpandedState<any> & UseGlobalFiltersState<any> & UseFiltersState<any> & UseRowSelectState<any> & UseSortByState<any>;
    initialState: UsePaginationState<any> & UseExpandedState<any> & UseGlobalFiltersState<any> & UseFiltersState<any> & UseRowSelectState<any>;
};

type TPrTable = {
    columns: Array<Column>,
    dataFetcher: Function,
    parser: Function,
    globalFilter: string,
    localFilter: IlocalFilter,
    pageSize: number,
    TableContent: React.FC<iContent>,
    tableType: string,
    rowSelect?: boolean,
    reloadTrigger?: Function,
    selectedRows?: Function,
    setSelected?: Function,
    sortee?: Array<any>,
    invalidate?: boolean,
    print?:boolean,
    csvDownload?:boolean,
    random?:boolean|number
}

interface iContent {
    prepareRow: Function,
    row: Row & UseExpandedRowProps<any>,
    rawdata: any[]
}

export interface IlocalFilter {
    columnId: string|null;
    filter: string|number[]|Date[]|null
}

// @ts-ignore
const IndeterminateCheckbox = forwardRef<HTMLInputElement,HTMLInputTypeAttribute>( ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef<HTMLInputElement>(null)
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            // @ts-ignore
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input' type="checkbox" ref={resolvedRef} {...rest} />
            </div>
        )
    })

const reducer = (state: {[index:string]:any}, action:{[index:string]:any}):{[index:string]:any} => {
    switch (action.type) {
        case 'PAGE_CHANGED':
            return {
                ...state,
                queryPageIndex: action.payload,
            }
        case 'PAGE_SIZE_CHANGED':
            return {
                ...state,
                queryPageSize: action.payload,
            }
        case 'TOTAL_COUNT_CHANGED':
            return {
                ...state,
                totalCount: action.payload,
            }
        case 'SORT_BY_CHANGED':
            return {
                ...state,
                queryPageSortBy: action.payload,
            }
        case 'FILTER_CHANGED':
            return {
                ...state,
                queryPageFilter: action.payload,
            }
        case 'GLOBAL_FILTER_CHANGED':
            return {
                ...state,
                queryPageGlobalFilter: action.payload,
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
export const PrTable: React.FC<TPrTable> = ({
    columns,dataFetcher, parser,
    globalFilter, localFilter,
    pageSize, TableContent,
    tableType, rowSelect=false, selectedRows,
    reloadTrigger, sortee, invalidate,
    print=false, csvDownload=false, random=false
}) => {
    const queryClient = useQueryClient()
    const intl = useIntl();
    const [showCSV, setShowCSV] = useState<boolean>(false);
    const initialState:{[index:string]:any} = {
        queryPageSize:pageSize,
        queryPageIndex: 0,
        totalCount: null,
        queryPageSortBy:sortee,
        queryPageFilter:localFilter,
        queryPageGlobalFilter: globalFilter,
    };
    const printRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => {
        }
    });
    const [
        { queryPageIndex, queryPageSize, queryPageSortBy, queryPageFilter, queryPageGlobalFilter, totalCount },
        dispatch
    ] = useReducer(reducer, initialState);
    const { isLoading, error, data, isSuccess, refetch } = useQuery(
        [tableType, queryPageIndex, queryPageSize, queryPageFilter, queryPageGlobalFilter, queryPageSortBy, random],
        () => dataFetcher(queryPageIndex, queryPageSize, queryPageFilter, queryPageGlobalFilter, queryPageSortBy),
        {
            keepPreviousData: true,
            staleTime: Infinity,
            onSuccess: (data) => {
                setTimeout(() => MenuComponent.reinitialization(),200)
            }
        }
    );
    const memoData = useMemo(() => {
        if(typeof data === "undefined")
            return []
        return parser(data.results)
    }, [data])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setFilter,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({
            columns,
            data:isSuccess? memoData: [],
            // @ts-ignore
            initialState: {
                // @ts-ignore
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
                sortBy: queryPageSortBy,
                globalFilter: queryPageGlobalFilter,
                localFilter: queryPageFilter,
            },
            manualPagination: true,
            manualSortBy: true,
            manualGlobalFilter: true,
            manualFilters: true,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
            autoResetSelectedRows: false,
            autoResetFilters: false,
            pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if(rowSelect) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        // @ts-ignore
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({row}) => (
                            <div>
                                {/*
                                @ts-ignore */}
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
        ) as PaginationTableInstance<any>

    useEffect(() => {
        if(isLoading || !print)
            return
        handlePrint()
    }, [print]);
    useEffect(() => {
        if(isLoading||!csvDownload)
            return
        setShowCSV(true)
        setTimeout(() => setShowCSV(false), 1000)
    }, [csvDownload]);
    useEffect(() => {
        queryClient.invalidateQueries()
    }, [invalidate]);
    useEffect(() => {
        dispatch({ type: 'PAGE_CHANGED', payload: state.pageIndex })
    }, [state.pageIndex])
    useEffect(() => {
        setPageSize(pageSize)
        dispatch({ type: 'PAGE_SIZE_CHANGED', payload: pageSize })
        gotoPage(0)
    }, [pageSize, gotoPage])
    useEffect(() => {
        if (data?.count)
            dispatch({ type: 'TOTAL_COUNT_CHANGED', payload: data.count })
    }, [data?.count])
    useEffect(() => {
        dispatch({ type: 'GLOBAL_FILTER_CHANGED', payload: globalFilter })
    }, [globalFilter])
    useEffect(() => {
        dispatch({ type: 'FILTER_CHANGED', payload: localFilter })
    }, [localFilter])
    useEffect(() => {
        dispatch({ type: 'SORT_BY_CHANGED', payload: state?.sortBy })
    }, [state?.sortBy])
    useEffect(() => {
        if(typeof data === "undefined"||typeof selectedRows === "undefined")
            return
        selectedRows(data.results.filter((row:any, key:number) => state?.selectedRowIds[key]).map((row:any)=>parseInt(row?.id)))
    }, [state?.selectedRowIds]);

    return isLoading?
                <div key={`skeleton-${tableType}-loader`} style={{marginTop: 30}}>
                    <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                    <Skeleton width={'100%'} height={55} count={10}/>
                </div>
                :
                (<Fragment key={`prtable-${tableType}-fragment2`}>
                    {showCSV&&<CSVDownload data={data.results} filename={`${tableType}.csv`} target='_blank'/>}
                    <table {...getTableProps({className:'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'})} ref={printRef} key={`prtable-${tableType}`}>
                        <thead key={`prtable-${tableType}-thead`}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps({className:'fw-bolder text-muted'})}>
                                {headerGroup.headers.map(column => (
                                    // @ts-ignore
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column?.className}>
                                        {column.render('Header')}
                                        <span>
                                        {/*
                                        @ts-ignore */}
                                            {column.isSorted?(column.isSortedDesc?<FontAwesomeIcon icon="fa-solid fa-caret-down" className='ms-2' />:<FontAwesomeIcon icon="fa-solid fa-caret-up" className='ms-2' />):''}
                                    </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} key={`prtable-${tableType}-tbody`}>
                        {page.map((row, i) => {
                            //@ts-ignore
                            return (<TableContent prepareRow={prepareRow} row={row} rawdata={isSuccess? parser(data.results) : []} reloadTrigger={reloadTrigger} />)
                        })}
                        </tbody>
                    </table>
                    <div className='d-flex my-5 pt-5'>
                        <span className='d-flex align-items-center'>{intl.formatMessage({id: 'TRANSACTIONS.SHOWINGXOFX', defaultMessage: 'Showing {num} {type} of {num2}'},{num:data.count<queryPageSize?data.count:queryPageSize,num2:data.count, type: tableType})}</span>
                        <Pagination  gotoPage={gotoPage} previousPage={previousPage} canPreviousPage={canPreviousPage} nextPage={nextPage} canNextPage={canNextPage} pageIndex={queryPageIndex} pageCount={pageCount} pageOptions={pageOptions} />
                    </div>
                </Fragment>)
}

export const PrTableSelect: React.FC<TPrTable> = ({columns,dataFetcher, globalFilter, localFilter, pageSize, TableContent, tableType, rowSelect=false, reloadTrigger, selectedRows}) => {
    const intl = useIntl();
    const rawdata:Array<any> = [];
    const data = useMemo(() => rawdata,[rawdata]);
    const filterOptions = { filteredIds: [] };
    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilter,
            rangeDate: rangeDateFilter,
            text: dumbTextFilter,
            filterSearch: dataFilter
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setFilter,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({
            columns,
            data,
            // @ts-ignore
            initialState: { globalFilter, pageSize:pageSize },
            globalFilter: (rows: any[], columnIds: any[], filterValue: any) => defaultGlobalFilter(rows, columnIds, filterValue,filterOptions),
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if(rowSelect) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        // @ts-ignore
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                {/*
                                @ts-ignore */}
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }) as PaginationTableInstance<any>
    useEffect(() => {
        setGlobalFilter(globalFilter || undefined);
        if(localFilter.columnId!==null){
            setFilter(localFilter.columnId,localFilter.filter)
        }
        setPageSize(pageSize);
        setTimeout(() => MenuComponent.reinitialization(),200)
    }, [globalFilter,localFilter,pageSize]);
    useEffect(() => {
        if(typeof selectedFlatRows === "undefined" || typeof selectedRows === "undefined")
            return;
        selectedRows(selectedFlatRows.map(row => row.original.id))
    }, [selectedFlatRows]);

    return (
        <>
            <table {...getTableProps({className:'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'})}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps({className:'fw-bolder text-muted'})}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column?.className}>
                                {column.render('Header')}
                                <span>
                                    {/*
                                    @ts-ignore */}
                                    {column.isSorted?(column.isSortedDesc?<FontAwesomeIcon icon="fa-solid fa-caret-down" className='ms-2' />:<FontAwesomeIcon icon="fa-solid fa-caret-up" className='ms-2' />):''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    //@ts-ignore
                    return (<TableContent prepareRow={prepareRow} row={row} rawdata={rawdata} key={`prow${row.id}`} reloadTrigger={reloadTrigger} />)
                })}
                </tbody>
            </table>
            <div className='d-flex my-5 pt-5'>
                <span className='d-flex align-items-center'>{intl.formatMessage({id: 'TRANSACTIONS.SHOWINGXOFX', defaultMessage: 'Showing {num} {type} of {num2}'},{num: state.pageSize,num2:data.length, type: tableType})}</span>
                <Pagination  gotoPage={gotoPage} previousPage={previousPage} canPreviousPage={canPreviousPage} nextPage={nextPage} canNextPage={canNextPage} pageIndex={state.pageIndex} pageCount={pageCount} pageOptions={pageOptions} />
            </div>
        </>
    )
}