import React, {FC, forwardRef, useEffect, useMemo, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {CustomFieldGroups, useAuth, UserModel} from "../../auth";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router";
import Select, {components, DropdownIndicatorProps, PropsValue} from "react-select";
import {KTSVG} from "../../../../_metronic/helpers";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {IlocalFilter, PrTableSelect} from "../../../../_metronic/partials/widgets/tables/PrTable";
import countryList from "react-select-country-list";
import {Rates} from "../../../../_metronic/partials/widgets/tables/content/rates";
import clsx from "clsx";

import { ReactSortable } from "react-sortablejs";
import {DataOption, ItemCollection, ItemType} from "../core/_models";
import {FormField} from "./FormField";
import {SketchPicker} from "react-color";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {deleteGroup as deleteGroupCall, getExtraFieldGroups, getExtraFields, updateOrAddGroup} from "../core/_requests";
import {array} from "yup";

const groupViewBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Extra Field Groeps',
        path: '/extrafields',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const GroupView: FC = () => {
    const {config,classes,setLayout} = useLayout()
    const location = useLocation()
    const intl = useIntl()
    const { id } = useParams()
    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [activeItem, setActiveItem] = useState<ItemType|undefined>();
    const [activeIndex, setActiveIndex] = useState<number|undefined>();
    const [group, setGroup] = useState<CustomFieldGroups>();
    const [hideAllLabels, setHideAllLabels] = useState<boolean>(false);
    const [borderRadius, setBorderRadius] = useState<Array<number>>([]);
    const [dataFields, setDataFields] = useState<Array<DataOption>>([]);

    config.toolbar.layout = 'buttons'
    classes.contentContainer = classes.contentContainer.filter(cls => cls !== 'container')
    if(!classes.contentContainer.includes("container-fluid"))
        classes.contentContainer.push('container-fluid')
    /*const container = document.querySelector('#kt_content_container')
    if(container) {
        container.classList.remove('container')
        container.classList.add('container-fluid')
    }*/
    useEffect(() => {
        classes.contentContainer = classes.contentContainer.filter(cls => cls !== 'container')
        if(!classes.contentContainer.includes("container-fluid"))
            classes.contentContainer.push('container-fluid')
        return () => {
            classes.contentContainer = classes.contentContainer.filter(cls => cls !== 'container-fluid')
            if(!classes.contentContainer.includes("container"))
                classes.contentContainer.push('container')
        };
    }, [location.key]);

    useEffect(() => {
        if(typeof activeItem === "undefined"||typeof activeIndex === "undefined")
            return
        let newFields = [...formFields];
        newFields[activeIndex] = activeItem;
        setFormFields(newFields as ItemType[]);
        setBorderRadius(activeItem?.field?.borderRadius?.split(' ')?.map(radius=>parseInt(radius.replace('px',''))) || []);
        setDataFields(activeItem?.options??[])
    }, [activeItem]);

    const [fieldsCollection,setFieldsCollection] = useState<ItemCollection>({
        'layout': [
            {
                id: 1,
                name: 'Plain text',
                icon: 'text/txt001.svg'
            },
            {
                id: 2,
                name: 'Spacer',
                icon: 'abstract/abs002.svg'
            }
        ],
        'text': [
            {
                id: 3,
                name: 'Single Line',
                icon: 'art/art001.svg'
            },
            {
                id: 4,
                name: 'Multi line',
                icon: 'text/txt001.svg'
            },
            {
                id: 5,
                name: 'Number',
                icon: 'text/txt009.svg'
            },
            {
                id: 6,
                name: 'Telephone',
                icon: 'electronics/elc002.svg'
            },
            {
                id: 7,
                name: 'Email',
                icon: 'communication/com002.svg'
            },
            {
                id: 8,
                name: 'WYSIWYG',
                icon: 'art/art005.svg'
            },
        ],
        'datetime': [
            {
                id: 9,
                name: 'Date',
                icon: 'general/gen014.svg'
            },
            {
                id: 10,
                name: 'Time',
                icon: 'general/gen013.svg'
            },
            {
                id: 11,
                name: 'Date and time',
                icon: 'abstract/abs039.svg'
            }
        ],
        'multi': [
            {
                id: 12,
                name: 'Yes/No',
                icon: 'general/gen019.svg'
            },
            {
                id: 13,
                name: 'Dropdown',
                icon: 'general/gen023.svg'
            },
            {
                id: 14,
                name: 'Checkbox',
                icon: 'general/gen025.svg'
            },
            {
                id: 15,
                name: 'Radiobutton',
                icon: 'abstract/abs029.svg'
            }
        ],
        'media': [
            {
                id: 16,
                name: 'Image upload',
                icon: 'general/gen006.svg'
            },
            {
                id: 17,
                name: 'Video upload',
                icon: 'files/fil022.svg'
            },
            {
                id: 18,
                name: 'File upload',
                icon: 'files/fil009.svg'
            }
        ]
    });
    const [formFields, setFormFields] = useState<Array<ItemType>>([]);

    useEffect(() => {
        if(typeof currentUser?.id === "undefined")
            return
        else if(typeof id !== "undefined" && id!=='new') {
            getExtraFieldGroups().then(res => {
                if ("error" in res)
                    return
                setGroup(res?.find(group=>group.id === parseInt(id))??undefined)
                getExtraFields(parseInt(id)).then(fields=>{
                    if("error" in fields)
                        return
                    setFormFields(fields)
                })
            }).catch(e=>setGroup({
                id:0,
                name: 'New Group',
                enabled: false,
                fields: 0
            } as CustomFieldGroups))
        }else if(typeof id === "undefined"||id==='new'){
            setGroup({
                id:0,
                name: 'New Group',
                enabled: false,
                fields: 0
            } as CustomFieldGroups)
        }
        setTitle('test')
    }, [currentUser]);
    const formComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
        return <div ref={ref} className={"row gx-5 gy-5"}>{props.children}</div>;
    });

    const [containerBackground, setContainerBackground] = useState<boolean>(false);
    const [borderBackground, setBorderBackground] = useState<boolean>(false);
    const [labelColor, setLabelColor] = useState<boolean>(false);
    const [fieldBackground, setFieldBackground] = useState<boolean>(false);
    const [fieldBorderBackground, setFieldBorderBackground] = useState<boolean>(false);

    const [width, setWidth] = useState<number>(0);
    useEffect(() => {

        config.toolbar.layout = 'buttons'

        const resizeListener = () => setWidth(document.querySelector('.formbuilder')?.clientWidth??0);
        window.addEventListener('resize', resizeListener);
        setWidth(document.querySelector('.formbuilder')?.clientWidth??0)
        return () => {
            window.removeEventListener('resize', resizeListener);
            //classes.contentContainer = classes.contentContainer.filter(cls => cls !== 'container-fluid')
            //classes.contentContainer.push('container')
        }
    }, [classes.contentContainer])
    const deleteGroup = (groupid:number) => {
        // verify if user really wants to enable tax, if so send to api
        MySwal.fire({
            title: intl.formatMessage({id: 'FIELDS.CONFIRMDELETE', defaultMessage: 'Are you sure you want to delete this group?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                deleteGroupCall(groupid).then((message) => {

                    if(message.status==='success'){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'FIELDS.DELETED', defaultMessage: 'Group deleted successfully'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message){
                        MySwal.fire({
                            title: intl.formatMessage({id: 'FIELDS.ERROR', defaultMessage: 'Something went wrong!'}),
                            html: message.error,
                            icon: 'error',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }
                })
            }
        })
    };
    return (
        <>
            <PageTitle breadcrumbs={groupViewBreadCrumbs}>{title}</PageTitle>
            <PageToolbar>
                <button className='btn btn-secondary btn-sm me-5' disabled={isBusy} onClick={e=>{
                    e.preventDefault()
                    navigate(`/extrafields`)
                }}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-angle-left" className='me-3' /> Go back
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
                <button className='btn btn-light-primary btn-sm me-5' disabled={isBusy} onClick={e=>{
                    e.preventDefault()
                    if(typeof id === "undefined")
                        return;
                    setIsBusy(true)
                    MySwal.fire({
                        title: 'Saving...',
                        text: "One moment please, this won't take long.",
                        icon: 'info',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                    })
                    updateOrAddGroup(id,group?.enabled??false,group?.name??'',formFields.map(field=>({type:field?.name,config:JSON.stringify(field)}))).then((res)=>{
                        if("error" in res){
                            MySwal.fire({
                                title: intl.formatMessage({id: 'FIELDS.ERRORCREATE', defaultMessage: 'Something went wrong while saving... Please try again.'}),
                                icon: 'error',
                                showConfirmButton: false,
                                didOpen: () => MySwal.showLoading(),
                                timer: 3000
                            })
                        }else {
                            MySwal.fire({
                                title: intl.formatMessage({id: 'SWAL.SAVE.TITLE', defaultMessage: 'Saved!'}),
                                text: intl.formatMessage({
                                    id: 'SWAL.SAVE.GROUP',
                                    defaultMessage: 'Group saved successfully!'
                                }),
                                icon: 'success',
                                confirmButtonText: intl.formatMessage({id: 'SWAL.SAVE.CONFIRM', defaultMessage: 'Ok'}),
                                didOpen: () => MySwal.showLoading(),
                                timer: 3000,
                                didClose: () => {
                                    navigate(`/extrafields/${res?.id}`)
                                    setIsBusy(false)
                                }
                            })
                        }
                    })
                }}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk" className='me-3' /> Save Group
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
                {id!=="new"&&<button className='btn btn-light-danger btn-sm' disabled={isBusy} onClick={e=>{
                    e.preventDefault()
                    if(typeof id !== "number")
                        return;
                    MySwal.fire({
                        title: 'Deleting...',
                        text: "One moment please, this won't take long.",
                        icon: 'info',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                    })
                    setIsBusy(true)
                    deleteGroupCall(id).then(()=>{
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SWAL.DELETE.GROUP', defaultMessage: 'Deleted!'}),
                            text: intl.formatMessage({id: 'SWAL.DELETE.GROUP', defaultMessage: 'Group has been deleted, you will be redirected to the overview.'}),
                            icon: 'success',
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000,
                            didClose: () => navigate('/extrafields',{replace: true})
                        })
                    })
                }}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-trash-can" className='me-3' />
                    Delete Group
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>}
            </PageToolbar>
            <form className="form d-flex flex-row gap-3 gap-lg-5 flex-wrap flex-md-nowrap">
                <div className='card card-flush py-4 w-100 w-md-20 w-xxxl-500px'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Components</span>
                            {/*<span className='text-muted fw-bold fs-7'>All important product details</span>*/}
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='fs-5 fw-bolder mb-5'>
                            Layout Elements
                        </div>
                        <ReactSortable
                            className="row gx-5 gy-5"
                            list={fieldsCollection.layout}
                            setList={layout=>setFieldsCollection((oldCollection:ItemCollection)=>({...oldCollection,layout}))}
                            group={{
                                name: 'layout',
                                pull: 'clone',
                                put: false
                            }}
                            animation={150}
                            sort={false}>
                            {fieldsCollection.layout.map((item:ItemType, index:number) => (
                                <div className="col-6" key={`newfield-${item.id}`}>
                                    <div
                                        className="btn btn-outline btn-outline-dashed btn-outline-default bg-light btn-sm w-100">
                                        <KTSVG path={`/media/icons/duotune/${item.icon}`} className="svg-icon svg-icon-muted"/>
                                        {item.name}
                                    </div>
                                </div>))}
                        </ReactSortable>
                        <div className="separator my-5"></div>
                        <div className='fs-5 fw-bolder mb-5'>
                            Text Elements
                        </div>
                        <ReactSortable
                            className="row gx-5 gy-5"
                            list={fieldsCollection.text}
                            setList={text=>setFieldsCollection((oldCollection:ItemCollection)=>({...oldCollection,text}))}
                            group={{
                                name: 'text',
                                pull: 'clone',
                                put: false
                            }}
                            animation={150}
                            sort={false}>
                            {fieldsCollection.text.map((item:ItemType, index:number) => (
                                <div className="col-6" key={`newfield-${item.id}`}>
                                    <div
                                        className="btn btn-outline btn-outline-dashed btn-outline-default bg-light btn-sm w-100">
                                        <KTSVG path={`/media/icons/duotune/${item.icon}`} className="svg-icon svg-icon-muted"/>
                                        {item.name}
                                    </div>
                                </div>))}
                        </ReactSortable>
                        <div className="separator my-5"></div>
                        <div className='fs-5 fw-bolder mb-5'>
                            Date Elements
                        </div>
                        <ReactSortable
                            className="row gx-5 gy-5"
                            list={fieldsCollection.datetime}
                            setList={datetime=>setFieldsCollection((oldCollection:ItemCollection)=>({...oldCollection,datetime}))}
                            group={{
                                name: 'datetime',
                                pull: 'clone',
                                put: false
                            }}
                            animation={150}
                            sort={false}>
                            {fieldsCollection.datetime.map((item:ItemType, index:number) => (
                                <div className="col-6" key={`newfield-${item.id}`}>
                                    <div
                                        className="btn btn-outline btn-outline-dashed btn-outline-default bg-light btn-sm w-100">
                                        <KTSVG path={`/media/icons/duotune/${item.icon}`} className="svg-icon svg-icon-muted"/>
                                        {item.name}
                                    </div>
                                </div>))}
                        </ReactSortable>
                        <div className="separator my-5"></div>
                        <div className='fs-5 fw-bolder mb-5'>
                            Multi Elements
                        </div>
                        <ReactSortable
                            className="row gx-5 gy-5"
                            list={fieldsCollection.multi}
                            setList={multi=>setFieldsCollection((oldCollection:ItemCollection)=>({...oldCollection,multi}))}
                            group={{
                                name: 'multi',
                                pull: 'clone',
                                put: false
                            }}
                            animation={150}
                            sort={false}>
                            {fieldsCollection.multi.map((item:ItemType, index:number) => (
                                <div className="col-6" key={`newfield-${item.id}`}>
                                    <div
                                        className="btn btn-outline btn-outline-dashed btn-outline-default bg-light btn-sm w-100">
                                        <KTSVG path={`/media/icons/duotune/${item.icon}`} className="svg-icon svg-icon-muted"/>
                                        {item.name}
                                    </div>
                                </div>))}
                        </ReactSortable>
                        <div className="separator my-5"></div>
                        <div className='fs-5 fw-bolder mb-5'>
                            Media Elements
                        </div>
                        <ReactSortable
                            className="row gx-5 gy-5"
                            list={fieldsCollection.media}
                            setList={media=>setFieldsCollection((oldCollection:ItemCollection)=>({...oldCollection,media}))}
                            group={{
                                name: 'media',
                                pull: 'clone',
                                put: false
                            }}
                            animation={150}
                            sort={false}>
                            {fieldsCollection.media.map((item:ItemType, index:number) => (
                                <div className="col-6" key={`newfield-${item.id}`}>
                                    <div
                                        className="btn btn-outline btn-outline-dashed btn-outline-default bg-light btn-sm w-100">
                                        <KTSVG path={`/media/icons/duotune/${item.icon}`} className="svg-icon svg-icon-muted"/>
                                        {item.name}
                                    </div>
                                </div>))}
                        </ReactSortable>
                    </div>
                </div>
                <div className='card card-flush py-4 flex-grow-1'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>General</span>
                            {/*<span className='text-muted fw-bold fs-7'>All important product details</span>*/}
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <ReactSortable
                            className="row gx-5 gy-5 min-h-100px formbuilder"
                            list={formFields}
                            setList={e=>setFormFields(e)}
                            group={{
                                name: 'form',
                                pull: true,
                                put: ['layout','text', 'datetime','multi','media']
                            }}
                            handle={'.form-field-handle'}
                            animation={150}
                            /*onAdd={(e) => {
                                component.appendChild(e.clone)
                            }}*/
                            sort={true}>
                            {formFields.map((item:ItemType, index:number) => (
                                <FormField key={`formfield-${index}`} setActive={index=>{
                                    setActiveIndex(index)
                                    setActiveItem(formFields[index])
                                }} fullWidth={width} item={item} index={index} removeItem={(i)=>{
                                    if(activeIndex===i){
                                        setActiveItem(undefined)
                                        setActiveIndex(undefined)
                                    }
                                    let newFields = [...formFields];
                                    newFields.splice(i, 1);
                                    setFormFields(newFields as ItemType[]);
                                }} setItem={newItem=> {
                                    let newFields = [...formFields];
                                    newFields[index] = newItem;
                                    setFormFields(newFields as ItemType[]);
                                }}/>))}
                        </ReactSortable>
                    </div>
                </div>
                <div className='card card-flush py-4 w-100 w-md-20 w-xxxl-500px'>
                    <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Field Settings</span>
                            {/*<span className='text-muted fw-bold fs-7'>All important product details</span>*/}
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        {typeof activeItem !== "undefined"&&<div className="accordion accordion-icon-toggle" id="field_settings">
                            <div className="mb-5">
                                <div
                                    className="accordion-header py-3 d-flex"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#field_settings_general" >
                                    <span className="accordion-icon">
                                        <KTSVG
                                            className="svg-icon svg-icon-4"
                                            path="/media/icons/duotune/arrows/arr064.svg" />
                                    </span>
                                    <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                        General
                                    </h3>
                                </div>
                                <div
                                    id="field_settings_general"
                                    className="fs-6 collapse show ps-10"
                                    data-bs-parent="#field_settings" >
                                    <label className={'fs-6 text-dark form-label w-100 '}>Required?</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <label className="form-check-label me-3" htmlFor={`labels-border`}>
                                            No
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="1" id={`labels-border`}
                                               checked={activeItem?.general?.required??false}
                                               onChange={()=>{
                                                   setActiveItem(oldField=>({...oldField,general:{...oldField?.general,required:!(oldField?.general?.required??false)}} as ItemType))
                                               }}/>
                                        <label className="form-check-label" htmlFor={`labels-border`}>
                                            Yes
                                        </label>
                                    </div>
                                    <label className='fs-6 text-dark form-label w-100 mt-8'>Placeholder</label>
                                    <input type="text" className="form-control form-control-solid" value={activeItem?.general?.placeholder??''} onChange={e=>setActiveItem(oldField=>({...oldField,general:{...oldField?.general,placeholder:e?.target?.value??''}} as ItemType))} />
                                    {(activeItem?.name?.toLowerCase()??'')==='plain text'&&<>
                                        <label className='fs-6 text-dark form-label w-100 mt-8'>HTML</label>
                                        <CodeEditor
                                            value={activeItem?.html}
                                            language="html"
                                            className={'rounded'}
                                            /*placeholder="Enter your custom CSS here to make your pages look great!"*/
                                            onChange={(evn) => setActiveItem(oldField=>({...oldField, html:evn?.target?.value??''} as ItemType))}
                                            padding={15}
                                            minHeight={100}
                                            style={{
                                                fontSize: 12,
                                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                            }}
                                        />
                                    </>}
                                    {['spacer'].includes((activeItem?.name?.toLowerCase()??'').toLowerCase())&&<>
                                        <label className='fs-6 text-dark form-label w-100 mt-8'>Height</label>
                                        <div className='input-group input-group-solid flex-grow-0'>
                                            <input type="number" min={1} step={1} max={10000} className="form-control form-control-solid" value={(activeItem?.field?.height??'100').replace('px','')}
                                                   onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, height: `${e?.target?.value??100}px`}}) as ItemType)}/>
                                            <span className='pe-5 input-group-text'>
                                              PX
                                            </span>
                                        </div>
                                    </>}
                                    {/*{['telephone','email','text'].includes((activeItem?.name??'').toLowerCase())&&<>
                                        <label className={'fs-6 text-dark form-label w-100 '}>Override Default field?</label>
                                        <div className="form-check form-switch form-check-custom form-check-solid">
                                            <label className="form-check-label me-3" htmlFor={`labels-border`}>
                                                No
                                            </label>
                                            <input className="form-check-input" type="checkbox" value="1" id={`labels-border`}
                                                   checked={activeItem?.general?.required??false}
                                                   onChange={()=>{
                                                       setActiveItem(oldField=>({...oldField,general:{...oldField?.general,required:!(oldField?.general?.required??true)}} as ItemType))
                                                   }}/>
                                            <label className="form-check-label" htmlFor={`labels-border`}>
                                                Yes
                                            </label>
                                        </div>
                                    </>}*/}
                                </div>
                            </div>
                            <div className="mb-5">
                                <div
                                    className="accordion-header py-3 d-flex collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#field_settings_container" >
                                    <span className="accordion-icon">
                                        <KTSVG
                                            className="svg-icon svg-icon-4"
                                            path="/media/icons/duotune/arrows/arr064.svg" />
                                    </span>
                                    <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                        Container
                                    </h3>
                                </div>
                                <div
                                    id="field_settings_container"
                                    className="fs-6 collapse ps-10"
                                    data-bs-parent="#field_settings" >
                                    <label className='fs-6 text-dark form-label w-100'>Container background</label>
                                    <div className='btn py-2 px-2 rounded border' style={{background:'#f5f8fa'}} onClick={e=>setContainerBackground(!containerBackground)}>
                                        <div className='h-20px w-60px rounded' style={{background: activeItem?.container?.background??'transparent'}}></div>
                                    </div>
                                    {containerBackground&&
                                    <div className="position-relative w-100 mw-240px" style={{zIndex: 2}}>
                                        <div className="position-absolute top-0 start-0 end-0" onClick={e=>{
                                            /* @ts-ignore */
                                            if(e?.target?.tagName==='INPUT')
                                                return
                                            setContainerBackground(false)
                                        }}>
                                            <SketchPicker
                                                disableAlpha={true}
                                                color={activeItem?.container?.background}
                                                onChangeComplete={e=>setActiveItem(oldItem=>({...oldItem,container:{...oldItem?.container, background: e.hex}}) as ItemType)} />
                                        </div>
                                    </div>}
                                    <label className={'fs-6 text-dark form-label w-100 mt-8'}>Show border?</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <label className="form-check-label me-3" htmlFor={`labels-border`}>
                                            No
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="1" id={`labels-border`}
                                               checked={activeItem?.container?.border??false}
                                               onChange={()=>{
                                                   setActiveItem(oldField=>({...oldField,container:{...oldField?.container,border:!(oldField?.container?.border??false)}} as ItemType))
                                               }}/>
                                        <label className="form-check-label" htmlFor={`labels-border`}>
                                            Yes
                                        </label>
                                    </div>
                                    {(activeItem?.container?.border??false)&&(<>
                                        <label className='fs-6 text-dark form-label w-100 mt-8'>Border color</label>
                                        <div className='btn py-2 px-2 rounded border' style={{background:'#f5f8fa'}} onClick={e=>setBorderBackground(!borderBackground)}>
                                            <div className='h-20px w-60px rounded' style={{background: activeItem?.container?.borderColor??'transparent'}}></div>
                                        </div>
                                        {borderBackground&&
                                            <div className="position-relative w-100 mw-240px" style={{zIndex: 2}}>
                                                <div className="position-absolute top-0 start-0 end-0" onClick={e=>{
                                                    /* @ts-ignore */
                                                    if(e?.target?.tagName==='INPUT')
                                                        return
                                                    setBorderBackground(false)
                                                }}>
                                                    <SketchPicker
                                                        disableAlpha={true}
                                                        color={activeItem?.container?.borderColor}
                                                        onChangeComplete={e=>setActiveItem(oldItem=>({...oldItem,container:{...oldItem?.container, borderColor: e.hex}}) as ItemType)} />
                                                </div>
                                            </div>}
                                        <label className='fs-6 text-dark form-label w-100 mt-8'>Border Size</label>
                                        <div className='input-group input-group-solid flex-grow-0'>
                                            <input type="number" min={1} step={1} max={15} className="form-control form-control-solid" value={activeItem?.container?.borderSize}
                                                   onChange={e=>setActiveItem(oldItem=>({...oldItem,container:{...oldItem?.container, borderSize: parseInt(e?.target?.value??0)}}) as ItemType)}/>
                                            <span className='pe-5 input-group-text'>
                                              PX
                                            </span>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                            {(!['plain text','spacer'].includes((activeItem?.name?.toLowerCase()??'').toLowerCase()))&&<div className="mb-5">
                                <div
                                    className="accordion-header py-3 d-flex collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#field_settings_label" >
                                    <span className="accordion-icon">
                                        <KTSVG
                                            className="svg-icon svg-icon-4"
                                            path="/media/icons/duotune/arrows/arr064.svg" />
                                    </span>
                                    <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                        Label
                                    </h3>
                                </div>
                                <div
                                    id="field_settings_label"
                                    className="fs-6 collapse ps-10"
                                    data-bs-parent="#field_settings" >

                                    <label className={'fs-6 text-dark form-label w-100'}>Show label?</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <label className="form-check-label me-3" htmlFor={`labels-label`}>
                                            No
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="1" id={`labels-label`}
                                               checked={activeItem?.label?.visible??true}
                                               onChange={()=>setActiveItem(oldField=>({...oldField,label:{...oldField?.label,visible:!(oldField?.label?.visible??true)}} as ItemType))}/>
                                        <label className="form-check-label" htmlFor={`labels-label`}>
                                            Yes
                                        </label>
                                    </div>
                                    {(activeItem?.label?.visible??true)&&<>
                                        <label className='fs-6 text-dark form-label w-100 mt-8'>Text</label>
                                        <input type="text" className="form-control form-control-solid" value={activeItem?.label?.text??activeItem?.name} onChange={e=>setActiveItem(oldField=>({...oldField,label:{...oldField?.label,text:e?.target?.value??''}} as ItemType))} />
                                        <label className='fs-6 text-dark form-label w-100 mt-8'>Label color</label>
                                        <div className='btn py-2 px-2 rounded border' style={{background:'#f5f8fa'}} onClick={e=>setLabelColor(!labelColor)}>
                                            <div className='h-20px w-60px rounded' style={{background: activeItem?.label?.color??'black'}}></div>
                                        </div>
                                        {labelColor&&
                                            <div className="position-relative w-100 mw-240px" style={{zIndex: 2}}>
                                                <div className="position-absolute top-0 start-0 end-0" onClick={e=>{
                                                    /* @ts-ignore */
                                                    if(e?.target?.tagName==='INPUT')
                                                        return
                                                    setLabelColor(false)
                                                }}>
                                                    <SketchPicker
                                                        disableAlpha={true}
                                                        color={activeItem?.label?.color??'#000000'}
                                                        onChangeComplete={e=>setActiveItem(oldItem=>({...oldItem,label:{...oldItem?.label, color: e.hex}}) as ItemType)} />
                                                </div>
                                            </div>}
                                    </>}
                                </div>
                            </div>}
                            {(!['plain text','spacer'].includes((activeItem?.name?.toLowerCase()??'').toLowerCase()))&&<>
                                <div className="mb-5">
                                    <div
                                        className="accordion-header py-3 d-flex collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#field_settings_field" >
                                        <span className="accordion-icon">
                                            <KTSVG
                                                className="svg-icon svg-icon-4"
                                                path="/media/icons/duotune/arrows/arr064.svg" />
                                        </span>
                                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                            Field
                                        </h3>
                                    </div>
                                    <div
                                        id="field_settings_field"
                                        className="fs-6 collapse ps-10"
                                        data-bs-parent="#field_settings" >
                                        {['yes/no'].includes(activeItem?.name?.toLowerCase()??'')&&<>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Checked by default?</label>
                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                <label className="form-check-label me-3" htmlFor={`field-checked-enabled`}>
                                                    No
                                                </label>
                                                <input className="form-check-input" type="checkbox" value="1" id={`field-checked-enabled`}
                                                       checked={activeItem?.field?.checked??false}
                                                       onChange={()=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field,checked:!(activeItem?.field?.checked??false)}}) as ItemType)}/>
                                                <label className="form-check-label" htmlFor={`field-checked-enabled`}>
                                                    Yes
                                                </label>
                                            </div>
                                        </>}
                                        {['dropdown'].includes(activeItem?.name?.toLowerCase()??'')&&<>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Searchable?</label>
                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                <label className="form-check-label me-3" htmlFor={`field-searchable-enabled`}>
                                                    No
                                                </label>
                                                <input className="form-check-input" type="checkbox" value="1" id={`field-searchable-enabled`}
                                                       checked={activeItem?.field?.searchable??false}
                                                       onChange={()=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field,searchable:!(activeItem?.field?.searchable??false)}}) as ItemType)}/>
                                                <label className="form-check-label" htmlFor={`field-searchable-enabled`}>
                                                    Yes
                                                </label>
                                            </div>
                                        </>}
                                        <label className='fs-6 text-dark form-label w-100'>Field background</label>
                                        <div className='btn py-2 px-2 rounded border' style={{background:'#f5f8fa'}} onClick={e=>setFieldBackground(!fieldBackground)}>
                                            <div className='h-20px w-60px rounded' style={{background: activeItem?.field?.background??'transparent'}}></div>
                                        </div>
                                        {fieldBackground&&
                                            <div className="position-relative w-100 mw-240px" style={{zIndex: 2}}>
                                                <div className="position-absolute top-0 start-0 end-0" onClick={e=>{
                                                    /* @ts-ignore */
                                                    if(e?.target?.tagName==='INPUT')
                                                        return
                                                    setFieldBackground(false)
                                                }}>
                                                    <SketchPicker
                                                        disableAlpha={true}
                                                        color={activeItem?.field?.background}
                                                        onChangeComplete={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, background: e.hex}}) as ItemType)} />
                                                </div>
                                            </div>}
                                        <label className={'fs-6 text-dark form-label w-100 mt-8'}>Show border?</label>
                                        <div className="form-check form-switch form-check-custom form-check-solid">
                                            <label className="form-check-label me-3" htmlFor={`field-border`}>
                                                No
                                            </label>
                                            <input className="form-check-input" type="checkbox" value="1" id={`field-border`}
                                                   checked={activeItem?.field?.border??false}
                                                   onChange={()=>{
                                                       setActiveItem(oldField=>({...oldField,field:{...oldField?.field,border:!(oldField?.field?.border??false)}} as ItemType))
                                                   }}/>
                                            <label className="form-check-label" htmlFor={`field-border`}>
                                                Yes
                                            </label>
                                        </div>
                                        {(activeItem?.field?.border??false)&&(<>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Border color</label>
                                            <div className='btn py-2 px-2 rounded border' style={{background:'#f5f8fa'}} onClick={e=>setFieldBorderBackground(!fieldBorderBackground)}>
                                                <div className='h-20px w-60px rounded' style={{background: activeItem?.field?.borderColor??'transparent'}}></div>
                                            </div>
                                            {fieldBorderBackground&&
                                                <div className="position-relative w-100 mw-240px" style={{zIndex: 2}}>
                                                    <div className="position-absolute top-0 start-0 end-0" onClick={e=>{
                                                        /* @ts-ignore */
                                                        if(e?.target?.tagName==='INPUT')
                                                            return
                                                        setFieldBorderBackground(false)
                                                    }}>
                                                        <SketchPicker
                                                            disableAlpha={true}
                                                            color={activeItem?.field?.borderColor}
                                                            onChangeComplete={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, borderColor: e.hex}}) as ItemType)} />
                                                    </div>
                                                </div>}
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Border Size</label>
                                            <div className='input-group input-group-solid flex-grow-0'>
                                                <input type="number" min={1} step={1} max={15} className="form-control form-control-solid" value={activeItem?.field?.borderSize}
                                                       onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, borderSize: parseInt(e?.target?.value??0)}}) as ItemType)}/>
                                                <span className='pe-5 input-group-text'>
                                              PX
                                            </span>
                                            </div>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Border Radius</label>
                                            <div className='input-group input-group-solid flex-grow-0 mt-5'>
                                                <span className='ps-5 input-group-text'>
                                                  Left Top
                                                </span>
                                                <input type="number" min={0} step={1} max={100} className="form-control form-control-solid" value={borderRadius[0]??0}
                                                       onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, borderRadius: [e?.target?.value??0,borderRadius[1]??0,borderRadius[2]??0,borderRadius[3]??0].map(bR=>`${bR}px`).join(' ')}}) as ItemType)}/>
                                                <span className='pe-5 input-group-text'>
                                                  PX
                                                </span>
                                            </div>
                                            <div className='input-group input-group-solid flex-grow-0 mt-5'>
                                                <span className='ps-5 input-group-text'>
                                                  Right Top
                                                </span>
                                                <input type="number" min={0} step={1} max={100} className="form-control form-control-solid" value={borderRadius[1]??0}
                                                       onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, borderRadius: [borderRadius[0]??0,e?.target?.value??0,borderRadius[2]??0,borderRadius[3]??0].map(bR=>`${bR}px`).join(' ')}}) as ItemType)}/>
                                                <span className='pe-5 input-group-text'>
                                                  PX
                                                </span>
                                            </div>
                                            <div className='input-group input-group-solid flex-grow-0 mt-5'>
                                                <span className='ps-5 input-group-text'>
                                                  Right Bottom
                                                </span>
                                                <input type="number" min={0} step={1} max={100} className="form-control form-control-solid" value={borderRadius[2]??0}
                                                       onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, borderRadius: [borderRadius[0]??0,borderRadius[1]??0,e?.target?.value??0,borderRadius[3]??0].map(bR=>`${bR}px`).join(' ')}}) as ItemType)}/>
                                                <span className='pe-5 input-group-text'>
                                                  PX
                                                </span>
                                            </div>
                                            <div className='input-group input-group-solid flex-grow-0 mt-5'>
                                                <span className='ps-5 input-group-text'>
                                                  Left Bottom
                                                </span>
                                                <input type="number" min={0} step={1} max={100} className="form-control form-control-solid" value={borderRadius[3]??0}
                                                       onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, borderRadius: [borderRadius[0]??0,borderRadius[1]??0,borderRadius[2]??0,e?.target?.value??0].map(bR=>`${bR}px`).join(' ')}}) as ItemType)}/>
                                                <span className='pe-5 input-group-text'>
                                                  PX
                                                </span>
                                            </div>
                                        </>)}
                                        {['number'].includes(activeItem?.name?.toLowerCase()??'')&&<>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Minimal input value</label>
                                            <input type="number" className="form-control form-control-solid" value={activeItem?.field?.min}
                                                   onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, min: parseInt(e?.target?.value??0)}}) as ItemType)}/>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Maximal input value</label>
                                            <input type="number" className="form-control form-control-solid" value={activeItem?.field?.max}
                                                   onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, max: parseInt(e?.target?.value??0)}}) as ItemType)}/>
                                            <label className='fs-6 text-dark form-label w-100 mt-8'>Step size</label>
                                            <input type="number" className="form-control form-control-solid" value={activeItem?.field?.step}
                                                   onChange={e=>setActiveItem(oldItem=>({...oldItem,field:{...oldItem?.field, step: parseInt(e?.target?.value??0)}}) as ItemType)}/>
                                        </>}
                                    </div>
                                </div>
                                {['dropdown','checkbox','radiobuttons','radiobutton','yes/no'].includes(activeItem?.name?.toLowerCase()??'')&&<div className="mb-5">
                                <div
                                    className="accordion-header py-3 d-flex collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#field_settings_data" >
                                    <span className="accordion-icon">
                                        <KTSVG
                                            className="svg-icon svg-icon-4"
                                            path="/media/icons/duotune/arrows/arr064.svg" />
                                    </span>
                                    <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                        Data
                                    </h3>
                                </div>
                                <div
                                    id="field_settings_data"
                                    className="fs-6 collapse ps-10"
                                    data-bs-parent="#field_settings" >
                                    {dataFields.map((field,index)=>
                                        <div className='d-flex align-items-center justify-content-between mt-5 position-relative data-fields-loop gap-3' key={index}>
                                            <div className='d-flex align-items-center'>
                                                <label className='fs-7 text-dark form-label me-5'>Label</label>
                                                <input type="text" className="form-control form-control-solid" value={field?.label}
                                                        onChange={e=>{
                                                            setDataFields(oldFields=>oldFields.map((oldField,oldIndex)=>oldIndex===index?{...oldField,label: e?.target?.value}:oldField))
                                                            setActiveItem(oldItem=>({...oldItem,options:oldItem?.options?.map((oldField,oldIndex)=>oldIndex===index?{...oldField,label: e?.target?.value}:oldField)}) as ItemType)
                                                        }}/>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <label className='fs-7 text-dark form-label me-5'>Value</label>
                                                <input type="text" className="form-control form-control-solid" value={field?.value}
                                                        onChange={e=> {
                                                            setDataFields(oldFields => oldFields.map((oldField, oldIndex) => oldIndex === index ? {
                                                                ...oldField,
                                                                value: e?.target?.value
                                                            } : oldField))
                                                            setActiveItem(oldItem=>({...oldItem,options:oldItem?.options?.map((oldField,oldIndex)=>oldIndex===index?{...oldField,value: e?.target?.value}:oldField)}) as ItemType)
                                                        }}/>
                                            </div>
                                            <button className='btn btn-light-danger btn-icon btn-sm delete-data-field' onClick={e=>{
                                                e.preventDefault()
                                                setDataFields(oldFields=>oldFields.filter((oldField,oldIndex)=>oldIndex!==index))
                                                setActiveItem(oldItem=>({...oldItem,options:oldItem?.options?.filter((oldField,oldIndex)=>oldIndex!==index)}) as ItemType)
                                            }}>
                                                {/*
                                                    @ts-ignore */}
                                                <FontAwesomeIcon icon="fa-duotone fa-trash" />
                                            </button>
                                        </div>)}
                                    <button className='btn btn-secondary mt-5 btn-sm' key={'add-data-field'} onClick={e=>{
                                        e.preventDefault()
                                        setDataFields(oldFields=>[...oldFields,{label: '',value: ''}])
                                        setActiveItem(oldItem=>({...oldItem,options:[...oldItem?.options??[],{label: '',value: ''}]}) as ItemType)
                                    }}>
                                        {/*
                                            @ts-ignore */}
                                        <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' />
                                        Add new field
                                    </button>
                                </div>
                            </div>}
                            </>}
                        </div>}
                    </div>
                    {typeof group !== "undefined"&&<>
                        <div className="separator my-5"></div>
                        <div className='card-header border-0 pt-2 pb-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Global Settings</span>
                                {/*<span className='text-muted fw-bold fs-7'>All important product details</span>*/}
                            </h3>
                        </div>
                        <div className='card-body py-3 flex-shrink-1 flex-grow-0'>
                            <label className='fs-6 text-dark form-label w-100'>Name</label>
                            <input type="text" className="form-control form-control-solid" value={group?.name??'Unkown #1512'} onChange={e=>setGroup(oldGroup=>({...oldGroup,name:e?.target?.value??''}) as CustomFieldGroups)} />
                            <div className="row gy-3 gx-3">
                                <div className="col-6">
                                    <label className='fs-6 fw-bolder text-dark form-label mt-8'>Group enabled?</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <label className="form-check-label me-3" htmlFor={`labels-enabled`}>
                                            No
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="1" id={`labels-enabled`}
                                               checked={group?.enabled??true}
                                               onChange={()=>setGroup(oldGroup=>({...oldGroup,enabled:!(group?.enabled??true)}) as CustomFieldGroups)}/>
                                        <label className="form-check-label" htmlFor={`labels-enabled`}>
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    <label className='fs-6 fw-bolder text-dark form-label mt-8'>Hide all labels?</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <label className="form-check-label me-3" htmlFor={`labels-global`}>
                                            No
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="1" id={`labels-global`}
                                               checked={hideAllLabels}
                                               onChange={()=>{
                                                   const newValue = !hideAllLabels
                                                   setHideAllLabels(newValue)
                                                   if(typeof activeItem !== "undefined")
                                                       setActiveItem(oldItem=>({...oldItem,label:{...oldItem?.label,visible:!newValue}}) as ItemType)
                                                   setFormFields(oldFields=>([...oldFields.map(field=>({...field,label:{...field?.label,visible:!newValue}}))] as ItemType[]))
                                               }}/>
                                        <label className="form-check-label" htmlFor={`labels-global`}>
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </form>
            <div className='card card-flush py-4 flex-grow-1 mt-3 mt-lg-5'>
                <div className='card-header border-0 py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Getting Started</span>
                        <span className='text-muted fw-bold fs-7'>Easy to learn the features and create forms with ease.</span>
                    </h3>
                </div>
                <div className='card-body py-3 d-flex flex-row gap-3 gap-lg-5 flex-wrap'>
                    <div className='w-50'>
                        <h4>How to add fields</h4>
                        <p>Just drag a field in the left column and drop it in the center column.</p>
                        <div className="separator my-5"></div>
                        <h4>How to change field settings</h4>
                        <p>Click on the field you want to alter in the middle column, on the right settings will appear.</p>
                    </div>
                    <div className="flex-grow-1">
                        <h4>How to assign them to products</h4>
                        <p>Upon creating or editing a product you can select if you want to load extra fields.</p>
                        <div className="separator my-5"></div>
                        <h4>How to change size</h4>
                        <p>Move your mouse to the right of the field, a black bar should appear. Click it and make your field smaller or wider.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export {GroupView}