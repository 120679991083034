import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {useAuth} from "../../auth";
import {CurrencyFormat} from "../../currency/components/format";
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useParams} from "react-router";
import {MenuComponent, StepperComponent} from "../../../../_metronic/assets/ts/components";
import {Form, Formik, FormikValues} from "formik";
import {Step1} from "./steps/Step1";
import {Step2} from "./steps/Step2";
import {Step3} from "./steps/Step3";
import {Step4} from "./steps/Step4";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {downloadBlob, downloadDataUri} from "../../../../_metronic/helpers/svgDownload";
import {Canvg} from "canvg";
import {QRCodeSVG} from "qrcode.react";
import Skeleton from "react-loading-skeleton";
import {createPaymentLink} from "../core/_requests";

const newRequestBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Requests',
        path: '/requests',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const NewPage: FC = () => {
    /*const {config} = useLayout()*/
    const { type } = useParams()
    const intl = useIntl()
    const {currentUser} = useAuth()
    const MySwal = withReactContent(Swal)
    const [data, setData] = useState<{[index: string]:any}>({type: 1});
    const [initType, setInitType] = useState(false);

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [errors, setErrors] = useState<{[index: string]:any}>({});
    const [isSubmitButton, setSubmitButton] = useState(false)
    const [success, setSuccess] = useState(false);
    const [requestLink, setRequestLink] = useState<string>();
    const [copied, setCopied] = useState(false);

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }
    const prevStep = () => {
        if (!stepper.current)
            return
        stepper.current.goPrev()
        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
        //setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex - 1])
    }
    const submitStep = (values: object, actions: FormikValues) => {
        if (!stepper.current)
            return
        setErrors({});
        let hasErrors = false;
        if(stepper.current.currentStepIndex===2){
            if(data?.type===6){
                if(typeof data?.product === "undefined"||data?.product==="") {
                    setErrors(e => ({...e, product: "Product is required"}))
                    hasErrors = true
                }
            }else{
                if(typeof data?.title === "undefined"||data?.title==="") {
                    setErrors(e => ({...e, title: "Title is required"}))
                    hasErrors = true
                }
                if(typeof data?.price === "undefined"||data?.price==="") {
                    setErrors(e => ({...e, price: "Price is required"}))
                    hasErrors = true
                }
            }
        }else if(stepper.current.currentStepIndex===3){
            if(data?.type!==2){
                if(typeof data?.customer === "undefined"||data?.customer==="") {
                    setErrors(e => ({...e, customer: "Customer is required"}))
                    hasErrors = true
                }
            }
            if(data?.reminder){
                if(typeof data?.reminderCycle === "undefined"||data?.reminderCycle==="") {
                    setErrors(e => ({...e, reminderCycle: "Cycle is required"}))
                    hasErrors = true
                }
                if(typeof data?.reminderCount === "undefined"||data?.reminderCount==="") {
                    setErrors(e => ({...e, reminderCount: "Times is required"}))
                    hasErrors = true
                }
            }
        }
        if(hasErrors)
            return;
        const maxSteps = data?.type===2?3:stepper.current.totatStepsNumber;
        setSubmitButton(stepper.current.currentStepIndex === maxSteps! - 1)

        //setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex])
        if (stepper.current.currentStepIndex !== maxSteps)
            stepper.current.goNext()
        else {
            MySwal.fire({
                title: 'Creating request...',
                text: "One moment please, this won't take long.",
                icon: 'info',
                allowOutsideClick: false,
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: false
            })
            createPaymentLink(data).then(res => {
                MySwal.close()
                if ("url" in res) {
                    setSuccess(true)
                    MenuComponent.reinitialization()
                    setRequestLink(`${process.env.REACT_APP_LINK_URL}/${res?.url}`)
                } else if("error" in res){
                    MySwal.fire({
                        title: 'Error',
                        text: res.error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            //stepper.current.goto(1)
            //actions.resetForm()
        }
    }
    /*config.toolbar.layout = 'request'*/

    const updateType = () => {
        switch (type) {
            case "donation":
                setData({type: 2})
                break;
            case "product":
                setData({type: 6})
                break;
            case "preauth":
            case "pre-auth":
            case "pre-authorization":
                setData({type: 3})
                break;
            case "dynamic":
                setData({type: 4})
                break;
            case "qr":
                setData({type: 5})
                break;
            case "normal":
            default:
                setData({type: 1})
        }
    }
    useEffect(() => {
        if(typeof type !== "undefined" && initType){
            updateType()
            if (!stepper.current)
                return
            stepper.current?.goto(2)
        }
    }, [type]);

    const svgRef = useRef();
    const downloadSVG = useCallback(() => {
        // @ts-ignore
        const svg = svgRef.current.innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
        downloadBlob(blob, `new-qrcode.svg`);
    }, [])
    const downloadPNG = useCallback(async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if(!ctx)
            return;
        let v = null
        // @ts-ignore
        v = await Canvg.fromString(ctx, svgRef.current.innerHTML)
        v.start()
        const png = canvas.toDataURL("img/png")
        downloadDataUri(png, `new-qrcode.png`)
    } ,[])

    useEffect(() => {
        loadStepper()
        if(typeof type !== "undefined" && !initType){
            setInitType(true)
            updateType()
            if (!stepper.current)
                return
            stepper.current?.goto(2)
        }else if(typeof type === "undefined")
            setInitType(true)
    }, [stepperRef,type]);

    return (
        <>
            <PageTitle breadcrumbs={newRequestBreadCrumbs}>{`New Request`}</PageTitle>
            <div className='card'>
                <div className='card-body'>
                    {!success?(<div
                        ref={stepperRef}
                        className='stepper stepper-links d-flex flex-column'
                        id='pr_new_request_stepper'
                    >
                        <div className='stepper-nav mb-5 justify-content-evenly w-100'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Request Type</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Request Information</h3>
                            </div>

                            {(data.type!==4&&data.type!==2) && <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Receiving End</h3>
                            </div>}

                            {data.type!==4 && <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Extra Information</h3>
                            </div>}

                        </div>

                        <Formik initialValues={{}} onSubmit={submitStep}>
                            {() => (
                                <Form className='mx-auto w-100 pt-15 pb-10' id='pr_new_request_form'>
                                    <div className='current flex-column' data-kt-stepper-element='content'>
                                        <Step1 data={data} setData={setData} errors={errors} />
                                    </div>

                                    <div data-kt-stepper-element='content' className='flex-column'>
                                        <Step2 data={data} setData={setData} errors={errors} />
                                    </div>

                                    {(data.type!==4&&data.type!==2) && <div data-kt-stepper-element='content' className='flex-column'>
                                        <Step3 data={data} setData={setData} errors={errors} />
                                    </div>}

                                    {data.type!==4 && <div data-kt-stepper-element='content' className='flex-column'>
                                        <Step4 data={data} setData={setData} errors={errors} />
                                    </div>}

                                    <div className='d-flex flex-stack pt-15'>
                                        <div className='mr-2'>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                Back
                                            </button>
                                        </div>

                                        {(data.type!==4||(data.type===4&&stepper?.current?.currentStepIndex!==2))&&<div>
                                            <button type='submit' className='btn btn-lg btn-primary me-3'>
                                              <span className='indicator-label'>
                                                {!isSubmitButton && 'Continue'}
                                                  {isSubmitButton && 'Submit'}
                                                  <KTSVG
                                                      path='/media/icons/duotune/arrows/arr064.svg'
                                                      className='svg-icon-3 ms-2 me-0'
                                                  />
                                              </span>
                                            </button>
                                        </div>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>):(
                        <div className='w-100'>
                            <div className='pb-10 pb-lg-15'>
                                <h1 className='fw-bolder d-flex align-items-center justify-content-center text-dark'>
                                    Your request is created!
                                </h1>
                            </div>
                            <div className='d-flex flex-column align-items-center'>
                                <div className="row g-10 w-100">
                                    <div className='col-12 col-md-3 d-flex flex-column align-items-center'>
                                        {/* @ts-ignore */}
                                        <div ref={svgRef}>
                                            {requestLink?<QRCodeSVG fgColor={'#1954bf'} value={requestLink} size={200} />:<Skeleton width={200} height={200} />}
                                        </div>
                                        <div className="mb-10">
                                            <button
                                                type='button'
                                                className='btn btn-light-primary mt-4 btn-sm'
                                                id="svgDownloadButton"
                                                data-kt-menu-trigger='hover'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='bottom'
                                            >
                                                {/*
                                                @ts-ignore */}
                                                <FontAwesomeIcon icon="fa-duotone fa-download" className="me-2" />
                                                Download QR Code
                                            </button>

                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-active-bg fw-semibold py-3" data-kt-menu="true" style={{maxWidth: 200}}>
                                                <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                                    <a href="javascript:void(0);" onClick={downloadSVG}
                                                       className="menu-link px-3">
                                                        <span className="menu-title">SVG</span>
                                                    </a>
                                                </div>
                                                <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                                    <a href="javascript:void(0);" onClick={downloadPNG}
                                                       className="menu-link px-3">
                                                        <span className="menu-title">PNG</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="fs-6 fw-semibold text-gray-500 mb-10 text-center">
                                            Your request url is: {requestLink}
                                        </div>
                                        <div className='d-flex flex-row justify-content-center'>
                                            <CopyToClipboard text={requestLink??''} onCopy={(e) => setCopied(true)}>
                                                <button className="highlight-copy btn btn-sm btn-primary me-5">
                                                    {/*
                                            @ts-ignore */}
                                                    <FontAwesomeIcon icon="fa-duotone fa-copy" className='me-3' />
                                                    {copied?'Copied':'Copy url'}
                                                </button>
                                            </CopyToClipboard>
                                            <button className="btn btn-sm btn-primary ms-5"
                                                    onClick={e=>{
                                                        navigator.share({
                                                            title: 'Please pay me',
                                                            text: 'Click on the attached link to pay',
                                                            url: requestLink
                                                        })
                                                    }}>
                                                {/*
                                        @ts-ignore */}
                                                <FontAwesomeIcon icon="fa-duotone fa-copy" className='me-3' />
                                                Share url
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <img src={toAbsoluteUrl('/media/illustrations/user-welcome.png')}
                                             className="mw-100 mh-300px " alt="Request created!" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export {NewPage}