/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {
  TablesWidget5,
  MixedWidget8, CustomerHeads, PaymentLinksDonut,
} from '../../../_metronic/partials/widgets'
import {useAuth} from "../../modules/auth";
import {SalesSummary} from "../../../_metronic/partials/widgets/mixed/SalesSummary";
import {PayRate} from "../../../_metronic/partials/widgets/mixed/PayRate";
import {RecentTransactions} from "../../../_metronic/partials/widgets/tables/RecentTransactions";
import {SubscriptionWidgets} from "../../../_metronic/partials/widgets/statistics/SubscriptionWidgets";

const DashboardPage: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
      <>
        {/* begin::Row */}
        <div className='row gy-5 g-xl-8'>
          <div className='col-xxl-4'>
            {/*<MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />*/}
            <CustomerHeads
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                image='abstract-4.svg'
                title={intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}/>
            <PayRate className='card-xl-stretch-50' chartColor='success' chartHeight='230px' />
          </div>
          <div className='col-xxl-4'>
            {/*<ListsWidget5 className='card-xxl-stretch' />*/}
            <PaymentLinksDonut
                className='card-xl-stretch mb-5 mb-xl-8'
                image='abstract-2.svg'
                title={intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
            />
          </div>
          <div className='col-xxl-4'>
            <SalesSummary
                className='card-xl-stretch mb-xl-8'
                color='success'
                image='abstract-3.svg' />
          </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <RecentTransactions className='card-xxl-stretch mb-5 mb-xl-8' />
          </div>
        </div>
        {/* end::Row */}

        <div className='row gy-5 g-xl-8 mt-4 mb-2'>
          <h1 className="fw-bolder mb-5">
            {intl.formatMessage({id: 'PRODUCTS.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}
          </h1>
        </div>
        {/* begin::Row */}
        <SubscriptionWidgets className='row gy-5 g-xl-8' />
        {/* end::Row */}
      </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {config} = useLayout()
    config.toolbar.layout = 'none';
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
