import React, {FC, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {PaymentLinkStatusColors, PaymentLinkStatusIcons, TaxClasses, useAuth} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {Link, useNavigate} from "react-router-dom";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import {useReactToPrint} from 'react-to-print';
import {PrTable, IlocalFilter, PrTableSelect} from "../../../../_metronic/partials/widgets/tables/PrTable";
import {Taxes} from "../../../../_metronic/partials/widgets/tables/content/taxes";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import {getProductTaxes, tableProductTaxes} from "../../products/core/_requests";

const taxBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Requests',
        path: '/requests',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Overview: FC = () => {
    const intl = useIntl();
    const {currentUser} = useAuth()
    const navigate = useNavigate()

    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: Date = new Date(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: Date = new Date(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: number = parseFloat(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.RATES', defaultMessage: '# Rates'}),
            accessor: 'rates',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.PRODUCTS', defaultMessage: '# Products'}),
            accessor: 'products',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            defaultCanSort: true
        },{
            Header: intl.formatMessage({id: 'TABLE.LASTUPDATED', defaultMessage: 'Last updated'}),
            accessor: 'updated_at',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const parser = (taxes:Array<TaxClasses>) => taxes.map(tax => {
        return {
            id: tax.id,
            name: (
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {tax.name}
                            </span>
            ),
            rates: (
                <span className="badge badge-light-primary">
                                    {tax.rates.length} {tax.rates.length===1?'Rate':'Rates'}
                              </span>
            ),
            products: (
                <span className="badge badge-light-primary">
                                    {tax.products} {tax.products===1?'Product':'Products'}
                              </span>
            ),
            status: (
                <span className={`badge badge-${tax.status?'success':'danger'}`}>
                                    {tax.status?'Enabled':'Disabled'}
                                </span>
            ),
            updated_at: (
                <div className="d-flex flex-column">
                                    <span className='text-gray-700 fw-bolder text-hover-primary fs-6'>
                                        {intl.formatDate(tax.updatedAt,{year:'numeric',month:'long',day:'numeric'})}
                                    </span>
                    <span className='text-gray-500 fs-8'>
                                        Created at: {intl.formatDate(tax.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                    </span>
                </div>
            ),
            actions: false
        }
    }).reverse()
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshing, setRefreshing] = useState<boolean>(false)
    const reloadTrigger = () => setRefreshData(!refreshData)
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const initialSort = useMemo( () => [ { id: "updated_at", desc: false } ],[])
    return (
        <>
            <PageTitle breadcrumbs={taxBreadCrumbs}>{intl.formatMessage({id: 'MENU.TAX', defaultMessage: 'Tax'})}</PageTitle>
            <PageToolbar>
                <button className='btn btn-light-primary btn-sm me-5' onClick={e=>navigate('/tax/new')}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' /> New Group
                </button>
            </PageToolbar>
            <div className={'card mb-5 mb-xl-10'}>
                <div className='card-header border-0'>
                    <div className='d-flex align-items-center my-5 pt-5 w-100 justify-content-between'>
                        <div className="d-flex align-items-center position-relative my-1">
                            {/*
                                 @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                            <input type="text"
                                   value={globalFilter}
                                   className="form-control form-control-solid w-250px ps-14"
                                   placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                   onChange={onChange}/>
                        </div>

                        <div className='d-flex justify-content-end flex-shrink-0 ms-auto my-1'>

                            <button className='btn btn-light-primary btn-sm ms-5 fw-bolder px-7' onClick={e=>navigate('/tax/new')}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' /> New Group
                            </button>
                        </div>
                        <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                            {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                        </div>
                        <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                            setRefreshing(true)
                            setRefreshData(!refreshData)
                            setTimeout(() => setRefreshing(false), 1000)
                        }}>
                            {/*
                                @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                        </button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                            <PrTable columns={columns} parser={parser} dataFetcher={tableProductTaxes} reloadTrigger={reloadTrigger} invalidate={refreshData} sortee={initialSort} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Taxes} tableType={'taxes'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}