import React, {createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {Products} from "../../../../_metronic/partials/widgets/tables/content/products";
import {MetaValues, ProductsStatus, useAuth, UserModel} from "../../auth";
import {KTSVG} from "../../../../_metronic/helpers";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {
    defaultImageInputOptions,
    defaultImageInputQueires,
    ImageInputComponent,
    MenuComponent
} from "../../../../_metronic/assets/ts/components";
import {useParams} from "react-router";
import {
    checkPostmark,
    currencyList,
    deleteAccount,
    fileUploadString,
    getPostmark, updateUser,
    UPLOAD_URLS
} from "../../auth/core/_requests";
import {v4 as uuidv4} from "uuid";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import Skeleton from "react-loading-skeleton";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {productStatus} from "../../products/ProductsHelper";
import TextEditor from "../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {CurrencyIcon} from "../../currency/components/icon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MetaField} from "../../products/components/MetaField";
import {CurrencyFormat} from "../../currency/components/format";
import RequestNumbers from "./RequestNumbers";
// @ts-ignore
import DragSort from '@yaireo/dragsort'
import Tags from "@yaireo/tagify/dist/react.tagify"
import {getPaymentMethods} from "../../../integrations/core/_requests";
import {paymentMethodDescriptions, paymentMethodIcons} from "../../requests/core/_assets";
import clsx from "clsx";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Crisp } from "crisp-sdk-web";
import BecomePro from "../../pro/components/BecomePro";
import PayProModal2 from "../../pro/components/PayProModal2";

const settingsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Settings',
        path: '/settings',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Overview: FC = () => {
    const [showAPIKey, setShowAPIKey] = useState(false);
    const show = () => Crisp.chat?.show()
    const {config} = useLayout()
    config.toolbar.layout = 'buttons'
    const {tab} = useParams()
    const intl = useIntl()
    const {currentUser, setCurrentUser} = useAuth()
    const navigate = useNavigate()
    const [userDetails, setUserDetails] = useState<UserModel>();
    const [tabType, setTabType] = useState(tab || 'Profile');
    const [isBusy, setIsBusy] = useState(false);
    const [currency, setCurrency] = useState<any[]>([])
    const [chosenCurrency, setChosenCurrency] = useState<any>();
    const [title, setTitle] = useState(intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'}));
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    useEffect(() => {
        setTabType(tab || 'Profile')
        setTitle(`${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - ${tabType}`)
        if(typeof tab !== "undefined"&&document.querySelector(`[href="#pr_${tab}"]`)!==null)
            (document?.querySelector(`[href="#pr_${tab}"]`) as HTMLElement)?.click()
    }, [tab]);

    const [copyV1, setCopyV1] = useState(false);
    const [copyV2, setCopyV2] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const chunkSize = 1048576 * 3
    const [uploadFile, setUploadFile] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [counter, setCounter] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [chunks, setChunks] = useState(0)
    const [chunkStart, setChunkStart] = useState(0)
    const [chunkEnd, setChunkEnd] = useState(chunkSize)
    const [fileType, setFileType] = useState("")
    const [fileGuid, setFileGuid] = useState("")
    const [fileName, setFileName] = useState("")

    const [paymentMethods, setPaymentMethods] = useState<Array<any>>();
    const [chosenMethods, setChosenMethods] = useState<{[index: string]:any}>();

    const tagifyRefDragSort = useRef()
    const [tagifyProps, setTagifyProps] = useState({})
    useEffect(() => {
        setTagifyProps({loading: true})

        // do something
        setTagifyProps((lastProps) => ({
            ...lastProps,
            whitelist: ["customer initials","invoice number","year","dash","customerid"],
            showFilteredDropdown: true,
            loading: false
        }))

        // simulate state change where some tags were deleted
        setTimeout(
            () =>
                setTagifyProps((lastProps) => ({
                    ...lastProps,
                    defaultValue: ["abc"],
                    showFilteredDropdown: false
                })),
            5000
        )
    }, [])

    const processUpload = () => {

        console.log(chunkStart,chunkEnd)
        console.log(uploadFile.slice(chunkStart,chunkEnd))
        fileUploadString(UPLOAD_URLS['logo'],fileName,fileGuid,uploadFile.slice(chunkStart,chunkEnd), fileType).then(response => {
            if(typeof response !== "boolean")
                setImgUrl(`${process.env.REACT_APP_API_URL}/uploads/avatar/${response.url}`)
            setChunkStart(chunkStart + chunkSize)
            setChunkEnd(chunkEnd + chunkSize)
            setCounter(counter+1)
        })
    }

    useEffect(() => {
        if(counter<chunks) {
            setUploading(true)
            processUpload()
        }else if(counter>=1) {
            setUploading(false)
            setChunkStart(0)
            setChunkEnd(chunkSize)
            setCounter(0)
            setChunks(0)
            // MySwal.close()

            if (imgUrl) {
                setUserDetails({...userDetails, avatar: imgUrl} as UserModel)
            }
        }
    }, [counter,uploadFile])
    const handleFileChange = (e:any) => {

        if(e===null){
            setUserDetails({...userDetails, avatar: ""} as UserModel)
            return
        }
        const file = e
        /*MySwal.fire({
            title: 'Uploading...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })*/

        setImgUrl('')
        setFileName(file.name)
        setFileGuid(uuidv4())
        setChunkStart(0)
        setChunkEnd(chunkSize)
        setChunks(Math.ceil(file.size / chunkSize))
        setFileType(file.type)
        setUploadFile(file)
        setCounter(0)
    }

    const [usePostmark, setUsePostmark] = useState<boolean>();
    const [resendPostmark, setResendPostmark] = useState<boolean|null>();
    const [waitingPostmark, setWaitingPostmark] = useState(false);
    const MySwal = withReactContent(Swal)
    const sendPostmark = () => {
        checkPostmark().then(postmarkResponse=>{
            if("error" in postmarkResponse)
                return
            setUsePostmark(postmarkResponse.allowed)
            setResendPostmark(postmarkResponse.resend)
            setWaitingPostmark(false)
        })
    }
    const sendPostmarkAgain = () => {
        getPostmark('resend').then(postmarkResponse=>{
            if("error" in postmarkResponse)
                return
            setResendPostmark(postmarkResponse.resend)
            setWaitingPostmark(false)
        })
    }
    if(typeof currentUser?.id !== "undefined" && typeof userDetails === "undefined")
        setUserDetails(currentUser)
    useEffect(() => {
        if(typeof currentUser?.id === "undefined")
            return
        setUserDetails(currentUser)
        const imgInput = document.querySelector(defaultImageInputQueires.instanseQuery)
        if (imgInput)
            new ImageInputComponent(imgInput as HTMLElement, defaultImageInputOptions, defaultImageInputQueires, handleFileChange)
        currencyList().then(currencylist => {
            if(typeof currencylist !== 'boolean') {
                const newlist = currencylist.data.map(curr=>{
                    return {value:`${curr.key}`,label:(<>
                            {typeof curr.icon === "string"?<span dangerouslySetInnerHTML={{__html:curr.icon}} className='me-1 text-gray-900'></span>:(
                                <>
                                    <span dangerouslySetInnerHTML={{__html:curr.icon[0]}} className='me-1 text-gray-900' ></span>
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={`fa-duotone ${curr.icon[1]}`} className='me-1' />
                                </>
                            )}
                            {curr.text}
                        </>)};
                })
                //newlist.unshift({value:'default',label:<>Default currency</>})
                setCurrency(newlist)
                const chosen = newlist.find(curr => curr.value===(userDetails?.currency ?? currentUser?.currency)?.toUpperCase())
                if(typeof chosen !== "undefined")
                    setChosenCurrency(chosen)
            }
        })
        getPaymentMethods().then(paymentMethods => {
            if("error" in paymentMethods)
                return
            setChosenMethods(paymentMethods.userMethods ?? [])
            setPaymentMethods(paymentMethods.methods ?? [])
        })
        sendPostmark()
    }, [currentUser]);
    useEffect(() => {
        setUserDetails({...userDetails, methods: chosenMethods} as UserModel)
    }, [chosenMethods]);

    const onChange = useCallback((e:any) => setUserDetails({...userDetails,invoiceSettings:{enabled:userDetails?.invoiceSettings?.enabled??true,invoiceid:userDetails?.invoiceSettings?.invoiceid,invoiceidtemplate:e.detail.tagify.getCleanValue()}} as UserModel),[])
    const tagifySettings = {
        blacklist: [],
        maxTags: 8,
        //backspace: "edit",
        whitelist: ["customer initials","invoice number","year","dash","customerid"],
        placeholder: "Start typing to see suggestions",
        dropdown: {
            enabled: 0 // always show suggestions dropdown
        }
    }
    useMemo(() => {
        if(  tagifyRefDragSort.current )
            // @ts-ignore
            new DragSort(tagifyRefDragSort.current.DOM.scope, {
                selector: '.tagify__tag',
                callbacks: {
                    dragEnd: onDragEnd
                }
            })
    }, [tagifyRefDragSort.current])

    function onDragEnd(elm:any){
        // @ts-ignore
        tagifyRefDragSort.current.updateValueByDOMTags()
    }
    const getIcon = (method:string) => {
        if(typeof paymentMethodIcons[method.toLowerCase()] !== "undefined")
            return paymentMethodIcons[method]
        return "ideal-logo.svg";
    }
    const getDescription = (method:string) => {
        if(typeof paymentMethodDescriptions[method.toLowerCase()] !== "undefined")
            return paymentMethodDescriptions[method]
        return "";
    }
    const saveHandle = () => {
        if(typeof userDetails === "undefined")
            return;
        setIsBusy(true)
        MySwal.fire({
            title: 'Saving...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })
        updateUser(userDetails).then(res => {
            if(!("error" in res)){
                /*if(currentUser?.companyMail!==userDetails?.companyMail){
                    MySwal.fire({
                        title: <p>Settings are saved!</p>,
                        html: `<p>Please check your mail on ${userDetails?.companyMail} to verify that it's yours within 3 days.</p>`,
                        icon: 'success',
                        didOpen: () => MySwal.showLoading(),
                        timer: 3000
                    })
                }else {*/
                    MySwal.fire({
                        title: <p>Settings are saved!</p>,
                        /*html: <p>Something went wrong. Please try again later.</p>,*/
                        icon: 'success',
                        didOpen: () => MySwal.showLoading(),
                        timer: 3000
                    })
                //}
                setCurrentUser(userDetails)
            }else if("error" in res){
                MySwal.fire({
                    title: <p>Error!</p>,
                    html: <>
                        <p>Something went wrong. Please try again later.</p>
                        <pre>{res.error}</pre>
                    </>,
                    icon: 'error',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
            }
            setIsBusy(false)
        })
    }
    return (
        <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>{title}</PageTitle>
            <PageToolbar>
                <button className='btn btn-light-primary btn-sm me-5' disabled={isBusy} onClick={e=>{saveHandle()}}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk" className='me-3' /> Save
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
            </PageToolbar>
            <form className="form d-flex flex-column flex-lg-row gap-3 gap-lg-5">
                <div className="d-flex flex-column gap-3 gap-lg-5 w-100 w-lg-300px flex-shrink-0">
                    <div className="card card-flush py-4">
                        <div className={isBlocked(uploading,"card-body text-center")}>
                            <div className="image-input  image-input-outline mb-3" data-kt-image-input="true">
                                {typeof userDetails?.avatar !== "undefined"?<div className="image-input-wrapper w-150px h-150px"
                                                          style={{backgroundImage: `url('${userDetails?.avatar}')`}} ></div>:<Skeleton height={150} width={150}/>}
                                <OverlayTrigger
                                    key='main-image'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image'>{intl.formatMessage({id: 'IMAGE.CHANGE', defaultMessage: 'Click to change image'})}</Tooltip>}
                                >
                                    <label
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute top-min-10 end-min-10">
                                        <i className="bi bi-pencil-fill fs-7"></i>
                                        <input type="file" name="mainimage"
                                               className="main_image_upload d-none" accept=".png, .jpg, .jpeg, .gif, .webp" />
                                        <input type="hidden" name="mainimage_url" className="mainimage_url"
                                               value={currentUser?.avatar}/>
                                    </label>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='main-image-cancel'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-cancel'>{intl.formatMessage({id: 'IMAGE.CANCEL', defaultMessage: 'Revert to previous image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="cancel">
                                        <i className="bi bi-arrow-counterclockwise fs-2"></i>
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    key='main-image-remove'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-remove'>{intl.formatMessage({id: 'IMAGE.REMOVE', defaultMessage: 'Remove image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="remove">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="text-muted fs-7 mt-2">
                                Only *.png, *.jpg, *.jpeg, *.gif and *.webp image files are accepted
                            </div>
                            <Blocker state={uploading}/>
                        </div>
                    </div>
                    <div className="card card-flush py-4">
                        <div className="card-header">
                            <div className="card-title d-flex flex-row justify-content-between w-100">
                                <h2>Sales this month</h2>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <RequestNumbers/>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">
                    <ul
                        className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
                        role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4 active"
                               data-bs-toggle="tab" href="#pr_profile"
                               aria-selected="true" role="tab" onClick={()=>{window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - Profile`, "/settings/profile")}}>
                                Profile</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_invoicing"
                               aria-selected="false" role="tab" onClick={()=>window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - Invoicing`, "/settings/invoicing")}>
                                Invoicing</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_methods"
                               aria-selected="false" role="tab" onClick={()=>window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - Methods`, "/settings/methods")}>
                                Payment Methods</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_notifications"
                               aria-selected="false" role="tab" onClick={()=>window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - Notifications`, "/settings/notifications")}>
                                Notifications</a>
                        </li>
                        {/*<li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_layout"
                               aria-selected="false" role="tab">
                                Layout</a>
                        </li>*/}
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_api"
                               aria-selected="false" role="tab" onClick={()=>window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - API`, "/settings/api")}>
                                API</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_pro"
                               aria-selected="false" role="tab" onClick={()=>{
                                if(currentUser?.Pro)
                                    navigate('/pro/manage')
                                else
                                   window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - PRO`, "/settings/pro")
                            }}>
                                PRO</a>
                        </li>

                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                                href="/affiliate"
                               aria-selected="false" role="tab" onClick={()=>window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - Affiliate`, "/affiliate")}>
                                Affiliate</a>
                        </li>

                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_gdpr"
                               aria-selected="false" role="tab" onClick={()=>window.history.pushState(null, `${intl.formatMessage({id: 'MENU.SETTINGS', defaultMessage: 'Settings'})} - GDPR`, "/settings/gdpr")}>
                                GDPR</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pr_customer_tabs">
                        <div className="tab-pane fade active show" id="pr_profile"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Profile</span>
                                            <span className='text-muted fw-bold fs-7'>How do you want your customers to see your company</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Company Name</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof userDetails?.companyName !== "undefined"?<input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={userDetails?.companyName}
                                                    onChange={e =>setUserDetails({...userDetails,companyName:e?.target?.value ?? userDetails.companyName} as UserModel)}
                                                />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                <span className='text-muted fs-8'>Profile yourself as a company instead of your personal name. Leave empty to use your own name</span>
                                            </div>
                                        </div>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Company Address</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof userDetails?.companyAdres !== "undefined"?<input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={userDetails?.companyAdres}
                                                    onChange={e =>setUserDetails({...userDetails,companyAdres:e?.target?.value ?? userDetails.companyAdres} as UserModel)}
                                                />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                <span className='text-muted fs-8'>We won't share this information with third party's or customers</span>
                                            </div>
                                        </div>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Company Email</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof userDetails?.companyMail !== "undefined"?<input
                                                    type="email"
                                                    className="form-control form-control-solid"
                                                    value={userDetails?.companyMail}
                                                    onChange={e =>setUserDetails({...userDetails,companyMail:e?.target?.value ?? userDetails.companyMail} as UserModel)}
                                                />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                <span className='text-muted fs-8'>If you want your notifications and support mail for your customers to another mail address then {userDetails?.email}</span>
                                            </div>
                                        </div>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Company Site</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof userDetails?.companySite !== "undefined"?<input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={userDetails?.companySite}
                                                    onChange={e =>setUserDetails({...userDetails,companySite:e?.target?.value ?? userDetails.companySite} as UserModel)}
                                                />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                <span className='text-muted fs-8'>If you want to add link on your pages to your own website</span>
                                            </div>
                                        </div>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Currency</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof chosenCurrency === "undefined"?<Skeleton width={'100%'} height={46}/>:
                                                    <Select
                                                        //@ts-ignore
                                                        defaultValue={chosenCurrency}
                                                        options={currency}
                                                        isLoading={typeof chosenCurrency === "undefined"}
                                                        isSearchable={true}
                                                        components={{ DropdownIndicator }}
                                                        //@ts-ignore
                                                        onChange={event => setUserDetails({...userDetails,currency:event?.value ?? userDetails.currency} as UserModel)}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                background: '#f5f8fa',
                                                                borderColor: '#f5f8fa',
                                                                color: '#5e6278',
                                                                transition: 'color 0.2s ease, background-color 0.2s ease',
                                                                borderRadius: '0.3rem'
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                minHeight: 'calc(1.5em + 1rem + 2px)',
                                                                padding: '0.5rem 1rem',
                                                                fontSize: '1.25rem',
                                                            })
                                                        }}
                                                    />}
                                            </div>
                                        </div>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Fee title</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof userDetails?.feeTitle !== "undefined"?<input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder={'Additional fee'}
                                                    value={userDetails?.feeTitle??''}
                                                    onChange={e =>setUserDetails({...userDetails,feeTitle:e?.target?.value ?? userDetails.feeTitle} as UserModel)}
                                                />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                <span className='text-muted fs-8'>If empty the default title will be used (Additional Fee)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pr_invoicing"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Invoicing</span>
                                            <span className='text-muted fw-bold fs-7'>Do you want to send invoices?</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Invoices</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                <div className="form-check form-switch form-check-custom form-check-solid">
                                                    {typeof userDetails?.invoiceSettings?.enabled !== "undefined"&&<>
                                                        <input className="form-check-input" type="checkbox" value="1" id={`invoices_boolean`}
                                                               defaultChecked={(userDetails?.invoiceSettings?.enabled ?? false)} onChange={()=>setUserDetails({...userDetails,invoiceSettings:{...userDetails?.invoiceSettings,enabled:!(userDetails?.invoiceSettings?.enabled??true)}} as UserModel)}/>
                                                        <label className="form-check-label ms-3" htmlFor={`invoices_boolean`}>
                                                            Enabled
                                                        </label>
                                                    </>}
                                                </div>
                                                <span className='text-muted fs-8'>When enabled the invoice will be attached to the payment mail.</span>
                                            </div>
                                        </div>
                                        {userDetails?.invoiceSettings?.enabled && (<>
                                            <div className={'form-group row mb-6'}>
                                                <label className="col-xl-3 col-lg-3 col-form-label text-end">Invoice ID</label>
                                                <div className='col-xl-9 col-lg-9'>
                                                    {typeof userDetails?.invoiceSettings?.invoiceid !== "undefined"?<input
                                                        type="number"
                                                        className="form-control form-control-solid"
                                                        value={userDetails?.invoiceSettings?.invoiceid}
                                                        onChange={e =>setUserDetails({...userDetails,invoiceSettings:{enabled:userDetails?.invoiceSettings?.enabled??true,invoiceid:parseInt(e?.target?.value),invoiceidtemplate:userDetails?.invoiceSettings?.invoiceidtemplate}} as UserModel)}
                                                    />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                    <span className='text-muted fs-8'>What will is the latest invoice number?</span>
                                                </div>
                                            </div>
                                            <div className={'form-group row mb-6'}>
                                                <label className="col-xl-3 col-lg-3 col-form-label text-end">Invoice Template</label>
                                                <div className='col-xl-9 col-lg-9'>
                                                    {typeof userDetails?.invoiceSettings?.invoiceidtemplate !== "undefined"?<Tags
                                                        tagifyRef={tagifyRefDragSort}
                                                        settings={tagifySettings}
                                                        className='form-control form-control-solid'
                                                        {...tagifyProps}
                                                        onChange={onChange}
                                                        defaultValue={userDetails?.invoiceSettings?.invoiceidtemplate.map(tag=>tag.value).join(',')}/>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                    <span className='text-muted fs-8'>The suggestions will be converted to variables. Want a custom variable? Just start typing.</span>
                                                </div>
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pr_methods"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Payment Methods</span>
                                            <span className='text-muted fw-bold fs-7'>Choose which payment methods you wish to activate.</span>
                                        </h3>
                                    </div>

                                    <div className="row">

    {typeof paymentMethods !== "undefined" ? paymentMethods.map(method => (
            <div className="col-lg-4">
        <div className="card card-custom card-stretch border m-4">
            <div className="card-header">
                <div className="card-title">
                    <div className="d-flex">
                        <img src={`https://media.payrequest.nl/images/payment-icons/${getIcon(method)}`} className="w-60px me-6 mh-45px" alt={method} style={{objectFit:'contain'}}/>
                    </div>
                    <h3 className="card-label">{method.charAt(0).toUpperCase() + method.slice(1)}</h3>
                    <div className="d-flex justify-content-end">
                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" defaultChecked={chosenMethods?.[method] ?? false} onClick={e => setChosenMethods({...chosenMethods, [method]: !(chosenMethods?.[method] ?? false)})} />
                            <span className="form-check-label fw-bold text-gray-400"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="card-body">
                {getDescription(method)}
            </div>
        </div>
    </div>

    )) : <Skeleton width={'100%'} height={60} count={8} className='d-block' />}
</div>



                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pr_notifications"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Notifications</span>
                                            <span className='text-muted fw-bold fs-7'>Choose when you want to be notified.</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        {typeof userDetails?.emailnotifications !== "undefined"?<label
                                            className="form-check form-check-custom form-check-solid align-items-start">
                                            <input className="form-check-input me-3" type="checkbox"
                                                   defaultChecked={userDetails?.emailnotifications===1} onClick={e=>setUserDetails({...userDetails,emailnotifications:(userDetails?.emailnotifications===1)?0:1} as UserModel)} />
                                            <span className="form-check-label d-flex flex-column align-items-start">
                                                    <span className="fw-bolder fs-5 mb-0">E-mail Notifications</span>
                                                    <span className="text-muted fs-7">Receive notifications through mail.</span>
                                                </span>
                                        </label>:<Skeleton height={40} width={'100%'}/>}
                                        <div className="separator separator-dashed my-6"></div>
                                        {typeof userDetails?.customernotifications !== "undefined"?<label
                                            className="form-check form-check-custom form-check-solid align-items-start">
                                            <input className="form-check-input me-3" type="checkbox"
                                                   defaultChecked={userDetails?.customernotifications===1} onClick={e=>setUserDetails({...userDetails,customernotifications:(userDetails?.customernotifications===1)?0:1} as UserModel)} />
                                            <span className="form-check-label d-flex flex-column align-items-start">
                                                    <span className="fw-bolder fs-5 mb-0">Customer E-mail Notifications</span>
                                                    <span className="text-muted fs-7">Let the customer receive a confirmation mail after a succesfull payment.</span>
                                                </span>
                                        </label>:<Skeleton height={40} width={'100%'}/>}
                                        <div className="separator separator-dashed my-6"></div>
                                        {typeof userDetails?.pushnotifications !== "undefined"?<label
                                            className="form-check form-check-custom form-check-solid align-items-start">
                                            <input className="form-check-input me-3" type="checkbox"
                                                   defaultChecked={userDetails?.pushnotifications===1} onClick={e=>setUserDetails({...userDetails,pushnotifications:(userDetails?.pushnotifications===1)?0:1} as UserModel)} />
                                            <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">Push Notifications</span>
                                        <span className="text-muted fs-7">Receive notifications through push messages on your phone or pc/laptop.</span>
                                    </span>
                                        </label>:<Skeleton height={40} width={'100%'}/>}
                                        <div className="separator separator-dashed my-6"></div>
                                        {typeof userDetails?.completedmail !== "undefined"?<label
                                            className="form-check form-check-custom form-check-solid align-items-start">
                                            <input className="form-check-input me-3" type="checkbox"
                                                   defaultChecked={userDetails?.completedmail===1} onClick={e=>setUserDetails({...userDetails,completedmail:(userDetails?.completedmail===1)?0:1} as UserModel)} />
                                            <span className="form-check-label d-flex flex-column align-items-start">
                                                    <span className="fw-bolder fs-5 mb-0">A request has been completed. (E-mail notification)</span>
                                                    <span className="text-muted fs-7">Receive a notification once the request has been completed.</span>
                                                </span>
                                        </label>:<Skeleton height={40} width={'100%'}/>}
                                        <div className="separator separator-dashed my-6"></div>
                                        {typeof userDetails?.updatemail !== "undefined"?<label
                                            className="form-check form-check-custom form-check-solid align-items-start">
                                            <input className="form-check-input me-3" type="checkbox"
                                                   defaultChecked={userDetails?.updatemail ===1} onClick={e=>setUserDetails({...userDetails,updatemail:(userDetails?.updatemail===1)?0:1} as UserModel)} />
                                            <span className="form-check-label d-flex flex-column align-items-start">
                                                    <span
                                                        className="fw-bolder fs-5 mb-0">Payrequest Updates. (E-mail notification)</span>
                                                    <span className="text-muted fs-7">Receive a notification if Payrequest has been updated.</span>
                                                </span>
                                        </label>:<Skeleton height={40} width={'100%'}/>}
                                        <div className="separator separator-dashed my-6"></div>
                                        {typeof userDetails?.completedpush !== "undefined"?<label
                                            className="form-check form-check-custom form-check-solid align-items-start">
                                            <input className="form-check-input me-3" type="checkbox"
                                                   defaultChecked={userDetails?.completedpush ===1} onClick={e=>setUserDetails({...userDetails,completedpush:(userDetails?.completedpush===1)?0:1} as UserModel)} />
                                            <span className="form-check-label d-flex flex-column align-items-start">
                                                    <span className="fw-bolder fs-5 mb-0">A request has been completed. (Push Notification)</span>
                                                    <span className="text-muted fs-7">Receive a notification once A request has been completed.</span>
                                                </span>
                                        </label>:<Skeleton height={40} width={'100%'}/>}
                                    </div>
                                </div>
                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Mail with your own domain</span>
                                            <span className='text-muted fw-bold fs-7'>Verify you mail adress with Postmark, and we can send your payment links & reminders through you own mail address</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        {typeof usePostmark === "undefined"?(<Skeleton width={'100%'} height={200} />):usePostmark?(
                                            resendPostmark===null?<button
                                                disabled={waitingPostmark}
                                                className='btn btn-light-warning btn-sm'
                                                onClick={e=>{setWaitingPostmark(true);sendPostmark()}}>
                                                {waitingPostmark?'Sending...':`Send verification to ${userDetails?.companyMail ?? userDetails?.email}`}
                                                {/*
                                                @ts-ignore */}
                                                <FontAwesomeIcon icon={waitingPostmark?"fa-duotone fa-spinner":"fa-duotone fa-envelope-dot"} className={clsx("ms-2",waitingPostmark?"fa-spin":"")} />
                                            </button>:(!resendPostmark?<>
                                                <div className="text-body mb-10">Your mail isn't verified yet!</div>
                                                <button
                                                    disabled={waitingPostmark}
                                                    className='btn btn-light-warning btn-sm'
                                                    onClick={e=>{setWaitingPostmark(true);sendPostmarkAgain()}}>
                                                    {waitingPostmark?'Sending...':`Resend verification to ${userDetails?.companyMail ?? userDetails?.email}`}
                                                    {/*
                                                @ts-ignore */}
                                                    <FontAwesomeIcon icon={waitingPostmark?"fa-duotone fa-spinner":"fa-duotone fa-envelope-dot"} className={clsx("ms-2",waitingPostmark?"fa-spin":"")} />
                                                </button>
                                            </>:<div className="alert alert-success d-flex align-items-center p-5 mb-10">
                                                <KTSVG path='/media/icons/duotune/general/gen002.svg' className='svg-icon svg-icon-3hx svg-icon-success me-3' />
                                                <div className="d-flex flex-column">
                                                    <h5 className="mb-1">You're verified!</h5>
                                                    <span>
                                                        Your mail is verified, we will send your requests with your own mail!<br/>
                                                        <strong>Remember</strong>, changing your company mail will result in verifying your mail again!
                                                    </span>
                                                </div>
                                            </div>)
                                            ):(
                                            <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                                                <KTSVG path='/media/icons/duotune/general/gen051.svg' className='svg-icon svg-icon-3hx svg-icon-danger me-3' />
                                                <div className="d-flex flex-column">
                                                    <h5 className="mb-1">Not allowed!</h5>
                                                    <span>
                                                        Your mail is on a public domain meaning we can't verify it for you!<br/>
                                                        Try changing your company mail to a non-public domain so you can send with your own domain!
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pr_api"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>API</span>
                                            <span className='text-muted fw-bold fs-7'>API key for seamless integration</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        {typeof currentUser?.token !== "undefined"?(
                                            <table className="table table-rounded table-striped border gy-7 gs-7">
                                                <thead>
                                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                                    <th>Version</th>
                                                    <th>Key</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td><strong>V1</strong></td>
                                                    <td>

                                                        {showAPIKey ? currentUser?.token : <button className="btn btn-light-primary btn-sm me-5"onClick={() => setShowAPIKey(true)}>Click to reveal API Key</button>}
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-light-success fs-7 fw-bold">Active</span>
                                                    </td>
                                                    <td>
                                                        <CopyToClipboard
                                                            text={currentUser?.token ?? ""}
                                                            onCopy={() => {
                                                                setCopyV1(true)
                                                                window.setTimeout(()=>setCopyV1(false),1500)
                                                            }}>
                                                            <span className="btn btn-sm btn-icon btn-light-primary ms-3">
                                                                {/*
                                                            @ts-ignore */}
                                                                <FontAwesomeIcon icon={`fa-duotone ${copyV1?'fa-check-double':'fa-copy'}`} />
                                                            </span>
                                                        </CopyToClipboard>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>V2</strong></td>
                                                    <td>
                                                    {showAPIKey ? currentUser?.tokenV2 : <button className="btn btn-light-primary btn-sm me-5"onClick={() => setShowAPIKey(true)}>Click to reveal API Key</button>}

                                                    </td>
                                                    <td>
                                                        <span className={`badge badge-light-${currentUser?.Pro===1?'success':'warning'} fs-7 fw-bold`}>{currentUser?.Pro===1?'Active':'Only for PRO'}</span>
                                                    </td>
                                                    <td>
                                                        {currentUser?.Pro===1 && <CopyToClipboard
                                                            text={currentUser?.tokenV2 ?? ""}
                                                            onCopy={() => {
                                                                setCopyV2(true)
                                                                window.setTimeout(()=>setCopyV2(false),1500)
                                                            }}>
                                                            <span className="btn btn-sm btn-icon btn-light-primary ms-3">
                                                                {/*
                                                            @ts-ignore */}
                                                                <FontAwesomeIcon icon={`fa-duotone ${copyV2?'fa-check-double':'fa-copy'}`} />
                                                            </span>
                                                        </CopyToClipboard>}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        ):<>
                                            <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                                            <Skeleton width={'100%'} height={55} count={2} className='mb-3'/>
                                        </>}
                                    </div>
                                </div>
                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Webhook</span>
                                            <span className='text-muted fw-bold fs-7'>Want to integrate our payments with your system or your custom solution?<br/>Setup the webhooks to make this possible!</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Webhook url</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                {typeof userDetails?.webhook !== "undefined"?<input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={userDetails?.webhook}
                                                    onChange={e =>setUserDetails({...userDetails,webhook:e?.target?.value ?? userDetails.webhook} as UserModel)}
                                                />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                                <span className='text-muted fs-8'>We will send payment updates, subscription changes and cancellation requests.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pr_pro"
                             role="tabpanel">

                            <BecomePro/>
                        </div>
                        <div className="tab-pane fade" id="pr_gdpr"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">
                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>GDPR</span>
                                            <span className='text-muted fw-bold fs-7'>Export your details or delete your account</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-xl-3 col-lg-3 col-form-label text-end">Export you data to JSON</label>
                                            <div className='col-xl-9 col-lg-9'>
                                                <button className="btn btn-primary btn-sm" onClick={(e)=>{
                                                    e.preventDefault()
                                                    const exportData = {...userDetails}
                                                    delete exportData.roles
                                                    delete exportData.googleid
                                                    delete exportData?.products
                                                    delete exportData?.customers
                                                    delete exportData?.paymentLinks
                                                    delete exportData?.customFieldGroups
                                                    delete exportData?.productMetas
                                                    delete exportData?.taxClasses
                                                    delete exportData?.referral
                                                    delete exportData?.affiliates
                                                    delete exportData?.affiliateFees
                                                    delete exportData?.affiliatePayouts
                                                    delete exportData?.password
                                                    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                                                        JSON.stringify(exportData)
                                                    )}`;
                                                    const link = document.createElement("a")
                                                    link.href = jsonString
                                                    const dt = new Date()
                                                    link.download = `userExport-${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()}.json`
                                                    link.click()
                                                }}>
                                                    {/*
                                                            @ts-ignore */}
                                                    <FontAwesomeIcon icon={`fa-duotone fa-download`} className='me-3' />
                                                    Download JSON
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Account Deletion</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                                            <KTSVG path='/media/icons/duotune/medicine/med001.svg' className='svg-icon svg-icon-3hx svg-icon-danger me-3' />
                                            <div className="d-flex flex-column">
                                                <span>
                                                        You will be permanently delete your PayRequest account, this account cannot be undone!
                                                    </span>
                                            </div>
                                        </div>
                                        <div className={'form-group row mb-6'}>
                                            <label className="col-12 col-form-label">Please check the box to deactivate your account</label>
                                            <div className='col-12'>
                                                <div
                                                    className="form-check form-check-custom form-check-solid form-check-sm">
                                                    <input className="form-check-input" type="checkbox" value={'delete'}
                                                           onClick={()=>{
                                                               MySwal.fire({
                                                                   title: intl.formatMessage({id: 'SWAL.DELETE.USER', defaultMessage: 'Are you sure you want to delete your account?'}),
                                                                   text: 'Remember, this will be permanent!',
                                                                   icon: 'warning',
                                                                   showCancelButton: true,
                                                                   confirmButtonText: intl.formatMessage({id: 'SWAL.CANCEL.PARTIALLY', defaultMessage: 'Yes'}),
                                                                   confirmButtonColor: '#f64e60',
                                                                   preConfirm: () => {
                                                                       return deleteAccount().then(res => {
                                                                           if(res.status==='error' && "error" in res){
                                                                               Swal.showValidationMessage(
                                                                                   `Deletion failed, please mail us with the request to remove your account. Including the following error: ${res.error}`
                                                                               )
                                                                           }else{
                                                                               MySwal.fire({
                                                                                   title: intl.formatMessage({
                                                                                       id: 'SWAL.DELETE.SUCCESSACCOUNT',
                                                                                       defaultMessage: 'Your account is successfully deleted!'
                                                                                   }),
                                                                                   text: 'Thank you for our time together and we hope to see you again.',
                                                                                   icon: 'success',
                                                                                   didOpen: () => MySwal.showLoading(),
                                                                                   timer: 3000,
                                                                                   didClose() {
                                                                                       navigate('/')
                                                                                   }
                                                                               })
                                                                           }
                                                                       }).catch((err:any) => {
                                                                           Swal.showValidationMessage(
                                                                               `Deletion failed, please mail us with the request to remove your account.`
                                                                           )})
                                                                   }
                                                               });
                                                           }}
                                                           id="deleteAccount"/>
                                                    <label className="form-check-label" htmlFor="deleteAccount">
                                                        I confirm my account deactivation
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <PayProModal2/>
        </>
    )
}

export {Overview}
