import {Route, Routes, Outlet} from 'react-router-dom'
import {Overview} from "./components/Overview";
import {GroupView} from "./components/groupView";

const ExtraFieldsPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
            path='overview'
            element={<Overview />}
        />
        <Route
            path=':id'
            element={<GroupView />}
        />
        <Route
            index
            element={<Overview />}
        />
      </Route>
    </Routes>
  )
}

export default ExtraFieldsPage
