import {Route, Routes, Outlet} from 'react-router-dom'
import {PageTitle, useLayout} from "../../../_metronic/layout/core";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Blocker, isBlocked} from "../../modules/widgets/components/Blocker";
import {getIntegrationStripe} from "../stripe/core/_requests";
import Swal from 'sweetalert2'
import clsx from "clsx";
import withReactContent from "sweetalert2-react-content";
import { Crisp } from "crisp-sdk-web";
import {Subscription, SubscriptionStatus, useAuth} from "../../modules/auth";
import {getIntegrations, removeIntegration} from "../core/_requests";
import {getPaypalLink} from "../paypal/core/_requests";
import {getMollieLink} from "../mollie/core/_requests";
import {setSubscriptionCancel} from "../../modules/subscriptions/core/_requests";
import {mspCheck} from "../msp/core/_requests";

type pspStatus = {
    [key: number]: boolean
}

const Overview = () => {
    const intl = useIntl()
    const {config} = useLayout()
    const show = () => Crisp.chat?.show()
    const {currentUser} = useAuth()
    config.toolbar.layout = 'none'

    const [stripeLoading, setStripeLoading] = useState(true)
    const [paypalLoading, setPaypalLoading] = useState(true)
    const [mollieLoading, setMollieLoading] = useState(true)
    const [mspLoading, setMspLoading] = useState(true)
    const [paypalLink, setPaypalLink] = useState<string>('');
    const [mollieLink, setMollieLink] = useState<string>('');
    const [pspStatus, setPspStatus] = useState<pspStatus>();
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        getIntegrations().then(res => {
            if(typeof res === "boolean")
                return;
            const psps: pspStatus = {};
            // foreach each psp and add to psp with active
            res.forEach(integration => psps[integration.psp] = integration.active==true);
            setPspStatus(psps)
            setStripeLoading(false)
            setMspLoading(false)
            getPaypalLink().then(res => {
                if(typeof res === "boolean")
                    return;
                setPaypalLink(res)
                setPaypalLoading(false)
            })
            getMollieLink().then(res => {
                if(typeof res === "boolean")
                    return;
                setMollieLink(res)
                setMollieLoading(false)
            })
        })
    },[]);
    const isActive = (psp:number) => typeof pspStatus !== "undefined" && typeof pspStatus[psp] !== "undefined" && pspStatus[psp];
    const errorSwal = () => MySwal.fire({
        title: <p>Woops</p>,
        html: <p>Something went wrong. Please try again later.</p>,
        didOpen: () => MySwal.showLoading(),
        timer: 3000
    })
    const mspSwal = () => MySwal.fire({
        title: intl.formatMessage({id: 'MSP.INSERTAPI', defaultMessage: 'Fill in your API key'}),
        html: 'Don\'t know where to find yours?<br/>Read more on <a href="https://docs.multisafepay.com/tools/multisafepay-control/get-your-api-key/" target="_blank">this link</a>',
        icon: "info",
        input: 'text',
        inputLabel: 'API Key',
        inputPlaceholder: 'eg. cbh5c7ba74d61418d4cc3dd1f58863af076q130o',
        inputAttributes: {
            'aria-label': 'eg. cbh5c7ba74d61418d4cc3dd1f58863af076q130o'
        },
        inputValue: '',
        buttonsStyling: false,
        confirmButtonText: "Connect",
        cancelButtonText: "Cancel",
        showDenyButton: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        customClass: {
            confirmButton: "btn fw-bold btn-primary",
            cancelButton: "btn fw-bold btn-secondary",
        },
        preConfirm(inputValue: any) {
            return mspCheck(inputValue).then(response=>{
                if("error" in response) {
                    Swal.showValidationMessage(
                        `API key invalid`
                    )
                    return
                }
                setPspStatus({...pspStatus, 3: true})
                MySwal.fire({
                    title: <p>MultiSafePay is connected!</p>,
                    /*html: <p>Something went wrong. Please try again later.</p>,*/
                    icon: 'success',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000,
                    didClose() {
                        setMspLoading(false)
                    }
                })
            })
        },
        didClose() {
            setMspLoading(false)
        }
    })
    const disconnectIntegration = (loader: Function, pspId: number) => {
        loader(true);
        // get stripe integration url and redirect to that.
        removeIntegration(pspId).then((res) => {
            if(res.status) {
                loader(false);
                MySwal.fire({
                    title: <p>Integration is disconnected</p>,
                    /*html: <p>Something went wrong. Please try again later.</p>,*/
                    icon: 'success',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
                const psps = {...pspStatus}
                if (typeof psps !== "undefined")
                    psps[pspId] = false
                setPspStatus(psps)
            }else{
                loader(false)
                errorSwal()
            }
        }).catch((err) => {
            loader(false)
            errorSwal()
        })
    }

    return (<>
        <PageTitle breadcrumbs={[
            {title: intl.formatMessage({id: 'MENU.DASHBOARD'}), path: '/dashboard', isActive: false},
            {title: 'sep', path: '#', isActive: false, isSeparator: true}
        ]}>{intl.formatMessage({id: 'MENU.INTEGRATIONOVERVIEW', defaultMessage: 'Integrations'})}</PageTitle>
        <div className="row g-5">
            <div className="col-xl-12">
                <div className="card card-flush h-md-100">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <h2>
                                Connect your PSP
                            </h2>
                            <span className='text-muted fw-bold fs-7'>Don't have one yet? Follow their onboarding while choosing one from below.</span>
                        </div>
                    </div>
                    <div className="card-body pt-1">
                        <div className="text-gray-600 mb-5">
                            If you experience any issues or need help please contact us through <a className='link-primary fw-bolder' href='#' onClick={event => { event.preventDefault(); show(); }}>our chat</a> or info@payrequest.io!
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4">
                <div className={isBlocked(stripeLoading, `card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column`)}>
                    <span className="d-flex flex-row w-100 justify-content-between my-3">
                        <span className="fs-1 fw-bolder text-gray-900">Stripe</span>
                        <span className="float-end align-self-center">
                            {isActive(2)?(
                                <button
                                    disabled={stripeLoading}
                                    type='button'
                                    onClick={()=>disconnectIntegration(setStripeLoading, 2)}
                                    className='btn btn-light-warning btn-sm'
                                >
                                    Disconnect
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={stripeLoading?"fa-duotone fa-spinner":"fa-duotone fa-link-slash"} className={clsx("ms-2",stripeLoading?"fa-spin":"")} />
                                </button>
                            ):(
                                <button
                                    disabled={stripeLoading}
                                    type='button'
                                    onClick={()=> {
                                        setStripeLoading(true);
                                        // get stripe integration url and redirect to that.
                                        getIntegrationStripe().then((res) => {
                                            if(typeof res !=="boolean") {
                                                setStripeLoading(false)
                                                window.location.href = res?.url
                                            }else{
                                                setStripeLoading(false)
                                                errorSwal()
                                            }
                                        }).catch((err) => {
                                            setStripeLoading(false)
                                            errorSwal()
                                        })
                                    }}
                                    className='btn btn-light-success btn-sm'
                                >
                                    Connect
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={stripeLoading?"fa-duotone fa-spinner":"fa-duotone fa-link"} className={clsx("ms-2",stripeLoading?"fa-spin":"")} />
                                </button>
                            )}
                        </span>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="d-flex fw-bolder text-primary mt-0 mb-4">Only 29 cents for IDEAL payments<br/>Creditcard starting at 25 cents + 1.4%</span>
                        <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                            Millions of businesses of all sizes—from startups to large enterprises—use Stripe’s software and APIs to accept payments, send payouts, and manage their businesses online.
                        </p>
                    </span>
                    <Blocker state={stripeLoading}/>
                </div>
            </div>
            <div className="col-xl-4">
                <div className={isBlocked(paypalLoading, `card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column`)}>
                    <span className="d-flex flex-row w-100 justify-content-between my-3">
                        <span className="fs-1 fw-bolder text-gray-900">PayPal</span>
                        <span className="float-end align-self-center">
                            {isActive(4)?<button
                                disabled={paypalLoading}
                                type='button'
                                onClick={()=>disconnectIntegration(setPaypalLoading, 4)}
                                className='btn btn-light-warning btn-sm'
                            >
                                Disconnect
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon={paypalLoading?"fa-duotone fa-spinner":"fa-duotone fa-link-slash"} className={clsx("ms-2",paypalLoading?"fa-spin":"")} />
                            </button>:<a className="btn btn-light-success btn-sm" href={`${paypalLink}&displayMode=minibrowser`} data-paypal-button='true' target='PPFrame'>
                                Connect
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                            </a>}
                        </span>
                        {(!paypalLoading && !isActive(4)) && ((function (d, s, id) {
                            let js, ref = d.getElementsByTagName(s)[0];
                            if (!d.getElementById(id)) {
                                js = d.createElement(s) as HTMLScriptElement;
                                js.id = id;
                                js.async = true;
                                js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
                                // @ts-ignore
                                ref.parentNode.insertBefore(js, ref);
                            }
                        }(document, "script", "paypal-js")))}
                    </span>
                    <span className="d-flex flex-column">
                        <span className="d-flex fw-bolder text-primary mt-0 mb-4">Only 29 cents for IDEAL payments<br/>PayPal checkout without redirects</span>
                        <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                            Get paid and make payments risk free without any problems.
                        </p>
                    </span>
                    <Blocker state={paypalLoading}/>
                </div>
            </div>
            <div className="col-xl-4">
                <div className={isBlocked(mspLoading, `card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column`)}>
                    <span className="d-flex flex-row w-100 justify-content-between my-3">
                        <span className="fs-1 fw-bolder text-gray-900">MultiSafePay</span>
                        <span className="float-end align-self-center">
                            {isActive(3)?(
                                <button
                                    disabled={mspLoading}
                                    type='button'
                                    onClick={()=>disconnectIntegration(setMspLoading, 3)}
                                    className='btn btn-light-warning btn-sm'
                                >
                                    Disconnect
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={mspLoading?"fa-duotone fa-spinner":"fa-duotone fa-link-slash"} className={clsx("ms-2",mspLoading?"fa-spin":"")} />
                                </button>
                            ):<button className="btn btn-light-success btn-sm" onClick={e=>{
                                e.preventDefault();
                                setMspLoading(true);
                                mspSwal()
                            }}>
                                Connect
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                            </button>}
                        </span>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="d-flex fw-bolder text-primary mt-0 mb-4">Accept, manage and stimulate online sales with MultiSafepay.</span>
                        <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                            Increase conversion rates with MultiSafepay unique solutions.
                        </p>
                    </span>
                    <Blocker state={mspLoading}/>
                </div>
            </div>
            <div className="col-xl-4">
                <div className={isBlocked(mollieLoading, `card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column`)}>
                    <span className="d-flex flex-row w-100 justify-content-between my-3">
                        <span className="fs-1 fw-bolder text-gray-900">Mollie</span>
                        <span className="float-end align-self-center">
                            {isActive(1)?(
                                <button
                                    disabled={mollieLoading}
                                    type='button'
                                    onClick={()=>disconnectIntegration(setMollieLoading, 1)}
                                    className='btn btn-light-warning btn-sm'
                                >
                                    Disconnect
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={mollieLoading?"fa-duotone fa-spinner":"fa-duotone fa-link-slash"} className={clsx("ms-2",mollieLoading?"fa-spin":"")} />
                                </button>
                            ):<a className="btn btn-light-success btn-sm" href={mollieLink}>
                                Connect
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                            </a>}
                        </span>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="d-flex fw-bolder text-primary mt-0 mb-4">Only 29 cents for IDEAL payments<br/>Many methods available</span>
                        <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                            Start growing your business with effortless payments.
                        </p>
                    </span>
                    <Blocker state={mollieLoading}/>
                </div>
            </div>
            <div className="col-xl-4">

                    <a href="#" onClick={event => { event.preventDefault(); show(); }} className="card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column opacity-50">
                        <span className="d-flex flex-row w-100 justify-content-between my-3">
                            <span className="fs-1 fw-bolder text-gray-900">Coming soon?</span>
                            <span className="float-end align-self-center">
                                <button disabled={true} className="btn btn-light-success btn-sm">
                                    Open Chat
                                    {/*
                                    @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                                </button>
                            </span>
                        </span>
                        <span className="d-flex flex-column">
                            <span className="d-flex fw-bolder text-primary mt-0 mb-4">Missing a Payment Provider?<br/>Let us know!</span>
                            <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                                Mail or chat with us and we will try to integrate it a.s.a.p!
                            </p>
                        </span>
                    </a>
                </div>
        </div>
    </>)
}

export default Overview
