/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useState,useEffect,useCallback} from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import clsx from "clsx";
import {Editor} from "@tiptap/react/src/Editor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fileUpload, UPLOAD_URLS} from "../../../../app/modules/auth/core/_requests";
import {v4 as uuidv4} from "uuid";
import withReactContent from "sweetalert2-react-content";
import Swal, { SweetAlertResult } from "sweetalert2";


type Props = {
    editor: Editor | null
}

const TextEditorMenuBar: FC<Props> = ({editor}) => {
    const chunkSize = 1048576 * 3
    const [uploadFile, setUploadFile] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [counter, setCounter] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [chunks, setChunks] = useState(0)
    const [chunkStart, setChunkStart] = useState(0)
    const [chunkEnd, setChunkEnd] = useState(chunkSize)
    const [fileType, setFileType] = useState("")
    const [fileGuid, setFileGuid] = useState("")
    const [fileName, setFileName] = useState("")
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        if(counter<chunks) {
            setUploading(true)
            processUpload()
        }else if(counter>=1) {
            setUploading(false)
            setCounter(0)
            setChunks(0)
            MySwal.close()

            if (imgUrl && editor!==null)
                editor.chain().focus().setImage({ src: imgUrl }).run()
        }
    }, [counter,uploadFile])

    const processUpload = () => {

        fileUpload(UPLOAD_URLS['content'],fileName,fileGuid,uploadFile.slice(chunkStart,chunkEnd), fileType).then(response => {
            if(typeof response !== "boolean")
                setImgUrl(response.url)
            setChunkStart(chunkStart + chunkSize)
            setChunkEnd(chunkEnd + chunkSize)
            setCounter(counter+1)
        })
    }
    if (!editor)
        return null
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)
        if (url === null)
            return
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()
            return
        }
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run()
    }, [editor])
    const handleFileChange = (e:any) => {

        if (e.target.files.length === 0)
            return false
        const file = e.target.files[0]
        // create swal without buttons that upload it processing with preconfirm function
        MySwal.fire({
            title: 'Uploading...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })

        setImgUrl('')
        setChunkStart(0)
        setChunkEnd(1048576*3)
        setCounter(0)
        setFileName(file.name)
        setFileGuid(uuidv4())
        setChunks(Math.ceil(file.size / chunkSize))
        setFileType(file.type)
        setUploadFile(file)
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const addImage = useCallback(() => {
        const fileHandle = document.querySelector('input[name="file"]')
        if(fileHandle instanceof HTMLElement)
            fileHandle.click()

    }, [editor])

    return (
        <div className='fv-row titapbuttons border-bottom px-4 bg-light'>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-bold" className={editor.isActive('bold') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-italic" className={editor.isActive('italic') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-strikethrough" className={editor.isActive('strike') ? 'fa-swap-opacity' : ''} />
            </button>
            <span className='border-end'></span>
            <button type="button" 
                onClick={() => editor.chain().focus().setTextAlign('left').run()}
                className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-align-left" className={editor.isActive('left') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().setTextAlign('center').run()}
                className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-align-center" className={editor.isActive('center') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().setTextAlign('right').run()}
                className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-align-right" className={editor.isActive('right') ? 'fa-swap-opacity' : ''} />
            </button>
            <span className='border-end'></span>
            <button type="button" 
            >

                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-brush" />
                <input
                    type="color"
                    onInput={event => editor.chain().focus().setColor((event.target as HTMLInputElement).value).run()}
                    value={editor.getAttributes('textStyle').color}
                />
            </button>
            <button type="button" 
                onClick={setLink}
                className={editor.isActive('link') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-link" className={editor.isActive('link') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().unsetLink().run()}
                disabled={!editor.isActive('link')}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-unlink" className={editor.isActive('paragraph') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button"  onClick={addImage}>
                <input type='file' name='file' className='d-none' accept='image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp' onChange={handleFileChange}/>
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-image" />
            </button>
            <span className='border-end'></span>
            <button type="button" 
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive('paragraph') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-paragraph" className={editor.isActive('paragraph') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-h1" className={editor.isActive('heading', { level: 1 }) ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-h2" className={editor.isActive('heading', { level: 2 }) ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-h3" className={editor.isActive('heading', { level: 3 }) ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-h4" className={editor.isActive('heading', { level: 4 }) ? 'fa-swap-opacity' : ''} />
            </button>
            <span className='border-end'></span>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-list" className={editor.isActive('bulletList') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-list-ol" className={editor.isActive('orderedList') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-code" className={editor.isActive('codeBlock') ? 'fa-swap-opacity' : ''} />
            </button>
            <button type="button" 
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-block-quote" />
            </button>
            <button type="button"  onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-horizontal-rule" />
            </button>
            <span className='border-end'></span>
            <button type="button"  onClick={() => editor.chain().focus().undo().run()}>
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-arrow-rotate-left" />
            </button>
            <button type="button"  onClick={() => editor.chain().focus().redo().run()}>
                {/*
                @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-arrow-rotate-right" />
            </button>
        </div>
    )
}

export default TextEditorMenuBar