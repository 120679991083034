import React, {FC, useEffect, useRef, useState} from "react";
import {PaymentLinkTypes, Transactions, useAuth} from "../../auth";
import {ApexOptions} from "apexcharts";
import {getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {getTransactionsRange} from "../../dashboard/core/_requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {CurrencyFormat} from "../../currency/components/format";
import {KTSVG} from "../../../../_metronic/helpers";
import {useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";

const RequestNumbers: FC = () => {
    const {currentUser} = useAuth()
    const intl = useIntl()
    const [requests, setRequests] = useState(0);
    const [personal, setPersonal] = useState(0);
    const [products, setProducts] = useState(0);
    const [subscriptions, setSubscriptions] = useState(0);
    const [lrequests, setLrequests] = useState(0);
    const [lpersonal, setLpersonal] = useState(0);
    const [lproducts, setLproducts] = useState(0);
    const [lsubscriptions, setLsubscriptions] = useState(0);

    useEffect(() => {
        if(typeof currentUser?.id === "undefined")
            return

        const parseTransactions = (transactions: Array<Transactions>) => {
            const requests:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.NORMAL||transaction.type===PaymentLinkTypes.DONATION||transaction.type===PaymentLinkTypes.PREAUTH);
            const personal:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.PERSONAL);
            const products:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.PRODUCT&&transaction.producttype!=="Subscription");
            const subscriptions:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.PRODUCT&&transaction.producttype==="Subscription");
            return [requests,personal,products,subscriptions];
        }
        const now = new Date();
        const startOfLastMonth = new Date();
        startOfLastMonth.setMonth(startOfLastMonth.getMonth()-1);
        startOfLastMonth.setDate(1);
        startOfLastMonth.setHours(0, 0, 0);
        const lastOfLastMonth = new Date(startOfLastMonth);
        lastOfLastMonth.setMonth(lastOfLastMonth.getMonth() +1);
        lastOfLastMonth.setDate(0);
        lastOfLastMonth.setHours(23,59,59);
        const startOfMonth = new Date();
        startOfMonth.setDate(1);
        startOfMonth.setHours(0, 0, 0);
        getTransactionsRange(startOfLastMonth, lastOfLastMonth).then(transactions => {
            const [_requests,_personal,_products,_subscriptions] = parseTransactions(transactions);
            setLrequests(_requests.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            setLpersonal(_personal.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            setLproducts(_products.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            setLsubscriptions(_subscriptions.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            getTransactionsRange(startOfMonth, now).then(transactions => {
                const [_requests,_personal,_products,_subscriptions] = parseTransactions(transactions);
                setRequests(_requests.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                setPersonal(_personal.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                setProducts(_products.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                setSubscriptions(_subscriptions.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])

    return <>

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                                  <span className='symbol-label bg-lighten'>
                                      {/*
                                      @ts-ignore */}
                                      <FontAwesomeIcon icon="fa-duotone fa-globe-stand" className='fs-2' />
                                  </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <Link to='/requests' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                        {intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
                    </Link>
                    <div className='text-gray-400 fw-bold fs-7'> {intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>

                    {typeof requests === "undefined"?<Skeleton width={70} height={27}/>:(<>
                        <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                            <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={requests} formatAmount={true}/>
                        </div>
                        {lrequests<=requests?(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr066.svg'
                                className='svg-icon-5 svg-icon-success ms-1'
                            />
                        ):(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr065.svg'
                                className='svg-icon-5 svg-icon-danger ms-1'
                            />
                        )}
                    </>)}
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-1'/>
                          </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                        {intl.formatMessage({id: 'GLOBAL.PERSONAL', defaultMessage: 'Personal'})}
                    </a>
                    <div className='text-gray-400 fw-bold fs-7'>{intl.formatMessage({id: 'MENU.PERSONALPAGE', defaultMessage: 'Personal page'})}</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    {typeof personal === "undefined"?<Skeleton width={70} height={27}/>:(<>
                        <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                            <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={personal} formatAmount={true}/>
                        </div>
                        {lpersonal<=personal?(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr066.svg'
                                className='svg-icon-5 svg-icon-success ms-1'
                            />
                        ):(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr065.svg'
                                className='svg-icon-5 svg-icon-danger ms-1'
                            />
                        )}
                    </>)}
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                            <KTSVG path='/media/icons/duotune/electronics/elc005.svg' className='svg-icon-1'/>
                          </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                        {intl.formatMessage({id: 'MENU.PRODUCTS', defaultMessage: 'Products'})}
                    </a>
                    <div className='text-gray-400 fw-bold fs-7'>
                        {intl.formatMessage({id: 'PRODUCTS.SIMPLE', defaultMessage: 'Simple products'})}
                    </div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    {typeof products === "undefined"?<Skeleton width={70} height={27}/>:(<>
                        <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                            <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={products} formatAmount={true}/>
                        </div>
                        {lproducts<=products?(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr066.svg'
                                className='svg-icon-5 svg-icon-success ms-1'
                            />
                        ):(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr065.svg'
                                className='svg-icon-5 svg-icon-danger ms-1'
                            />
                        )}
                    </>)}
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-1'/>
                          </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                        {intl.formatMessage({id: 'PRODUCTS.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}
                    </a>
                    <div className='text-gray-400 fw-bold fs-7'>
                        {intl.formatMessage({id: 'PRODUCTS.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}
                    </div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    {typeof subscriptions === "undefined"?<Skeleton width={70} height={27}/>:(<>
                        <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                            <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={subscriptions} formatAmount={true}/>
                        </div>
                        {lsubscriptions<=subscriptions?(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr066.svg'
                                className='svg-icon-5 svg-icon-success ms-1'
                            />
                        ):(
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr065.svg'
                                className='svg-icon-5 svg-icon-danger ms-1'
                            />
                        )}
                    </>)}
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
    </>
}

export default RequestNumbers