import React, {FC, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import PRIcon from "../../../../../_metronic/partials/content/icons/PayrequestIcons";
import PhoneInput from "react-phone-number-input";
import {E164Number} from "libphonenumber-js/core";
import SocialPicker, {SocialIcon} from "../../../../../_metronic/partials/widgets/socialpicker/SocialPicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface iStep3 {
    values: any
}
const Step3: FC<iStep3> = (props) => {
    const [phoneNumber, setPhoneNumber] = useState<E164Number|string>(props.values?.telephone);
    const [values, setValues] = useState<SocialIcon[]>([]); //{link:'test',icon:'app-store'}
    return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Additional business information</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Adding information increases your paymentrate!
        </div>
      </div>

        <div className='mb-10 fv-row'>
            <label className='d-flex align-items-center form-label mb-3'>
                What solution are you mainly looking for?
            </label>

            <div className='row mb-2 gy-5' data-kt-buttons='true'>
                <div className='col-4 '>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='solution'
                        value='paymentlinks'
                        id='solution_1'
                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 btn-flex'
                        htmlFor='solution_1'
                    >
                        {PRIcon.PaymentLinkIcon()}
                        <span className="d-flex flex-column align-items-start ms-2" style={{textAlign:'left'}}>
                            <span className="fs-3 fw-bolder">Payment Links</span>
                            <span className="fs-7">Easy personal payment links for your customers.</span>
                        </span>
                    </label>
                </div>
                <div className='col-4 '>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='solution'
                        value='subscriptions'
                        id='solution_2'
                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 btn-flex'
                        htmlFor='solution_2'
                    >
                        {PRIcon.SubscriptionIcon()}
                        <span className="d-flex flex-column align-items-start ms-2" style={{textAlign:'left'}}>
                            <span className="fs-3 fw-bolder">Subscriptions</span>
                            <span className="fs-7">Let users pay on subscription basis. No more goofing around!</span>
                        </span>
                    </label>
                </div>
                <div className='col-4 '>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='solution'
                        value='donations'
                        id='solution_3'
                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 btn-flex'
                        htmlFor='solution_3'
                    >
                        {PRIcon.DonationIcon()}
                        <span className="d-flex flex-column align-items-start ms-2" style={{textAlign:'left'}}>
                            <span className="fs-3 fw-bolder">Donations</span>
                            <span className="fs-7">Want to raise money? Now you can!</span>
                        </span>
                    </label>
                </div>
                <div className='col-4 '>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='solution'
                        value='website-payments'
                        id='solution_4'
                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 btn-flex'
                        htmlFor='solution_4'
                    >
                        {PRIcon.WebshopIcon()}
                        <span className="d-flex flex-column align-items-start ms-2" style={{textAlign:'left'}}>
                            <span className="fs-3 fw-bolder">Website payments</span>
                            <span className="fs-7">Looking to integrate Payrequest?</span>
                        </span>
                    </label>
                </div>
                <div className='col-4 '>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='solution'
                        value='paid-content'
                        id='solution_5'
                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 btn-flex'
                        htmlFor='solution_5'
                    >
                        {PRIcon.DigitalDownloadIcon()}
                        <span className="d-flex flex-column align-items-start ms-2" style={{textAlign:'left'}}>
                            <span className="fs-3 fw-bolder">Paid content</span>
                            <span className="fs-7">You want to sell video's, access or other digital assets without any issues</span>
                        </span>
                    </label>
                </div>
                <div className='col-4 '>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='solution'
                        value='products'
                        id='solution_6'
                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 btn-flex'
                        htmlFor='solution_6'
                    >
                        {PRIcon.PreAuthIcon()}
                        <span className="d-flex flex-column align-items-start ms-2" style={{textAlign:'left'}}>
                            <span className="fs-3 fw-bolder">Pre Authorisation</span>
                            <span className="fs-7">Want to capture funds later in the process for your hotel or other service?</span>
                        </span>
                    </label>
                </div>
            </div>

        </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Tell us something about your business</label>

        <Field name='companyAbout' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className=''>Company Phone</span>
        </label>

        {/*<Field
          name='companyPhone' type="tel"
          className='form-control form-control-lg form-control-solid'
        />*/}
          <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              className="input-group input-group-lg input-group-solid"
              numberInputProps={{
                  className: "form-control form-control-lg form-control-solid",
                  name: "companyPhone"
              }}
              // @ts-ignore
              onChange={setPhoneNumber}/>
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyPhone' />
        </div>

        <div className='form-text'>
            We won't <strong>ever</strong> contact you by phone!
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Company Address</label>
          <div className="row">
              <div className="col-6">
                  <Field name='companyAddress' placeholder="Streetname + number" className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                      <ErrorMessage name='companyAddress' />
                  </div>
              </div>
              <div className="col-6">
                  <Field name='companyPostal' placeholder="Postal" className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                      <ErrorMessage name='companyPostal' />
                  </div>
              </div>
          </div>
          <select
              className='form-select form-select-solid form-select-lg fw-bold'
              name="companyCountry"
          >
              <option value=''>Select a Country...</option>
              <option value='AF'>Afghanistan</option>
              <option value='AX'>Aland Islands</option>
              <option value='AL'>Albania</option>
              <option value='DZ'>Algeria</option>
              <option value='AS'>American Samoa</option>
              <option value='AD'>Andorra</option>
              <option value='AO'>Angola</option>
              <option value='AI'>Anguilla</option>
              <option value='AQ'>Antarctica</option>
              <option value='AG'>Antigua and Barbuda</option>
              <option value='AR'>Argentina</option>
              <option value='AM'>Armenia</option>
              <option value='AW'>Aruba</option>
              <option value='AU'>Australia</option>
              <option value='AT'>Austria</option>
              <option value='AZ'>Azerbaijan</option>
              <option value='BS'>Bahamas</option>
              <option value='BH'>Bahrain</option>
              <option value='BD'>Bangladesh</option>
              <option value='BB'>Barbados</option>
              <option value='BY'>Belarus</option>
              <option value='BE'>Belgium</option>
              <option value='BZ'>Belize</option>
              <option value='BJ'>Benin</option>
              <option value='BM'>Bermuda</option>
              <option value='BT'>Bhutan</option>
              <option value='BO'>Bolivia, Plurinational State of</option>
              <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
              <option value='BA'>Bosnia and Herzegovina</option>
              <option value='BW'>Botswana</option>
              <option value='BV'>Bouvet Island</option>
              <option value='BR'>Brazil</option>
              <option value='IO'>British Indian Ocean Territory</option>
              <option value='BN'>Brunei Darussalam</option>
              <option value='BG'>Bulgaria</option>
              <option value='BF'>Burkina Faso</option>
              <option value='BI'>Burundi</option>
              <option value='KH'>Cambodia</option>
              <option value='CM'>Cameroon</option>
              <option value='CA'>Canada</option>
              <option value='CV'>Cape Verde</option>
              <option value='KY'>Cayman Islands</option>
              <option value='CF'>Central African Republic</option>
              <option value='TD'>Chad</option>
              <option value='CL'>Chile</option>
              <option value='CN'>China</option>
              <option value='CX'>Christmas Island</option>
              <option value='CC'>Cocos (Keeling) Islands</option>
              <option value='CO'>Colombia</option>
              <option value='KM'>Comoros</option>
              <option value='CG'>Congo</option>
              <option value='CD'>Congo, the Democratic Republic of the</option>
              <option value='CK'>Cook Islands</option>
              <option value='CR'>Costa Rica</option>
              <option value='CI'>Côte d'Ivoire</option>
              <option value='HR'>Croatia</option>
              <option value='CU'>Cuba</option>
              <option value='CW'>Curaçao</option>
              <option value='CY'>Cyprus</option>
              <option value='CZ'>Czech Republic</option>
              <option value='DK'>Denmark</option>
              <option value='DJ'>Djibouti</option>
              <option value='DM'>Dominica</option>
              <option value='DO'>Dominican Republic</option>
              <option value='EC'>Ecuador</option>
              <option value='EG'>Egypt</option>
              <option value='SV'>El Salvador</option>
              <option value='GQ'>Equatorial Guinea</option>
              <option value='ER'>Eritrea</option>
              <option value='EE'>Estonia</option>
              <option value='ET'>Ethiopia</option>
              <option value='FK'>Falkland Islands (Malvinas)</option>
              <option value='FO'>Faroe Islands</option>
              <option value='FJ'>Fiji</option>
              <option value='FI'>Finland</option>
              <option value='FR'>France</option>
              <option value='GF'>French Guiana</option>
              <option value='PF'>French Polynesia</option>
              <option value='TF'>French Southern Territories</option>
              <option value='GA'>Gabon</option>
              <option value='GM'>Gambia</option>
              <option value='GE'>Georgia</option>
              <option value='DE'>Germany</option>
              <option value='GH'>Ghana</option>
              <option value='GI'>Gibraltar</option>
              <option value='GR'>Greece</option>
              <option value='GL'>Greenland</option>
              <option value='GD'>Grenada</option>
              <option value='GP'>Guadeloupe</option>
              <option value='GU'>Guam</option>
              <option value='GT'>Guatemala</option>
              <option value='GG'>Guernsey</option>
              <option value='GN'>Guinea</option>
              <option value='GW'>Guinea-Bissau</option>
              <option value='GY'>Guyana</option>
              <option value='HT'>Haiti</option>
              <option value='HM'>Heard Island and McDonald Islands</option>
              <option value='VA'>Holy See (Vatican City State)</option>
              <option value='HN'>Honduras</option>
              <option value='HK'>Hong Kong</option>
              <option value='HU'>Hungary</option>
              <option value='IS'>Iceland</option>
              <option value='IN'>India</option>
              <option value='ID'>Indonesia</option>
              <option value='IR'>Iran, Islamic Republic of</option>
              <option value='IQ'>Iraq</option>
              <option value='IE'>Ireland</option>
              <option value='IM'>Isle of Man</option>
              <option value='IL'>Israel</option>
              <option value='IT'>Italy</option>
              <option value='JM'>Jamaica</option>
              <option value='JP'>Japan</option>
              <option value='JE'>Jersey</option>
              <option value='JO'>Jordan</option>
              <option value='KZ'>Kazakhstan</option>
              <option value='KE'>Kenya</option>
              <option value='KI'>Kiribati</option>
              <option value='KP'>Korea, Democratic People's Republic of</option>
              <option value='KW'>Kuwait</option>
              <option value='KG'>Kyrgyzstan</option>
              <option value='LA'>Lao People's Democratic Republic</option>
              <option value='LV'>Latvia</option>
              <option value='LB'>Lebanon</option>
              <option value='LS'>Lesotho</option>
              <option value='LR'>Liberia</option>
              <option value='LY'>Libya</option>
              <option value='LI'>Liechtenstein</option>
              <option value='LT'>Lithuania</option>
              <option value='LU'>Luxembourg</option>
              <option value='MO'>Macao</option>
              <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
              <option value='MG'>Madagascar</option>
              <option value='MW'>Malawi</option>
              <option value='MY'>Malaysia</option>
              <option value='MV'>Maldives</option>
              <option value='ML'>Mali</option>
              <option value='MT'>Malta</option>
              <option value='MH'>Marshall Islands</option>
              <option value='MQ'>Martinique</option>
              <option value='MR'>Mauritania</option>
              <option value='MU'>Mauritius</option>
              <option value='YT'>Mayotte</option>
              <option value='MX'>Mexico</option>
              <option value='FM'>Micronesia, Federated States of</option>
              <option value='MD'>Moldova, Republic of</option>
              <option value='MC'>Monaco</option>
              <option value='MN'>Mongolia</option>
              <option value='ME'>Montenegro</option>
              <option value='MS'>Montserrat</option>
              <option value='MA'>Morocco</option>
              <option value='MZ'>Mozambique</option>
              <option value='MM'>Myanmar</option>
              <option value='NA'>Namibia</option>
              <option value='NR'>Nauru</option>
              <option value='NP'>Nepal</option>
              <option value='NL'>Netherlands</option>
              <option value='NC'>New Caledonia</option>
              <option value='NZ'>New Zealand</option>
              <option value='NI'>Nicaragua</option>
              <option value='NE'>Niger</option>
              <option value='NG'>Nigeria</option>
              <option value='NU'>Niue</option>
              <option value='NF'>Norfolk Island</option>
              <option value='MP'>Northern Mariana Islands</option>
              <option value='NO'>Norway</option>
              <option value='OM'>Oman</option>
              <option value='PK'>Pakistan</option>
              <option value='PW'>Palau</option>
              <option value='PS'>Palestinian Territory, Occupied</option>
              <option value='PA'>Panama</option>
              <option value='PG'>Papua New Guinea</option>
              <option value='PY'>Paraguay</option>
              <option value='PE'>Peru</option>
              <option value='PH'>Philippines</option>
              <option value='PN'>Pitcairn</option>
              <option value='PL'>Poland</option>
              <option value='PT'>Portugal</option>
              <option value='PR'>Puerto Rico</option>
              <option value='QA'>Qatar</option>
              <option value='RE'>Réunion</option>
              <option value='RO'>Romania</option>
              <option value='RU'>Russian Federation</option>
              <option value='RW'>Rwanda</option>
              <option value='BL'>Saint Barthélemy</option>
              <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
              <option value='KN'>Saint Kitts and Nevis</option>
              <option value='LC'>Saint Lucia</option>
              <option value='MF'>Saint Martin (French part)</option>
              <option value='PM'>Saint Pierre and Miquelon</option>
              <option value='VC'>Saint Vincent and the Grenadines</option>
              <option value='WS'>Samoa</option>
              <option value='SM'>San Marino</option>
              <option value='ST'>Sao Tome and Principe</option>
              <option value='SA'>Saudi Arabia</option>
              <option value='SN'>Senegal</option>
              <option value='RS'>Serbia</option>
              <option value='SC'>Seychelles</option>
              <option value='SL'>Sierra Leone</option>
              <option value='SG'>Singapore</option>
              <option value='SX'>Sint Maarten (Dutch part)</option>
              <option value='SK'>Slovakia</option>
              <option value='SI'>Slovenia</option>
              <option value='SB'>Solomon Islands</option>
              <option value='SO'>Somalia</option>
              <option value='ZA'>South Africa</option>
              <option value='GS'>South Georgia and the South Sandwich Islands</option>
              <option value='KR'>South Korea</option>
              <option value='SS'>South Sudan</option>
              <option value='ES'>Spain</option>
              <option value='LK'>Sri Lanka</option>
              <option value='SD'>Sudan</option>
              <option value='SR'>Suriname</option>
              <option value='SJ'>Svalbard and Jan Mayen</option>
              <option value='SZ'>Swaziland</option>
              <option value='SE'>Sweden</option>
              <option value='CH'>Switzerland</option>
              <option value='SY'>Syrian Arab Republic</option>
              <option value='TW'>Taiwan, Province of China</option>
              <option value='TJ'>Tajikistan</option>
              <option value='TZ'>Tanzania, United Republic of</option>
              <option value='TH'>Thailand</option>
              <option value='TL'>Timor-Leste</option>
              <option value='TG'>Togo</option>
              <option value='TK'>Tokelau</option>
              <option value='TO'>Tonga</option>
              <option value='TT'>Trinidad and Tobago</option>
              <option value='TN'>Tunisia</option>
              <option value='TR'>Turkey</option>
              <option value='TM'>Turkmenistan</option>
              <option value='TC'>Turks and Caicos Islands</option>
              <option value='TV'>Tuvalu</option>
              <option value='UG'>Uganda</option>
              <option value='UA'>Ukraine</option>
              <option value='AE'>United Arab Emirates</option>
              <option value='GB'>United Kingdom</option>
              <option value='US'>United States</option>
              <option value='UY'>Uruguay</option>
              <option value='UZ'>Uzbekistan</option>
              <option value='VU'>Vanuatu</option>
              <option value='VE'>Venezuela, Bolivarian Republic of</option>
              <option value='VN'>Vietnam</option>
              <option value='VI'>Virgin Islands</option>
              <option value='WF'>Wallis and Futuna</option>
              <option value='EH'>Western Sahara</option>
              <option value='YE'>Yemen</option>
              <option value='ZM'>Zambia</option>
              <option value='ZW'>Zimbabwe</option>
          </select>
          <div className='text-danger mt-2'>
              <ErrorMessage name='companyCountry' />
          </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Social Media</label>
        {/*
        @ts-ignore */}
        <SocialPicker values={values} setValues={setValues} addNewComponent={<><FontAwesomeIcon icon="fa-duotone fa-add" className="me-2"/> Add Social</>} useFormik={true} addNewProps={{className:"btn btn-success rounded-pill"}}/>
          <div className='form-text'>
              Drag 'n drop us to get the correct order!
          </div>
      </div>


      {/*<div className='fv-row mb-0'>
        <label className='fs-6 fw-bold form-label required'>Contact Email</label>

        <Field name='businessEmail' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessEmail' />
        </div>
      </div>*/}
    </div>
  )
}

export {Step3}
