/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Fragment, useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Crisp } from "crisp-sdk-web";
import {languages, setLanguage, useLang} from "../../../../../_metronic/i18n/Metronici18n";
import clsx from "clsx";
import {Blocker, isBlocked} from "../../../widgets/components/Blocker";
import {getIntegrationStripe} from "../../../../integrations/stripe/core/_requests";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {getIntegrations} from "../../../../integrations/core/_requests";
import {getPaypalLink} from "../../../../integrations/paypal/core/_requests";
import {getMollieLink} from "../../../../integrations/mollie/core/_requests";

interface iStep4 {
    props: any
}

type pspStatus = {
    [key: number]: boolean
}

const Step4: FC<iStep4> = ({props:any}) => {
    const show = () => Crisp.chat?.show()
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)

    const MySwal = withReactContent(Swal)

    const [stripeLoading, setStripeLoading] = useState(true)
    const [paypalLoading, setPaypalLoading] = useState(true)
    const [mollieLoading, setMollieLoading] = useState(true)
    const [paypalLink, setPaypalLink] = useState<string>('');
    const [mollieLink, setMollieLink] = useState<string>('');
    const [pspStatus, setPspStatus] = useState<pspStatus>();

    useEffect(() => {
        getIntegrations().then(res => {
            if(typeof res === "boolean")
                return;
            const psps: pspStatus = {};
            // foreach each psp and add to psp with active
            res.forEach(integration => psps[integration.psp] = integration.active==true);
            setPspStatus(psps)
            setStripeLoading(false)
            getPaypalLink().then(res => {
                if(typeof res === "boolean")
                    return;
                setPaypalLink(res)
                setPaypalLoading(false)
            })
            getMollieLink().then(res => {
                if(typeof res === "boolean")
                    return;
                setMollieLink(res)
                setMollieLoading(false)
            })
        })
    },[]);
    const isActive = (psp:number) => typeof pspStatus !== "undefined" && typeof pspStatus[psp] !== "undefined" && pspStatus[psp];
    const errorSwal = () => MySwal.fire({
        title: <p>Woops</p>,
        html: <p>Something went wrong. Please try again later.</p>,
        didOpen: () => MySwal.showLoading(),
        timer: 3000
    })

    return (
        <div className='w-100 py-20 px-9'>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Connect your PSP
                    <OverlayTrigger
                        key={`step4-psp-1`}
                        placement='bottom'
                        overlay={<Tooltip id='tooltip-psp-stepper'>
                            Don't have one yet? Follow their onboarding while choosing one from below.
                        </Tooltip>}
                    >
                        {/*
                        @ts-ignore */}
                        <FontAwesomeIcon icon="fa-duotone fa-circle-info" className='ms-2 fs-7' />
                    </OverlayTrigger>
                </h2>

                <div className='text-gray-400 fw-bold fs-6'>
                    If you experience any issues or need help please contact us through <a className='link-primary fw-bolder' href='#' onClick={event => { event.preventDefault(); show(); }}>our chat</a> or info@payrequest.io!
                </div>
            </div>

            <div className="row g-5 g-xl-8">
                <div className="col-xl-4">
                    <div className={isBlocked(stripeLoading, `card card-dashed bg-white shadow hoverable card-xl-stretch p-5 mb-8 flex-column`)}>
                        <span className="d-flex flex-row w-100 justify-content-between my-3">
                            <span className="fs-1 fw-bolder text-gray-900">Stripe</span>
                            <span className="float-end align-self-center">
                                <button
                                    disabled={stripeLoading}
                                    type='button'
                                    onClick={()=> {
                                        setStripeLoading(true);
                                        // get stripe integration url and redirect to that.
                                        getIntegrationStripe().then((res) => {
                                            if(typeof res !=="boolean") {
                                                setStripeLoading(false)
                                                window.location.href = res?.url
                                            }else{
                                                setStripeLoading(false)
                                                errorSwal()
                                            }
                                        }).catch((err) => {
                                            setStripeLoading(false)
                                            errorSwal()
                                        })
                                    }}
                                    className='btn btn-light-success'
                                >
                                  Connect
                                    {/*
                                    @ts-ignore */}
                                    <FontAwesomeIcon icon={stripeLoading?"fa-duotone fa-spinner":"fa-duotone fa-link"} className={clsx("ms-2",stripeLoading?"fa-spin":"")} />
                                </button>
                            </span>
                        </span>
                        <span className="d-flex flex-column">
                            <span className="d-flex fw-bolder text-primary mt-0 mb-4">Only 29 cents for IDEAL payments<br/>Creditcard starting at 25 cents + 1.4%</span>
                            <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                                Millions of businesses of all sizes—from startups to large enterprises—use Stripe’s software and APIs to accept payments, send payouts, and manage their businesses online.
                            </p>
                        </span>
                        <Blocker state={stripeLoading}/>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className={isBlocked(paypalLoading, `card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column`)}>
                        <span className="d-flex flex-row w-100 justify-content-between my-3">
                            <span className="fs-1 fw-bolder text-gray-900">PayPal</span>
                            <span className="float-end align-self-center">
                                <a className="btn btn-light-success btn-sm" href={`${paypalLink}&displayMode=minibrowser`} data-paypal-button='true' target='PPFrame'>
                                Connect
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                            </a>
                            </span>
                            {(!paypalLoading && !isActive(4)) && ((function (d, s, id) {
                                let js, ref = d.getElementsByTagName(s)[0];
                                if (!d.getElementById(id)) {
                                    js = d.createElement(s) as HTMLScriptElement;
                                    js.id = id;
                                    js.async = true;
                                    js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
                                    // @ts-ignore
                                    ref.parentNode.insertBefore(js, ref);
                                }
                            }(document, "script", "paypal-js")))}
                        </span>
                        <span className="d-flex flex-column">
                            <span className="d-flex fw-bolder text-primary mt-0 mb-4">Only 29 cents for IDEAL payments<br/>PayPal checkout without redirects</span>
                            <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                                Get paid and make payments risk free without any problems.
                            </p>
                        </span>
                        <Blocker state={paypalLoading}/>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column opacity-50">
                    <span className="d-flex flex-row w-100 justify-content-between my-3">
                        <span className="fs-1 fw-bolder text-gray-900">MultiSafePay</span>
                        <span className="float-end align-self-center">
                            <span className="btn btn-light-secondary btn-sm">
                                Coming Soon!
                                {/*
                                @ts-ignore */}
                                {/*<FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />*/}
                            </span>
                        </span>
                    </span>
                        <span className="d-flex flex-column">
                        <span className="d-flex fw-bolder text-primary mt-0 mb-4">First 300 IDEAL transactions free<br/>Lower fee's overall</span>
                        <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                            Your renewed payment solution.
                        </p>
                    </span>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className={isBlocked(mollieLoading, `card bg-white shadow hoverable card-stretch p-5 mb-8 flex-column`)}>
                        <span className="d-flex flex-row w-100 justify-content-between my-3">
                            <span className="fs-1 fw-bolder text-gray-900">Mollie</span>
                            <span className="float-end align-self-center">
                                <a className="btn btn-light-success btn-sm" href={mollieLink}>
                                Connect
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                            </a>
                            </span>
                        </span>
                        <span className="d-flex flex-column">
                            <span className="d-flex fw-bolder text-primary mt-0 mb-4">Only 29 cents for IDEAL payments<br/>Many methods available</span>
                            <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                                Start growing your business with effortless payments.
                            </p>
                        </span>
                        <Blocker state={mollieLoading}/>
                    </div>
                </div>
                <div className="col-xl-4">

                    <a href="#" onClick={event => { event.preventDefault(); show(); }} className="card card-dashed bg-white shadow hoverable card-xl-stretch p-5 mb-8 flex-column opacity-50">
                        <span className="d-flex flex-row w-100 justify-content-between my-3">
                            <span className="fs-1 fw-bolder text-gray-900">Coming soon?</span>
                            <span className="float-end align-self-center">
                                <button disabled={true} className="btn btn-light-success">
                                    Open Chat
                                    {/*
                                    @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-link" className="ms-2" />
                                </button>
                            </span>
                        </span>
                        <span className="d-flex flex-column">
                            <span className="d-flex fw-bolder text-primary mt-0 mb-4">Missing a Payment Provider?<br/>Let us know!</span>
                            <p className="d-flex text-gray-700 fw-bold m-0 mb-4">
                                Mail or chat with us and we will try to integrate it a.s.a.p!
                            </p>
                        </span>
                    </a>
                </div>
            </div>

        </div>
    )
}

export {Step4}
