import React, {FC} from "react";
import {useIntl} from "react-intl";
import {Metas, MetaValues, Products, ProductsStatus} from "../../auth";
import Skeleton from "react-loading-skeleton";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {KTSVG} from "../../../../_metronic/helpers";
import {updateTestmode} from "../../auth/core/_requests";
import {CurrencyIcon} from "../../currency/components/icon";

type MetaFieldProps = {
    metas: Array<Metas>|undefined
    slug: string
    label: string
    meta: MetaValues|undefined
    change: Function
    showIfUndefined?: boolean,
    children?: any
    currency?: string
}

type FieldProps = {
    type: string
    options: {[index: string]:any}
    change: Function
    loading: boolean
    value: boolean|string|undefined
    slug: string
    currency?: string
}

type SelectProps = {
    defaultValue: string|undefined
    isLoading: boolean
    options: Array<{value:string, label:string}>
    change: Function
}

type BooleanProps = {
    value: boolean|undefined
    change: Function
    identifier: string
}

type TextProps = {
    value: string|undefined
    change: Function
}

type NumberProps = {
    value: number|undefined
    change: Function
    min: number
    max: number
    step: number,
    type: string,
    currency?: string
}

const SelectField: FC<SelectProps> = ({defaultValue, isLoading = false, options,  change}) => {
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    return <Select
        components={{DropdownIndicator}}
        //@ts-ignore
        defaultValue={options.find(option => option.value === defaultValue)}
        isLoading={isLoading}
        // @ts-ignore
        options={options}
        onChange={event => {
            if(event === null)
                return
            // @ts-ignore
            change(event?.value)
        }}
        styles={{
            control: (provided) => ({
                ...provided,
                background: '#f5f8fa',
                borderColor: '#f5f8fa',
                color: '#5e6278',
                transition: 'color 0.2s ease, background-color 0.2s ease',
                borderRadius: '0.3rem'
            }),
            valueContainer: (provided) => ({
                ...provided,
                minHeight: 'calc(1.5em + 1rem + 2px)',
                padding: '0.5rem 1rem',
                fontSize: '1rem',
            })
        }}/>
}

const BooleanField: FC<BooleanProps> = ({identifier, value, change}) => {
    return <div className="form-check form-switch form-check-custom form-check-solid">
        <label className="form-check-label me-3" htmlFor={`${identifier}_boolean`} /*onClick={change(!value)}*/>
           No
        </label>
        <input className="form-check-input" type="checkbox" value="1" id={`${identifier}_boolean`}
               checked={value===true} onChange={()=>{change(!value)}}/>
        <label className="form-check-label" htmlFor={`${identifier}_boolean`} /*onClick={change(!value)}*/>
            Yes
        </label>
    </div>
}

const NumberField: FC<NumberProps> = ({value, change, min, max, step, type, currency}) => {
    switch (type) {
        case 'currency':
            return <div className="input-group mb-3">
                <span className="input-group-text">
                    <CurrencyIcon currency={currency}/>
                </span>
                <input type="number" min={min} step={step} pattern="^\d*(,|\.)?(\d{0,2})?$"
                       value={value} placeholder={min.toString()} className="form-control"
                       onChange={e => {change(e?.target?.value)}}/>
            </div>
        default:
            return <></>
    }
}

const TextField: FC<TextProps> = ({value, change}) => {
    return <input type="text" value={value} className="form-control form-control-solid" onChange={e => {change(e?.target?.value)}} />
}

const FieldRender: FC<FieldProps> = ({slug, loading, value, type , options, change, currency}) => {
    switch (type){
        case "select":
            return <SelectField defaultValue={typeof value === "boolean"?(value?'1':'0'):value} isLoading={loading}
                                options={options?.options} change={change} />
        case "boolean":
            return <BooleanField identifier={slug} value={typeof value === "string"?(value==='1'):value} change={change}/>
        case "number":
            return <NumberField value={(typeof value === "string"||typeof value === "boolean")?(typeof value === "boolean"?(value?1:0):parseFloat(value)):value}
                                change={change} min={options?.min ?? 0} max={options?.max ?? 10000000}
                                step={options?.step ?? 0.01} type={options?.numberType} currency={currency} />
        case "text":
            if(typeof value !== "string"&&typeof value !== "undefined")
                return <></>
            return <TextField value={value} change={change}/>
        default:
            return <>Unknown</>
    }
}

const MetaField: FC<MetaFieldProps> = ({metas, slug, label, meta, change, showIfUndefined = false, currency, children}) => {
    const intl = useIntl()
    if(typeof metas === "undefined" || (!showIfUndefined && typeof meta === "undefined"))
        return <div className={'mb-5'}><label className="form-label">{label}</label><Skeleton width={'100%'} height={43} className='d-block' /></div>;
    const base = metas.find(meta=>meta.slug===slug)
    if(typeof base === "undefined")
        return<div className={'mb-5'}><Skeleton width={200} height={26}/><Skeleton width={'100%'} height={43} className='d-block' /></div>
    const options = base.options
    return <div className={'mb-5'}>
        <label className="form-label">{label}</label>
        <FieldRender slug={slug} loading={typeof meta?.value==="undefined"} value={meta?.value} type={base?.options?.type.toString()} options={options}  change={change} currency={currency} />
        {children}
    </div>
}

export {MetaField}