import {paymentMethodIcons} from "../../requests/core/_assets";
import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {KTSVG} from "../../../../_metronic/helpers";
import {useAuth} from "../../auth";

const PayProModal2: FC = () => {
    const {currentUser} = useAuth()

    return <form method="POST" className="modal fade form" id="get_pro" aria-hidden="true">
        <div className="modal-dialog modal-md">
            <div className="modal-content p-0 m-0 bg-transparent shadow-none">
                <div className="modal-header justify-content-end border-0 p-0 position-absolute">
                    <div className="btn btn-sm btn-icon btn-white btn-active-color-primary" data-bs-dismiss="modal">
						<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg"
                                         width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                           fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1"/>
											<rect fill="#000000" opacity="0.5"
                                                  transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)"
                                                  x="0" y="7" width="16" height="2" rx="1"/>
										</g>
									</svg>
								</span>
                    </div>
                </div>
                {/*
                @ts-ignore */}
                <iframe src={`${process.env.REACT_APP_LINK_URL}/iframe/payrequest-pro?name=${currentUser?.name}&mail=${currentUser?.email}`} allowtransparency={true}
                    style={{
                        width: "440px",
                        display: "flex",
                        height: "800px",
                        margin: "0 auto"
                    }}></iframe>
            </div>
        </div>
    </form>
}
export default PayProModal2