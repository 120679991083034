import React, {FC, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {PaymentLinkStatusColors, PaymentLinkStatusIcons, useAuth} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {Link} from "react-router-dom";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import {useReactToPrint} from 'react-to-print';
import {PrTable, IlocalFilter} from "../../../../_metronic/partials/widgets/tables/PrTable";
import {Requests} from "../../../../_metronic/partials/widgets/tables/content/requests";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import {getPaymentLinks, tablePaymentLinks} from "../core/_requests";
import {PaymentLink} from "../core/_models";

const requestsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Requests',
        path: '/requests',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Overview: FC = () => {
    const intl = useIntl();
    const {currentUser} = useAuth()
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshing, setRefreshing] = useState<boolean>(false)
    const [print, setPrint] = useState(false);
    const [csv, setCsv] = useState(false);
    const requestType = (type?:string) => {
        switch (type) {
            case 'DONATION':
                return intl.formatMessage({id: 'REQUESTS.DONATIONSINGLE', defaultMessage: 'Donation'})
            case 'PREAUTH':
                return intl.formatMessage({id: 'REQUESTS.PREAUTHSINGLE', defaultMessage: 'Pre-authorisation'})
            case 'PRODUCT':
                return intl.formatMessage({id: 'REQUESTS.PRODUCTSINGLE', defaultMessage: 'Product'})
            case 'PERSONAL':
                return intl.formatMessage({id: 'REQUESTS.PERSONALSINGLE', defaultMessage: 'Personal'})
            default:
                return intl.formatMessage({id: 'REQUESTS.NORMALSINGLE', defaultMessage: 'Normal'})
        }
    }
    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: Date = new Date(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: Date = new Date(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[])
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            const bv: number = parseFloat(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[])
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.TITLE', defaultMessage: 'Title'}),
            accessor: 'title',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.CUSTOMER', defaultMessage: 'Customer'}),
            accessor: 'customer',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.AMOUNT', defaultMessage: 'Amount'}),
            accessor: 'amount',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            sortType: sortPrice
        },{
            Header: intl.formatMessage({id: 'TABLE.TYPE', defaultMessage: 'Type'}),
            accessor: 'type',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.CREATEDAT', defaultMessage: 'Created At'}),
            accessor: 'createdAt',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            sortType: sortDateTime,
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const parser = (requests:Array<PaymentLink>) => requests.map(request => {
        return {
            status: (
                <>
                    <div className='symbol symbol-40px me-4'>
                        <div className={clsx(
                            'symbol-label text-light',
                            'bg-' + (PaymentLinkStatusColors[request.status as keyof typeof PaymentLinkStatusColors])
                        )}
                             data-bs-toggle='tooltip'
                             data-bs-boundary='window'
                             data-bs-placement='top'
                             title={request.status}>
                            {/*
                            // @ts-ignore */}
                            <FontAwesomeIcon icon={clsx(
                                'fa-solid',
                                (PaymentLinkStatusIcons[request.status as keyof typeof PaymentLinkStatusIcons])
                            )}/>
                        </div>
                    </div>
                </>
            ),
            title: (
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {request.title}
                            </span>
            ),
            customer: (
                <div className='d-flex justify-content-start flex-column'>
                    <Link
                        to={typeof request.customer === "undefined" ? '#' : `/customer/${request.customer?.id}`}
                        className='text-dark fw-bolder text-hover-primary fs-6'>
                        {request.customer?.name ?? ""}
                    </Link>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {request.customer?.email ?? ""}
                          </span>
                </div>
            ),
            amount: (
                <>
                                <span data-filter={request.amount}
                                      className='text-dark fw-bolder text-hover-primary fs-6'>
                                  <CurrencyFormat currency={request.currency} amount={request.amount}/>
                                </span>
                    {(request.type === 'PREAUTH' && "captured" in request && typeof request?.captured !== "undefined") && <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  Captured:
                                  <CurrencyFormat currency={request.currency} amount={request.captured}/>
                                </span>}
                </>
            ),
            type: (
                <span className="badge badge-light-primary">
                            {requestType(request.type)}
                          </span>
            ),
            createdAt: (
                <>
                        <span data-filter={request.createdAt} className="badge badge-light-primary">
                            {intl.formatDate(request.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                        </span>
                </>
            ),
            urlpath: request.urlpath,
            actions: false
        }
    }).reverse()
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const initialSort = useMemo( () => [ { id: "createdAt", desc: false } ],[])

    /*const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setPageCount(10)
        }
    });
    const delay = (ms: number) => {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }*/
    return (
        <>
            <PageTitle breadcrumbs={requestsBreadCrumbs}>{intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}</PageTitle>
            <div className={'card mb-5 mb-xl-10'}>
                <div className='card-header border-0'>
                    <div className='d-flex align-items-center my-5 pt-5 w-100'>
                        <div className="d-flex align-items-center position-relative my-1">
                            {/*
                                 @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                            <input type="text"
                                   value={globalFilter}
                                   className="form-control form-control-solid w-250px ps-14"
                                   placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                   onChange={onChange}/>
                        </div>
                        <div className="d-flex align-items-center ms-auto position-relative my-1">
                            <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                {intl.formatMessage({id: 'GLOBAL.EXPORT', defaultMessage: 'Export to'})}
                            </div>
                            <div className='btn-group btn-group-sm ms-5' role='group' aria-label='Export possibilities'>
                                <span className='btn btn-primary' style={{pointerEvents: 'none'}}>
                                {/*
                                     @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-down" />
                                </span>
                                <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setCsv(!csv)}>
                                    CSV
                                </button>
                                <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setPrint(!print)}>
                                    PDF/Print
                                </button>
                            </div>
                        </div>
                        <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                            {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                        </div>
                        <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                            setRefreshing(true)
                            setRefreshData(!refreshData)
                            setTimeout(() => setRefreshing(false), 1000)
                        }}>
                            {/*
                            @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                        </button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <PrTable columns={columns} dataFetcher={tablePaymentLinks} parser={parser} invalidate={refreshData} sortee={initialSort} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Requests} tableType={'requests'} print={print} csvDownload={csv}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}