/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from "react-intl";
import {PaymentLinkTypes, Transactions, useAuth} from "../../../../app/modules/auth";
import {controller, getTransactionsLimit} from "../../../../app/modules/dashboard/core/_requests";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Blocker, isBlocked} from "../../../../app/modules/widgets/components/Blocker";
import {CurrencyFormat} from "../../../../app/modules/currency/components/format";
import {Column, useTable} from 'react-table'

type Props = {
  className: string
}

type TPrTable = {
  columns: Array<Column>,
  rawdata: Array<any>
}

const PrTable: React.FC<TPrTable> = ({columns,rawdata}) => {
  const data = useMemo(() => rawdata,[])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({columns,data})
  return (
      <table {...getTableProps({className:'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'})}>
        <thead>
        {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps({className:'fw-bolder text-muted'})}>
              {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
          )
        })}
        </tbody>
      </table>
  )
}

const RecentTransactions: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [loadingData, setLoadingData] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<Array<any>>([]);
  const transactionType = (type?:string) => {
    switch (type) {
      case 'DONATION':
        return intl.formatMessage({id: 'REQUESTS.DONATIONSINGLE', defaultMessage: 'Donation'})
      case 'PREAUTH':
        return intl.formatMessage({id: 'REQUESTS.PREAUTHSINGLE', defaultMessage: 'Pre-authorisation'})
      case 'PRODUCT':
        return intl.formatMessage({id: 'REQUESTS.PRODUCTSINGLE', defaultMessage: 'Product'})
      case 'PERSONAL':
        return intl.formatMessage({id: 'REQUESTS.PERSONALSINGLE', defaultMessage: 'Personal'})
      case 'SUBSCRIPTION':
        return intl.formatMessage({id: 'REQUESTS.SUBSCRIPTION', defaultMessage: 'Subscription'})
      default:
        return intl.formatMessage({id: 'REQUESTS.NORMALSINGLE', defaultMessage: 'Normal'})
    }
  }
  const columns = useMemo(() => [
    {
      Header: intl.formatMessage({id: 'TABLE.CUSTOMER', defaultMessage: 'Customer'}),
      accessor: 'customer',
      className: 'min-w-150px'
    },{
      Header: intl.formatMessage({id: 'TABLE.AMOUNT', defaultMessage: 'Amount'}),
      accessor: 'amount',
      className: 'min-w-80px'
    },{
      Header: intl.formatMessage({id: 'TABLE.TYPE', defaultMessage: 'Type'}),
      accessor: 'type',
      className: 'min-w-120px'
    },{
      Header: intl.formatMessage({id: 'TABLE.PAIDON', defaultMessage: 'Paid on'}),
      accessor: 'paid',
      className: 'min-w-120px'
    },{
      Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
      accessor: 'actions',
      className: 'min-w-0px text-end'
    }
  ], [])
  useEffect(() => {
    getTransactionsLimit(5).then(trans => {
        if(typeof trans === "boolean")
            return;
      setTransactions(trans.map(transaction => {
        return {
          customer: (
              <div className='d-flex justify-content-start flex-column'>
                <Link to={typeof transaction.customer === "undefined"?'#':`/customer/${transaction.customer?.id}`} className='text-dark fw-bolder text-hover-primary fs-6'>
                  {transaction.customer?.name ?? transaction.name}
                </Link>
                <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {transaction.customer?.email ?? transaction.email}
                          </span>
              </div>
          ),
          amount: (
              <>
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                  <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                </span>
                {typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && transaction.currency !== null && transaction.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                    <CurrencyFormat currency={currentUser.currency} amount={transaction.amountBase}/>
                  </span>
                )}
              </>
          ),
          type: (
              <span className="badge badge-light-primary">
                {transactionType(transaction.type)}
              </span>
          ),
          paid: (
              <>
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                  {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                </span>
                <span className='text-muted fw-bold text-muted d-block fs-7'>
                  {intl.formatTime(transaction.createdAt,{hour12:false})}
                </span>
              </>
          ),
          actions: (
              <div className='d-flex justify-content-end flex-shrink-0'>
                <Link
                    to={transaction?.urlpath ?? '/'}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  {/*
                       @ts-ignore*/}
                  <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3' />
                </Link>
              </div>
          )
        }
      }))
      setLoadingData(false)
    });
    return function cleanup() {
        //controller.abort()
        setLoadingData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, window.location.pathname]);

  return (
    <div className={isBlocked(loadingData, `card ${className}`)}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'TRANSACTIONS.RECENTTRANSACTIONS', defaultMessage: 'Recent Transactions'})}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{intl.formatMessage({id: 'TRANSACTIONS.LASTXTRANSACTIONS', defaultMessage: 'Last {num} transactions'},{num:5})}</span>
        </h3>
        <div
          className='card-toolbar'
        >
          <Link
            to='/transactions'
            className='btn btn-sm btn-light-primary'
          >
            {/*
             @ts-ignore */}
            <FontAwesomeIcon icon="fa-duotone fa-rectangle-vertical-history" className='fs-3 me-2' />
            {intl.formatMessage({id: 'TRANSACTIONS.ALLTRANSACTIONS', defaultMessage: 'All Transactions'})}
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {/*
          @ts-ignore*/}
          {!loadingData&&(<PrTable columns={columns} rawdata={transactions}/>)}
        </div>
        {/* end::Table container */}
        <Blocker state={loadingData}/>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {RecentTransactions}
