import React, {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent, CookieComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../helpers'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom'
import {useIntl} from "react-intl";
//import {EventHandlerUtil} from "../../../assets/ts/_utils";
//import {array} from "yup";
import {PaymentLinkStatusColors, PaymentLinkStatusIcons, useAuth, ISearchResults} from "../../../../app/modules/auth";
import clsx from "clsx";
import {CurrencyFormat} from "../../../../app/modules/currency/components/format";
import {sendSearch} from "../../../../app/modules/auth/core/_requests";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const [searchPreferences, setSearchPreferences] = useState(['requests','transactions','customers','products']);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<ISearchResults>({requests:[],transactions:[],customers:[],products:[]});
  const {currentUser} = useAuth()

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  const togglePreferences = (item: string) => searchPreferences.includes(item)?setSearchPreferences(searchPreferences.filter(type=>type!==item)):setSearchPreferences([...searchPreferences,item])
  const doRecentSearch = (search: string) => {
    const searchObject = SearchComponent.createInsance('#kt_header_search');
    (document.querySelector('input[name="search"]') as HTMLInputElement).value = search;
    if(searchObject!==undefined)
      searchObject.search()
  }
  useEffect(() => {

    const processs = (search: SearchComponent) => {
      setTimeout(function () {

        const recentSearch = (document.querySelector('input[name="search"]') as HTMLInputElement).value;
        sendSearch(recentSearch, searchPreferences).then(response => {
          if(typeof response !== "boolean")
            setSearchResults(response);
          else
            response = false;

          // Hide recently viewed
          suggestionsElement.current!.classList.add('d-none')
          if(response===false){
            resultsElement.current!.classList.add('d-none')
            emptyElement.current!.classList.remove('d-none')
          }else{
            resultsElement.current!.classList.remove('d-none')
            emptyElement.current!.classList.add('d-none')
          }
          // Complete search
          search.complete()
        });

        // @ts-ignore
        let recent : string[] = typeof (CookieComponent.get('searchRecent')) !== 'undefined'?CookieComponent.get('searchRecent').split('|'):[];
        recent.push(recentSearch)
        // @ts-ignore
        recent = [...new Set(recent)];
        setRecentSearches(recent)
        CookieComponent.set('searchRecent', recent.join('|'),{})

      }, 1500)
    }
    // @ts-ignore
    const preferences = typeof (CookieComponent.get('searchPreferences')) !== 'undefined'?CookieComponent.get('searchPreferences').split('|'):['requests','transactions','customers','products'];
    setSearchPreferences(typeof preferences === 'object'?preferences:['requests','transactions','customers','products']);
    // @ts-ignore
    const recent : string[] = typeof (CookieComponent.get('searchRecent')) !== 'undefined'?CookieComponent.get('searchRecent').split('|'):[];
    setRecentSearches(recent);
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // @ts-ignore
  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            {/*
            // @ts-ignore */}
            <FontAwesomeIcon  icon='fa-duotone fa-magnifying-glass' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              {/*
            // @ts-ignore */}
              <FontAwesomeIcon  icon='fa-duotone fa-magnifying-glass' className='position-absolute top-50 translate-middle-y ms-0' />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <OverlayTrigger
                    key='search-pref'
                    placement='top'
                    overlay={<Tooltip id='tooltip-search-pref'>{intl.formatMessage({id: 'SEARCH.SEARCHPREF', defaultMessage: 'Show search preferences'})}</Tooltip>}
                >
                  <div
                    data-kt-search-element='preferences-show'
                    className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                    onClick={() => {
                      setMenuState('preferences')
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                  </div>
                </OverlayTrigger>

                {/*<div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div>*/}
              </div>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {searchResults.requests.length>0?(
                    <>
                      <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                        {intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
                      </h3>

                      {searchResults.requests.map(request => (
                          <Link key={"request-"+request.id}
                              to={request?.urlpath}
                              className='d-flex text-dark text-hover-primary align-items-center mb-5'
                          >
                            <div className='symbol symbol-40px me-4'>
                              <div className={clsx(
                                  'symbol-label text-light',
                                  'bg-'+(PaymentLinkStatusColors[request.status as keyof typeof PaymentLinkStatusColors])
                              )}
                                   data-bs-toggle='tooltip'
                                   data-bs-boundary='window'
                                   data-bs-placement='top'
                                   title={request.status}>
                                {/*
                                // @ts-ignore */}
                                <FontAwesomeIcon icon={clsx(
                                    'fa-solid',
                                    (PaymentLinkStatusIcons[request.status as keyof typeof PaymentLinkStatusIcons])
                                )} />
                              </div>
                            </div>
                            <div className='d-flex flex-column justify-content-start fw-bold'>
                              <span className='fs-6 fw-bold'>
                                {request.title}
                                 - <CurrencyFormat currency={request?.currency ?? currentUser?.currency} amount={request.amount}/>
                              </span>
                              {request.customer!==null?(<span className='fs-7 fw-bold text-muted'>{request.customer.name}</span>):("")}
                              <span className='fs-7 fw-bold'>
                                <span className="badge badge-primary">{request.type}</span>
                              </span>
                            </div>
                          </Link>
                      ))}
                    </>):(<></>)}

                {searchResults.transactions.length>0?(
                    <>
                      <h3 className='fs-5 text-muted m-0 pt-5 pb-5' data-kt-search-element='category-title'>
                        {intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}
                      </h3>

                      {searchResults.transactions.map(transaction => (
                          <Link key={"transaction-"+transaction.id}
                              to={transaction?.urlpath ?? '/'}
                              className='d-flex text-dark text-hover-primary align-items-center mb-5'
                          >
                            <div className='d-flex flex-column justify-content-start fw-bold'>
                              <span className='fs-6 fw-bold'>{transaction.customer?.name ?? transaction.name}</span>
                              <span className='fs-7 fw-bold'>
                                {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}&nbsp;&nbsp;
                                {intl.formatTime(transaction.createdAt,{hour12:false})}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                                <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                              </span>
                            </div>
                          </Link>
                      ))}
                    </>):(<></>)}


                {searchResults.customers.length>0?(
                    <>
                      <h3 className='fs-5 text-muted m-0 pt-5 pb-5' data-kt-search-element='category-title'>
                        {intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}
                      </h3>

                      {searchResults.customers.map(customer => (
                          <Link key={"customer-"+customer.id}
                              to={'/customer/'+customer.id}
                              className='d-flex text-dark text-hover-primary align-items-center mb-5'
                          >
                            <div className='symbol symbol-40px me-4'>
                              <span className='symbol-label bg-light'>
                                <img
                                    className='w-20px h-20px'
                                    src={customer.avatar+'&s=20'}
                                    alt={customer.name}
                                />
                              </span>
                            </div>
                            <div className='d-flex flex-column justify-content-start fw-bold'>
                              <span className='fs-6 fw-bold'>{customer.name}</span>
                              <span className='fs-7 fw-bold text-muted'>
                                {customer.email}
                                {typeof customer.phone === 'undefined'||customer.phone === null||customer.phone.length<1?(""):(
                                    <>
                                    - {customer.phone}
                                    </>
                                )}
                              </span>
                            </div>
                          </Link>
                      ))}
                    </>):(<></>)}


                {searchResults.products.length>0?(
                    <>
                      <h3 className='fs-5 text-muted m-0 pt-5 pb-5' data-kt-search-element='category-title'>
                        {intl.formatMessage({id: 'MENU.PRODUCTS', defaultMessage: 'Products'})}
                      </h3>

                      {searchResults.products.map(product => (
                          <Link key={"product-"+product.id}
                              to={'/products/'+product.id}
                              className='d-flex text-dark text-hover-primary align-items-center mb-5'
                          >
                            <div className='d-flex flex-column justify-content-start fw-bold'>
                              <span className='fs-6 fw-bold'><span className="badge badge-primary">{intl.formatMessage({id: 'PRODUCTS.'+(product.type.name.toUpperCase()), defaultMessage: product.type.name})}</span> - {product.name}</span>
                              <span className='fs-7 fw-bold'>
                                <CurrencyFormat currency={product.currency} amount={product.price}/>
                              </span>
                            </div>
                          </Link>
                      ))}
                    </>):(<></>)}
              </div>
            </div>

            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              <div className='d-flex flex-stack fw-bold mb-4'>
                <span className='text-muted fs-6 me-2'>{intl.formatMessage({id: 'SEARCH.RECENT', defaultMessage: 'Recent searches'})}:</span>
              </div>

              <div className='scroll-y mh-200px mh-lg-325px'>

                {recentSearches.map(search => {
                  return (
                      <div key={search} className='d-flex align-items-center mb-5'>
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol-label bg-light' onClick={event => { doRecentSearch(search); }}>
                            <span className='svg-icon svg-icon-primary'>
                              {/*
                              // @ts-ignore */}
                              <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass-arrow-right" />
                            </span>
                          </span>
                        </div>
                        <div className='d-flex flex-column'>
                          <a href='/#' onClick={event => { event.preventDefault(); doRecentSearch(search); }} className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {search}
                          </a>
                        </div>
                      </div>
                  );
                })}

              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>{intl.formatMessage({id: 'SEARCH.NORESULTS', defaultMessage: 'No result found'})}</h3>
                <div className='text-muted fs-7'>{intl.formatMessage({id: 'SEARCH.TRYAGAIN', defaultMessage: 'Please try again with a different query'})}</div>
              </div>
            </div>
          </div>

          {/*<form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='attachment'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bolder btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form>*/}

          <form id='searchpreferences' className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>{intl.formatMessage({id: 'SEARCH.PREFERENCES', defaultMessage: 'Search Preferences'})}</h3>
            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}</span>
                <input className='form-check-input' type='checkbox' checked={searchPreferences.includes('requests')} name='requests' onClick={()=>togglePreferences('requests')} onChange={()=>{}} />
              </label>
            </div>
            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}</span>
                <input className='form-check-input' type='checkbox' checked={searchPreferences.includes('transactions')} name='transactions' onClick={()=>togglePreferences('transactions')} onChange={()=>{}} />
              </label>
            </div>
            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}</span>
                <input className='form-check-input' type='checkbox' checked={searchPreferences.includes('customers')} name='customers' onClick={()=>togglePreferences('customers')} onChange={()=>{}} />
              </label>
            </div>
            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{intl.formatMessage({id: 'MENU.PRODUCTS', defaultMessage: 'Products'})}</span>
                <input className='form-check-input' type='checkbox' checked={searchPreferences.includes('products')} name='products' onClick={()=>togglePreferences('products')} onChange={()=>{}} />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                {intl.formatMessage({id: 'GLOBAL.CANCEL', defaultMessage: 'Cancel'})}
              </button>
              <button className='btn btn-sm fw-bolder btn-primary' onClick={(e) => {
                e.preventDefault();
                // @ts-ignore
                const formData = new FormData(document.querySelector('#searchpreferences'));
                let preferences = [];
                // @ts-ignore
                for (let entry of Array.from(formData.entries())) {
                  preferences.push(entry[0]);
                }
                CookieComponent.set('searchPreferences',preferences.join('|'),{});
                setMenuState('main');
              }}>
                {intl.formatMessage({id: 'GLOBAL.SAVECHANGES', defaultMessage: 'Save changes'})}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {Search}
