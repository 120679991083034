import axios from 'axios'
import {ErrorMessage, StatusMessage} from "../../requests/core/_models";
import {TaxClasses} from "../../auth";

const API_URL = process.env.REACT_APP_API_URL

export const PRODUCTS_API = `${API_URL}/api/products`
export const TAXES_API = `${API_URL}/api/taxes`
export const TAX_API = `${API_URL}/api/tax`
export const TAX_DUPLICATE_API = `${API_URL}/tax/duplicate`

export const controller = new AbortController();


export function getProductTaxes(): Promise<Array<TaxClasses>|ErrorMessage> {
    return axios.get(`${PRODUCTS_API}/taxes`, {signal: controller.signal})
        .then(response => response.data as Array<TaxClasses>)
        .catch(err => err.response.data as ErrorMessage)
}

export function getProductTax(tid:number): Promise<TaxClasses|ErrorMessage> {
    return axios.get(`${PRODUCTS_API}/tax/${tid}`, {signal: controller.signal})
        .then(response => response.data as TaxClasses)
        .catch(err => err.response.data as ErrorMessage)
}

export function deleteTax(tid:number): Promise<StatusMessage|ErrorMessage> {
    return axios.get(`${PRODUCTS_API}/tax/${tid}/delete`, {signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

export function saveTax(tid:number, data:object): Promise<StatusMessage|ErrorMessage> {
    return axios.post(`${PRODUCTS_API}/tax/${tid}/save`, data,{signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

export function statusChangeTax(tid:number, status: string): Promise<ErrorMessage|StatusMessage> {
    return axios.post(`${TAXES_API}/status`, {tid, status},{signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}

export function doDuplicateTax(tid:number): Promise<StatusMessage|ErrorMessage> {
    return axios.get(`${TAX_DUPLICATE_API}/${tid}`,{signal: controller.signal})
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}