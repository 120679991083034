import axios from 'axios'
import {IntegrationUrl, StatusResponse} from "./_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const STRIPE_URL = "https://connect.stripe.com/"
const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATION_URL = `${API_URL}/integrations/stripe`
export const INTEGRATION_CHECK_URL = `${API_URL}/integrations/stripe/check`

export function getIntegrationStripe() {
    return axios.get<IntegrationUrl>(`${INTEGRATION_URL}`).then(response => response.data ?? false).catch(err => false)
}

export function checkIntegrationStripe(code:string, scope: string, error: string|null): Promise<StatusResponse|ErrorMessage> {
    const data: {[index: string]:any} = {code,scope}
    if(error) data['error'] = error
    return axios.post(
        `${INTEGRATION_CHECK_URL}`,
        data).then(response => response.data as unknown as StatusResponse)
        .catch(err => ({success:false, error: "Unknown error"}))
}