import {Route, Routes, Outlet, useNavigate, Link} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers";
import { Crisp } from "crisp-sdk-web";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";

const ThankYou = () => {
    const show = () => Crisp.chat?.show()
    const navigate = useNavigate()
    const intl = useIntl()

    const thankyouBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Pro',
            path: '/pro',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return <>

        <PageTitle breadcrumbs={thankyouBreadCrumbs}>Thank you for subscribing!</PageTitle>
    <div className="card card-flush h-md-100 mh-600px mw-700px mx-auto shadow">
        <div
            className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
            style={{
                backgroundPosition: '100% 50%',
                backgroundImage: `url(${toAbsoluteUrl('/media/shapes/shape-bg.png')})`
            }}>
            <div className="mb-10">
                <div className="fs-2hx fw-bold text-gray-800 text-center mb-13">
                    <span className="me-2">
                        Thank you for subscribing to our
                        <span className="position-relative d-inline-block text-danger">
                            <span className="text-danger opacity-75-hover">
                                PRO Plan
                            </span>
                            <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
                        </span>
                    </span> , enjoy the extra's right away!
                </div>
                <div className="text-center">
                    <Link to={'/dashboard'} className="btn btn-sm btn-dark fw-bold">
                        Continue to dashboard
                    </Link>
                </div>
            </div>
            <img className="mx-auto h-150px h-lg-300px"
                 src={toAbsoluteUrl('/media/illustrations/pro.svg')} alt="Thank you!" />
        </div>
    </div>
    </>
}

export default ThankYou
