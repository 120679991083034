/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useLang, setLanguage, languages} from '../../../i18n/Metronici18n'
import Dropdown from 'react-bootstrap/Dropdown'


const Languages: FC = () => {
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)
    return (
        <>
            <Dropdown key='languageSelector' autoClose="outside">
                <Dropdown.Toggle
                    variant="light"
                    id="languageSelector"
                    className='btn btn-flex flex-center btn-lg w-100 mb-5'>
                    <img
                        className='w-15px h-15px rounded-1 me-2'
                        src={currentLanguage?.flag}
                        alt={currentLanguage?.name}
                    />
                    {' '}{currentLanguage?.name}
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className='menu-item shadow-lg rounded overflow-hidden'
                >
                    {languages.map((l) => (
                        <Dropdown.Item
                            key={l.name}
                            eventKey={l.name}
                            className={clsx('menu-link d-flex p-5', {active: l.lang === currentLanguage?.lang})}
                            onClick={() => setLanguage(l.lang)}>
                        <span className='symbol symbol-20px me-4'>
                            <img className='rounded-1' src={l.flag} alt={l.name} />
                        </span>
                            {l.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export {Languages}
