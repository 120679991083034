/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {FC, useEffect, useState} from "react";
import {StepInterface} from "../../core/_scheme";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {currencyList} from "../../../auth/core/_requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAuth} from "../../../auth";
import {KTSVG} from "../../../../../_metronic/helpers";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {CurrencyIcon} from "../../../currency/components/icon";
import Skeleton from "react-loading-skeleton";
import {getCustomFieldGroups} from "../../core/_requests";
import clsx from "clsx";

const Step4: FC<StepInterface> = ({data, setData, errors}) => {
    const {currentUser} = useAuth()
    const updateData = (key:string, value:any) => {
        const newData = {...data}
        newData[key] = value
        setData(newData)
    }
    const toggleData = (key:string, value:string) => {
        const newData = {...data}
        if(typeof newData[key] === "undefined")
            newData[key] = [value]
        else if(newData[key]?.includes(value))
            newData[key] = newData[key].filter((item:string) => item !== value)
        else
            newData[key].push(value)
        setData(newData)
    }
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    return <>
        <div className="pb-10 pb-lg-15">
            <h2 className="fw-bolder d-flex align-items-center text-dark">
                Extra Information
            </h2>
            <div className="text-gray-400 fw-bold fs-6">
                Redirects, extra links and other information
            </div>
        </div>
        <div className="pb-2 pb-lg-3">
            <h3 className="fw-bolder text-dark">Responses</h3>
            <span className="form-text text-dark">After the payment do you want to redirect the customer to a certain url? If left empty the user will be redirected to your PayRequest status page.</span>
        </div>
        <div className="rounded border p-5 mb-5 d-flex flex-row flex-wrap w-100">
            <div className="row g-10 w-100">
                <div className="col-12 col-lg-6">
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3">Successful payment</label>
                        <input type="text" className="form-control form-control-lg form-control-solid" aria-required={true}
                               value={data?.successurl ?? ''} onChange={event=>{updateData('successurl',event?.target?.value)}}/>
                        {errors?.successurl && <span className="form-text text-danger">{errors?.successurl}</span>}
                        <span className='text-muted fs-8'>When the payment succeeds, redirect the customer to a certain url.</span>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3">Failed payment</label>
                        <input type="text" className="form-control form-control-lg form-control-solid" aria-required={true}
                               value={data?.failedurl ?? ''} onChange={event=>{updateData('failedurl',event?.target?.value)}}/>
                        {errors?.failedurl && <span className="form-text text-danger">{errors?.failedurl}</span>}
                        <span className='text-muted fs-8'>When the payment fails, redirect the customer to a certain url.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="mb-5 mt-5 fv-row non-product-group">
            <label className="form-label mb-3">Do you need to ship the product?</label>
            <div className="form-check form-switch form-check-custom form-check-solid">
                <label className="form-check-label me-3" htmlFor={`shipping_boolean`}>
                    No
                </label>
                <input className="form-check-input" type="checkbox" value="1" id={`shipping_boolean`}
                       checked={data?.shipping??false} onChange={()=>updateData('shipping',!(data?.shipping))}/>
                <label className="form-check-label" htmlFor={`shipping_boolean`}>
                    Yes
                </label>
            </div>
            <span className='text-muted fs-8'>For some payment providers this question is required</span>
        </div>
    </>
}

export {Step4}