import {Route, Routes, Outlet} from 'react-router-dom'
import {Overview} from "./components/Overview";
import {CustomerView} from "./components/CustomerView";

const CustomersPage = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='overview'
                    element={<Overview />}
                />
                <Route
                    path='new'
                    element={<CustomerView />}
                />
                <Route
                    path=':customerid'
                    element={<CustomerView />}
                />
                <Route
                    index
                    element={<Overview />}
                />
            </Route>
        </Routes>
    )
}

export default CustomersPage
