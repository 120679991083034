/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {MenuComponent} from "../../assets/ts/components";

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  pageToolbar?: React.ReactNode
  setPageToolbar: (_toolbar: React.ReactNode) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageToolbar: (_toolbar: React.ReactNode) => {},
})

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [pageToolbar, setPageToolbar] = useState<React.ReactNode>(null)
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageToolbar,
    setPageToolbar,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  toolbar?: React.ReactNode
}

const PageTitle: FC<Props> = ({children, description, breadcrumbs, toolbar}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setPageToolbar} = usePageData()
  useEffect(() => {
    const prevTitle = document.title
    if (children) {
      document.title = "PayRequest | "+children.toString()
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle(prevTitle)
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (toolbar) {
      setPageToolbar(toolbar)
    }
    return () => {
      setPageToolbar(null)
    }
  }, [toolbar])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

const PageToolbar: React.FC = ({children}) => {
  const {setPageToolbar} = usePageData()
  useEffect(() => {
    if (children) {
      setPageToolbar(children)
      MenuComponent.reinitialization()
    }
    return () => {
      setPageToolbar(null)
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData, PageToolbar}
