/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuAdmin() {
  const intl = useIntl()

  return (
      <>
        <div className='menu-item'>
          <div className='menu-content'>
            <div className='separator mx-1 my-4'></div>
          </div>
        </div>

        <div className='menu-item'>
          <div className='menu-content pt-4 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
          </div>
        </div>

        <AsideMenuItem
            to='/admin/users'
            title={intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}
            fontIcon='fa-users'
            icon='fa-users'
        />
        <AsideMenuItem
            to='/admin/requests'
            title={intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
            fontIcon='fa-rectangle-vertical-history'
            icon='fa-rectangle-vertical-history'
        />
        <AsideMenuItem
            to='/admin/transactions'
            title={intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}
            fontIcon='fa-money-from-bracket'
            icon='fa-money-from-bracket'
        />

      </>
  )
}
