import axios from 'axios'
import {PspIntegration, StatusResponse} from "./_models";
import {ErrorMessage} from "../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/api/integrations`
export const INTEGRATION_REMOVE = `${API_URL}/api/integration/remove`
export const METHODS_GET = `${API_URL}/api/paymentmethods`

export function getIntegrations() {
    return axios.get<Array<PspIntegration>>(`${INTEGRATIONS_GET}`).then(response => response.data ?? false).catch(err => false)
}
export function removeIntegration(id: number) : Promise<StatusResponse|ErrorMessage> {
    return axios.post(`${INTEGRATION_REMOVE}`,{id}).then(response => response.data as unknown as StatusResponse).catch(err => ({status:false, error: 'unknown'}))
}
export function getPaymentMethods(): Promise<{[index: string]:any}|ErrorMessage> {
    return axios.get(METHODS_GET,{})
        .then(response => response.data ?? ({status:'error', error: 'unknown'} as ErrorMessage))
        .catch(err => (err as ErrorMessage))
}