import React, {FC, useEffect, useState} from 'react'

import {PageLink, PageTitle, PageToolbar, useLayout} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {
    CustomFieldGroups,
    Metas,
    MetaValues, PersonalLink,
    Products,
    ProductsStatus,
    ProductType,
    TaxClasses,
    useAuth, UserModel
} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {useNavigate} from "react-router-dom";
import {CurrencyIcon} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {
    deleteProduct,
    getProduct,
    getProductMetas,
    getProductTaxes,
    getProductTypes,
    saveProduct
} from "../core/_requests";
import {useParams} from "react-router";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {productStatus, StatusOptions} from '../ProductsHelper';
import Select, {components, DropdownIndicatorProps, PropsValue} from "react-select";
import {KTSVG} from "../../../../_metronic/helpers";
import TextEditor from "../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {MetaField} from "./MetaField";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
    defaultImageInputOptions,
    defaultImageInputQueires,
    ImageInputComponent
} from "../../../../_metronic/assets/ts/components";
import {fileUploadString, UPLOAD_URLS} from "../../auth/core/_requests";
import {v4 as uuidv4} from "uuid";
import CopyToClipboard from "react-copy-to-clipboard";
import {getCustomFieldGroups} from "../../requests/core/_requests";

const productBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Products',
        path: '/products',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const ProductView: FC = () => {
    const {config} = useLayout()

    config.toolbar.layout = 'buttons'
    const intl = useIntl()
    const { pid } = useParams()
    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [product, setProduct] = useState<Products>();
    const [title, setTitle] = useState(intl.formatMessage({id: 'MENU.PRODUCT', defaultMessage: 'Product'}));
    const [status, setStatus] = useState<PropsValue<any>>();
    const [productTypes, setProductTypes] = useState<Array<ProductType>>();
    const [metas, setMetas] = useState<Array<Metas>>();
    const [taxClasses, setTaxClasses] = useState<Array<TaxClasses>>();
    const [rawGroups, setRawGroups] = useState<Array<CustomFieldGroups>>();
    const [extraFieldGroups, setExtraFieldGroups] = useState<any[]>();
    const [chosenFields, setChosenFields] = useState<any>();
    const [isBusy, setIsBusy] = useState(false);

    const chunkSize = 1048576 * 3
    const [uploadFile, setUploadFile] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [counter, setCounter] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [chunks, setChunks] = useState(0)
    const [chunkStart, setChunkStart] = useState(0)
    const [chunkEnd, setChunkEnd] = useState(chunkSize)
    const [fileType, setFileType] = useState("")
    const [fileGuid, setFileGuid] = useState("")
    const [fileName, setFileName] = useState("")

    const processUpload = () => {

        console.log(chunkStart,chunkEnd)
        console.log(uploadFile.slice(chunkStart,chunkEnd))
        fileUploadString(UPLOAD_URLS['logo'],fileName,fileGuid,uploadFile.slice(chunkStart,chunkEnd), fileType).then(response => {
            if(typeof response !== "boolean")
                setImgUrl(response.url)
            setChunkStart(chunkStart + chunkSize)
            setChunkEnd(chunkEnd + chunkSize)
            setCounter(counter+1)
        })
    }
    useEffect(() => {
        if(counter<chunks) {
            setUploading(true)
            processUpload()
        }else if(counter>=1) {
            setUploading(false)
            setChunkStart(0)
            setChunkEnd(chunkSize)
            setCounter(0)
            setChunks(0)
            // MySwal.close()

            if (imgUrl) {
                let newProduct = {...product}
                // @ts-ignore
                const check = newProduct?.metaValues.find(value=>value.slug==='main_image')
                if(check)
                    check.value = imgUrl
                else {
                    const newMeta = metas?.find(meta=>meta.slug==='main_image')
                    if(typeof newProduct.metaValues !== "undefined" && typeof newMeta!=="undefined") {
                        newProduct.metaValues.push({
                            ...newMeta,
                            value: imgUrl
                        } as MetaValues)
                    }
                }
                setProduct(newProduct as Products)
            }
        }
    }, [counter,uploadFile])
    const handleFileChange = (e:any) => {

        if(e===null){
            let newProduct = {...product}
            // @ts-ignore
            const check = newProduct?.metaValues.find(value=>value.slug==='main_image')
            if(check)
                check.value = ''
            else {
                const newMeta = metas?.find(meta=>meta.slug==='main_image')
                if(typeof newProduct.metaValues !== "undefined" && typeof newMeta!=="undefined") {
                    newProduct.metaValues.push({
                        ...newMeta,
                        value: ''
                    } as MetaValues)
                }
            }
            setProduct(newProduct as Products)
            return
        }
        const file = e
        /*MySwal.fire({
            title: 'Uploading...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })*/

        setImgUrl('')
        setFileName(file.name)
        setFileGuid(uuidv4())
        setChunkStart(0)
        setChunkEnd(chunkSize)
        setChunks(Math.ceil(file.size / chunkSize))
        setFileType(file.type)
        setUploadFile(file)
        setCounter(0)
    }

    useEffect(() => {
        if(typeof pid === 'undefined')
            return
        getProductTaxes().then(taxClasses => {
            const newTaxClasses = "error" in taxClasses? [] : taxClasses
            newTaxClasses.unshift({id:0, name: 'No tax', status: true, createdAt: new Date(), updatedAt: new Date(), description:'',rates:[]} as TaxClasses)
            setTaxClasses(newTaxClasses)
        })
        getCustomFieldGroups().then(customFieldGroups => {
            if("error" in customFieldGroups)
                return
            setRawGroups(customFieldGroups)
        })
        getProductTypes().then(types => "error" in types?setProductTypes([]):setProductTypes(types))
        getProductMetas().then(metaItems => "error" in metaItems?setMetas([]):setMetas(metaItems))
        return () => {
            //controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])
    useEffect(() => {
        if(typeof productTypes === "undefined"||typeof metas === "undefined"||typeof taxClasses === "undefined"||typeof pid === 'undefined'||typeof rawGroups === 'undefined')
            return
        const newGroups = rawGroups.map(group => {
            return {
                value: group.id,
                label: <>{group.name} <small>({group.fields} fields)</small></>
            }
        })
        const defaultChoice = {value: 0, label: <>No custom fields</>}
        newGroups.unshift(defaultChoice)
        setExtraFieldGroups(newGroups)
        if(pid!='new') {
            getProduct(parseInt(pid)).then(res => {
                if ("error" in res)
                    return

                setTitle(`${intl.formatMessage({
                    id: 'MENU.PRODUCT',
                    defaultMessage: 'Product'
                })} - #${res?.metaValues.find(value => value.slug === 'sku')?.value ?? ''} - ${res?.name}`)
                const status = StatusOptions().find(option => option.value === (res?.status ?? 'ENABLED'))
                if (typeof status !== "undefined")
                    setStatus(status)
                setProduct(res)
                setLoadingData(false)
                const imgInput = document.querySelector(defaultImageInputQueires.instanseQuery)
                if (imgInput)
                    new ImageInputComponent(imgInput as HTMLElement, defaultImageInputOptions, defaultImageInputQueires, handleFileChange)
                if(typeof res?.customFields !== "undefined" && res?.customFields!==null && !Array.isArray(res?.customFields))
                    setChosenFields(newGroups.find(group => group.value === res?.customFields?.id))
                else
                    setChosenFields(defaultChoice)
            })
        }else{
            setProduct({
                id: 0,
                name: '',
                slug: '',
                description: '',
                price: 0,
                // @ts-ignore
                type: productTypes?.find(type => type.id === 1),
                status: ProductsStatus.CONCEPT,
                currency: currentUser?.currency ?? null,
                createdAt: new Date(),
                updatedAt: new Date(),
                customFields: null,
                metaValues: [],
                taxClass: null,
            } as Products)
            setTitle('New Product')
            setStatus(StatusOptions().find(option => option.value === 'CONCEPT'))
            setChosenFields(defaultChoice)
            setLoadingData(false)
            const imgInput = document.querySelector(defaultImageInputQueires.instanseQuery)
            if (imgInput)
                new ImageInputComponent(imgInput as HTMLElement, defaultImageInputOptions, defaultImageInputQueires, handleFileChange)
        }
    }, [productTypes,metas,taxClasses, rawGroups]);

    const updateMetaField = (slug:string, value:any) => {
        let newProduct = {...product}
        const check = newProduct?.metaValues?.find(v=>v.slug===slug)
        console.log(newProduct?.metaValues,check)
        if(check)
            check.value = value
        else {
            const newMeta = metas?.find(meta=>meta.slug===slug)
            console.log(metas,newMeta)
            if(typeof newProduct.metaValues !== "undefined" && typeof newMeta!=="undefined") {
                newProduct.metaValues.push({
                    ...newMeta,
                    value: value
                } as MetaValues)
            }
        }
        setProduct(newProduct as Products)
    }
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    useEffect(()=>{
        if(typeof extraFieldGroups === "undefined" || typeof product === "undefined" || typeof rawGroups === "undefined")
            return
        if(chosenFields?.value===0)
            setProduct(oldProduct=>({...oldProduct, customfieldGroup: null} as Products))
        else
            setProduct(oldProduct=>({...oldProduct, customfieldGroup: (chosenFields?.value===0?null:(rawGroups?.find(group => group.id === chosenFields.value) ?? null))} as Products))
    },[chosenFields])
    const saveProductHandle = (goback:boolean = false) => {
        if(typeof product === "undefined")
            return;
        if(product.id===0){
            const missingFields = [];
            if(product.name.length===0)
                missingFields.push('Product Name')
            if(product.price==0)
                missingFields.push('Base price')
            if(missingFields.length!==0){
                MySwal.fire({
                    title: 'Not all required fields are completed',
                    html: `${missingFields.map(field=>`- ${field}`).join("<br/>")}<br/><hr/> Complete Fields and try again`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
                return
            }
        }
        setIsBusy(true)
        MySwal.fire({
            title: 'Saving...',
            text: "One moment please, this won't take long.",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false
        })
        saveProduct(product).then(res => {
            if(res.status==='success'){
                if(goback) {
                    MySwal.fire({
                        title: <p>Product is saved!</p>,
                        html: <p>You will be redirected to the products overview shortly...</p>,
                        icon: 'success',
                        didOpen: () => MySwal.showLoading(),
                        timer: 3000,
                        didClose() {
                            navigate('/products')
                        }
                    })
                }else{
                    if("id" in res && product?.id===0 && typeof res.id !== "undefined"){
                        const newProduct = {...product}
                        newProduct.id = res.id
                        setProduct(newProduct)
                    }
                    MySwal.fire({
                        title: <p>Product is saved!</p>,
                        /*html: <p>Something went wrong. Please try again later.</p>,*/
                        icon: 'success',
                        didOpen: () => MySwal.showLoading(),
                        timer: 3000
                    })
                }
            }else if("error" in res){
                MySwal.fire({
                    title: <p>Error!</p>,
                    html: <>
                        <p>Something went wrong. Please try again later.</p>
                        <pre>{res.error}</pre>
                    </>,
                    icon: 'error',
                    didOpen: () => MySwal.showLoading(),
                    timer: 3000
                })
            }
            setIsBusy(false)
        })
    }
    const deleteProductHandle = () => {
        if(typeof product === "undefined")
            return;
        MySwal.fire({
            title: intl.formatMessage({id: 'SWAL.DELETE.CONFIRMPRODUCT', defaultMessage: 'Are you sure you want to cancel the product?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'SWAL.CANCEL.PARTIALLY', defaultMessage: 'Yes'}),
            preConfirm: () => {
                return deleteProduct(product.id).then(res => {
                    if(res.status==='failed' && "error" in res){
                        Swal.showValidationMessage(
                            `Request failed: ${res.error}`
                        )
                    }else{
                        MySwal.fire({
                            title: intl.formatMessage({
                                id: 'SWAL.DELETE.SUCCESSPRODUCT',
                                defaultMessage: 'Product deleted successfully'
                            }),
                            icon: 'success',
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000,
                            didClose() {
                                navigate('/products')
                            }
                        })
                    }
                }).catch((err:any) => {
                    Swal.showValidationMessage(
                        `Request failed: Unknown error`
                    )})
            }
        });
    }
    return (
        <>
            <PageTitle breadcrumbs={productBreadCrumbs}>{title}</PageTitle>
            <PageToolbar>
                <button className='btn btn-light-primary btn-sm me-5' disabled={isBusy} onClick={e=>saveProductHandle()}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk" className='me-3' /> Save Product
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
                <button className='btn btn-primary btn-sm me-5' disabled={isBusy} onClick={(e)=>saveProductHandle(true)}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-floppy-disk-circle-arrow-right" className='me-3' />
                    Save Product and return
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>
                {pid!=="new"&&<button className='btn btn-light-danger btn-sm' disabled={isBusy} onClick={e=>{deleteProductHandle()}}>
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-trash-can" className='me-3' />
                    Delete Product
                    {/*
                    @ts-ignore */}
                    {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                </button>}
            </PageToolbar>
            <form className="form d-flex flex-column flex-lg-row gap-3 gap-lg-5">
                <div className="d-flex flex-column gap-3 gap-lg-5 w-100 w-lg-300px flex-shrink-0">
                    <div className="card card-flush py-4">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Product Image</h2>
                            </div>
                        </div>
                        <div className={isBlocked(uploading,"card-body text-center pt-0")}>
                            <div className="image-input  image-input-outline mb-3" data-kt-image-input="true">
                                {product?.metaValues?<div className="image-input-wrapper w-150px h-150px"
                                     style={{backgroundImage: `url('${process.env.REACT_APP_API_URL}/uploads/avatar/${product?.metaValues.find(value=>value.slug==='main_image')?.value ?? 'fallback.jpg'}')`}} ></div>:<Skeleton height={150} width={150}/>}
                                <OverlayTrigger
                                    key='main-image'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image'>{intl.formatMessage({id: 'IMAGE.CHANGE', defaultMessage: 'Click to change image'})}</Tooltip>}
                                >
                                    <label
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute top-min-10 end-min-10">
                                        <i className="bi bi-pencil-fill fs-7"></i>
                                        <input type="file" name="mainimage"
                                               className="main_image_upload d-none" accept=".png, .jpg, .jpeg, .gif, .webp" />
                                        <input type="hidden" name="mainimage_url" className="mainimage_url"
                                               value={product?.metaValues.find(value=>value.slug==='main_image')?.value}/>
                                    </label>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='main-image-cancel'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-cancel'>{intl.formatMessage({id: 'IMAGE.CANCEL', defaultMessage: 'Revert to previous image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="cancel">
                                        <i className="bi bi-arrow-counterclockwise fs-2"></i>
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    key='main-image-remove'
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-main-image-remove'>{intl.formatMessage({id: 'IMAGE.REMOVE', defaultMessage: 'Remove image'})}</Tooltip>}
                                >
                                    <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute bottom-min-10 end-min-10"
                                        data-kt-image-input-action="remove">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="text-muted fs-7 mt-2">
                                Only *.png, *.jpg, *.jpeg, *.gif and *.webp image files are accepted
                            </div>
                            <Blocker state={uploading}/>
                        </div>
                    </div>
                    <div className="card card-flush py-4">
                        <div className="card-header">
                            <div className="card-title d-flex flex-row justify-content-between w-100">
                                <h2>Status</h2>
                                <span className={`badge badge-circle bg-${product?.status?productStatus(product?.status):'secondary'}`}>&nbsp;</span>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            {typeof product?.status === "undefined" ? <Skeleton height={45} width={'100%'}/> :
                            <Select
                                components={{DropdownIndicator}}
                                //@ts-ignore
                                defaultValue={status}
                                isLoading={typeof product?.status === "undefined"}
                                // @ts-ignore
                                options={Object.values(ProductsStatus).map(status => ({value: status, label: `${status.charAt(0).toUpperCase()}${status.slice(1).toLowerCase()}`}))}
                                onChange={event => {
                                    let newProduct = {...product}
                                    if(typeof newProduct !== "undefined" && typeof event !== "undefined") {
                                        // @ts-ignore
                                        const _status = event?.value ?? "CONCEPT";
                                        newProduct.status = _status
                                        setStatus({value: newProduct.status, label: `${_status.charAt(0).toUpperCase()}${_status.slice(1).toLowerCase()}`})
                                        setProduct(newProduct as Products)
                                    }
                                }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        background: '#f5f8fa',
                                        borderColor: '#f5f8fa',
                                        color: '#5e6278',
                                        transition: 'color 0.2s ease, background-color 0.2s ease',
                                        borderRadius: '0.3rem'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        minHeight: 'calc(1.5em + 1rem + 2px)',
                                        padding: '0.5rem 1rem',
                                        fontSize: '1rem',
                                    })
                                }}/>}
                        </div>
                    </div>

                    {/*
                    @ts-ignore */}
                    <CopyToClipboard text={`${process.env.REACT_APP_PERSONAL_URL}/${product?.slug}`} onCopy={() => MySwal.fire({
                        title: intl.formatMessage({id: 'PRODUCT.COPIED', defaultMessage: 'Copied!'}),
                        icon: 'success',
                        showConfirmButton: false,
                        didOpen: () => MySwal.showLoading(),
                        timer: 2000
                    })}>
                        <div className={`btn btn-primary d-flex w-100 flex-grow`}>
                            {/*
                            @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-copy" className='fs-3 me-3 text-secondary' />
                            Copy direct link
                        </div>
                    </CopyToClipboard>
                </div>
                <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">
                    <ul
                        className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
                        role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4 active"
                               data-bs-toggle="tab" href="#pr_general"
                               aria-selected="true" role="tab">
                                General</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-bs-toggle="tab" href="#pr_advanced"
                               aria-selected="false" role="tab">
                                Advanced</a>
                        </li>
                        {/*<li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary pb-4"
                               data-kt-countup-tabs="true" data-bs-toggle="tab"
                               href="#pr_subscriptions" data-kt-initialized="1"
                               aria-selected="false" role="tab">
                                Subscriptions</a>
                        </li>*/}
                    </ul>
                    <div className="tab-content" id="pr_customer_tabs">
                        <div className="tab-pane fade active show" id="pr_general"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>General</span>
                                            <span className='text-muted fw-bold fs-7'>All important product details</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <div className={'mb-5'}>
                                            <label className="form-label required">Product Name</label>
                                            {typeof product?.name !== "undefined"?<input
                                                type="text"
                                                className="form-control form-control-solid"
                                                value={product?.name}
                                                onChange={e => {
                                                    let newProduct = {...product}
                                                    // @ts-ignore
                                                    newProduct.name = e?.target?.value
                                                    setProduct(newProduct as Products)
                                                }
                                                }
                                            />:<Skeleton width={'100%'} height={43} className='d-block' />}
                                            <span className='text-muted fs-8'>A product name is required and recommended to be unique.</span>
                                        </div>
                                        <div className={'mb-5'}>
                                            <label className="form-label">Description</label>
                                            {typeof product?.description !== "undefined"?<TextEditor className='tiptapSmall' save={(description:string) => {
                                                let newProduct = {...product}
                                                // @ts-ignore
                                                newProduct.description = description
                                                setProduct(newProduct as Products)
                                            }} liveSave={true} content={product?.description} />:<Skeleton width={'100%'} height={300} className='d-block' />}
                                            <span className='text-muted fs-8'>Set a description to the product for better visibility.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-flush py-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h2>Pricing</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0 row">
                                        <div className={`mb-5 ${product?.type?.id===1?'col-6':'col-12'}`}>
                                            <label className="form-label required">Base price</label>
                                            {typeof product?.price !== "undefined"? <div className="input-group mb-3">
                                                <span className="input-group-text">
                                                    <CurrencyIcon currency={product?.currency}/>
                                                </span>
                                                <input type="number" min="1" step="0.01" pattern="^\d*(,|\.)?(\d{0,2})?$"
                                                       name="price" value={product?.price} className="form-control"
                                                       aria-label="Product Base Price"
                                                       onChange={e => {
                                                           let newProduct = {...product}
                                                           // @ts-ignore
                                                           newProduct.price = e?.target?.value
                                                           setProduct(newProduct as Products)
                                                       }
                                                       }/>
                                            </div>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                            <span className='text-muted fs-8'>The base price excluding any fees</span>
                                        </div>
                                        {product?.type?.id===1&&<div className="mb-5 col-6">
                                            <label className="form-label required">Product fee</label>
                                            {typeof product?.price !== "undefined"? <div className="input-group mb-3">
                                                <span className="input-group-text">
                                                    <CurrencyIcon currency={product?.currency}/>
                                                </span>
                                                <input type="number" min="0" step="0.01" max={100000000} pattern="^\d*(,|\.)?(\d{0,2})?$"
                                                       name="price" value={parseFloat(product?.metaValues?.find(value => value.slug === 'fee')?.value??"0")??0} className="form-control"
                                                       aria-label="Product Base Price"
                                                       onChange={e => {
                                                           console.log(parseFloat(e?.target?.value))
                                                           updateMetaField('fee', parseFloat(e?.target?.value))
                                                           console.log(product?.metaValues?.find(value => value.slug === 'fee'))
                                                       }}/>
                                            </div>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                            <span className='text-muted fs-8'>The base price excluding any fees if subscription is selected</span>
                                        </div>}
                                        <div className={'mb-5 col-12'}>
                                            <label className="form-label required">Product Type</label>
                                            <div className={'d-flex flex-column gap-3 gap-lg-5 '}>
                                                {typeof product?.type !== "undefined" && productTypes?productTypes.map(type=>(<>
                                                    <input type="radio" className="btn-check"
                                                           name='type' value={type.id} defaultChecked={product?.type?.id === type.id}
                                                           onChange={e => {
                                                               let newProduct = {...product}
                                                               // @ts-ignore
                                                               newProduct.type = type
                                                               setProduct(newProduct as Products)
                                                           }
                                                           }
                                                           id={`pr_producttype_${type.id}`}/>
                                                    <label
                                                        className="btn btn-sm btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mw-500px bg-light"
                                                        htmlFor={`pr_producttype_${type.id}`}>
                                                        <span className="svg-icon svg-icon-2x me-5">
                                                            {/*
                                                            @ts-ignore */}
                                                            <FontAwesomeIcon icon={`fa-duotone fa-${product?.type.id===type.id?'circle-dot':'circle'}`} />
                                                        </span>

                                                        <span className="d-block fw-semibold text-start">
                                                            <span className="text-dark fw-bold d-block fs-4">{type.name}</span>
                                                            <span className="text-muted fw-semibold fs-7">
                                                                {type?.description}
                                                            </span>
                                                        </span>
                                                    </label>
                                                </>)):(<><Skeleton height={90} width={500}/> <Skeleton height={90} width={500}/> <Skeleton height={90} width={500}/></>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {typeof product?.type !== "undefined" && product?.type?.id === 2 && (
                                    <div className="card card-flush py-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h2>Subscription Details</h2>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className={'row'}>
                                                <div className={'col-12 col-md-6'}>
                                                    <MetaField metas={metas} slug={"cycle"} label='Cycle'
                                                               meta={product?.metaValues.find(value => value.slug === 'cycle')} change={(e:any)=>{updateMetaField('cycle', e)}} showIfUndefined={true}/>
                                                    <MetaField metas={metas} slug={"can_cancel"} label='Is it possible for the customer to terminate the contract?'
                                                               meta={product?.metaValues.find(value => value.slug === 'can_cancel')} change={(e:any)=>{updateMetaField('can_cancel',e)}} showIfUndefined={true}>
                                                        <div className="text-muted fs-7">
                                                            If this option is disabled customer cannot terminate the subscription without contacting you.
                                                        </div>
                                                    </MetaField>
                                                </div>
                                                <div className={'col-12 col-md-6'}>
                                                    <MetaField metas={metas} slug={"until"} label='Run time'
                                                               meta={product?.metaValues.find(value => value.slug === 'until')} change={(e:any)=>{updateMetaField('until', e)}} showIfUndefined={true}/>
                                                    <MetaField metas={metas} slug={"first_payment_full"} label='Do you want to include a verification payment?'
                                                               meta={product?.metaValues.find(value => value.slug === 'first_payment_full')} change={(e:any)=>{updateMetaField('first_payment_full',e)}} showIfUndefined={true}>
                                                        <div className="text-muted fs-7">
                                                            If this option is enabled a verification payment will be charged to the customer to ensure the correct payment details.
                                                        </div>
                                                    </MetaField>
                                                </div>
                                                <MetaField metas={metas} slug={"starting_fee"} label='Is there an additional fee included in the first payment?' currency={product?.currency ?? undefined}
                                                           meta={product?.metaValues.find(value => value.slug === 'starting_fee')} change={(e:any)=>{updateMetaField('starting_fee',e)}} showIfUndefined={true}>
                                                    <div className="text-muted fs-7">
                                                        If left empty no additional fee will be charged.
                                                    </div>
                                                </MetaField>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pr_advanced"
                             role="tabpanel">
                            <div className="d-flex flex-column flex-row-fluid gap-3 gap-lg-5 ">

                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>Product</span>
                                            <span className='text-muted fw-bold fs-7'>Extra product options that might be good to setup</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <MetaField metas={metas} slug={"sku"} label='SKU'
                                                   meta={product?.metaValues.find(value => value.slug === 'sku')} change={(e:any)=>{updateMetaField('sku', e)}} showIfUndefined={true}/>
                                        <MetaField metas={metas} slug={"shipping"} label='Will the product be shipped?'
                                                   meta={product?.metaValues.find(value => value.slug === 'shipping')} change={(e:any)=>{updateMetaField('shipping',e)}} showIfUndefined={true} />
                                        <div className={'mb-5'}>
                                            <label className="form-label">Extra Fields</label>
                                            {typeof chosenFields === "undefined"?<Skeleton width={'100%'} height={46}/>:
                                                <Select
                                                    //@ts-ignore
                                                    defaultValue={chosenFields}
                                                    options={extraFieldGroups}
                                                    isLoading={typeof chosenFields === "undefined"}
                                                    isSearchable={true}
                                                    components={{ DropdownIndicator }}
                                                    //@ts-ignore
                                                    onChange={event => setChosenFields(extraFieldGroups.find(x=>x.value===event.value) ?? null)}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            background: '#f5f8fa',
                                                            borderColor: '#f5f8fa',
                                                            color: '#5e6278',
                                                            transition: 'color 0.2s ease, background-color 0.2s ease',
                                                            borderRadius: '0.3rem'
                                                        }),
                                                        valueContainer: (provided) => ({
                                                            ...provided,
                                                            minHeight: 'calc(1.5em + 1rem + 2px)',
                                                            padding: '0.5rem 1rem',
                                                            fontSize: '1rem',
                                                        })
                                                    }}
                                                />}
                                            <span className='text-muted fs-8'>Does your customer need to fill in additional information?</span>
                                        </div>
                                        <div className={'mb-5'}>
                                            <label className="form-label">Tax class</label>
                                            {taxClasses?<Select
                                                components={{DropdownIndicator}}
                                                //@ts-ignore
                                                defaultValue={taxClasses?.map(tax => ({value: tax.id, label: tax.name}))?.find(tax => tax.value === product?.taxClass?.id)}
                                                isLoading={typeof taxClasses === "undefined"}
                                                // @ts-ignore
                                                options={taxClasses?.map(tax => ({value: tax.id, label: tax.name}))}
                                                onChange={event => {
                                                    if(event === null)
                                                        return
                                                    let newProduct = {...product}
                                                    // @ts-ignore
                                                    newProduct.taxClass = taxClasses.find(tax => tax.id === event?.value)
                                                    setProduct(newProduct as Products)
                                                }}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        background: '#f5f8fa',
                                                        borderColor: '#f5f8fa',
                                                        color: '#5e6278',
                                                        transition: 'color 0.2s ease, background-color 0.2s ease',
                                                        borderRadius: '0.3rem'
                                                    }),
                                                    valueContainer: (provided) => ({
                                                        ...provided,
                                                        minHeight: 'calc(1.5em + 1rem + 2px)',
                                                        padding: '0.5rem 1rem',
                                                        fontSize: '1rem',
                                                    })
                                                }}/>:<Skeleton width={'100%'} height={43} className='d-block' />}
                                            <span className='text-muted fs-8'>If not set no tax will be calculated</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='card card-flush py-4'>
                                    <div className='card-header border-0 py-5'>
                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>SEO</span>
                                            <span className='text-muted fw-bold fs-7'>Ranking in google will be a little easier when filled</span>
                                        </h3>
                                    </div>
                                    <div className='card-body py-3'>
                                        <MetaField metas={metas} slug={"meta_title"} label='Meta Title'
                                                   meta={product?.metaValues.find(value => value.slug === 'meta_title')} change={(e:any)=>{updateMetaField('meta_title', e)}} showIfUndefined={true}>
                                            <div className="text-muted fs-7">
                                                We advise a maximum of 60 characters, a little extra won't do any harm since Google will truncate your title if needed.
                                            </div>
                                        </MetaField>
                                        <MetaField metas={metas} slug={"meta_description"} label='Meta Description'
                                                   meta={product?.metaValues.find(value => value.slug === 'meta_description')} change={(e:any)=>{updateMetaField('meta_description',e)}} showIfUndefined={true} >
                                            <div className="text-muted fs-7">
                                                We advise a maximum of 155/160 characters.<br/>
                                                It's best to keep meta descriptions long enough that they're sufficiently descriptive, so we recommend descriptions between 50 and 160 characters.
                                            </div>
                                        </MetaField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export {ProductView}