/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {Login} from './components/Login'
import {Validate} from './components/Validate'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-attachment-fixed'
      style={{
        backgroundColor: `var(--bs-dark-blue)`,
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/finance/online-report.svg')})`,
        backgroundSize: `30vw`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logo/logo-white.svg')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://payrequest.io/' className='text-muted text-hover-primary px-2'>
            Home
          </a>

          <a href='https://payrequest.io/blog/' className='text-muted text-hover-primary px-2'>
            Blog
          </a>

          <a href='https://payrequest.io/contact' className='text-muted text-hover-primary px-2'>
            Contact
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='registration/:referral' element={<Registration />} />
      <Route path=':email/:expires/:hash' element={<Validate />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage as default}
