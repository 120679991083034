/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout, usePageData} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {MenuComponent} from "../../../assets/ts/components";

const Toolbar3: FC = () => {
  const {classes} = useLayout()
  const {pageToolbar} = usePageData()

  setTimeout(() => MenuComponent.reinitialization(), 1000)
  return (
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
            id='kt_toolbar_container'
            className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

              <div className='d-flex align-items-center py-1'>
                {/* begin::Wrapper */}
                <div className='me-4'>
                      {pageToolbar ?? <></>}
                </div>
                {/* end::Wrapper */}
              </div>
        </div>
        {/* end::Container */}
      </div>
  )
}

export {Toolbar3}
