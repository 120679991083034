/* eslint-disable jsx-a11y/anchor-is-valid */
import type { Identifier, XYCoord } from 'dnd-core'
import React, {FC, useState, useEffect, memo, useRef} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface PillProps {
    id: string
    movePill: (dragIndex: number, hoverIndex: number) => void
    index: number
    children: any[]|any
    icon: string
    link: string
    className: string
}

interface DragItem {
    index: number
    id: string
    type: string
}

export const Pill: FC<PillProps> = ({id,index, icon, link, movePill, className, children}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
        >({
        accept: "pill",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current)
                return
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex)
                return
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left
            if ((dragIndex < hoverIndex && hoverClientX < hoverMiddleX) || (dragIndex > hoverIndex && hoverClientX > hoverMiddleX))
                return
            movePill(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag,preview] = useDrag({
        type: "pill",
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? .3 : 1
    drop(preview(ref))
    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className={className}>
            {/*
            @ts-ignore */}
            <FontAwesomeIcon icon={`${['phone','mobile','envelope', 'at','globe'].includes(icon)?'fa-duotone':'fa-brands'} fa-${icon}`} className='me-2' />
            <span ref={drag} className="d-inline-block align-middle text-truncate" style={{maxWidth:'100px'}}>{link}</span>
            {children}
        </div>
    )
}