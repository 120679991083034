import React, {useEffect, useState} from 'react'
import {useLayout} from '../../core/LayoutProvider'
import {Toolbar1} from './Toolbar1'
import {ToolbarNone} from "./ToolbarNone";
import {Toolbar2} from "./Toolbar2";
import {Toolbar3} from "./Toolbar3";
import {useLocation} from "react-router-dom";

const Toolbar = () => {
  const {config} = useLayout()
  const location = useLocation()
  const [type, setType] = useState(config.toolbar.layout);
  useEffect(() => {
    setType(config.toolbar.layout)
  }, [location.key, config.toolbar.layout]);


  switch (type) {
    case 'toolbar1':
      return <Toolbar1 />

    case 'request':
      return <Toolbar2 />

    case 'buttons':
      return <Toolbar3 />

    case 'none':
      return <ToolbarNone />

    default:
      return <ToolbarNone />
  }
}

export {Toolbar}
