/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Fragment, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Crisp } from "crisp-sdk-web";
import {currencyList} from "../../../auth/core/_requests";
import {Currency} from "../../../auth";
import {languages, setLanguage, useLang} from "../../../../../_metronic/i18n/Metronici18n";
import Dropdown from "react-bootstrap/Dropdown";
import clsx from "clsx";
import Select, { components, DropdownIndicatorProps } from 'react-select'
import {Blocker, isBlocked} from "../../../widgets/components/Blocker";
import Skeleton from "react-loading-skeleton";

interface iStep1 {
    values: any
}

const Step1: FC<iStep1> = (props) => {
    const show = () => Crisp.chat?.show()
    const lang = useLang()
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [currency, setCurrency] = useState<any[]>([]);
    const [chosenCurrency, setChosenCurrency] = useState<any>();
    const currentLanguage = languages.find((x) => x.lang === lang)
    // @ts-ignore
    const setPropCurrency = (currency:string) => props.values.currency = currency.split('-')[0]
    useEffect(() => {
        currencyList().then(currencylist => {
            if(typeof currencylist !== 'boolean') {
                const newlist = currencylist.data.map(curr=>{
                    return {value:`${curr.key}`,label:(<>
                            {typeof curr.icon === "string"?<span dangerouslySetInnerHTML={{__html:curr.icon}} className='me-1 text-gray-900'></span>:(
                                <>
                                    <span dangerouslySetInnerHTML={{__html:curr.icon[0]}} className='me-1 text-gray-900' ></span>
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={`fa-duotone ${curr.icon[1]}`} className='me-1' />
                                </>
                            )}
                            {curr.text}
                        </>)};
                })
                setCurrency(newlist)
                const chosen = newlist.find(curr => curr.value===(props.values.currency ?? "EUR"))
                if(typeof chosen !== "undefined")
                    setChosenCurrency(chosen)
                setLoadingData(false)
            }
        });
        return () => {

        };
    }, []);
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className='dropdown-caret'></i>
            </components.DropdownIndicator>
        );
    };
    return (
        <div className={'w-100 py-20 px-9'}>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Onboarding Start
                    <OverlayTrigger
                        key={`step1-onboarding-1`}
                        placement='top'
                        overlay={<Tooltip id='tooltip-onboarding-stepper'>
                            Fill in the minimum details to continue and use your account.
                        </Tooltip>}
                    >
                        {/*
                        @ts-ignore */}
                        <FontAwesomeIcon icon="fa-duotone fa-circle-info" className='ms-2 fs-7' />
                    </OverlayTrigger>
                </h2>

                <div className='text-gray-400 fw-bold fs-6'>
                    If you experience any issues or need help please contact us through <a className='link-primary fw-bolder' href='#' onClick={event => { event.preventDefault(); show(); }}>our chat</a> or info@payrequest.io!
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Company Name</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='companyName'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='companyName' />
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Company Mail address</label>

                <Field
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    name='companyMail'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='companyMail' />
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Company Site</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='companySite'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='companySite' />
                </div>
            </div>

            <div className='fv-row mb-10 currency-select'>
                <label className='form-label required'>Currency</label>
                {loadingData?(<Skeleton width={"100%"} height={45} style={{marginTop:10}}/>):(
                <Select
                    //@ts-ignore
                    defaultValue={chosenCurrency}
                    options={currency}
                    isLoading={loadingData}
                    isSearchable={true}
                    components={{ DropdownIndicator }}
                    onChange={event => {
                        // @ts-ignore
                        if(event!==null&&event!==true&&event?.target!==null) {
                            // @ts-ignore
                            console.log(event?.value)
                            // @ts-ignore
                            setChosenCurrency(event?.value)
                            // @ts-ignore
                            setPropCurrency(event?.value)
                        }
                    }}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            background: '#f5f8fa',
                            borderColor: '#f5f8fa',
                            color: '#5e6278',
                            transition: 'color 0.2s ease, background-color 0.2s ease',
                            borderRadius: '0.3rem'
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            minHeight: 'calc(1.5em + 1rem + 2px)',
                            padding: '0.5rem 1rem',
                            fontSize: '1.25rem',
                        })
                    }}
                />)}

                <div className='text-danger mt-2'>
                    <ErrorMessage name='currency' />
                </div>
            </div>

            <div className='fv-row mb-10'>
                <label className='form-label required'>Language</label>

                <Dropdown key='languageSelector' autoClose="outside">
                    <Dropdown.Toggle
                        variant="light"
                        id="languageSelector"
                        className='btn btn-flex flex-center btn-lg w-100 mb-5'>
                        <img
                            className='w-15px h-15px rounded-1 me-2'
                            src={currentLanguage?.flag}
                            alt={currentLanguage?.name}
                        />
                        <span className='me-auto ms-1'>{currentLanguage?.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className='menu-item shadow-lg rounded overflow-hidden'
                    >
                        {languages.map((l) => (
                            <Dropdown.Item
                                key={l.name}
                                eventKey={l.name}
                                className={clsx('menu-link d-flex p-5', {active: l.lang === currentLanguage?.lang})}
                                onClick={() => setLanguage(l.lang)}>
                                <span className='symbol symbol-20px me-4'>
                                    <img className='rounded-1' src={l.flag} alt={l.name} />
                                </span>
                                {l.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='language' />
                </div>
            </div>
        </div>
    )
}

export {Step1}
