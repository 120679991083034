/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useState,useEffect,useCallback} from 'react'
import {useDropzone} from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import {fileUpload, UPLOAD_URLS} from "../../../../app/modules/auth/core/_requests";
import clsx from "clsx";

type Props = {
    className: string
    type: "logo"|"background"|"email"
    chunkSize?: number
    showPreview?: boolean,
    chosenImage: string|boolean
}

const DropZoneWidget: FC<Props> = ({
    className,
    type,
    chunkSize = 1048576 * 3,
    showPreview = true,
    chosenImage
}) => {
    const uploadUrl = UPLOAD_URLS[type]
    const [counter, setCounter] = useState(0);
    const [uploadFile, setUploadFile] = useState<string>("");
    const [previewImage, setPreviewImage] = useState<string>(typeof chosenImage == "boolean"?"":chosenImage);
    const [uploading, setUploading] = useState(false);
    const [chuncks, setChuncks] = useState(0);
    const [chunkStart, setChunkStart] = useState(0);
    const [chunkEnd, setChunkEnd] = useState(chunkSize);
    const [fileType, setFileType] = useState("");
    const [fileGuid, setFileGuid] = useState("");
    const [fileName, setFileName] = useState("");
    useEffect(() => {
        if(counter<chuncks) {
            setUploading(true)
            processUpload()
        }else
            setUploading(false)
    }, [counter,uploadFile]);

    const processUpload = () => {
        console.log(uploadFile)
        fileUpload(uploadUrl,fileName,fileGuid,uploadFile.slice(chunkStart,chunkEnd), fileType).then(response => {
            setChunkStart(chunkStart + chunkSize)
            setChunkEnd(chunkEnd + chunkSize)
            setCounter(counter+1)
        })
    }
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file:any) => {
            /*const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {
                if (reader.result === null || typeof reader.result === "object")
                    return;
                const binaryStr: string = reader.result
                setFileName(file.name)
                setFileGuid(uuidv4())
                setChuncks(Math.ceil(binaryStr.length / chunkSize))
                setCounter(0)
                setUploadFile(binaryStr)
            }*/
            setCounter(0)
            setFileName(file.name)
            setChunkStart(0)
            setChunkEnd(chunkSize)
            setFileGuid(uuidv4())
            setChuncks(Math.ceil(file.size / chunkSize))
            setFileType(file.type)
            setUploadFile(file)
            //setFileType(file.type)
            //reader.readAsBinaryString(file)

            if(showPreview) {
                const preview = new FileReader()
                preview.onabort = () => console.log('file reading was aborted')
                preview.onerror = () => console.log('file reading has failed')
                preview.onload = () => {
                    if (preview.result === null || typeof preview.result === "object")
                        return;
                    setPreviewImage(preview.result)
                }
                preview.readAsDataURL(file)
            }
        })

    }, [])

    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        onDrop,
        useFsAccessApi: false,
        accept: {'image/*': []}
    })
    return (
        <div className='row'>
            {showPreview && (
                typeof previewImage === "undefined" || previewImage === ''?
                    <div className="symbol col-2 g-0 me-5 shadow rounded-2 overflow-hidden">
                        <div className="symbol-label fs-3 fw-bold text-success w-100 h-100">Logo</div>
                    </div>
                    :
                <div
                className={clsx( uploading ? 'loadingimage' : 'isloaded', 'col-2 g-0')}>
                    <div className='me-8 d-flex align-items-center shadow rounded-2 overflow-hidden'>
                        <img className='img-fluid  w-100 mw-100px'
                             src={typeof previewImage !== "undefined" ? previewImage : ''}/>
                    </div>
                </div>
            )
            }
            <div {...getRootProps({className: clsx('dropzone row flex-row rounded-2 shadow',showPreview?'col-10':'')})}>
                <input {...getInputProps()} />
                { uploading ?
                    <div className='form-text' data-kt-indicator="on">
                        <span className="indicator-label">
                            Uploading...
                        </span>
                        <span className="indicator-progress">
                            Uploading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </div> :
                    ( isDragReject? <div className='form-text'>Only images are allowed</div> :
                        (isDragActive ?
                            <div className='form-text'>Drop it like it's hot...</div> :
                                ( previewImage !== ""? <div className='form-text'>That's it you can continue!</div> :
                                    <div className='form-text'>Drag 'n' drop your company logo here, or click to select files</div>) )
                    ) }
            </div>
        </div>
    );
};

export default DropZoneWidget;