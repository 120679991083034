import React, {FC, useEffect, useMemo, useState} from 'react'

import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";


const InvoicesPage = () => {

    return (
        <div>
            <div>
                <h1>Invoices Page</h1>
            </div>
        </div>
    )
  }
  
  export default InvoicesPage