import {Route, Routes, Outlet} from 'react-router-dom'
import {Overview} from "./components/Overview";
import {SubscriptionView} from "./components/SubscriptionView";

const SubscriptionsPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
          <Route
              path='overview'
              element={<Overview />}
          />
          <Route
              path=':sid'
              element={<SubscriptionView />}
          />
        <Route
            index
            element={<Overview />}
        />
      </Route>
    </Routes>
  )
}

export default SubscriptionsPage
