/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {useIntl} from "react-intl";
import {PaymentLinkStatus} from "../../auth";

type StatusProp = {
    status?: PaymentLinkStatus,
    size?: 'sm' | 'lg'
}
const RequestStatus: FC<StatusProp> = ({status, size= 'lg'}) => {
    const intl = useIntl()
    if(typeof status === "undefined")
        return <Skeleton width={"100%"} height = {60} />
    let border = 'primary'
    let title = ''
    let description = '';
    switch (status) {
        case 'PENDING':
            title = intl.formatMessage({id: 'REQUEST.STATUS.PENDING', defaultMessage: 'Pending'})
            description = intl.formatMessage({id: 'REQUEST.STATUS.PENDING_DESCRIPTION', defaultMessage: 'The request is pending and waiting for approval'})
            break;
        case 'REFUNDED':
            title = intl.formatMessage({id: 'REQUEST.STATUS.REFUNDED', defaultMessage: 'Refunded'})
            description = intl.formatMessage({id: 'REQUEST.STATUS.REFUNDED_DESCRIPTION', defaultMessage: 'The request has been refunded partially or fully'})
            border = 'info'
            break;
        case 'PAID':
            title = intl.formatMessage({id: 'REQUEST.STATUS.PAID', defaultMessage: 'Paid'})
            description = intl.formatMessage({id: 'REQUEST.STATUS.PAID_DESCRIPTION', defaultMessage: 'The request has been paid one or more times'})
            border = 'success'
            break;
        case 'CANCELLED':
            title = intl.formatMessage({id: 'REQUEST.STATUS.CANCELLED', defaultMessage: 'Cancelled'})
            description = intl.formatMessage({id: 'REQUEST.STATUS.CANCELLED_DESCRIPTION', defaultMessage: 'The request has been cancelled'})
            border = 'danger'
            break;
        case 'CAPTURE':
            title = intl.formatMessage({id: 'REQUEST.STATUS.CAPTURE', defaultMessage: 'Ready to capture'})
            description = intl.formatMessage({id: 'REQUEST.STATUS.CAPTURE_DESCRIPTION', defaultMessage: 'The request can now be captured'})
            border = 'success'
            break;
        case 'CAPTURED':
            title = intl.formatMessage({id: 'REQUEST.STATUS.CAPTURED', defaultMessage: 'Captured'})
            description = intl.formatMessage({id: 'REQUEST.STATUS.CAPTURED_DESCRIPTION', defaultMessage: 'The request has been captured partially or fully'})
            border = 'success'
            break;
        default:
            title = 'Unknown'
            description = `Unknown status "${status}"`
            border = 'danger'
            break;
    }

    if(size === 'sm'){
        return (
            <span className={`badge badge-light-${border}`}>{title}</span>
        )
    }
    return (
        <div className={`alert alert-secondary bg-white border border-${border} d-flex flex-column flex-sm-row p-5 mb-5`}>
            <div className="d-flex flex-column pe-0 pe-sm-10">
                <h5 className={`mb-1 text-${border}`}>
                    {title}
                </h5>
                <span>{description}</span>
            </div>
        </div>
    )
}

export { RequestStatus };