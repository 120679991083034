import axios from 'axios'
import {AuthModel, Products, Subscription, SubscriptionLogs, Transactions} from "../../auth";
import {CustomerOverview, VALIDATE_URL} from "../../auth/core/_requests";
import {controller, SUBSCRIPTIONS_CHARTS} from "../../dashboard/core/_requests";
import {ErrorMessage, StatusMessage} from "../../requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const SUBSCRIPTIONS_API = `${API_URL}/api/subscriptions`
export const SUBSCRIPTION_API = `${API_URL}/api/subscription`


export function getSubscriptions(): Promise<Array<Subscription> | ErrorMessage> {
    return axios.get(`${SUBSCRIPTIONS_API}`)
        .then(response => response.data as unknown as Array<Subscription>)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function tableSubscriptions(pageIndex:number,pageSize:number,localFilter:Array<any>, globalFilter:string, sortBy:Array<any>): Promise<{ results: Array<Subscription>; count: any } | ErrorMessage> {
    return axios.post(`${SUBSCRIPTIONS_API}/table`,
        {pageIndex, pageSize, localFilter, globalFilter, sortBy})
        .then(response => response.data as unknown as { results: Array<Subscription>; count: any })
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function getSubscription(id:string): Promise<Subscription | ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}`)
        .then(response => response.data as unknown as Subscription)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function getSubscriptionLogs(id:string): Promise<Array<SubscriptionLogs> | ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}/logs`)
        .then(response => response.data as unknown as Array<SubscriptionLogs>)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function getSubscriptionProduct(id:string): Promise<Products | ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}/product`)
        .then(response => response.data as unknown as Products)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function getSubscriptionPayments(id:string): Promise<Array<Transactions> | ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}/payments`)
        .then(response => response.data as unknown as Array<Transactions>)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionPause(id:string,psp:string): Promise<StatusMessage | ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}/${psp}/pause`)
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionResume(id:string,psp:string): Promise<StatusMessage | ErrorMessage> {
    return axios.get(`${SUBSCRIPTION_API}/${id}/${psp}/resume`)
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionCancel(id:string,psp:string,when:string,reason:string): Promise<StatusMessage | ErrorMessage> {
    return axios.post(`${SUBSCRIPTION_API}/${id}/${psp}/cancel`,{when,reason})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionRefund(id:string,psp:string,amount:number,reason:string): Promise<StatusMessage | ErrorMessage> {
    return axios.post(`${SUBSCRIPTION_API}/${id}/${psp}/refund`,{amount,reason})
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
