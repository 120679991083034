import axios from 'axios'
import {StatusResponse, SuccessResponse} from "../../stripe/core/_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/integrations`

export function mspCheck(apikey:string): Promise<StatusResponse|ErrorMessage> {
    return axios.post(`${INTEGRATIONS_GET}/msp/check`, {apikey})
        .then(response => response.data as unknown as StatusResponse)
        .catch(err => ({status:'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}