export interface IOnboarding {
    // onboarding
    solution_type: string | undefined
    about_business: string | undefined
    platform_type: string | undefined

    // client data
    avatar: string | undefined
    companyName: string | undefined
    companyAdres: string | undefined
    companyMail: string | undefined
    companySite: string | undefined
    emailnotifications: number
    pushnotifications: number
    CompanyLogo: string
    currency: string | undefined
    backgroundImage: string | undefined
    background1: string | undefined
    background2: string | undefined
    locale: string
    telephone: string
    Description: string | undefined
    spf: boolean | undefined
    sendexternal: boolean | undefined
    postmarkId: number | undefined
    country: string | undefined
    socials: object | undefined
}

export const initOnboarding: IOnboarding = {
    avatar: undefined,
    CompanyLogo: "",
    Description: '',
    about_business: '',
    backgroundImage: undefined,
    background1: undefined,
    background2: undefined,
    companyAdres: '',
    companyMail: '',
    companyName: '',
    companySite: '',
    country: undefined,
    currency: undefined,
    emailnotifications: 0,
    locale: "",
    telephone: "",
    platform_type: undefined,
    postmarkId: undefined,
    pushnotifications: 0,
    sendexternal: undefined,
    socials: undefined,
    solution_type: undefined,
    spf: undefined

}