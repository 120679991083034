import React, {FC} from 'react'
import clsx from "clsx";

const isBlocked = (state:boolean, classes?: string) => typeof classes !== "undefined"?clsx(classes,(!state?'':'overlay overlay-block')):(!state?'':'overlay overlay-block');

type Props = {
    state: boolean
}

const Blocker: FC<Props> = ({state}) => {

    return (
        <div className={!state?'d-none':'overlay-layer rounded bg-dark bg-opacity-5'}>
            <div
                className="spinner-border text-primary"
                role="status"
            >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export {Blocker, isBlocked}
