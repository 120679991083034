import React from "react";
import SVG from "react-inlinesvg";
import{toAbsoluteUrl} from "../../../helpers";

const mapToSvg = (path:string,svgClassName?:string) => <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
const BookingIcon = (className?:string) => mapToSvg("media/icons/payrequest/booking-pages.svg",className)
const DigitalDownloadIcon = (className?:string) => mapToSvg("media/icons/payrequest/digital-downloads.svg",className)
const DonationIcon = (className?:string) => mapToSvg("media/icons/payrequest/donation-pages.svg",className)
const InvoiceIcon = (className?:string) => mapToSvg("media/icons/payrequest/invoices.svg",className)
const PaymentLinkIcon = (className?:string) => mapToSvg("media/icons/payrequest/payment-links.svg",className)
const PaymentPageIcon = (className?:string) => mapToSvg("media/icons/payrequest/payment-page.svg",className)
const PreAuthIcon = (className?:string) => mapToSvg("media/icons/payrequest/pre-authorization.svg",className)
const SubscriptionIcon = (className?:string) => mapToSvg("media/icons/payrequest/subscriptions.svg",className)
const WebshopIcon = (className?:string) => mapToSvg("media/icons/payrequest/webshop-pages.svg",className)

export default {BookingIcon,DigitalDownloadIcon,DonationIcon,InvoiceIcon,PaymentLinkIcon,PaymentPageIcon,PreAuthIcon,SubscriptionIcon,WebshopIcon}