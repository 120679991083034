/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {StepInterface} from "../../core/_scheme";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {currencyList} from "../../../auth/core/_requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PersonalLink, Products, TaxClasses, useAuth, UserModel} from "../../../auth";
import {KTSVG, toAbsoluteUrl} from "../../../../../_metronic/helpers";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {CurrencyIcon} from "../../../currency/components/icon";
import Skeleton from "react-loading-skeleton";
import {getCustomFieldGroups} from "../../core/_requests";
import {getProducts, getProductTaxes} from "../../../products/core/_requests";
import {CurrencyFormat} from "../../../currency/components/format";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {QRCodeSVG} from "qrcode.react";
import {downloadBlob, downloadDataUri} from "../../../../../_metronic/helpers/svgDownload";
import {Canvg} from "canvg";
import {MenuComponent} from "../../../../../_metronic/assets/ts/components";
import {Gif, Grid} from "@giphy/react-components";
import IGif from "@giphy/js-types/dist/gif";
import {GiphyFetch} from "@giphy/js-fetch-api";

const linkUrl = process.env.REACT_APP_PERSONAL_URL

const Step2: FC<StepInterface> = ({data, setData, errors}) => {
    const {currentUser} = useAuth()
    const [currency, setCurrency] = useState<any[]>([])
    const [chosenCurrency, setChosenCurrency] = useState<any>();
    const [customFieldGroups, setCustomFieldGroups] = useState<any[]>();
    const [chosenFields, setChosenFields] = useState<any>();
    const [products, setProducts] = useState<any[]>();
    const [chosenProduct, setChosenProduct] = useState<any>();
    const [copied, setCopied] = useState(false);
    const [dynamicUrl, setDynamicUrl] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [taxClasses, setTaxClasses] = useState<Array<TaxClasses>>();
    const [chosenTax, setChosenTax] = useState<any>();
    const [gifSearch, setGifSearch] = useState<string>("");
    const [chosenGif, setChosenGif] = useState<IGif|undefined>(undefined);
    const gf = new GiphyFetch('3nqHFo1i8VgFtTTYZHJANlSqVcL1eK8g')
    const fetchGifs = (offset: number) => gf.search(gifSearch, { offset, limit: 10 })
    const updateData = (key:string, value:any, _data?:{[index: string]:any}) => {
        let newData;
        if(key !== 'description')
            newData = {...data}
        else
            newData = {..._data}
        newData[key] = value
        setData(newData)
    }
    useEffect(() => {
        updateData('description', description, data)
    }, [description]);
    useEffect(() => {
        if(typeof chosenGif === "undefined")
            updateData('gif', "", data)
        else
            updateData('gif', chosenGif?.id, data)
    }, [chosenGif]);


    useEffect(() => {
        if(data?.type!==4)
            return
        MenuComponent.reinitialization()
        if(typeof data?.title !== "undefined" &&
            typeof data?.price !== "undefined")
            setDynamicUrl(`${linkUrl}/${currentUser?.personalLink?.slug}/${(data?.currency??currentUser?.currency).toUpperCase()}/${data?.price}/${data?.title}`)
    }, [data]);

    useEffect(() => {
        if(typeof currentUser?.currency === "undefined")
            return
        getCustomFieldGroups().then(customFieldGroups => {
            if("error" in customFieldGroups)
                return
            const newGroups = customFieldGroups.map(group => {
                return {
                    value: group.id,
                    label: <>{group.name} <small>({group.fields} fields)</small></>
                }
            })
            const defaultChoice = {value: 0, label: <>No custom fields</>}
            newGroups.unshift(defaultChoice)
            setCustomFieldGroups(newGroups)
            setChosenFields(defaultChoice)
        })
        getProducts().then(productsData => {
            if ("error" in productsData)
                return
            setProducts(productsData.map(product=>{
                return {
                    value: product.id,
                    label: <div className="d-flex">
                        <div
                            className="symbol symbol-50px me-5">
                            <span className="symbol-label"
                                  style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/uploads/avatar/${product.metaValues.find(value => value.slug==='main_image')?.value ?? "fallback.jpg"})`}}></span>
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bolder text-hover-primary fs-6">{product.name}</span>
                            <span className="text-muted fw-bold text-muted d-block fs-7"><CurrencyFormat currency={product.currency} amount={product.price}/></span>
                        </div>
                    </div>
                }
            }))
        })
        getProductTaxes().then(taxClasses => {
            const newTaxClasses = "error" in taxClasses? [] : taxClasses
            newTaxClasses.unshift({id:0, name: 'No tax', status: true, createdAt: new Date(), updatedAt: new Date(), description:'',rates:[]} as TaxClasses)
            setTaxClasses(newTaxClasses)
        })
        currencyList().then(currencylist => {
            if(typeof currencylist !== 'boolean') {
                const newlist = currencylist.data.map(curr=>{
                    return {value:`${curr.key}`,label:(<>
                            {typeof curr.icon === "string"?<span dangerouslySetInnerHTML={{__html:curr.icon}} className='me-1 text-gray-900'></span>:(
                                <>
                                    <span dangerouslySetInnerHTML={{__html:curr.icon[0]}} className='me-1 text-gray-900' ></span>
                                    {/*
                                @ts-ignore */}
                                    <FontAwesomeIcon icon={`fa-duotone ${curr.icon[1]}`} className='me-1' />
                                </>
                            )}
                            {curr.text}
                        </>)};
                })
                //newlist.unshift({value:'default',label:<>Default currency</>})
                setCurrency(newlist)
                const chosen = newlist.find(curr => curr.value===currentUser?.currency)
                if(typeof chosen !== "undefined")
                    setChosenCurrency(chosen)
            }
        })
    }, [currentUser])
    useEffect(()=>{
        if(typeof products === "undefined" || typeof data?.product === "undefined")
            return
        const newProduct = products.find(curr=>curr.value===data.product)
        if(typeof newProduct !== "undefined")
            setChosenProduct(newProduct)
    },[data?.product])
    useEffect(()=>{
        if(typeof customFieldGroups === "undefined" || typeof data?.customfields === "undefined")
            return
        const newGroup = customFieldGroups.find(curr=>curr.value===data.customfields)
        if(typeof newGroup !== "undefined")
            setChosenFields(newGroup)
    },[data?.customfields])
    useEffect(()=>{
        if(typeof taxClasses === "undefined" || typeof data?.tax === "undefined")
            return
        const newGroup = taxClasses?.map(tax => ({value: tax.id, label: tax.name}))?.find(tax => tax.value === data?.tax)
        if(typeof newGroup !== "undefined")
            setChosenTax(newGroup)
    },[data?.tax])
    useEffect(()=>{
        if(typeof currency === "undefined" || typeof data?.currency === "undefined")
            return
        const newCurrency = currency.find(curr=>curr.value===data.currency)
        if(typeof newCurrency !== "undefined")
            setChosenCurrency(newCurrency)
    },[data?.currency])
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    const svgRef = useRef();
    const downloadSVG = useCallback(() => {
        // @ts-ignore
        const svg = svgRef.current.innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
        downloadBlob(blob, `dynamic-qrcode.svg`);
    }, [])
    const downloadPNG = useCallback(async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if(!ctx)
            return;
        let v = null
        // @ts-ignore
        v = await Canvg.fromString(ctx, svgRef.current.innerHTML)
        v.start()
        const png = canvas.toDataURL("img/png")
        downloadDataUri(png, `dynamic-qrcode.png`)
    } ,[])
    return <>
        <div className="pb-10 pb-lg-15">
            <h2 className="fw-bolder d-flex align-items-center text-dark">
                Request Information
            </h2>
            <div className="text-gray-400 fw-bold fs-6">
                General information about your request
            </div>
        </div>
        {data?.type!==6 && <div className="row g-10">
            <div className="col-12 col-lg-6">
                <div className="mb-5 fv-row non-product-group">
                    <label className="form-label mb-3 required">Request Title</label>
                    <input type="text" className="form-control form-control-lg form-control-solid" aria-required={false}
                           value={data?.title ?? ''} onChange={event=>{updateData('title',event?.target?.value)}}/>
                    {errors?.title && <span className="form-text text-danger">{errors?.title}</span>}
                </div>
            </div>
            <div className="col-6 col-lg-3">
                <div className="mb-5 fv-row non-product-group">
                    <label className="form-label mb-3 required">Currency</label>
                    {typeof chosenCurrency === "undefined"?<Skeleton width={'100%'} height={46}/>:
                    <Select
                        //@ts-ignore
                        defaultValue={chosenCurrency}
                        options={currency}
                        isLoading={typeof chosenCurrency === "undefined"}
                        isSearchable={true}
                        components={{ DropdownIndicator }}
                        //@ts-ignore
                        onChange={event => { updateData('currency',event?.value) }}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                background: '#f5f8fa',
                                borderColor: '#f5f8fa',
                                color: '#5e6278',
                                transition: 'color 0.2s ease, background-color 0.2s ease',
                                borderRadius: '0.3rem'
                            }),
                            valueContainer: (provided) => ({
                                ...provided,
                                minHeight: 'calc(1.5em + 1rem + 2px)',
                                padding: '0.5rem 1rem',
                                fontSize: '1.25rem',
                            })
                        }}
                    />}
                </div>
            </div>
            <div className="col-6 col-lg-3">
                <div className="mb-5 fv-row non-product-group">
                    <label className="form-label mb-3 required">Price</label>
                    <div className="input-group input-group-lg input-group-solid">
                        <div className="input-group-prepend">
                            <span className="input-group-text h-100">
                                <CurrencyIcon
                                    currency={chosenCurrency?.value === 'default' ? currentUser?.currency : chosenCurrency?.value}/>
                            </span>
                        </div>
                        <input type="number" className="form-control form-control-lg form-control-solid d-inline-flex ps-0" aria-required={false}
                               min={1} step={0.01}
                               value={data?.price ?? ''} onChange={event=>{updateData('price',event?.target?.value)}}/>
                    </div>
                    {errors?.price && <span className="form-text text-danger">{errors?.price}</span>}
                </div>
            </div>
        </div>}
        {data?.type===6 &&  <div className="mb-5 fv-row non-product-group">
            <label className="form-label mb-3 required">Product</label>
            {typeof products === "undefined"?<Skeleton width={'100%'} height={46}/>:
                <Select
                    //@ts-ignore
                    defaultValue={chosenProduct}
                    options={products}
                    isLoading={typeof products === "undefined"}
                    isSearchable={true}
                    components={{ DropdownIndicator }}
                    //@ts-ignore
                    onChange={event => { updateData('product',event?.value) }}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            background: '#f5f8fa',
                            borderColor: '#f5f8fa',
                            color: '#5e6278',
                            transition: 'color 0.2s ease, background-color 0.2s ease',
                            borderRadius: '0.3rem'
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            minHeight: 'calc(1.5em + 1rem + 2px)',
                            padding: '0.5rem 1rem',
                            fontSize: '1.25rem',
                        })
                    }}
                />}
            <span className='form-text text-muted'>Need to add a new product? Create it here.</span>
            {errors?.product && <span className="form-text text-danger">{errors?.product}</span>}
        </div>}
        {data.type!==4 && <div className="mb-5 fv-row non-product-group">
            <label className="form-label mb-3">Request Description</label>
            <TextEditor className='tiptapSmall' save={(description:string) => { setDescription( description ) }} liveSave={true} content={data?.description ?? ''} />
            {errors?.description && <span className="form-text text-danger">{errors?.description}</span>}
        </div>}
        {data.type!==4 &&
            <div className="row g-10">
                <div className="col-12 col-lg-4">
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3">Extra Fields</label>
                        {typeof chosenFields === "undefined"?<Skeleton width={'100%'} height={46}/>:
                            <Select
                                //@ts-ignore
                                defaultValue={chosenFields}
                                options={customFieldGroups}
                                isLoading={typeof chosenFields === "undefined"}
                                isSearchable={true}
                                components={{ DropdownIndicator }}
                                //@ts-ignore
                                onChange={event => { updateData('customfields',event?.value) }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        background: '#f5f8fa',
                                        borderColor: '#f5f8fa',
                                        color: '#5e6278',
                                        transition: 'color 0.2s ease, background-color 0.2s ease',
                                        borderRadius: '0.3rem'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        minHeight: 'calc(1.5em + 1rem + 2px)',
                                        padding: '0.5rem 1rem',
                                        fontSize: '1.25rem',
                                    })
                                }}
                            />}
                        <span className='form-text text-muted'>Need a certain group of fields? Create them here.</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label">Tax class</label>
                        {taxClasses?<Select
                            components={{DropdownIndicator}}
                            //@ts-ignore
                            defaultValue={chosenTax}
                            isLoading={typeof taxClasses === "undefined"}
                            // @ts-ignore
                            options={taxClasses?.map(tax => ({value: tax.id, label: tax.name}))}
                            onChange={event => {
                                if(event === null)
                                    return
                                // @ts-ignore
                                updateData('tax',event?.value)
                            }}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    background: '#f5f8fa',
                                    borderColor: '#f5f8fa',
                                    color: '#5e6278',
                                    transition: 'color 0.2s ease, background-color 0.2s ease',
                                    borderRadius: '0.3rem'
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    minHeight: 'calc(1.5em + 1rem + 2px)',
                                    padding: '0.5rem 1rem',
                                    fontSize: '1rem',
                                })
                            }}/>:<Skeleton width={'100%'} height={43} className='d-block' />}
                        <span className='text-muted fs-8'>If not set no tax will be calculated</span>
                    </div>
                </div>
                <div className="col-10 col-lg-3">
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3 required">Additional Fee</label>
                        <div className="input-group input-group-lg input-group-solid">
                            <div className="input-group-prepend">
                            <span className="input-group-text h-100">
                                <CurrencyIcon
                                    currency={chosenCurrency?.value === 'default' ? currentUser?.currency : chosenCurrency?.value}/>
                            </span>
                            </div>
                            <input type="number" className="form-control form-control-lg form-control-solid d-inline-flex ps-0" aria-required={false}
                                   min={0} step={0.01}
                                   value={data?.fee ?? '0'} onChange={event=>{updateData('fee',event?.target?.value)}}/>
                        </div>
                        {errors?.fee && <span className="form-text text-danger">{errors?.fee}</span>}
                    </div>
                </div>
                <div className="col-2 col-lg-1">
                    <div className="mb-5 fv-row non-product-group">
                        <label className="form-label mb-3">&nbsp;</label>
                        <select
                            name='feeType'
                            style={{fontSize:'1rem',lineHeight:'2.1rem'}}
                            data-control='select2'
                            data-hide-search='true'
                            onChange={e=>{updateData('feeType',e?.target?.value)}}
                            className='form-select form-select-solid form-select-lg'
                            defaultValue={data?.feeType ?? 'Fixed'}
                        >
                            <option value='Fixed'>Fixed</option>
                            <option value='Percentage'>Percentage</option>
                        </select>
                    </div>
                </div>
            </div>}
        {data.type===4 && <div className="row g-10">
            <div className="col-12 col-lg-6">
                <div className="mb-5 fv-row non-product-group">
                    <label className="form-label mb-3 required">Your dynamic url</label>
                    <div className="d-flex">
                        <input type="text" className="form-control form-control-lg form-control-solid me-3 flex-grow-1" aria-required={true}
                               value={dynamicUrl} />
                        <CopyToClipboard text={dynamicUrl??''} onCopy={() => setCopied(true)}>
                            <button className="btn btn-light fw-bold flex-shrink-0 highlight-copy" onClick={e=>e.preventDefault()}>
                                {copied?'Copied':'Copy Link'}
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="mb-5 fv-row non-product-group">
                    <label className="form-label mb-3 required">Your QR code</label>
                    {/* @ts-ignore */}
                    <div ref={svgRef}>
                        {dynamicUrl?<QRCodeSVG fgColor={'#1954bf'} value={dynamicUrl} size={200} />:<Skeleton width={200} height={200} />}
                    </div>
                    <div className="mb-10">
                        <button
                            type='button'
                            className='btn btn-light-primary mt-4 btn-sm'
                            id="svgDownloadButton"
                            data-kt-menu-trigger='hover'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='bottom'
                        >
                            {/*
                                                @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-download" className="me-2" />
                            Download QR Code
                        </button>

                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-active-bg fw-semibold py-3" data-kt-menu="true" style={{maxWidth: 200}}>
                            <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                <a href="javascript:void(0);" onClick={downloadSVG}
                                   className="menu-link px-3">
                                    <span className="menu-title">SVG</span>
                                </a>
                            </div>
                            <div className="menu-item px-3 text-hover-inverse-light bg-hover-light">
                                <a href="javascript:void(0);" onClick={downloadPNG}
                                   className="menu-link px-3">
                                    <span className="menu-title">PNG</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        <div className="row g-10">
            <div className="col-12 col-md-6 form-group">
                <label className="form-label text-start mt-10">Success GIF</label>
                <div className='form-text'>
                    Select a GIF that you want to show when a user has paid.
                </div>
                <input type="text" className={`form-control form-control-solid`}
                       placeholder="What kind of gif do you want?" value={gifSearch}
                       onChange={e=>setGifSearch(e?.target?.value ?? "")} />
                <div>
                    <img
                        alt='Giphy'
                        src={toAbsoluteUrl('/media/giphy.png')}
                        className='w-200px mt-2'
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 form-group d-flex flex-column">
                {chosenGif && (<>
                    <label className="form-label text-start mt-10" style={{marginLeft: 'auto',width:'200px'}}>Your current GIF</label>
                    <Gif gif={chosenGif} width={200} style={{marginLeft: 'auto'}} />
                    <button className="btn btn-danger btn-sm mt-1" onClick={e=>{setChosenGif(undefined);}} style={{marginLeft: 'auto',width:'200px'}}>Remove GIF</button>
                </>)}
            </div>
        </div>
        <div className={"d-flex mt-5 justify-content-center"}>
            {gifSearch.length>0&&(
                <Grid width={800} columns={3} gutter={6} fetchGifs={fetchGifs} key={gifSearch} onGifClick={(gif,e)=>{
                    e.preventDefault();
                    setChosenGif(gif)
                    setGifSearch("")
                }} />
            )}
        </div>
    </>
}

export {Step2}
