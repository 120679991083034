import axios from 'axios'
import {AuthModel, Currency, ISearchResults, PersonalLink, Transactions, UserModel} from './_models'
import {AuthErrorMessage, ErrorMessage, StatusMessage} from "../../requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const GET_HEADS = `${API_URL}/api/customerheads`
export const GET_CUSTOMERLIST = `${API_URL}/api/customerlist`
export const GET_CUSTOMEROVERVIEW = `${API_URL}/api/customeroverview`
export const GET_PAYMENTSTATUSCOUNT = `${API_URL}/api/paymentstatuscount`
export const GET_PAYRATE = `${API_URL}/api/payrate`
export const REFRESH_TOKEN = `${API_URL}/token/refresh`
export const LOGIN_URL = `${API_URL}/magic-link`
export const ONETAP_URL = `${API_URL}/one-tap`
export const VALIDATE_URL = `${API_URL}/validate`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const UPDATE_URL = `${API_URL}/update/user`
export const UPDATE_COMPLETE_USER = `${API_URL}/api/user/save`
export const USER_TESTMODE = `${API_URL}/dashboard/settings/settestmode`
export const SEARCH_API = `${API_URL}/api/search`
export const CURRENCY_LIST = `${API_URL}/api/currencylist`
export const POSTMARK_CHECK = `${API_URL}/api/verify/postmark`
export const POSTMARK_GET = `${API_URL}/api/get/postmark`
export const DELETE_ACCOUNT = `${API_URL}/api/delete/account`
export const UPLOAD_URLS = {
  logo: `${API_URL}/api/logoupload`,
  background: `${API_URL}/api/backgroundupload`,
  email: `${API_URL}/api/emailupload`,
  content: `${API_URL}/api/contentupload`
}
export const DESCRIPTION_SAVE = `${API_URL}/api/savedescription`
export const SLUG_CHECK = `${API_URL}/api/check/slug`

// Server should return bool
export function login(email: string) {
  return axios.post(LOGIN_URL, {
    email
  })
}

export function oneTapLogin(email: string,exp:number,kid:string,sub:string,name:string,picture:string): Promise<StatusMessage|ErrorMessage> {
  return axios.post(ONETAP_URL, {
    email, exp, kid, sub, name, picture
  }).then(response => response.data as unknown as StatusMessage)
    .catch(err => ({status:'error', error: err?.response?.status}) as ErrorMessage)
}

// Server should return bool
export function register(
  email: string,
  firstname: string,
  lastname: string,
  referral?: string
) {
  let data;
  if(typeof referral !== 'undefined'){
    data = {
      email,
      fullname: firstname+" "+lastname,
      referral: referral
    };
  }else{
    data = {
      email,
      fullname: firstname+" "+lastname
    };
  }
  return axios.post(REGISTER_URL, data)
}

// Server should return AuthModel
export function validate(email: string, expires: number, hash: string) {
  return axios.get<AuthModel>(VALIDATE_URL+'?user='+email+'&expires='+expires+'&hash='+hash).then(response => response).catch(err => false)
}

export function currencyList() {
  return axios.get<Array<Currency>>(CURRENCY_LIST).then(response => response).catch(err => false)
}
type PayRate = {
  count: number
  paid: number
}
export function getPayRate():Promise<PayRate|ErrorMessage> {
  return axios.get(GET_PAYRATE)
      .then(response => response.data as unknown as PayRate)
      .catch(err => ({status:'error', error: err?.response?.error}) as ErrorMessage)
}
type StatusCount = {
  normal: number,
  preauth: number,
  donation: number,
  product: number,
  count: number
}
export function getStatusCount():Promise<StatusCount|ErrorMessage> {
  return axios.get(GET_PAYMENTSTATUSCOUNT)
      .then(response => response.data as unknown as StatusCount)
      .catch(err => ({status:'error', error: err?.response?.error}) as ErrorMessage)
}
type HeadsProps = {
  heads: Array<{
    id: number,
    name: string,
    avatar: string
  }>
  count: number
}
export function getHeads():Promise<HeadsProps|ErrorMessage> {
  return axios.get(GET_HEADS)
      .then(response => response.data as unknown as HeadsProps)
      .catch(err => ({status:'error', error: err?.response?.error}) as ErrorMessage)
}
type CustomerList = {
  id: number,
  name: string,
  email: string,
  phone: string
}
export function getCustomerList():Promise<Array<CustomerList>|ErrorMessage> {
  return axios.get(GET_CUSTOMERLIST)
      .then(response => response.data as unknown as Array<CustomerList>)
      .catch(err => ({status:'error', error: err?.response?.error}) as ErrorMessage)
}
export type CustomerOverview = {
  id: number,
  name: string,
  email: string,
  phone: string,
  createdAt: Date,
  paid: number,
  total: number,
}
export function getCustomerOverview():Promise<Array<CustomerOverview>|ErrorMessage> {
  return axios.get(GET_CUSTOMEROVERVIEW)
      .then(response => response.data as unknown as Array<CustomerOverview>)
      .catch(err => ({status:'error', error: err?.response?.error}) as ErrorMessage)
}
export function tableCustomerOverview(pageIndex:number,pageSize:number,localFilter:Array<any>, globalFilter:string, sortBy:Array<any>) :Promise<{ results: Array<CustomerOverview>; count: any }|ErrorMessage> {
  return axios.post(`${GET_CUSTOMEROVERVIEW}/table`,
  {pageIndex, pageSize, localFilter, globalFilter, sortBy})
      .then(response => response.data as unknown as { results: Array<CustomerOverview>; count: any })
      .catch(err => ({status:'error', error: err?.response?.error}) as ErrorMessage)
}
type PostmarkCheck = {resend:boolean|null,allowed:boolean}

export function checkPostmark() :Promise<PostmarkCheck|ErrorMessage> {
  return axios.get(POSTMARK_CHECK)
      .then(response => response.data as PostmarkCheck)
      .catch(err => ({status:'error', error: 'unknown'} as ErrorMessage))
}
type PostmarkGet = {resend:boolean|null}
export function getPostmark(type:string) :Promise<PostmarkGet|ErrorMessage> {
  return axios.post(POSTMARK_GET,{type})
      .then(response => response.data as unknown as PostmarkGet)
      .catch(err => ({status:'error', error: 'unknown'} as ErrorMessage))
}

export function refreshToken(auth: AuthModel): Promise<AuthModel|AuthErrorMessage>{
  return axios.post(REFRESH_TOKEN, {
    refresh_token: auth.refresh_token
  }).then(response => response.data as unknown as AuthModel).catch(err => ({status:'error', error: err?.response?.status} as AuthErrorMessage))
}

export function getUserByToken(auth: AuthModel): Promise<UserModel|boolean> {
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {  }).then(response => response.data as unknown as UserModel).catch(err => false);
}

export function updateTestmode(testmode: boolean) {
  return axios.post(USER_TESTMODE,{ testmode: testmode }).then(response => response).catch(err => false)
}

export function updateUserField(field: string, value: string) {
  return axios.post(UPDATE_URL,{ field,value }).then(response => response).catch(err => false)
}

export function updateUser(user: UserModel): Promise<UserModel|ErrorMessage> {
  return axios.post(UPDATE_COMPLETE_USER,user).then(response => response.data as unknown as UserModel)
      .catch(err => ({status:'error', error: err?.response?.data?.error ?? "unknown"} as ErrorMessage))
}

export function sendSearch(search: string, preferences: string[]): Promise<ISearchResults|boolean> {
  return axios.post(SEARCH_API, {
    search: search,
    preferences: JSON.stringify(preferences)
  }).then(response => {
        if(typeof response.data !== "boolean" && "transactions" in response.data) {
          // @ts-ignore
          response.data.transactions.map(transaction => {
            transaction.createdAt = new Date(transaction.createdAt);
            return transaction as unknown as ISearchResults;
          });
        }
        return response.data as unknown as boolean
      }).catch(err => false);
}

type fileUploadType = {url:string}

export function fileUpload(url: string, filename: string, token: string, chunck: string, type: string): Promise<fileUploadType|boolean> {
  return axios.post(url, chunck, {
    params: {
      token: token,
      imageName: filename
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response.data as unknown as fileUploadType).catch(err => false)
}

export function fileUploadString(url: string, filename: string, token: string, chunck: string, type: string, nourl:number = 1): Promise<fileUploadType|boolean> {
  return axios.post(url, chunck, {
    params: {
      token: token,
      imageName: filename,
      nourl: nourl
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response.data as unknown as fileUploadType).catch(err => false)
}

export function saveDescription(description:string) {
  return axios.post(UPDATE_URL, {
    field: "description", value: description
  }).then(response => response).catch(err => false)
}

export function deleteAccount(): Promise<StatusMessage|ErrorMessage> {
    return axios.get(DELETE_ACCOUNT)
        .then(response => response.data as unknown as StatusMessage)
        .catch(err => ({status:'error', error: 'unknown'} as ErrorMessage))
}


export function checkSlug(slug:string, controller: AbortController): Promise<PersonalLink|ErrorMessage> {
  //slugProcess.abort()

  return axios.post(SLUG_CHECK,{slug},{signal: controller.signal})
      .then(response => response.data as unknown as PersonalLink)
      .catch(err => ({status:'error', error: err?.response?.data?.error ?? 'Unkown'} as ErrorMessage))
}