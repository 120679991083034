import axios from 'axios'
import {Customers, PaymentLinks, Transactions} from "../../auth";
import {ErrorMessage, PaymentLink, StatusMessage} from "../../requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const CUSTOMER_API = `${API_URL}/api/customer`
export const CUSTOMER_API_SAVE = `${API_URL}/api/customer/save`

export function getCustomer(customerId:string): Promise<Customers|boolean> {
    return axios.get(`${CUSTOMER_API}/${customerId}`)
        .then(response => response.data ?? false)
        .catch(err => false)
}
export function getCustomerTransactions(customerId:string): Promise<Array<Transactions>|ErrorMessage> {
    return axios.get(`${CUSTOMER_API}/transactions/${customerId}`)
        .then(response => response.data as unknown as Array<Transactions>)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}
export function getCustomerRequests(customerId:string): Promise<Array<PaymentLink>|ErrorMessage> {
    return axios.get(`${CUSTOMER_API}/transactions/${customerId}`)
        .then(response => response.data as unknown as Array<PaymentLink>)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function saveCustomer(customer:object): Promise<ErrorMessage|StatusMessage> {
    return axios.post(`${CUSTOMER_API_SAVE}`, customer)
        .then(response => response.data as StatusMessage)
        .catch(err => err.response.data as ErrorMessage)
}