/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {/*getUserByToken,*/ login, oneTapLogin} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Languages} from '../../../../_metronic/partials/content/dropdown/Languages'
import {useAuth} from '../core/Auth'
import { defineMessages,useIntl,FormattedMessage } from 'react-intl'
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function Login() {
  const [sending, setSending] = useState(false)
  const [response, setResponse] = useState(false)
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate()
  const [error, setError] = useState<string>();
  const {loading, setLoading, saveAuth/*, setCurrentUser*/} = useAuth()
  const intl = useIntl();
  const translatable = defineMessages({
    incorrect: {
      id: 'AUTH.INCORRECT',
      defaultMessage: 'Your mail address is unknown',
    },
    wait: {
      id: 'AUTH.WAIT',
      defaultMessage: 'Please wait 1 minute before resending',
    },
    format: {
      id: 'AUTH.FORMAT',
      defaultMessage: 'Wrong email format',
    },
    xsymbols: {
      id: 'AUTH.XSYMBOLS',
      defaultMessage: '{type} {num} symbols',
    },
    emailrequired: {
      id: 'AUTH.EMAILREQUIRED',
      defaultMessage: 'Email is required',
    }
  });
  const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email(intl.formatMessage(translatable.format))
        .min(3, intl.formatMessage(translatable.xsymbols, {type: 'Minimal', num: 3}))
        .max(50, intl.formatMessage(translatable.xsymbols, {type: 'Maximum', num: 50}))
        .required(intl.formatMessage(translatable.emailrequired)),
  });
  const initialValues = {
    email: ''
  };
  useGoogleOneTapLogin({
    onError: error => {
      console.error(error)
      setSending(false)
    },
    onSuccess: response => {
      //@ts-ignore
      oneTapLogin(response.email,parseInt(response.exp),response.kid,response.sub,response.name??'',response.picture??'').then(auth => {
        if("error" in auth){
          console.error(auth.error)
          setError(auth.error)
          setSending(false)
        }else
          navigate(`/auth/${auth?.url}`)
      })
    },
    googleAccountConfigs: {
      client_id: '233209603773-kb68n419k6255511stgoilkrsm9e4hfg.apps.googleusercontent.com'
    },
  });
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSending(true)
      try {
        const {data: success} = await login(values.email)
        saveAuth(undefined)
        setSending(false)
        setSubmitting(false)
        if(success){
          setResponse(true);
        }else{
          setStatus(intl.formatMessage(translatable.incorrect))
        }
        //saveAuth(auth)
        //const {data: user} = await getUserByToken(auth.api_token)
        //setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage(translatable.incorrect))
        setSubmitting(false)
        setSending(false)
      }
    },
  })

  useEffect(() => {
    document.title = "PayRequest.io | Login"
    return () => {
    }
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage
              id="AUTH.SIGNIN"
              defaultMessage="Sign In to PayRequest"
          />
        </h1>
        <div className='text-gray-400 fw-bold fs-4'>
          <FormattedMessage
              id="AUTH.NEW"
              defaultMessage="New here?"
          />{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            <FormattedMessage
                id="AUTH.CREATE"
                defaultMessage="Create an Account!"
            />
          </Link>
        </div>
      </div>
      {/* begin::Heading */}
      {response?(
          <div className='text-center'>
            <h1 className="text-dark mb-3">
              <img alt="Logo"
                   src={toAbsoluteUrl('/media/icons/communication/mail-notification.svg')}
                   className="me-1"/>
              <FormattedMessage
                  id="AUTH.MAIL"
                  defaultMessage="You've got mail!"
              />
            </h1>
            <div className="text-gray-400 fw-bold fs-4">
              <FormattedMessage
                id="AUTH.CHECKMAIL"
                defaultMessage="Please open your mail client and click on the magic login link we sent to you"
                />
              <br/>
              <button type="submit"
                      id="kt_resend_submit"
                      className="btn btn-lg btn-active-light-primary btn-text-primary"
              onClick={(e) => {
                e.preventDefault();
                if(waiting) {
                  /*setError("")
                  setError(intl.formatMessage(translatable.wait))
                  console.log(intl.formatMessage(translatable.wait))*/
                  return;
                }
                setWaiting(true);
                setTimeout(() => {
                    setWaiting(false);
                }, 60000);
                formik.submitForm()
              }}>
                {!sending && <span className='indicator-label'>
                  <FormattedMessage
                      id="AUTH.RESEND"
                      defaultMessage="Resend Link"
                  />
                </span>}
                {sending && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                  <FormattedMessage
                      id="GENERAL.WAIT"
                      defaultMessage="Please wait..."
                  />
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
              </button>
              {waiting&&<div className="alert alert-dismissible bg-warning d-flex flex-column flex-sm-row p-5 mb-10 mt-5">
                {/*
            @ts-ignore */}
                <FontAwesomeIcon icon="fa-duotone fa-square-info" className={'text-light fs-2hx me-4 mb-5 mb-sm-0'} />
                <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                  <h5 className="mb-1 text-white-50">Information!</h5>
                  <span>{intl.formatMessage(translatable.wait)}</span>
                </div>

                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                  {/*
              @ts-ignore */}
                  <FontAwesomeIcon icon="fa-duotone fa-xmark" className={'fs-2x text-light'} />
                </button>
              </div>}
            </div>
          </div>
      ):(
        <>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : ''}
          {typeof error !== "undefined"&&error!==""&&<div className="alert alert-dismissible bg-danger d-flex flex-column flex-sm-row p-5 mb-10">
            {/*
            @ts-ignore */}
            <FontAwesomeIcon icon="fa-duotone fa-triangle-exclamation" className={'text-light fs-2hx me-4 mb-5 mb-sm-0'} />
            <div className="d-flex flex-column text-light pe-0 pe-sm-10">
              <h5 className="mb-1 text-white-50">Error!</h5>
              <span>{error}</span>
            </div>

            <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
              {/*
              @ts-ignore */}
              <FontAwesomeIcon icon="fa-duotone fa-xmark" className={'fs-2x text-light'} />
            </button>
          </div>}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <FormattedMessage
                  id="GLOBAL.EMAIL"
                  defaultMessage="Email"
              />
            </label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!sending && <span className='indicator-label'><FormattedMessage
                      id="GENERAL.CONTINUE"
                      defaultMessage="Continue"
                  /></span>}
                  {sending && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                  <FormattedMessage
                      id="GENERAL.WAIT"
                      defaultMessage="Please wait..."
                  />
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                  )}
                </button>
          </div>
          {/* end::Action */}
        </>
      )}
      <div className='text-center'>
        {/* begin::Separator */}
        <div className='text-center text-muted text-uppercase fw-bolder mb-5'>
          <FormattedMessage
              id="GENERAL.OR"
              defaultMessage="or"
          />
        </div>
        {/* end::Separator */}

        {/* begin::Google link */}
        <a href={(process.env.REACT_APP_API_URL)+'/connect/google'} className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Google'
            src={toAbsoluteUrl('/media/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          <FormattedMessage
              id="GENERAL.CONTINUEWITH"
              defaultMessage="Continue with {subject}"
              values={{subject: 'Google'}}
          />
        </a>
        {/* end::Google link */}
      </div>
      {/* end::Action */}

      {/* begin::Language Selector */}
      <div className='separator my-5'></div>

      <Languages />
      {/* end::Language Selector */}
    </form>
  )
}
