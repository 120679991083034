import {Route, Routes, Outlet} from 'react-router-dom'
import Overview from "./components/Overview";
import ThankYou from "./components/ThankYou";
import OhNo from "./components/OhNo";
import SubscriptionsPage from "../subscriptions/SubscriptionsPage";
import {SubscriptionView} from "../subscriptions/components/SubscriptionView";

const ProPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='setup'
                    element={<Overview />}
                />
                <Route
                    path='thankyou'
                    element={<ThankYou />}
                />
                <Route
                    path='ohno'
                    element={<OhNo />}
                />
                <Route
                    path=':sid'
                    element={<SubscriptionView />}
                />
                <Route
                    index
                    element={<Overview />}
                />
            </Route>
        </Routes>
    )
}

export default ProPage
