/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Blocker, isBlocked} from "../../../../app/modules/widgets/components/Blocker";
import {PaymentLinkTypes, useAuth} from "../../../../app/modules/auth";
import {Chart, ChartConfiguration} from 'chart.js'
import {getCSSVariableValue} from "../../../assets/ts/_utils";
import {getStatusCount} from "../../../../app/modules/auth/core/_requests";

type Props = {
  className: string
  image: string
  title: string
}

const PaymentLinksDonut: React.FC<Props> = ({className, image, title}) => {
  const intl = useIntl()
    const {currentUser} = useAuth()
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [killSwitch, setKillSwitch] = useState(false);
    const [count, setCount] = useState<number>(0);

    const getChartOptions = (normal:number,preauth:number,donation:number,product:number) => {
        const tooltipBgColor = getCSSVariableValue('--bs-gray-200')
        const tooltipColor = getCSSVariableValue('--bs-gray-800')

        const color1 = getCSSVariableValue('--bs-success')
        const color2 = getCSSVariableValue('--bs-warning')
        const color3 = getCSSVariableValue('--bs-primary')
        const color4 = getCSSVariableValue('--bs-active-danger')

        const options: ChartConfiguration = {
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: [
                            normal,
                            preauth,
                            donation,
                            product,
                        ],
                        backgroundColor: [color1, color2, color3, color4],
                    },
                ],
                labels: [
                    intl.formatMessage({id: 'REQUESTS.NORMAL', defaultMessage: 'Normal requests'}),
                    intl.formatMessage({id: 'REQUESTS.PREAUTH', defaultMessage: 'Pre-authorization requests'}),
                    intl.formatMessage({id: 'REQUESTS.DONATIONS', defaultMessage: 'Donation requests'}),
                    intl.formatMessage({id: 'REQUESTS.PRODUCTS', defaultMessage: 'Product requests'}),
                ],
            },
            options: {
                cutoutPercentage: 75,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                title: {
                    display: false,
                    text: '',
                },
                animation: {
                    /* @ts-ignore */
                    animateScale: true,
                    animateRotate: true,
                },
                tooltips: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    bodySpacing: 5,
                    yPadding: 10,
                    xPadding: 10,
                    caretPadding: 0,
                    displayColors: false,
                    backgroundColor: tooltipBgColor,
                    bodyFontColor: tooltipColor,
                    cornerRadius: 4,
                    footerSpacing: 0,
                    titleSpacing: 0,
                },
            },
        }
        return options
    };

    useEffect(() => {
        const element = document.getElementById('paymentLinkDonut') as HTMLCanvasElement
        if (!element||typeof currentUser === "undefined") {
            return
        }
        let myDoughnut: Chart | null
        getStatusCount().then((response) => {
            if(killSwitch)
                return
            if("error" in response){
                console.log(response.error)
                setLoadingData(false)
                return
            }
            setCount(response.count)
            const options = getChartOptions(response.normal,response.preauth,response.donation,response.product)
            const ctx = element.getContext('2d')
            if (ctx) {
                myDoughnut = new Chart(ctx, options)
                setLoadingData(false)
            }
        }).catch((error) => {
            console.log(error)
            setLoadingData(false)
        })
        return function cleanUp() {
            if (myDoughnut) {
                myDoughnut.destroy()
                setKillSwitch(true)
            }
        }
    }, [currentUser]);

  return (
      <div
          className={isBlocked(loadingData, `card bgi-no-repeat ${className}`)}
          style={{
            backgroundPosition: 'right top',
            backgroundSize: '45% auto',
            backgroundImage: `url(${toAbsoluteUrl('/media/shapes/' + image)})`,
          }}
      >
        {/* begin::Body */}
        <div className='card-body d-flex flex-column'>
          <div className="fs-2hx fw-bolder">{count}</div>
          <div className="fs-4 fw-bold text-gray-400 mb-7">{title}</div>
            <div className='h-400px mb-5'>
            <canvas id="paymentLinkDonut"></canvas>
            </div>
          <div className="d-flex mt-auto">
            <Link to="/request/" className="btn btn-primary btn-sm me-3">
              {intl.formatMessage({id: 'REQUESTS.ALL', defaultMessage: 'All requests'})}
            </Link>
            <Link to="/request/new/" className="btn btn-light btn-sm">
              {intl.formatMessage({id: 'REQUESTS.NEW', defaultMessage: 'Create new request'})}
            </Link>
          </div>
          <Blocker state={loadingData}/>
        </div>
        {/* end::Body */}
      </div>
  )
}

export {PaymentLinksDonut}
