import React, {FC, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {useAuth} from "../../auth";
import {Blocker, isBlocked} from "../../widgets/components/Blocker";
import {getAllTransactions, tableTransactions} from "../../dashboard/core/_requests";
import {Link, useLocation} from "react-router-dom";
import {CurrencyFormat} from "../../currency/components/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Flatpickr from "react-flatpickr";
import {endOfMonth, endOfYear, startOfMonth, startOfYear, sub} from 'date-fns'
import { CSVLink } from "react-csv";
import {useReactToPrint} from 'react-to-print';
import ReactDOM from "react-dom";
import {PrTable, IlocalFilter} from "../../../../_metronic/partials/widgets/tables/PrTable";
import {Transactions} from "../../../../_metronic/partials/widgets/tables/content/transactions";
import {Transactions as TransactionsModel} from "../../auth/core/_models";
import Skeleton from "react-loading-skeleton";

const transactionsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Overview: FC = () => {
    const intl = useIntl();
    const {currentUser} = useAuth()
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [exportData, setExportData] = useState<Array<any>>([]);
    const [print, setPrint] = useState(false);
    const [csv, setCsv] = useState(false);
    //const [transactions, setTransactions] = useState<Array<any>>([]);
    const transactionType = (type?:string) => {
        switch (type) {
            case 'DONATION':
                return intl.formatMessage({id: 'REQUESTS.DONATIONSINGLE', defaultMessage: 'Donation'})
            case 'PREAUTH':
                return intl.formatMessage({id: 'REQUESTS.PREAUTHSINGLE', defaultMessage: 'Pre-authorisation'})
            case 'PRODUCT':
                return intl.formatMessage({id: 'REQUESTS.PRODUCTSINGLE', defaultMessage: 'Product'})
            case 'PERSONAL':
                return intl.formatMessage({id: 'REQUESTS.PERSONALSINGLE', defaultMessage: 'Personal'})
            default:
                return intl.formatMessage({id: 'REQUESTS.NORMALSINGLE', defaultMessage: 'Normal'})
        }
    }
    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: Date = new Date(a.original[columnId].props.children[0].props['data-filter']);
            const bv: Date = new Date(b.original[columnId].props.children[0].props['data-filter']);
            return av > bv ? -1 : (bv > av ? 1 : 0);
        };
    },[]);
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(a.original[columnId].props.children[0].props['data-filter']);
            const bv: number = parseFloat(b.original[columnId].props.children[0].props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.CUSTOMER', defaultMessage: 'Customer'}),
            accessor: 'customer',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.AMOUNT', defaultMessage: 'Amount'}),
            accessor: 'amount',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            sortType: sortPrice
        },{
            Header: intl.formatMessage({id: 'TABLE.TYPE', defaultMessage: 'Type'}),
            accessor: 'type',
            className: 'min-w-120px',
            filter: 'fuzzyText',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.PAIDON', defaultMessage: 'Paid on'}),
            accessor: 'paid',
            className: 'min-w-120px',
            filter: 'rangeDate',
            defaultCanSort: true,
            sortType: sortDateTime
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const location = useLocation()
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    /*const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setPageCount(10)
        }
    });*/
    const initialSort = useMemo( () => [ { id: "paid", desc: true } ],[])
    //useEffect(() => {
        //getAllTransactions().then(trans => {
            /*setExportData(trans.map(transaction => {
                const formatted = intl.formatNumber(transaction.amount,{style: 'currency', currency: transaction.currency??currentUser?.currency, currencyDisplay:'symbol'});
                let data = {
                    customer: transaction.customer?.name ?? transaction.name,
                    email: transaction.customer?.email ?? transaction.email,
                    //urlpath: transaction?.urlpath ?? null,
                    amount: formatted,
                    type: transaction.type,
                    note: typeof transaction.note === "undefined"||transaction.note.length < 1?"":transaction.note,
                    paid: transaction.createdAt
                }
                if(typeof transaction.customFields === "undefined" || transaction.customFields.length===0)
                    return data;
                let customFields = transaction.customFields;
                // @ts-ignore
                if('address' in customFields && typeof customFields.address !== "undefined") {
                    // @ts-ignore
                    const address = customFields.address;
                    // @ts-ignore
                    delete customFields.address;// @ts-ignore
                    return { ...data,...customFields,...address }
                }
                return { ...data, ...customFields }
            }));*/
            //setTransactions()
            /*setTimeout(()=>{
                setLoadingData(false)
                setRefreshing(false)
            },500)*/
        //});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [refreshData,location.key]);
    // const delay = (ms: number) => {
    //     return new Promise( resolve => setTimeout(resolve, ms) );
    // }
    const parser = (transactions:Array<TransactionsModel>) => (transactions.map(transaction => {
        return {
            customer: (
                <div className='d-flex justify-content-start flex-column'>
                    <Link to={typeof transaction.customer === "undefined"?'#':`/customer/${transaction.customer?.id}`} className='text-dark fw-bolder text-hover-primary fs-6'>
                        {transaction.customer?.name ?? transaction.name}
                    </Link>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                    {transaction.customer?.email ?? transaction.email}
                    </span>
                </div>
            ),
            amount: (
                <>
                        <span data-filter={transaction.amount} className='text-dark fw-bolder text-hover-primary fs-6'>
                <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                </span>
                    {typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && transaction.currency !== null && transaction.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                    <CurrencyFormat currency={currentUser.currency} amount={transaction.amountBase}/>
                </span>
                    )}
                </>
            ),
            _type: transaction.type,
            type: (
                <span className="badge badge-light-primary">
                        {transactionType(transaction.type)}
                </span>
            ),
            note: typeof transaction.note === "undefined"||transaction.note.length < 1?false:( <><strong>Note:</strong> {transaction.note}</> ),
            customFields: (typeof transaction.customFields === "undefined" || transaction.customFields.length < 1?false:(<>
                {Object.values(Object.fromEntries(Object.entries(transaction.customFields).map(([customFieldKey, customField]) => [
                    customFieldKey,
                    (typeof customField === "object")?
                        Object.values(Object.fromEntries(Object.entries(customField).map(([subFieldKey, subField]) => [subFieldKey,(<div className='py-2'><strong>{subFieldKey[0].toUpperCase() + subFieldKey.substring(1).toLowerCase()}: </strong>{subField}</div>)])))
                        :
                        (<div className='py-2'><strong>{customFieldKey[0].toUpperCase() + customFieldKey.substring(1).toLowerCase()}: </strong>{customField}</div>)
                ])))}
            </>)),
            urlpath: transaction.urlpath ?? null,
            invoice: transaction?.invoice?.file,
            paid: (
                <>
                        <span data-filter={transaction.createdAt} className='text-dark fw-bolder text-hover-primary fs-6'>
                    {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                    </span>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                    {intl.formatTime(transaction.createdAt,{hour12:false})}
                    </span>
                </>
            ),
            actions: false
        }
    }))
    return (
        <>
            <PageTitle breadcrumbs={transactionsBreadCrumbs}>{intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                    <div className='d-flex my-5 pt-5 w-100'>
                        <div className="d-flex align-items-center position-relative my-1">
                            {/*
                                 @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                            <input type="text"
                                   value={globalFilter}
                                   className="form-control form-control-solid w-250px ps-14"
                                   placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                   onChange={onChange}/>
                        </div>
                        <div className="d-flex align-items-center ms-auto position-relative my-1">
                            <Flatpickr
                                value={''}
                                onChange={([start, end], dateStr, instance) => {
                                    if(typeof end !== "undefined"){
                                        setLocalFilter({columnId:"paid",filter:[start.getTime(),end.getTime()]})
                                        instance.close()
                                    }
                                }}
                                className='form-control form-control-solid flatpickr-input'
                                placeholder={intl.formatMessage({id: 'DATE.RANGESELECT', defaultMessage: 'Select a date range'})}
                                options={{
                                    mode: "range",
                                    enableTime: false,
                                    onOpen: (selectedDates, dateStr, instance) => {

                                        if(document.querySelector('.flatpickr-predefined-ranges')!==null)
                                            return;
                                        instance.calendarContainer.classList.add('flatpickr-has-predefined-ranges')
                                        let buttons:JSX.Element[] = [];
                                        const ranges = {
                                            'Today': [new Date(), new Date()],
                                            "Yesterday": [sub(new Date(), {days: 1}), sub(new Date(), {days: 1})],
                                            'Last 30 Days': [sub(new Date(), {days: 29}), new Date()],
                                            'This Month': [startOfMonth(new Date()), new Date()],
                                            'Last Month': [
                                                startOfMonth(sub(new Date(), {months: 1})),
                                                endOfMonth(sub(new Date(), {months: 1}))
                                            ],
                                            'Year to Date': [
                                                startOfYear(new Date()),
                                                new Date()
                                            ],
                                            'Last Year': [
                                                startOfYear(sub(new Date(), {years: 1})),
                                                endOfYear(sub(new Date(), {years: 1}))
                                            ]
                                        }
                                        for (const [label, range] of Object.entries(ranges)) {
                                            buttons.push(<button onClick={event => { event.preventDefault(); instance.setDate([range[0],range[1]],true)}} className='nav-link btn btn-link' type='button'>{label}</button>)
                                        }
                                        const items = <>{buttons.map((button:JSX.Element) => <li className='nav-item d-grid' key={button.props.value}>{button}</li>)}</>
                                        const ulList = document.createElement('ul');
                                        ulList.classList.add('nav','flex-column','flatpickr-predefined-ranges')
                                        ReactDOM.render(items,ulList,() => {
                                            instance.calendarContainer.prepend(ulList)
                                        });
                                    },
                                }}
                            />
                            {/*<div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                {intl.formatMessage({id: 'GLOBAL.EXPORT', defaultMessage: 'Export to'})}
                            </div>
                            <div className='btn-group ms-5' role='group' aria-label='Export possibilities'>
                                <span className='btn btn-icon btn-outline border-primary btn-light-primary btn-lg' style={{pointerEvents: 'none'}}>

                                     @ts-ignore
                                    <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-down" />
                                </span>
                                <CSVLink data={exportData} className='btn btn-outline btn-outline-primary btn-active-color-white btn-sm'>
                                    CSV/Excel
                                </CSVLink>
                                <button className='btn btn-outline btn-outline-primary btn-active-color-white btn-sm' onClick={async ()=>{
                                    setPageCount(10000000)
                                    await delay(500);
                                    handlePrint()
                                }}>
                                    PDF/Print
                                </button>
                            </div>*/}
                            <div className="d-flex align-items-center ms-auto position-relative my-1">
                                <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                    {intl.formatMessage({id: 'GLOBAL.EXPORT', defaultMessage: 'Export to'})}
                                </div>
                                <div className='btn-group btn-group-sm ms-5' role='group' aria-label='Export possibilities'>
                                    <span className='btn btn-primary' style={{pointerEvents: 'none'}}>
                                    {/*
                                         @ts-ignore */}
                                        <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-down" />
                                    </span>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setCsv(!csv)}>
                                        CSV
                                    </button>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setPrint(!print)}>
                                        PDF/Print
                                    </button>
                                </div>
                            </div>
                            <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                            </div>
                            <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                                setRefreshing(true)
                                setRefreshData(!refreshData)
                                setTimeout(() => setRefreshing(false), 1000)
                            }}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <PrTable columns={columns} print={print} csvDownload={csv} dataFetcher={tableTransactions} parser={parser} invalidate={refreshData} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Transactions} tableType={'transactions'} sortee={initialSort}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}
