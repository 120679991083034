import React, {FC, useState} from "react";
import {ItemType} from "../../core/_models";
import PhoneInput from "react-phone-number-input";
import {E164Number} from "libphonenumber-js/core";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";

type itemProps = {
    item: ItemType
    index: number
}

const Telephone: FC<itemProps> = ({item, index}) => {
    const [phoneNumber, setPhoneNumber] = useState<E164Number|string>();
    return <PhoneInput
        placeholder={item?.general?.placeholder??''}
        value={phoneNumber}
        className="input-group  mb-1"
        numberInputProps={{
            className: "form-control",
            name: `telephone${index}`
        }}
        // @ts-ignore
        onChange={setPhoneNumber}/>
}

const Text:FC<itemProps> = ({item, index}) => {

    switch (item?.name.toLowerCase()) {
        default:
        case "text":
            return <input type="text" className='form-control mb-1' placeholder={item?.general?.placeholder??''} required={item?.general?.required??false} />
        case "number":
            return <input type="number" className='form-control mb-1' placeholder={item?.general?.placeholder??''} required={item?.general?.required??false} />
        case "email":
            return <input type="email" className='form-control mb-1' placeholder={item?.general?.placeholder??''} required={item?.general?.required??false} />
        case "telephone":
            return <Telephone item={item} index={index} />
        case "multi line":
            return <textarea className='form-control mb-1' placeholder={item?.general?.placeholder??''} required={item?.general?.required??false}></textarea>
        case "wysiwyg":
            return <TextEditor className='tiptapSmall mb-1' save={(description:string) => {  }} liveSave={false} content={''} />
    }
}

export {Text}