import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import React, {FC, useState} from "react";
import {useIntl} from "react-intl";
import {useAuth} from "../../auth";
import {KTSVG} from "../../../../_metronic/helpers";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";

const affiliateBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Affiliate',
        path: '/affiliate',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Overview: FC = () => {
    const intl = useIntl()

    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const {currentUser} = useAuth()
    return <>
        <PageTitle breadcrumbs={affiliateBreadCrumbs}>{intl.formatMessage({id: 'MENU.AFFILIATE', defaultMessage: 'Affiliate'})}</PageTitle>
        <div className="row gx-5 gx-xl-10">
            <div className="col-12 col-xxl-5 mb-5 mb-xl-8">
                <CopyToClipboard text={`${window.location.origin.toString()}/?ref=${currentUser?.id}`} onCopy={() => {setCopied1(true);setTimeout(()=>setCopied1(false),2000)}}>
                    <div className="btn btn-flush bg-white rounded-2 shadow-sm js-tooltip js-copy d-flex w-100">
                        <span className="py-4 flex-grow-1">{typeof currentUser?.id!=="undefined"?`${window.location.origin.toString()}/?ref=${currentUser?.id}`:<Skeleton width={'100%'} height={24} className='mw-350px'/>}</span>
                        <KTSVG path={`/media/icons/duotune/general/${copied1?'gen043':'gen054'}.svg`} className="p-4 bg-hover-light border-start border-gray-200 d-flex align-items-center me-0 rounded-end" />
                    </div>
                </CopyToClipboard>
            </div>
            <div className="col-12 col-xxl-2 mb-5 mb-xl-8">
                <div className="d-flex text-center align-center justify-center justify-content-center">
                    <span className="fs-4 fw-bolder py-2 ms-5 me-5">or</span>
                </div>
            </div>
            <div className="col-12 col-xxl-5 mb-5 mb-xl-8">
                <CopyToClipboard text={`https://payrequest.io/?ref=${currentUser?.id}`} onCopy={() => {setCopied2(true);setTimeout(()=>setCopied2(false),2000)}}>
                    <div className="btn btn-flush bg-white rounded-2 shadow-sm js-tooltip js-copy d-flex w-100">
                        <span className="py-4 flex-grow-1">{typeof currentUser?.id!=="undefined"?`https://payrequest.io/?ref=${currentUser?.id}`:<Skeleton width={'100%'} height={24} className='mw-350px'/>}</span>
                        <span
                            className="svg-icon p-4 bg-hover-light border-start border-gray-200 d-flex align-items-center me-0 rounded-end">
                            <KTSVG path={`/media/icons/duotune/general/${copied2 ? 'gen043' : 'gen054'}.svg`} className="p-4 bg-hover-light border-start border-gray-200 d-flex align-items-center me-0 rounded-end" />
                        </span>
                    </div>
                </CopyToClipboard>
            </div>
            <div className="col-12 mb-5 mb-xl-8">
                <div className="text-center py-4 text-muted fw-bold fs-7 bg-white rounded-2 shadow-sm">
                    Share this link with your clientele to make sure they sign in as a referral.
                </div>
            </div>
        </div>
        <div className="row gx-5 gx-xl-10">
            <div className="col-12">
                <div className="card card-flush h-xl-100">
                    <div className="card-header py-7 justify-content-center">
                        <div className="m-0">
                            <div className="d-flex align-items-center mb-2">
                                <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1 ls-n2">Get your first affiliate by sharing above links and let people sign in &amp; get paid!</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0 pb-10 text-center">
                        We can't help you with getting referrals... But maybe you have a friend, family member or
                        acquaintance who would benefit by using PayRequest?
                        <br/><br/>
                        Want statistics of your referrals? Those are coming <strong>VERY</strong> soon!
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Overview