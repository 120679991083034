import {Route, Routes, Outlet} from 'react-router-dom'
import {Overview} from "./components/Overview";
import {LinkPage} from "./components/LinkPage";
import {NewPage} from "./components/NewPage";

const RequestsPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
            path='overview'
            element={<Overview />}
        />
        <Route
            path='new'
            element={<NewPage />}
        />
        <Route
            path='new/:type'
            element={<NewPage />}
        />
        <Route
            path=':link'
            element={<LinkPage />}
        />
        <Route
            index
            element={<Overview />}
        />
      </Route>
    </Routes>
  )
}

export default RequestsPage
