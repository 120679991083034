/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Crisp } from "crisp-sdk-web";

const QuickLinks: FC = () => {

  const intl = useIntl()

  return (
      <div
          className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
          data-kt-menu='true'
      >
        <div
            className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
            style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
        >
          <h3 className='text-white fw-bold mb-3'>{intl.formatMessage({id: 'QL.QUICKLINKS', defaultMessage: 'Quick Links'})}</h3>
        </div>

        <div className='row g-0'>
          <div className='col-6'>
            <a
                href='https://help.payrequest.io/'
                target='_blank'
                rel='noopener noreferrer'
                className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
            >
              {/*
              // @ts-ignore */}
              <FontAwesomeIcon icon="fa-duotone fa-books" className="text-gray-400 fs-3x mb-3" />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{intl.formatMessage({id: 'QL.DOCS', defaultMessage: 'Documentation'})}</span>
              <span className='fs-7 text-gray-400'>{intl.formatMessage({id: 'QL.FINDANSWERS', defaultMessage: 'Find answers'})}</span>
            </a>
          </div>

          <div className='col-6'>
            <a
                href='mailto:help@payrequest.io'
                className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
            >
              {/*
              // @ts-ignore */}
              <FontAwesomeIcon icon="fa-duotone fa-envelope-open-text" className="text-gray-400 fs-3x mb-3" />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{intl.formatMessage({id: 'QL.MAIL', defaultMessage: 'Mail us'})}</span>
              <span className='fs-7 text-gray-400'>{intl.formatMessage({id: 'QL.MAILRESPONSE', defaultMessage: 'Response within 12h'})}</span>
            </a>
          </div>
        </div>

        <div className='py-2 text-center border-top'>
          <a href='#' onClick={event => {event.preventDefault();Crisp.chat?.show()}} className='btn btn-color-gray-600 btn-active-color-primary'>
            {intl.formatMessage({id: 'QL.LIVECHAT', defaultMessage: 'Start live chat'})} <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
          </a>
        </div>
      </div>
  )
}

export {QuickLinks}
