import ReactDOM from 'react-dom'
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import { PostHogProvider} from 'posthog-js/react'
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()


const options = {
    api_host: 'https://eu.posthog.com',
}

ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <PostHogProvider
                apiKey={'phc_hkXdw5ysISDkm3gWmHtqknTfUy1RMw2fhuyra7pLX4z'}
                options={options}
            >
                <MetronicI18nProvider>
                  <AuthProvider>
                    <AppRoutes />
                  </AuthProvider>
                </MetronicI18nProvider>
            </PostHogProvider>
        </QueryClientProvider>,
  document.getElementById('root')
)
