/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CurrencyIcon} from "./icon";
import {useIntl} from "react-intl";

interface ICurrencyFormat {
    currency:string|null|undefined
    amount:number
    formatAmount?:boolean|undefined
    className?:string|undefined
    currencyClassName?:string|undefined
}

function CurrencyFormat(props:ICurrencyFormat) {
    const currency = props.currency;
    const intl = useIntl()
    const amount = props.amount;
    const format = typeof props.formatAmount === "undefined" || !props.formatAmount?{}:{style: 'currency', currency: currency, currencyDisplay:'code'};
    // @ts-ignore
    let formatted = intl.formatNumber(amount,format);
    // remove the currency since we use our own icons. However, we DO want to use intl.formatNumber
    if(typeof props.formatAmount!=="undefined"&&props.formatAmount)
        formatted = formatted.substring(4);
    return (<>
        {props.currencyClassName?<span className={props.currencyClassName}><CurrencyIcon currency={currency}/></span>:<CurrencyIcon currency={currency}/>}
        <span className={typeof props.className === "undefined"?'fw-bold':props.className}>{formatted}</span>
        </>);
}

export {CurrencyFormat,CurrencyIcon};