import {Route, Routes, Outlet} from 'react-router-dom'
import {Overview} from "./components/Overview";

const DesignPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
            index
            element={<Overview />}
        />
      </Route>
    </Routes>
  )
}

export default DesignPage
