import {OverlayTrigger, Tooltip} from "react-bootstrap";
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {Row, UseExpandedRowProps} from "react-table";
import {useIntl} from "react-intl";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {doDuplicateProduct, statusChangeProduct} from "../../../../../app/modules/products/core/_requests";
import {KTSVG} from "../../../../helpers";
import {doDuplicateTax, statusChangeTax} from "../../../../../app/modules/tax/core/_requests";

interface iContent {
    prepareRow: Function,
    row: Row & UseExpandedRowProps<any>,
    rawdata: any[],
    reloadTrigger?: Function
}
export const Rates:React.FC<iContent> = ({prepareRow, row, rawdata, reloadTrigger}) => {
    /*const intl = useIntl()*/
    prepareRow(row)
    /*const navigate = useNavigate();
    const raw = rawdata[parseInt(row.id)]*/
    return (
        <tr {...row.getRowProps()} key={`row${row.id}`}>
            {row.cells.map(cell => (<td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>{cell.render('Cell')}</td>))}
        </tr>
    )
};