/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Home'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.INFORMATION', defaultMessage: 'E-Commerce'})}</span>
        </div>
      </div>
        <AsideMenuItem
            to='/customers/'
            title={intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}
            fontIcon='fa-users'
            icon='fa-users'
        />
                <AsideMenuItem
            to='/products'
            title={intl.formatMessage({id: 'MENU.PRODUCTS', defaultMessage: 'Products'})}
            fontIcon='fa-box-open-full'
            icon='fa-box-open-full'
        />
        <AsideMenuItem
            to='/subscriptions'
            title={intl.formatMessage({id: 'MENU.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}
            fontIcon='fa-arrows-repeat'
            icon='fa-arrows-repeat'
        />
                <AsideMenuItem
            to='/personal'
            title={intl.formatMessage({id: 'MENU.PERSONALPAGE', defaultMessage: 'Personal Page'})}
            fontIcon='fa-square-user'
            icon='fa-square-user'
            />

<AsideMenuItem
            to='/design'
            title={intl.formatMessage({id: 'MENU.DESIGN', defaultMessage: 'Design'})}
            fontIcon='fa-palette'
            icon='fa-palette'
        />

        <div className='menu-item'>
            <div className='menu-content'>
                <div className='separator mx-1 my-2'></div>
            </div>
        </div>

        <div className='menu-item'>
            <div className='menu-content pt-4 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.PAYMENTOPTIONS', defaultMessage: 'Payments'})}</span>
            </div>
        </div>

        <AsideMenuItem
            to='/transactions'
            title={intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}
            fontIcon='fa-thin fa-cash-register'
            icon='fa-thin fa-cash-register'
        />
        <AsideMenuItem
            to='/requests'
            title={intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
            fontIcon='fa-thin fa-folder-open'
            icon='fa-thin fa-folder-open'
        />
                <AsideMenuItem
            to='/tax'
            title={intl.formatMessage({id: 'MENU.TAX', defaultMessage: 'Tax'})}
            fontIcon='fa-thin fa-percent'
            icon='fa-thin fa-percent'
        />

        <AsideMenuItem
            to='/extrafields'
            title={intl.formatMessage({id: 'MENU.EXTRAFIELDS', defaultMessage: 'Extra Fields'})}
            fontIcon='fa-thin fa-input-text'
            icon='fa-thin fa-input-text'
        />

        <AsideMenuItem
            to='/integration/overview'
            title={intl.formatMessage({id: 'MENU.INTEGRATIONS', defaultMessage: 'Payment Integrations'})}
            fontIcon='fa-sharp fa-thin fa-link-horizontal'
            icon='fa-sharp fa-thin fa-link-horizontal'
        />
    

    </>
  )
}
