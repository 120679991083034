import React, {FC, useEffect, useMemo, useRef, useState} from 'react'

import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import { useAuth} from "../../auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PrTable, IlocalFilter} from "../../../../_metronic/partials/widgets/tables/PrTable";
import Skeleton from "react-loading-skeleton";
import {Customers} from "../../../../_metronic/partials/widgets/tables/content/customers";
import {Customers as CustomerModels} from "../../auth/core/_models";
import {useNavigate} from "react-router-dom";
import {CustomerOverview, getCustomerOverview, tableCustomerOverview} from "../../auth/core/_requests";

const customersBreadCrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Customers',
        path: '/customers',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Overview: FC = () => {
    const intl = useIntl();
    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [customers, setCustomers] = useState<Array<any>>();
    const [print, setPrint] = useState(false);
    const [csv, setCsv] = useState(false);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: Date = new Date(a.original[columnId].props.children.props['data-filter']);
            const bv: Date = new Date(b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-150px',
            filter: 'fuzzyText',
        },{
            Header: intl.formatMessage({id: 'TABLE.EMAIL', defaultMessage: 'Email'}),
            accessor: 'email',
            className: 'min-w-150px',
            filter: 'fuzzyText',
        },{
            Header: intl.formatMessage({id: 'TABLE.LINKS', defaultMessage: 'Links'}),
            accessor: 'links',
            className: 'min-w-60px',
            filter: 'fuzzyText',
        },{
            Header: intl.formatMessage({id: 'TABLE.CREATEDAT', defaultMessage: 'Created At'}),
            accessor: 'createdAt',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            sortType: sortDateTime,
            defaultCanSort: true,
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const parser = (customers:Array<CustomerOverview>) => customers.map(customer => {
        return {
            id: customer.id,
            name: (
                <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {customer.name}
                        </span>
                </div>
            ),
            email: (
                <div className='d-flex justify-content-start flex-column'>
                    <a
                        href={`mailto:${customer.email}`}
                        className='text-dark fw-bolder text-hover-primary fs-6'>
                        {customer.email}
                    </a>
                </div>
            ),
            links: (
                <div className='d-flex justify-content-start flex-row'>
                        <span
                            data-bs-toggle='tooltip'
                            data-bs-boundary='window'
                            data-bs-placement='top'
                            title={"Paid"}
                            className='text-dark fw-bolder text-hover-primary fs-6 p-3 pe-0'>
                            {customer.paid} /
                        </span>
                    <span
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title={"Total"}
                        className='text-muted fw-bold text-muted d-block fs-7 p-3 ps-1'>
                            {customer.total}
                        </span>
                </div>
            ),
            createdAt: (
                <>
                        <span data-filter={customer.createdAt} className="badge badge-light-primary">
                            {intl.formatDate(customer.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                        </span>
                </>
            ),
            actions: false
        }
    }).reverse()
    const initialSort = useMemo( () => [ { id: "name", desc: false } ],[])
    return (
        <>
            <PageTitle breadcrumbs={customersBreadCrumbs}>{intl.formatMessage({id: 'MENU.CUSTOMERS', defaultMessage: 'Customers'})}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                        <div className='d-flex align-items-center my-5 pt-5 w-100'>
                            <div className="d-flex align-items-center position-relative my-1">
                                {/*
                                     @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                                <input type="text"
                                       value={globalFilter}
                                       className="form-control form-control-solid w-250px ps-14"
                                       placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                       onChange={onChange}/>
                            </div>
                            <div className="d-flex align-items-center ms-auto position-relative my-1">
                                <button className='btn btn-light-primary' onClick={e=>{e.preventDefault();navigate('/customer/new')}}>
                                    {/*
                                     @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-plus" className='fs-3 pe-3' />
                                    Add Customer
                                </button>
                            </div>
                            <div className="d-flex align-items-center ms-auto position-relative my-1">
                                <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                    {intl.formatMessage({id: 'GLOBAL.EXPORT', defaultMessage: 'Export to'})}
                                </div>
                                <div className='btn-group btn-group-sm ms-5' role='group' aria-label='Export possibilities'>
                                        <span className='btn btn-primary' style={{pointerEvents: 'none'}}>
                                        {/*
                                             @ts-ignore */}
                                            <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-down" />
                                        </span>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setCsv(!csv)}>
                                        CSV
                                    </button>
                                    <button className='btn btn-light-primary btn-outline border-primary' onClick={()=>setPrint(!print)}>
                                        PDF/Print
                                    </button>
                                </div>
                            </div>
                            <div className='ms-5' style={{whiteSpace: 'nowrap'}}>
                                {intl.formatMessage({id: 'GLOBAL.REFRESH', defaultMessage: 'Refresh Data'})}
                            </div>
                            <button className="btn btn-icon btn-light-primary btn-lg ms-5 px-4" onClick={() => {
                                setRefreshing(true)
                                setRefreshData(!refreshData)
                                setTimeout(() => setRefreshing(false), 1000)
                            }}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-arrows-rotate" className={refreshing?'fa-spin':''} />
                            </button>
                        </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <PrTable columns={columns} print={print} csvDownload={csv} dataFetcher={tableCustomerOverview} parser={parser} sortee={initialSort} globalFilter={globalFilter} invalidate={refreshData} localFilter={localFilter} pageSize={pageCount} TableContent={Customers} tableType={'customers'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Overview}