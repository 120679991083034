import React, {useEffect, useState} from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useAuth} from "../../../../app/modules/auth";
import {updateTestmode} from "../../../../app/modules/auth/core/_requests";
import {KTSVG, toAbsoluteUrl} from "../../../helpers";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {Tooltip} from "react-bootstrap";

export function MenuInner() {
    const [testmode, setTestmode] = useState(false);
    const intl = useIntl()
    const {currentUser} = useAuth()
    useEffect(() => {
        setTestmode(typeof currentUser === 'undefined'?false:currentUser?.testmode);
    }, [currentUser]);

    return (
    <>
      {/*<MenuItem title={intl.formatMessage({id: 'MENU.CREATELINK', defaultMessage: 'Create Link'})} to='/request/new' />*/}
        {/* begin::Menu */}
          <button type="button"
                  className='btn btn-active-light-primary btn-custom fs-base text-gray-700'
                  data-kt-menu-trigger='click'
                  /*data-kt-menu-attach='parent'*/
                  data-kt-menu-placement="bottom-start"
                  data-kt-menu-flip='top'
          >
              {/*
                    @ts-ignore */}
              <FontAwesomeIcon icon="fa-duotone fa-plus" className='me-3' />
              Create
          </button>

          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-700px w-lg-700px p-10' data-kt-menu='true'>


                        <div className="row">


                        <div className="col-lg-6 mb-3">

                        <div className="menu-item p-0 m-0">
                            <a href="/request/new/paymentlink" className="menu-link">
                                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">

                             
                          <svg xmlns="http://www.w3.org/2000/svg" width="100.08" height="72.016" viewBox="0 0 100.08 72.016">
                              <defs>
                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                  <stop offset="0" stopColor="#004493"/>
                                  <stop offset="1" stopColor="#20abbc"/>
                                </linearGradient>
                              </defs>
                              <g id="Group_24" data-name="Group 24" transform="translate(-387.073 -222.059)">
                                <g id="Group_22" data-name="Group 22">
                                  <rect id="Rectangle_6" data-name="Rectangle 6" width="83.805" height="56.306" rx="6.333" transform="translate(387.573 237.269)" stroke="#707070" strokeMiterlimit="10" strokeWidth="1" fill="url(#linear-gradient)"/>
                                  <g id="Group_21" data-name="Group 21">
                                    <line id="Line_25" data-name="Line 25" x2="65.855" transform="translate(394.96 272.976)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                    <line id="Line_26" data-name="Line 26" x2="19.314" transform="translate(394.96 282.772)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                    <line id="Line_27" data-name="Line 27" x2="19.314" transform="translate(419.818 282.772)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                  </g>
                                  <rect id="Rectangle_7" data-name="Rectangle 7" width="12.679" height="8.519" rx="0.775" transform="translate(394.96 243.138)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                </g>
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="14.299" cy="14.299" r="14.299" transform="translate(457.555 223.059)" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" fill="url(#linear-gradient)"/>
                                <g id="Group_23" data-name="Group 23">
                                  <path id="Path_14" data-name="Path 14" d="M473.361,240.224a1.981,1.981,0,0,1-1.828,2.1h-7.906c-1.009,0-2.577-.582-2.577-3.314s1.568-3.314,2.577-3.314h7.906a1.981,1.981,0,0,1,1.828,2.1" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                  <path id="Path_15" data-name="Path 15" d="M470.347,234.491a1.981,1.981,0,0,1,1.827-2.1h7.907c1.009,0,2.577.583,2.577,3.314s-1.568,3.315-2.577,3.315h-7.907a1.981,1.981,0,0,1-1.827-2.1" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                </g>
                              </g>
                            </svg>
                                                                </span>

                                <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">Payment Link</span>
                                    <span className="fs-7 fw-semibold text-muted">Create and send a normal static payment link</span>
                                </span>
                            </a>
                        </div>

                        </div>

                       

                        <div className="col-lg-6 mb-3">
                        <div className="menu-item p-0 m-0">
                            <a href="/request/new/donation" className="menu-link">
                                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="87.693" height="70.548" viewBox="0 0 87.693 70.548">
                          <defs>
                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                              <stop offset="0" stopColor="#004493"/>
                              <stop offset="1" stopColor="#20abbc"/>
                            </linearGradient>
                          </defs>
                          <g id="Group_13" data-name="Group 13" transform="translate(-394.924 -429.271)">
                            <path id="Path_3" data-name="Path 3" d="M467.821,458.742c.337-4.262,9.161-4.754,9.161-9.809,0-3.789-6.1-2.75-5.692,1.813.233,2.582,6.919,4.582,9.913,1.71" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                            <path id="Path_4" data-name="Path 4" d="M416.089,447.333c9.38-4.444,26.766-10,42.317-1.631,11.493,4.593,25.809,26.808,4.582,39.643-1.975,1.975-.494,10.861-1.481,12.342-1.481,1.481-11.849,1.481-11.849,0v-7.4c0-2.567-16.291-1.481-16.784,0-.841,2.522.493,6.911-.988,7.9-2.468,1.481-8.392.987-10.86.493-2.963-1.481,1.579-8.294-.988-10.86s-12.243-5.826-14.81-8.393-4.839-.987-7.4-.987-4.146-14.81.987-14.81c7.4.987,5.924-3.95,10.833-7.656,0-2.566-5.08-6.512-7.377-9.129C401.108,445.507,407.2,442.889,416.089,447.333Z" stroke="#707070" strokeMiterlimit="10" strokeWidth="1" fill="url(#linear-gradient)"/>
                            <g id="Group_12" data-name="Group 12">
                              <g id="Path_5" data-name="Path 5" strokeMiterlimit="10" fill="url(#linear-gradient)">
                                <path d="M 440.0075073242188 458.6028442382812 L 427.59033203125 445.1806335449219 C 426.2421875 443.5525512695312 425.5 441.4921875 425.5 439.3760070800781 C 425.5 434.3554992675781 429.5845031738281 430.27099609375 434.6050109863281 430.27099609375 C 436.5513305664062 430.27099609375 438.4090576171875 430.8778381347656 439.977294921875 432.02587890625 L 440.5681762695312 432.4584350585938 L 441.1589050292969 432.0257263183594 C 442.7261047363281 430.8777770996094 444.5834045410156 430.27099609375 446.5299987792969 430.27099609375 C 451.550537109375 430.27099609375 455.635009765625 434.3554992675781 455.635009765625 439.3760070800781 C 455.635009765625 442.0046081542969 454.5174255371094 444.34716796875 452.1175842285156 446.7482299804688 L 440.0075073242188 458.6028442382812 Z" stroke="none"/>
                                <path d="M 434.6050109863281 431.27099609375 C 430.1358947753906 431.27099609375 426.5 434.9068908691406 426.5 439.3760070800781 C 426.5 441.2508544921875 427.154296875 443.0763244628906 428.343505859375 444.522216796875 L 440.0430297851562 457.1686706542969 L 451.4145812988281 446.0370178222656 C 453.6117248535156 443.8372802734375 454.635009765625 441.7205810546875 454.635009765625 439.3760070800781 C 454.635009765625 434.9068908691406 450.9991149902344 431.27099609375 446.5299987792969 431.27099609375 C 444.79736328125 431.27099609375 443.1444396972656 431.8109436035156 441.7498474121094 432.8324584960938 L 440.568359375 433.6978759765625 L 439.3865966796875 432.8327941894531 C 437.9909057617188 431.8110656738281 436.3374633789062 431.27099609375 434.6050109863281 431.27099609375 M 434.6050109863281 429.27099609375 C 436.8359985351562 429.27099609375 438.89599609375 429.9949951171875 440.5679931640625 431.218994140625 C 442.239013671875 429.9949951171875 444.2999877929688 429.27099609375 446.5299987792969 429.27099609375 C 452.1109924316406 429.27099609375 456.635009765625 433.7950134277344 456.635009765625 439.3760070800781 C 456.635009765625 442.7539978027344 454.9400024414062 445.3399963378906 452.8210144042969 447.4590148925781 L 439.9719848632812 460.0369873046875 L 426.8380126953125 445.8399963378906 C 425.3789978027344 444.0880126953125 424.5 441.8349914550781 424.5 439.3760070800781 C 424.5 433.7950134277344 429.0239868164062 429.27099609375 434.6050109863281 429.27099609375 Z" stroke="none" fill="#fff"/>
                              </g>
                              <g id="Group_11" data-name="Group 11">
                                <line id="Line_15" data-name="Line 15" y1="1.226" transform="translate(440.3 436.689)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                <line id="Line_16" data-name="Line 16" y1="1.226" transform="translate(440.183 448.032)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                <g id="Group_10" data-name="Group 10">
                                  <path id="Path_6" data-name="Path 6" d="M438.12,448.137a4.713,4.713,0,0,1-1.734-.953l.556-1.171a4.59,4.59,0,0,0,1.538.878,5.652,5.652,0,0,0,1.928.338,3.473,3.473,0,0,0,1.951-.443,1.365,1.365,0,0,0,.645-1.178,1.161,1.161,0,0,0-.352-.878,2.368,2.368,0,0,0-.871-.518q-.518-.18-1.463-.4a15.084,15.084,0,0,1-1.913-.57,3.157,3.157,0,0,1-1.246-.878,2.345,2.345,0,0,1-.517-1.6,2.708,2.708,0,0,1,.442-1.516,3.041,3.041,0,0,1,1.343-1.08,5.454,5.454,0,0,1,2.236-.4,7.074,7.074,0,0,1,1.831.24,4.875,4.875,0,0,1,1.546.69l-.495,1.2a5.454,5.454,0,0,0-1.411-.638,5.276,5.276,0,0,0-1.471-.218,3.241,3.241,0,0,0-1.913.465,1.428,1.428,0,0,0-.638,1.2,1.146,1.146,0,0,0,.361.878,2.487,2.487,0,0,0,.892.525,13.946,13.946,0,0,0,1.448.4,14.63,14.63,0,0,1,1.906.57,3.209,3.209,0,0,1,1.238.87,2.291,2.291,0,0,1,.518,1.576,2.683,2.683,0,0,1-.45,1.508,3.019,3.019,0,0,1-1.366,1.081,5.648,5.648,0,0,1-2.251.4A7.189,7.189,0,0,1,438.12,448.137Z" fill="#fff"/>
                                </g>
                              </g>
                            </g>
                            <path id="Path_7" data-name="Path 7" d="M429.171,457.7s12.412,3.1,21.722,0" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                          </g>
                        </svg>
                                                                </span>

                                <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">Donation Page</span>
                                    <span className="fs-7 fw-semibold text-muted">Accept donations online with an real-time donation feed</span>
                                </span>
                            </a>
                        </div>  </div>


                        <div className="col-lg-6 mb-3">
                        <div className="menu-item p-0 m-0">
                            <a href="/request/new/product" className="menu-link">
                                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="82.596" height="71.94" viewBox="0 0 82.596 71.94">
                        <defs>
                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#004493"/>
                        <stop offset="1" stopColor="#20abbc"/>
                        </linearGradient>
                        </defs>
                        <g id="Group_41" data-name="Group 41" transform="translate(-582.475 -222.635)">
                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="25.117" cy="25.117" r="25.117" transform="translate(593.271 233.472)" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" fill="url(#linear-gradient)"/>
                        <g id="Group_38" data-name="Group 38">
                        <path id="Path_144" data-name="Path 144" d="M653.261,255.7l7.165-.439a3.14,3.14,0,0,1,3.213,1.9,3.092,3.092,0,0,1-.471,3.725l-12.517,14.153a3.372,3.372,0,0,1-4.777.293l-14.152-12.518a3.09,3.09,0,0,1-.923-3.639,3.139,3.139,0,0,1,2.957-2.279l8.359-.513a23.541,23.541,0,0,0-7.836-15.4,22.763,22.763,0,0,0-17.152-6.041,22.426,22.426,0,0,0-16.484,8.1,23.307,23.307,0,0,0-5.795,17.087,22.615,22.615,0,0,0,7.938,16.244,22.866,22.866,0,0,0,12.037,5.665,25.807,25.807,0,0,0,3.61.268c1.1,0,1.531,1.681,1.6,2.742l.488,5.678c.073,1.194.153,2.843-1.694,2.843q-1.857.018-3.65-.135a34.937,34.937,0,0,1-22.887-57.971,34.893,34.893,0,0,1,49.359-3.026A33.349,33.349,0,0,1,653.261,255.7Z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" fill="url(#linear-gradient)"/>
                        </g>
                        <g id="Group_40" data-name="Group 40">
                        <line id="Line_28" data-name="Line 28" y1="2.187" transform="translate(618.154 247.382)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                        <line id="Line_29" data-name="Line 29" y1="2.187" transform="translate(617.946 267.61)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                        <g id="Group_39" data-name="Group 39">
                        <path id="Path_145" data-name="Path 145" d="M614.267,267.8a8.389,8.389,0,0,1-3.091-1.7l.99-2.087a8.376,8.376,0,0,0,2.963,1.641,10.106,10.106,0,0,0,3.318.526,6.082,6.082,0,0,0,3.38-.79,2.433,2.433,0,0,0,1.15-2.1,2.069,2.069,0,0,0-.629-1.566,4.2,4.2,0,0,0-1.552-.923q-.922-.321-2.609-.722a26.885,26.885,0,0,1-3.412-1.017,5.644,5.644,0,0,1-2.221-1.566,4.181,4.181,0,0,1-.923-2.85,4.812,4.812,0,0,1,.79-2.7,5.408,5.408,0,0,1,2.394-1.927,9.714,9.714,0,0,1,3.988-.723,12.642,12.642,0,0,1,3.265.428,8.7,8.7,0,0,1,2.756,1.231l-.883,2.141a9.718,9.718,0,0,0-2.516-1.137,9.375,9.375,0,0,0-2.622-.388,5.778,5.778,0,0,0-3.412.829,2.543,2.543,0,0,0-1.138,2.141,2.048,2.048,0,0,0,.643,1.566,4.437,4.437,0,0,0,1.592.936,24.435,24.435,0,0,0,2.582.709,26.485,26.485,0,0,1,3.4,1.017,5.726,5.726,0,0,1,2.207,1.552,4.087,4.087,0,0,1,.924,2.81,4.786,4.786,0,0,1-.8,2.69,5.377,5.377,0,0,1-2.435,1.927,10.088,10.088,0,0,1-4.014.709A12.79,12.79,0,0,1,614.267,267.8Z" fill="#fff"/>
                        </g>
                        </g>
                        </g>
                        </svg>
                                                                </span>

                                <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">Product</span>
                                    <span className="fs-7 fw-semibold text-muted">Create a simple or subscription product</span>
                                </span>
                            </a>
                        </div>  </div>



                        <div className="col-lg-6 mb-3">
                        <div className="menu-item p-0 m-0">
                            <a href="/request/new/preauth" className="menu-link">
                                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62.625" height="72.123" viewBox="0 0 62.625 72.123">
                          <defs>
                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                              <stop offset="0" stopColor="#004493"/>
                              <stop offset="1" stopColor="#20abbc"/>
                            </linearGradient>
                          </defs>
                          <g id="Group_9" data-name="Group 9" transform="translate(-206.103 -628.702)">
                            <path id="Path_1" data-name="Path 1" d="M214.707,656.066v7.59h33.686V645.022c0-8.225-7.541-14.32-16.843-14.32s-16.843,6.6-16.843,13.747" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4"/>
                            <path id="Rectangle_2" data-name="Rectangle 2" d="M0,0H50.894a0,0,0,0,1,0,0V32.3a6.333,6.333,0,0,1-6.333,6.333H6.333A6.333,6.333,0,0,1,0,32.3V0A0,0,0,0,1,0,0Z" transform="translate(206.103 662.191)" fill="url(#linear-gradient)"/>
                            <path id="Path_2" data-name="Path 2" d="M228.635,684.99a3.57,3.57,0,1,0,5.83,0,4.943,4.943,0,1,0-5.83,0Z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" fill="url(#linear-gradient)"/>
                            <g id="Group_8" data-name="Group 8">
                              <rect id="Rectangle_3" data-name="Rectangle 3" width="23.834" height="16.014" rx="2.721" transform="translate(244.519 683.207)" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75" fill="url(#linear-gradient)"/>
                              <g id="Group_7" data-name="Group 7">
                                <line id="Line_12" data-name="Line 12" x2="18.729" transform="translate(246.619 693.362)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.75"/>
                                <line id="Line_13" data-name="Line 13" x2="5.493" transform="translate(246.619 696.148)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.75"/>
                                <line id="Line_14" data-name="Line 14" x2="5.493" transform="translate(253.689 696.148)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.75"/>
                              </g>
                              <rect id="Rectangle_4" data-name="Rectangle 4" width="3.606" height="2.423" rx="0.224" transform="translate(246.619 684.876)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
                            </g>
                          </g>
                        </svg>
                                                                </span>

                                <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">Pre Authorization</span>
                                    <span className="fs-7 fw-semibold text-muted">Create a security deposit payment link</span>
                                </span>
                            </a>
                        </div>  </div>


                        <div className="col-lg-6 mb-3">
                        <div className="menu-item p-0 m-0">
                            <a href="/request/new/dynamic" className="menu-link">
                                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100.08" height="72.016" viewBox="0 0 100.08 72.016">
                              <defs>
                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                  <stop offset="0" stopColor="#004493"/>
                                  <stop offset="1" stopColor="#20abbc"/>
                                </linearGradient>
                              </defs>
                              <g id="Group_24" data-name="Group 24" transform="translate(-387.073 -222.059)">
                                <g id="Group_22" data-name="Group 22">
                                  <rect id="Rectangle_6" data-name="Rectangle 6" width="83.805" height="56.306" rx="6.333" transform="translate(387.573 237.269)" stroke="#707070" strokeMiterlimit="10" strokeWidth="1" fill="url(#linear-gradient)"/>
                                  <g id="Group_21" data-name="Group 21">
                                    <line id="Line_25" data-name="Line 25" x2="65.855" transform="translate(394.96 272.976)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                    <line id="Line_26" data-name="Line 26" x2="19.314" transform="translate(394.96 282.772)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                    <line id="Line_27" data-name="Line 27" x2="19.314" transform="translate(419.818 282.772)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                  </g>
                                  <rect id="Rectangle_7" data-name="Rectangle 7" width="12.679" height="8.519" rx="0.775" transform="translate(394.96 243.138)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                </g>
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="14.299" cy="14.299" r="14.299" transform="translate(457.555 223.059)" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" fill="url(#linear-gradient)"/>
                                <g id="Group_23" data-name="Group 23">
                                  <path id="Path_14" data-name="Path 14" d="M473.361,240.224a1.981,1.981,0,0,1-1.828,2.1h-7.906c-1.009,0-2.577-.582-2.577-3.314s1.568-3.314,2.577-3.314h7.906a1.981,1.981,0,0,1,1.828,2.1" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                  <path id="Path_15" data-name="Path 15" d="M470.347,234.491a1.981,1.981,0,0,1,1.827-2.1h7.907c1.009,0,2.577.583,2.577,3.314s-1.568,3.315-2.577,3.315h-7.907a1.981,1.981,0,0,1-1.827-2.1" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                </g>
                              </g>
                            </svg>
                                                                </span>

                                <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">Dynamic Payment Link</span>
                                    <span className="fs-7 fw-semibold text-muted">Create a dynamic payment link in seconds!</span>
                                </span>
                            </a>
                        </div>  </div>


                        <div className="col-lg-6 mb-3">
                        <div className="menu-item p-0 m-0">
                            <a href="/request/new/qr" className="menu-link">
                                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100.08" height="72.016" viewBox="0 0 100.08 72.016">
                              <defs>
                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                  <stop offset="0" stopColor="#004493"/>
                                  <stop offset="1" stopColor="#20abbc"/>
                                </linearGradient>
                              </defs>
                              <g id="Group_24" data-name="Group 24" transform="translate(-387.073 -222.059)">
                                <g id="Group_22" data-name="Group 22">
                                  <rect id="Rectangle_6" data-name="Rectangle 6" width="83.805" height="56.306" rx="6.333" transform="translate(387.573 237.269)" stroke="#707070" strokeMiterlimit="10" strokeWidth="1" fill="url(#linear-gradient)"/>
                                  <g id="Group_21" data-name="Group 21">
                                    <line id="Line_25" data-name="Line 25" x2="65.855" transform="translate(394.96 272.976)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                    <line id="Line_26" data-name="Line 26" x2="19.314" transform="translate(394.96 282.772)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                    <line id="Line_27" data-name="Line 27" x2="19.314" transform="translate(419.818 282.772)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                                  </g>
                                  <rect id="Rectangle_7" data-name="Rectangle 7" width="12.679" height="8.519" rx="0.775" transform="translate(394.96 243.138)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                </g>
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="14.299" cy="14.299" r="14.299" transform="translate(457.555 223.059)" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" fill="url(#linear-gradient)"/>
                                <g id="Group_23" data-name="Group 23">
                                  <path id="Path_14" data-name="Path 14" d="M473.361,240.224a1.981,1.981,0,0,1-1.828,2.1h-7.906c-1.009,0-2.577-.582-2.577-3.314s1.568-3.314,2.577-3.314h7.906a1.981,1.981,0,0,1,1.828,2.1" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                  <path id="Path_15" data-name="Path 15" d="M470.347,234.491a1.981,1.981,0,0,1,1.827-2.1h7.907c1.009,0,2.577.583,2.577,3.314s-1.568,3.315-2.577,3.315h-7.907a1.981,1.981,0,0,1-1.827-2.1" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                                </g>
                              </g>
                            </svg>
                                                                </span>

                                <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">QR Code Payment Request</span>
                                    <span className="fs-7 fw-semibold text-muted">Let users pay through your own QR code</span>
                                </span>
                            </a>
                        </div>  </div>
                      
                      
                      
                       </div>




              <div className={'d-flex flex-row align-content-stretch'}>
              </div>
          </div>
          {/* end::SubMenu */}
          {/* end::Menu */}
        <MenuItem icon={'/media/icons/duotune/communication/com013.svg'} title={intl.formatMessage({id: 'MENU.PERSONALPAGE', defaultMessage: 'Personal Page'})} to='/personal' />

        <div className="menu-item me-lg-1 my-3 mx-sm-3">
        <div className="form-check form-switch form-check-custom form-check-solid">
          <input className="form-check-input" type="checkbox" value="1" id="headerTestmode" checked={testmode} onChange={() => updateTestmode(!testmode).then(r => setTestmode(!testmode))}/>
          <label className="form-check-label" htmlFor="headerTestmode">
              {testmode?intl.formatMessage({id: 'MENU.TESTMODEENABLED', defaultMessage: 'Testmode is enabled'}):intl.formatMessage({id: 'MENU.TESTMODEDISABLED', defaultMessage: 'Testmode is disabled'})}
          </label>
        </div>
      </div>
    </>
    )
}
