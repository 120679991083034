/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useIntl} from "react-intl";
import {PaymentLinkTypes, Transactions, useAuth} from "../../../../app/modules/auth";
import {Blocker, isBlocked} from "../../../../app/modules/widgets/components/Blocker";
import {CurrencyFormat} from "../../../../app/modules/currency/components/format";
import ApexCharts, {ApexOptions} from "apexcharts";
import {getCSSVariableValue} from "../../../assets/ts/_utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {
    controller,
    getTransactionsByMonth,
    getTransactionsRange
} from "../../../../app/modules/dashboard/core/_requests";
import {currencyOptions} from "../../../../app/modules/currency/components/icon";

type Props = {
    className: string
    color: string
    image: string
}

const SalesSummary: React.FC<Props> = ({className, color, image}) => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [lastmtd, setLastmtd] = useState(0)
    const [mtd, setMtd] = useState(0)
    const [requests, setRequests] = useState(0);
    const [personal, setPersonal] = useState(0);
    const [products, setProducts] = useState(0);
    const [subscriptions, setSubscriptions] = useState(0);
    const [lrequests, setLrequests] = useState(0);
    const [lpersonal, setLpersonal] = useState(0);
    const [lproducts, setLproducts] = useState(0);
    const [lsubscriptions, setLsubscriptions] = useState(0);
    const chartRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        let loadCheck = 0
        const parseTransactions = (transactions: Array<Transactions>) => {
            const requests:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.NORMAL||transaction.type===PaymentLinkTypes.DONATION||transaction.type===PaymentLinkTypes.PREAUTH);
            const personal:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.PERSONAL);
            const products:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.PRODUCT);
            const subscriptions:Array<Transactions> = transactions.filter(transaction => transaction.type===PaymentLinkTypes.SUBSCRIPTION);
            return [requests,personal,products,subscriptions];
        }
        const chartOptions = (chartHeight: string, chartColor: string, transactions: Object): ApexOptions => {
            const monthLabels = Object.keys(transactions).map(month => intl.formatMessage({id: 'DATE.'+month.toUpperCase(), defaultMessage: month[0].toUpperCase() + month.substring(1).toLowerCase()}));
            // @ts-ignore
            const monthValues = Object.values(transactions).map(months => Object.values(months).map(month => parseFloat(month.amountBase)).reduce((prev,current) => prev+current,0));
            const labelColor = getCSSVariableValue('--bs-gray-800')
            const strokeColor = getCSSVariableValue('--bs-gray-300')
            const baseColor = getCSSVariableValue('--bs-' + chartColor)
            const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
            return {
                series: [
                    {
                        name: intl.formatMessage({id: "GLOBAL.PAID", defaultMessage:"Paid"}),
                        data: monthValues,
                    },
                ],
                chart: {
                    fontFamily: 'inherit',
                    type: 'area',
                    height: chartHeight,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {},
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: 'solid',
                    opacity: 1,
                },
                stroke: {
                    curve: 'smooth',
                    show: true,
                    width: 3,
                    colors: [baseColor],
                },
                xaxis: {
                    categories: monthLabels,
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        style: {
                            colors: labelColor,
                            fontSize: '12px',
                        },
                    },
                    crosshairs: {
                        show: false,
                        position: 'front',
                        stroke: {
                            color: strokeColor,
                            width: 1,
                            dashArray: 3,
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    logarithmic: true,
                    min: 0,
                    max: Math.max(...monthValues)*1.1,
                    labels: {
                        show: false,
                        style: {
                            colors: labelColor,
                            fontSize: '12px',
                        },
                    },
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: '12px',
                    },
                    y: {
                        formatter: val => intl.formatNumber(val,{style: 'currency', currency: currentUser?.currency ?? 'EUR', currencyDisplay:'symbol'}).toString(),
                    },
                },
                colors: [lightColor],
                markers: {
                    colors: [lightColor],
                    strokeColors: [baseColor],
                    strokeWidth: 3,
                },
            }
        }
        const now = new Date();
        const startOfLastMonth = new Date();
        startOfLastMonth.setMonth(startOfLastMonth.getMonth()-1);
        startOfLastMonth.setDate(1);
        startOfLastMonth.setHours(0, 0, 0);
        const lastOfLastMonth = new Date(startOfLastMonth);
        lastOfLastMonth.setMonth(lastOfLastMonth.getMonth() +1);
        lastOfLastMonth.setDate(0);
        lastOfLastMonth.setHours(23,59,59);
        const startOfMonth = new Date();
        startOfMonth.setDate(1);
        startOfMonth.setHours(0, 0, 0);
        getTransactionsRange(startOfLastMonth, lastOfLastMonth).then(transactions => {
            setLastmtd(transactions.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            const [_requests,_personal,_products,_subscriptions] = parseTransactions(transactions);
            setLrequests(_requests.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            setLpersonal(_personal.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            setLproducts(_products.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            setLsubscriptions(_subscriptions.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
            getTransactionsRange(startOfMonth, now).then(transactions => {
                setMtd(transactions.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                const [_requests,_personal,_products,_subscriptions] = parseTransactions(transactions);
                setRequests(_requests.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                setPersonal(_personal.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                setProducts(_products.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                setSubscriptions(_subscriptions.map(transaction => transaction.amountBase).reduce((prev,current)=>prev + current,0));
                loadCheck++
                if(loadCheck>1)
                    setLoadingData(false)
            });
        });

        getTransactionsByMonth(6).then(transactions => {
            if(chartRef.current===null)
                return
            const chart = new ApexCharts(chartRef.current, chartOptions('150px', 'primary', transactions))
            if (chart) {
                chart.render()
                loadCheck++
                if(loadCheck>1)
                    setLoadingData(false)
            }
        });
        /*return () => {
            if (chart) {
                chart.destroy()
            }
        }*/
        /*return () => {
            controller.abort()
        }*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef])
    return (
        <div className={isBlocked(loadingData, `card ${className}`)}>
            {/* begin::Body */}
            <div className='card-body p-0'>
                {/* begin::Header */}
                <div
                    className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
                    {/* begin::Heading */}
                    <div className='d-flex flex-stack'>
                        <h3 className='m-0 text-white fw-bolder fs-3'>
                            {intl.formatMessage({id: 'GLOBAL.SALES', defaultMessage: 'Sales Summary'})}
                        </h3>
                    </div>
                    {/* end::Heading */}
                    {/* begin::Balance */}
                    <div className='d-flex text-center flex-column text-white pt-6'>
                        <span className='fw-bold fs-7'>{intl.formatMessage({
                            id: 'GLOBAL.MTD',
                            defaultMessage: 'Month to date'
                        })}</span>
                        <span className='fw-bolder fs-2x pt-1'>
                            <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={mtd} formatAmount={true}/>
                            {lastmtd<mtd?(
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr066.svg'
                                    className='svg-icon-2x svg-icon-white ms-2'
                                />
                            ):(
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr065.svg'
                                    className='svg-icon-2x svg-icon-white ms-2'
                                />
                            )}
                        </span>
                        <span className='pt-1 fs-6'>
                            <span className='fs-7'>
                                {intl.formatMessage({
                                    id: 'GLOBAL.LMTD',
                                    defaultMessage: 'Last month'
                                })}:&nbsp;
                            </span>
                            <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={lastmtd} formatAmount={true}/>
                        </span>
                    </div>
                    {/* end::Balance */}
                </div>
                {/* end::Header */}
                {/* begin::Items */}
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                    style={{marginTop: '-120px'}}
                >
                    {/* begin::Item */}
                    <div className='d-flex align-items-center mb-6'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                              {/*
                              @ts-ignore */}
                              <FontAwesomeIcon icon="fa-duotone fa-globe-stand" className='fs-2' />
                          </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <Link to='/requests' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    {intl.formatMessage({id: 'MENU.REQUESTS', defaultMessage: 'Requests'})}
                                </Link>
                                <div className='text-gray-400 fw-bold fs-7'> {intl.formatMessage({id: 'MENU.TRANSACTIONS', defaultMessage: 'Transactions'})}</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center'>
                                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                    <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={requests} formatAmount={true}/>
                                </div>
                                {lrequests<=requests?(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr066.svg'
                                        className='svg-icon-5 svg-icon-success ms-1'
                                    />
                                ):(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr065.svg'
                                        className='svg-icon-5 svg-icon-danger ms-1'
                                    />
                                )}
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center mb-6'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-1'/>
                          </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    {intl.formatMessage({id: 'GLOBAL.PERSONAL', defaultMessage: 'Personal'})}
                                </a>
                                <div className='text-gray-400 fw-bold fs-7'>{intl.formatMessage({id: 'MENU.PERSONALPAGE', defaultMessage: 'Personal page'})}</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center'>
                                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                    <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={personal} formatAmount={true}/>
                                </div>
                                {lpersonal<=personal?(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr066.svg'
                                        className='svg-icon-5 svg-icon-success ms-1'
                                    />
                                ):(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr065.svg'
                                        className='svg-icon-5 svg-icon-danger ms-1'
                                    />
                                )}
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center mb-6'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                            <KTSVG path='/media/icons/duotune/electronics/elc005.svg' className='svg-icon-1'/>
                          </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    {intl.formatMessage({id: 'MENU.PRODUCTS', defaultMessage: 'Products'})}
                                </a>
                                <div className='text-gray-400 fw-bold fs-7'>
                                    {intl.formatMessage({id: 'PRODUCTS.SIMPLE', defaultMessage: 'Simple products'})}
                                </div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center'>
                                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                    <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={products} formatAmount={true}/>
                                </div>
                                {lproducts<=products?(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr066.svg'
                                        className='svg-icon-5 svg-icon-success ms-1'
                                    />
                                ):(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr065.svg'
                                        className='svg-icon-5 svg-icon-danger ms-1'
                                    />
                                )}
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='d-flex align-items-center'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                          <span className='symbol-label bg-lighten'>
                            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-1'/>
                          </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    {intl.formatMessage({id: 'PRODUCTS.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}
                                </a>
                                <div className='text-gray-400 fw-bold fs-7'>
                                    {intl.formatMessage({id: 'PRODUCTS.SUBSCRIPTIONS', defaultMessage: 'Subscriptions'})}
                                </div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center'>
                                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                    <CurrencyFormat currency={currentUser?.currency ?? 'EUR'} amount={subscriptions} formatAmount={true}/>
                                </div>
                                {lsubscriptions<=subscriptions?(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr066.svg'
                                        className='svg-icon-5 svg-icon-success ms-1'
                                    />
                                ):(
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr065.svg'
                                        className='svg-icon-5 svg-icon-danger ms-1'
                                    />
                                )}
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </div>
                    {/* end::Item */}
                </div>
                {/* end::Items */}

                <div style={{minHeight:150}}>
                    <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom'></div>
                </div>
                <Blocker state={loadingData}/>
            </div>
            {/* end::Body */}
        </div>
    )
}

export {SalesSummary}
