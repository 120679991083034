import {Route, Routes, Outlet} from 'react-router-dom'
import Overview from "./pages/Overview";
import Stripe from "./stripe/Stripe";
import Paypal from "./paypal/Paypal";
import Mollie from "./mollie/Mollie";


const IntegrationPage = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='overview'
                    element={<Overview />}
                />
                <Route
                    path='stripe/*'
                    element={<Stripe />}
                />
                <Route
                    path='paypal/*'
                    element={<Paypal />}
                />
                <Route
                    path='mollie/*'
                    element={<Mollie />}
                />
                <Route
                    index
                    element={<Overview />}
                />
            </Route>
        </Routes>
    )
}

export default IntegrationPage
