export const downloadBlob = (blob: Blob|MediaSource, filename: string) => {
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}
export const downloadDataUri = (dataUri: string, filename: string) => {
    //const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = dataUri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    //setTimeout(() => URL.revokeObjectURL(dataUri), 5000);
}